import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonCard,
  IonCardContent,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  getPlatforms,
  IonIcon,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
  IonToggle,
} from "@ionic/react";
import "./Tonespracticetwo.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import {
  chatboxOutline,
  chevronBack,
  close,
  notificationsOutline,
  options,
} from "ionicons/icons";
import CustomAudioPlayer from "./CustomAudioPlayer";
interface OwnProps extends RouteComponentProps {}
interface Tonespracticetwo extends OwnProps {}
const Tonespracticetwo: React.FC<Tonespracticetwo> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  let url: string = CONFIG.API_ENDPOINT; //live
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [myanswer, setmyanswer] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(
    null
  );
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>(
    []
  );
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [clickedSlides, setClickedSlides] = useState<number[]>([]);
  const [selected, setSelected] = useState<boolean>(false);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [length, setLength] = React.useState(-1);
  let item: any = [
    {
      EId: 2615,
      TemplateCode: "E4EV1",
      PageId: 79816,
      BookId: 14338,
      Options: [
        { Word: "ˇ", questionno: 1 },
        { Word: "–", questionno: 2 },
        { Word: "ˊ", questionno: 3 },
        { Word: "ˋ", questionno: 4 },
      ],
      Question: "./assets/IntroAudio/tonePractice_1.mp3",
      answer: "ˋ",
      score: false,
    },
    {
      EId: 2615,
      TemplateCode: "E4EV1",
      PageId: 79816,
      BookId: 14338,
      Options: [
        { Word: "ˇ", questionno: 1 },
        { Word: "–", questionno: 2 },
        { Word: "ˊ", questionno: 3 },
        { Word: "ˋ", questionno: 4 },
      ],
      Question: "./assets/IntroAudio/tonePractice_2.mp3",
      answer: "ˇ",
      score: false,
    },
    {
      EId: 2615,
      TemplateCode: "E4EV1",
      PageId: 79816,
      BookId: 14338,
      Options: [
        { Word: "ˇ", questionno: 1 },
        { Word: "–", questionno: 2 },
        { Word: "ˊ", questionno: 3 },
        { Word: "ˋ", questionno: 4 },
      ],
      Question: "./assets/IntroAudio/tonePractice_3.mp3",
      answer: "–",
      score: false,
    },
    {
      EId: 2615,
      TemplateCode: "E4EV1",
      PageId: 79816,
      BookId: 14338,
      Options: [
        { Word: "ˇ", questionno: 1 },
        { Word: "–", questionno: 2 },
        { Word: "ˊ", questionno: 3 },
        { Word: "ˋ", questionno: 4 },
      ],
      Question: "./assets/IntroAudio/tonePractice_4.mp3",
      answer: "ˊ",
      score: false,
    },
  ];
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(
    Array(item.length).fill("")
  );
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const userData: any = localStorage.getItem("userlevel");
    const data = JSON.parse(userData);
    if (data === null) {
      getUserLevel();
    } else {
      setitems(data);
    }
  });
  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      if (json.StatusCode === 1) {
        setitems(json.Result);
        const userLevel = json.Result;
        localStorage.setItem("userlevel", JSON.stringify(userLevel));
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
      // resetstate();
      // setShowResults(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
      // resetstate()
      // setShowResults((index-1))
      if (index === 0) {
        // handleChange(1);
      }
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        slidesRef.current?.getActiveIndex().then(async (index) => {
          if (index - 1 === length) {
            back();
          }
        });
      } else {
        slidesRef.current?.getActiveIndex().then(async (index) => {
          if (index + 1 === length) {
            gonextPage();
            // setLength(-1);
            // saveContent();
            // handleChange(0);
          }
        });
      }
    }
  };
  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/toneslasttwo" });
  };
  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/tonestwo" });
  };
  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    // Check if an answer has already been selected for this slide
    if (selectedAnswers[index] === "") {
      setSelected(true);
      setSelectedAnswer(selectedOption);
      setCorrectAnswer(correctAnswer);
      setSelectedAnswers((prevSelectedAnswers) => {
        const updatedSelectedAnswers = [...prevSelectedAnswers];
        updatedSelectedAnswers[index] = selectedOption;
        return updatedSelectedAnswers;
      });
      if (selectedOption === correctAnswer) {
        setIsCorrect(true);
        setRightAnsOption(true);
        setmyanswer("");
        setmyanswer(selectedOption);
        const pushValue = (selectedOption) => {
          setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
        };
        pushValue(selectedOption);
      } else {
        setIsCorrect(false);
        setRightAnsOption(false);
        setmyanswer("");
        setmyanswer(selectedOption);
      }
      const correctIndex = item[index].Options.findIndex(
        (option) => option.Word === correctAnswer
      );
      setCorrectAnswerIndex(correctIndex);
      setCorrectAnswerIndices((prevIndices) => {
        const newIndices = [...prevIndices];
        newIndices[index] = correctIndex;
        return newIndices;
      });
    }
  };
  return (
    <IonPage className="pages-space intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              onClick={() => back()}
              color="primary"
              placeholder={undefined}
            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("tonespracticetwo.let_prac")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color="primary" placeholder={undefined}></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing tonepractice" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            options,
          }}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef}
          placeholder={undefined}
        >
          {item.map((itemData, index) => (
            <IonSlide key={index}>
              <IonList className="ion-no-padding height100">
                <IonRow className="Practice leftrightspace ion-padding-horizontal">
                  <IonCol>
                    <IonText>{t("tonespracticetwo.chos_what")}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="Practice spacing ion-padding-horizontal">
                  <IonCol size="12">
                    <div className="customaudioplayer">
                      <CustomAudioPlayer src={itemData.Question} />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="answer spacing ion-padding-horizontal">
                  {itemData.Options.map((datav, indexnew) => (
                    <IonCol size="6" key={indexnew}>
                      <IonCard
                        id={datav.questionno}
                        style={{
                          borderColor:
                            isCorrect !== null &&
                            isCorrect &&
                            datav.Word === selectedAnswers[index]
                              ? "var(--ion-color-success)"
                              : correctAnswerIndices[index] !== null &&
                                indexnew === correctAnswerIndices[index]
                              ? "var(--ion-color-success)"
                              : isCorrect !== null &&
                                !isCorrect &&
                                datav.Word === selectedAnswers[index]
                              ? "var(--ion-color-danger)"
                              : "",
                        }}
                        onClick={() =>
                          selectedAnswers[index] === ""
                            ? handleAnswerSelection(
                                datav.Word,
                                itemData.answer,
                                index
                              )
                            : ""
                        }
                      >
                        <IonCardContent class="ion-no-padding">
                          <div className="min184">
                            <IonText>{datav.Word}</IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonList>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Tonespracticetwo);
