import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRow,
  IonImg,
  IonCol,
  IonGrid,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Login.scss";
import {
  setIsLoggedIn,
  setLoading,
  setUsername,
} from "../data/user/user.actions";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { CONFIG } from "../App";
import useApi from "../hooks/useApi";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface LoginProps extends OwnProps, DispatchProps {}

const Login: React.FC<LoginProps> = ({
  setIsLoggedIn,
  history,
  setUsername: setUsernameAction,
}) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const [items, setItems] = useState([]);

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (Object.keys(currentUser).length > 0) {
      history.replace("/tabs/mainhome", { direction: "none" });
      // history.go(0)
    }
  });

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!username) {
      setUsernameError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    // history.replace('/beforewestart', {direction: 'none'});

    if (username && password) {
      signin();
      // await setIsLoggedIn(true);
      // await setUsernameAction(username);

      // history.replace('/beforewestart', {direction: 'none'});
    }
  };

  const signin = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });

    var encodedString = btoa(password);

    const data = { email: username, password: encodedString };
    try {
      const response = await fetch(url + "/account/validateuser", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        // await setIsLoggedIn(true);
        //  await setUsernameAction(username);
        localStorage.setItem("logininfo", JSON.stringify(json));

        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));

        if (json.Result.IsBefore === false) {
          history.replace("/Beforewestart");
        } else if (
          json.Result.IsBefore === true &&
          json.Result.IsProficiency === false
        ) {
          history.replace("/pretestone", { direction: "none" });
        } else if (
          json.Result.IsBefore === true &&
          json.Result.IsProficiency === true
        ) {
          history.replace("/tabs/mainhome", { direction: "none" });
        }
      } else if (json.StatusCode === 3) {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
        localStorage.setItem("userID", json.Result.UserId);
        history.replace("/Termscondition", { direction: "none" });
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // useEffect(() => {
      //   localStorage.setItem('items', JSON.stringify(json));
      // }, [items]);

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const togglePasswordVisiblity = () => {
    setIsRevealPwd(isRevealPwd ? false : true);
  };

  return (
    <IonPage className="blue-bg" id="login-page">
      <IonHeader className="bluescreen">
        <IonToolbar color="primary">
          <IonTitle>{t("login.sign_in")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form noValidate onSubmit={login}>
          <IonGrid className="main-box">
            <IonList className="userform">
              <IonItem className="tooltiparea" color="white">
                <IonLabel>
                  <IonText no-margin>{t("login.email")}</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className="inputwithicon twoicon pr-0">
                {/* <IonIcon icon={mail} /> */}
                <IonImg src="./assets/images/mailicon.svg" alt="" />
                <IonInput
                  name="email"
                  type="text"
                  value={username}
                  spellCheck={false}
                  autocapitalize="off"
                  onIonChange={(e) => setUsername(e.detail.value!)}
                  required
                ></IonInput>
              </IonItem>

              {formSubmitted && usernameError && (
                <IonText color="warning">
                  <p className="required">{t("login.urname_req_err")}</p>
                </IonText>
              )}

              <IonItem className="tooltiparea" color="white">
                <IonLabel>
                  <IonText no-margin>{t("login.psword")}</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className="inputwithicon iconwithinput twoicon">
                {/* <IonIcon icon={lockClosed} /> */}

                <IonImg src="./assets/images/lock.svg" alt="" />
                <IonInput
                  name="password"
                  type={isRevealPwd ? "text" : "password"}
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value!)}
                ></IonInput>

                <IonImg
                  onClick={() => togglePasswordVisiblity()}
                  src={
                    isRevealPwd
                      ? "/assets/images/showpassword.svg"
                      : "./assets/images/hidepassword.svg"
                  }
                  alt=""
                />
              </IonItem>

              {formSubmitted && passwordError && (
                <IonText color="warning">
                  <p className="required">{t("login.psword_req_err")}</p>
                </IonText>
              )}
            </IonList>

            <IonRow>
              <IonCol size="12">
                <IonButton
                  color="white"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                >
                  {t("login.sign_in")}
                </IonButton>
              </IonCol>
              <IonCol size="12" className="ion-text-center linktext">
                <a href="/forgotpassemail" color="light">
                  {t("login.fgt_pass_link")}
                </a>
              </IonCol>

              {/* <IonCol size="12" className="ion-text-center">
                <IonText className="multioption" color="white">
                  {t("login.or")}
                </IonText>
              </IonCol>
              <IonCol size="12" className="socialbtn">
                <IonButton expand="full">
                  <div>
                    <img src="./assets/images/AppleLogo.svg" />
                    {t("login.sg_wt_apple")}
                  </div>
                </IonButton>
              </IonCol>
              <IonCol size="12" className="socialbtn">
                <IonButton expand="full">
                  <div>
                    <img src="./assets/images/google.svg" />
                    {t("login.sg_wt_google")}
                  </div>
                </IonButton>
              </IonCol>
              <IonCol size="12" className="ion-text-center linktext">
                <a href="/forgotpassemail" color="light">
                  {t("login.fgt_pass_link")}
                </a>
              </IonCol>s
              <IonCol size="12" className="ion-text-center linktext">
                <a href="/signup" color="light">
                  {t("login.reg_link")} <br /> {t("login.reg_btn")}
                </a>
              </IonCol> */}
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: {
//     setIsLoggedIn,
//     setUsername
//   },
//   component: Login
// })
