import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonGrid,
  IonItem,
  IonIcon,
  IonImg,
  IonText,
  IonLabel,
  IonInput,
  IonList,
  IonContent,
  IonPage,
  IonTitle,
  IonButton,
  IonCol,
  IonRow,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonPopover,
} from "@ionic/react";
import "./Changepassword.scss";
import { chevronBack } from "ionicons/icons";
import { CONFIG } from "../App";
import { Controller, useForm } from "react-hook-form";
import { lockClosed, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface ChangepasswordProps extends OwnProps {}

const Changepassword: React.FC<ChangepasswordProps> = () => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const history = useHistory();

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  // const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [isShowCurrentPassword, setIsShowCurrent] = useState(false);
  const [isShowNewPassword, setIsShowNew] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirm] = useState(false);

  const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);
  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
  });

  const resetpassword = async (item: any) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    if (item.pwd != item.confpwd) {
      presenttost({
        message: "Passwords does not match",
        duration: 5000,
        position: "bottom",
      });
      return;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    var email = localStorage.getItem("email");
    var oldPwdencodedString = btoa(item.oldPassword);
    var pwdencodedString = btoa(item.pwd);
    // var oldPwdencodedString = item.oldPassword;
    // var pwdencodedString =item.pwd;

    const data = {
      oldPassword: oldPwdencodedString,
      newPassword: pwdencodedString,
    };
    try {
      const response = await fetch(url + "/User/ChangePassword", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      dismiss();

      // presenttost({
      //   message: json.Message,
      //   duration: 1500,
      //   position: 'bottom'
      // });

      if (json.StatusCode == 1) {
        submit();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} />
            </div>
            <p>{t("changepassword.submit.pass_success")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton className="alertcancel w-150" routerLink="/login">
                {t("changepassword.submit.continue")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  // const togglePasswordVisiblity = () => {
  //   setIsRevealPwd(isRevealPwd ? false : true);
  // };
  // const togglePasswordVisiblityconf = () => {
  //   setIsRevealPwdconf(isRevealPwdconf ? false : true);
  // };

  const toggleCurrentVisiblity = () => {
    setIsShowCurrent(!isShowCurrentPassword);
  };

  const toggleNewVisiblity = () => {
    setIsShowNew(!isShowNewPassword);
  };

  const toggleConfirmVisiblity = () => {
    setIsShowConfirm(!isShowConfirmPassword);
  };

  const back = () => {
    history.replace({ pathname: "/tabs/settings" }, { direction: "none" });
  };

  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={back} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            {t("changepassword.change_password")}
          </IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit((data) => resetpassword(data))}>
          <IonGrid className="changepassword">
            <IonList>
              <IonItem className="subtitle inputwithicon">
                <IonLabel>
                  <IonText className="nowrap" no-margin>
                    {t("changepassword.enter_new_password")}
                    {/* Enter new password. */}
                  </IonText>
                </IonLabel>
              </IonItem>

              {/* current password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput"
                color="white"
              >
                <IonLabel>
                  <IonText no-margin>{t("changepassword.cur_pass")}</IonText>
                </IonLabel>
                {/* <div className="labeltitle">
                  <IonImg src="./assets/images/bang-in-circle.png" alt="" />
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div> */}
              </IonItem>

              <IonItem className="inputwithicon iconwithinput twoicon">
                <IonImg src="./assets/images/bang-in-circle.png" alt="" />
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isShowCurrentPassword ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                  control={control}
                  name="oldPassword"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => toggleCurrentVisiblity()}
                  src={
                    isShowCurrentPassword
                      ? "/assets/images/seepassword-dark.svg"
                      : "./assets/images/hidepassword-dark.svg"
                  }
                  alt=""
                />
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="oldPassword"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* current password end */}

              {/* new password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput warning"
                color="black"
              >
                <IonImg id="new-password" className="info" src="./assets/images/warning.svg" alt="" />
                {/* <IonPopover trigger="new-password" size="cover">
                  <IonContent class="ion-padding">Hello!</IonContent>
                </IonPopover> */}
                <IonLabel>
                  <IonText no-margin>{t("changepassword.new_pass")}</IonText>
                </IonLabel>
                <div className="labeltitle">
                  <IonImg src="./assets/images/bang-circle-blue.svg" alt="" />
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div>
              </IonItem>
              <IonItem className="inputwithicon iconwithinput twoicon">
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isShowNewPassword ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                  control={control}
                  name="pwd"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => toggleNewVisiblity()}
                  src={
                    isShowNewPassword
                      ? "/assets/images/seepassword-dark.svg"
                      : "./assets/images/hidepassword-dark.svg"
                  }
                  alt=""
                />
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="pwd"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* new password end */}

              {/* confirm new password strart */}

              <IonItem
                className="tooltiparea inputwithicon forminput warning"
                color="black"
              >
                <IonImg id="confirm-password" className="info" src="./assets/images/warning.svg" alt="" />
                {/* <IonPopover trigger="confirm-password" size="cover">
                  <IonContent class="ion-padding">Hello!</IonContent>
                </IonPopover> */}
                <IonLabel>
                  <IonText no-margin>
                    {t("changepassword.cnfm_new_pass")}
                  </IonText>
                </IonLabel>
                {/* <div className="labeltitle">
                  <div className="tooltip">{t("changepassword.char_desc")}</div>
                </div> */}
              </IonItem>
              <IonItem className="inputwithicon iconwithinput twoicon">
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      type={isShowConfirmPassword ? "text" : "password"}
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                    />
                  )}
                  control={control}
                  name="confpwd"
                  rules={{
                    required: t("changepassword.required"),
                  }}
                />
                <IonImg
                  onClick={() => toggleConfirmVisiblity()}
                  src={
                    isShowConfirmPassword
                      ? "/assets/images/seepassword-dark.svg"
                      : "./assets/images/hidepassword-dark.svg"
                  }
                  alt=""
                />
              </IonItem>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className="inputerror inputwithicon" />}
              />

              {/* confirm new password end */}

              {/* <IonCol>
                <IonLabel>Confirm New Password</IonLabel>
                <div className='iconwithinput'>
                  <IonImg src="./assets/images/lock.svg" alt="" />
                
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwdconf ? "text" : "password"} onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                      />
                    )}
                    control={control}
                    name="confpwd"
                    rules={{
                      required: "This is a required field",
                    
                    }}
                  />
                  <IonImg onClick={() => togglePasswordVisiblityconf()} src={isRevealPwdconf ? '/assets/images/showpassword.svg' : './assets/images/hidepassword.svg'} alt="" />
                
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className='inputerror' />}
              /> */}
            </IonList>

            <IonRow className="passwordbtn">
              <IonCol size="12">
                <IonButton
                  color="primary"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                >
                  {/* {t("changepassword.reset")} */}
                  Save
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>

        {/* <form>
          <IonGrid className="changepassword">
            <IonList>

              <IonItem className='subtitle'>
                <IonLabel>
                  <IonText className='nowrap' no-margin>Your password must be at least 9 characters long and should include uppercase letter and at least one special characters (!@#$).</IonText>
                </IonLabel>
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Current Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eye} />
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>New Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eyeOffOutline} />
              </IonItem>

              <IonItem className='inputwithicon tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Confirm New Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonImg src="./assets/images/lockdark.svg" alt="" />
                <IonInput name="username" type="password" required></IonInput>
                <IonIcon icon={eyeOffOutline} />
              </IonItem>

            </IonList>

            <IonRow class='passwordbtn'>
              <IonCol size="12">
                <IonButton color="primary" routerLink="" expand='block'>Save</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Changepassword);
