import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import './Pagelink.scss';

interface VocabularybankProps { }

const Vocabularybank: React.FC<VocabularybankProps> = () => {

  return (
    <IonPage className='intro' placeholder={undefined}>

      <IonContent className='listing ion-padding-start' placeholder={undefined}>
        <span className='ion-padding-start'>
          <p className='ion-padding-start'><a href='http://localhost:8100/starting'>starting</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/startinglogreg'>startinglogreg</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/signup'>signup</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/Termscondition'>Termscondition</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/login'>login</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/forgotpassemail'>forgotpassemail</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/forgotpassverify'>forgotpassverify</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/forgotpassresetpass'>forgotpassresetpass</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/Beforewestart'>Beforewestart</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/pretestone'>pretestone</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/pretesttwo'>pretesttwo</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/testquestion'>testquestion</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/testdone'>testdone</a></p>

          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/home'>home</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Wittywizard'>Wittywizard</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Notificationbell'>Notificationbell</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Proficient'>Proficient</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Themesports'>Themesports</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/lesson'>lesson</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Practicecompleted'>Practicecompleted</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Practiceresult'>Practiceresult</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Whilepressing'>Whilepressing</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Dialogue'>Dialogue</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Dialoguevideo'>Dialoguevideo</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Dialoguedone'>Dialoguedone</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Dialogueresult'>Dialogueresult</a></p>

          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/progress'>progress</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Detailedreports'>Detailedreports</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Vocabularybank'>Vocabularybank</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/mainhome/Starredvocabulary'>Starredvocabulary</a></p>

          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/profile'>profile == (Current level selection)</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/profile/editprofile'>editprofile == (Choose profile picture pop-up)</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/profile/currentlevel'>currentlevel</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/profile/leveldescriprion'>leveldescriprion</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/profile/adjustmylevel'>adjustmylevel == (slider)</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Subscribe'>Subscribe</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Mysubscription'>Mysubscription</a></p>

          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings'>settings</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings/Languages'>Languages == (Level selection)</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings/Notifications'>Notifications</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings/FAQs'>FAQs</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings/Privacypolicy'>Privacypolicy</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/settings/Changepassword'>Changepassword</a></p>

          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Lessonpractice'>Lessonpractice</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Whilepressing1'>Whilepressing1</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Whilepressing2'>Whilepressing2</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Whilepressing3'>Whilepressing3</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/Whilepressing4'>Whilepressing4 == (Drawing)</a></p>
          <p className='ion-padding-start'><a href='http://localhost:8100/tabs/dragdroplesson'>dragdroplesson</a></p>
        </span>

      </IonContent>

</IonPage>
  );
};

export default React.memo(Vocabularybank);