import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonImg,
  IonList,
  IonItem,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
} from "@ionic/react";
import "./Currentlevel.scss";
import { chevronBack, chevronForward } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface CurrentlevelProps extends OwnProps {}

const Currentlevel: React.FC<CurrentlevelProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="imgview">
              <IonImg src="./assets/images/goal.svg" />
            </div>
            <p className="popuppadding">
              {t("currentlevel.submit.confirm_redo_test")}
            </p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel">
                {t("currentlevel.submit.cancel")}
              </IonButton>
              <IonButton className="alertsubmit">
                {t("currentlevel.submit.redo")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const goNext = (value) => {
    history.replace(
      { pathname: "/tabs/profile/" + value },
      { direction: "none" }
    );
  };

  const back = () => {
    history.replace({ pathname: "/tabs/profile" }, { direction: "none" });
  };

  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={back} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("currentlevel.cur_level")}</span>
          </IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing">
        <IonList className="list selectlevels">
          <IonItem
            class="ion-no-padding"
            onClick={() => goNext("leveldescriprion")}
          >
            <IonLabel>{t("currentlevel.level_desc")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} />
          </IonItem>
          <IonItem
            class="ion-no-padding"
            onClick={() => goNext("adjustmylevel")}
          >
            <IonLabel>{t("currentlevel.adj_level")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} />
          </IonItem>
          {/* <IonItem onClick={submit} class="ion-no-padding pointer">
            <IonLabel>{t("currentlevel.redo_pro_test")}</IonLabel>
            <IonIcon color='primary' icon={chevronForward} />
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Currentlevel);
