import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonText,
  useIonViewWillEnter,
  IonIcon,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import "./Tones.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack } from "ionicons/icons";
import ReactAudioPlayer from "react-audio-player";
import audioJSON from "./json/audio.json";
import dataDescription from "./json/letterDesc.json";

interface OwnProps extends RouteComponentProps {}

interface Tones extends OwnProps {}

const Tones: React.FC<Tones> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [length, setLength] = React.useState(-1);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [isPlaying5, setIsPlaying5] = useState(false);
  const audioRef1 = useRef<any>();
  const audioRef2 = useRef<any>();
  const audioRef3 = useRef<any>();
  const audioRef4 = useRef<any>();
  const audioRef5 = useRef<any>();
  // json imported below
  const audioSrc: any = audioJSON;
  const audio: any = audioSrc?.audioSrc;
  const description: any = dataDescription;
  const data: any = description.description;

  useIonViewWillEnter(() => {
    setLength(1);
  });

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const handleSlideChange = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const currentIndex = swiper.activeIndex;
      const refs = [audioRef1, audioRef2, audioRef3, audioRef4, audioRef5];
      const setIsPlaying = [
        setIsPlaying1,
        setIsPlaying2,
        setIsPlaying3,
        setIsPlaying4,
        setIsPlaying5,
      ];
      refs.forEach((ref, index) => {
        if (ref.current) {
          const audioElement = ref.current.audioEl.current;
          // audioElement.pause();
          // setIsPlaying[index](false);
          if (index + 2 === currentIndex) {
          } else {
            // If the index does not match the active index, pause the audio
            audioElement.pause();
            setIsPlaying[index](false);
          }
        }
      });
    }
  };

  const handlePlayPause = (id) => {
    const refs = [audioRef1, audioRef2, audioRef3, audioRef4, audioRef5];
    const isPlaying = [
      isPlaying1,
      isPlaying2,
      isPlaying3,
      isPlaying4,
      isPlaying5,
    ];
    const setIsPlaying = [
      setIsPlaying1,
      setIsPlaying2,
      setIsPlaying3,
      setIsPlaying4,
      setIsPlaying5,
    ];

    refs.forEach((ref, index) => {
      if (ref.current) {
        const audioElement = ref.current.audioEl.current;
        if (index === id - 1) {
          if (isPlaying[index]) {
            audioElement.pause();
          } else {
            audioElement.play();
          }
          setIsPlaying[index](!isPlaying[index]);
        } else {
          audioElement.pause();
          setIsPlaying[index](false);
        }
      }
    });
  };

  const audioEnd = (id) => {
    const setIsPlaying = [
      setIsPlaying1,
      setIsPlaying2,
      setIsPlaying3,
      setIsPlaying4,
      setIsPlaying5,
    ];

    setIsPlaying[id - 1](false);
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/tonespractice" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("tones.tones")}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary"></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing  tonesmain">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionStart={handleSlideChange} // <-- Bind slide change event
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <IonList className="tone tone-main">
              <div className="spacing">
                <IonText className="subtitle">{t("tones.slide1")}</IonText>
                <div>
                  <span>
                    <IonText className="primary">
                      <img src="./assets/images/zhōngwén.svg" alt="" />
                    </IonText>
                    <IonText className="subtitle-chinese">中文</IonText>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <div className="spacing">
                <IonText className="subtitle">{t("tones.slide2")}</IonText>
                <div className="lanimg">
                  <IonImg src="./assets/images/lan.svg" />
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tones.1st")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("tones.first")}</IonText>
                <div className="tonesword">
                  <span>
                    {/* <IonText className="singletext">ā</IonText> */}
                    <IonImg src="./assets/images/1tone.svg" alt="" />
                    <div
                      className="PlayPause"
                      onClick={() => handlePlayPause(1)}
                    >
                      <ReactAudioPlayer
                        ref={audioRef1}
                        onEnded={() => audioEnd(1)}
                        className="String"
                        controls={false}
                        controlsList="nodownload noplaybackrate"
                        src={audio.toneFirst}
                      />
                      <IonImg
                        className="playbig"
                        src={
                          isPlaying1
                            ? "./assets/images/Pausebig.svg"
                            : "./assets/images/playbig.svg"
                        }
                      />
                    </div>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tones.2nd")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("tones.second")}</IonText>
                <div className="tonesword">
                  <span>
                    {/* <IonText className="singletext">á</IonText> */}
                    <IonImg src="./assets/images/2tone.svg" alt="" />
                    <div
                      className="PlayPause"
                      onClick={() => handlePlayPause(2)}
                    >
                      <ReactAudioPlayer
                        ref={audioRef2}
                        onEnded={() => audioEnd(2)}
                        className="String"
                        controls={false}
                        controlsList="nodownload noplaybackrate"
                        src={audio.toneSecond}
                      />
                      <IonImg
                        className="playbig"
                        src={
                          isPlaying2
                            ? "./assets/images/Pausebig.svg"
                            : "./assets/images/playbig.svg"
                        }
                      />
                    </div>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tones.3rd")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("tones.third")}</IonText>
                <div className="tonesword">
                  <span>
                    {/* <IonText className="singletext">ǎ</IonText> */}
                    <IonImg src="./assets/images/3tone.svg" alt="" />
                    <div
                      className="PlayPause"
                      onClick={() => handlePlayPause(3)}
                    >
                      <ReactAudioPlayer
                        ref={audioRef3}
                        onEnded={() => audioEnd(3)}
                        className="String"
                        controls={false}
                        controlsList="nodownload noplaybackrate"
                        src={audio.toneThird}
                      />
                      <IonImg
                        className="playbig"
                        src={
                          isPlaying3
                            ? "./assets/images/Pausebig.svg"
                            : "./assets/images/playbig.svg"
                        }
                      />
                    </div>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tones.4th")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("tones.fourth")}</IonText>
                <div className="tonesword">
                  <span>
                    {/* <IonText className="singletext">à</IonText> */}
                    <IonImg src="./assets/images/4tone.svg" alt="" />
                    <div
                      className="PlayPause"
                      onClick={() => handlePlayPause(4)}
                    >
                      <ReactAudioPlayer
                        ref={audioRef4}
                        onEnded={() => audioEnd(4)}
                        className="String"
                        controls={false}
                        controlsList="nodownload noplaybackrate"
                        src={audio.toneFourth}
                      />
                      <IonImg
                        className="playbig"
                        src={
                          isPlaying4
                            ? "./assets/images/Pausebig.svg"
                            : "./assets/images/playbig.svg"
                        }
                      />
                    </div>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tones.5th")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("tones.fifth")}</IonText>
                <div className="tonesword">
                  <span>
                    {/* <IonText className="singletext">a</IonText> */}
                    <IonImg src="./assets/images/5tone.svg" alt="" />
                    <div
                      className="PlayPause"
                      onClick={() => handlePlayPause(5)}
                    >
                      <ReactAudioPlayer
                        ref={audioRef5}
                        onEnded={() => audioEnd(5)}
                        className="String"
                        controls={false}
                        controlsList="nodownload noplaybackrate"
                        src={audio.toneFifth}
                      />
                      <IonImg
                        className="playbig"
                        src={
                          isPlaying5
                            ? "./assets/images/Pausebig.svg"
                            : "./assets/images/playbig.svg"
                        }
                      />
                    </div>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Tones);
