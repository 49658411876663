import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./E5sResultDetails.scss";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import ReactAudioPlayer from "react-audio-player";
import useMediaRecorder from "@wmik/use-media-recorder";
import { json } from "stream/consumers";
import { string } from "yup";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import CustomAudioPlayer from "./CustomAudioPlayer";
interface OwnProps extends RouteComponentProps {}
function Player({ srcBlob }: { srcBlob: any }) {
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: "e5s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E5sProps {
  itemE5s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E5sResultDetails: React.FC<E5sProps> = (
  props: E5sProps,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  // const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE5slist, setE5stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  let tmscorefinal: any;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [showResults, setShowResults] = React.useState(1);
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [length, setLength] = React.useState(-1);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const words1 = sentence1.split(" ");
  const words2 = sentence2.split(" ");
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  // DnD Start
  // Sample data
  const initialList1 = ["請", "問", "您", "想", "喝", "什", "麼", "？"];
  const initialList2 = [];
  const [list1, setList1] = useState<any>(initialList1);
  const [list2, setList2] = useState<any>(initialList2);
  // DnD End
  const notMatchingWords = words1
    .filter((word) => !words2.includes(word))
    .concat(words2.filter((word) => !words1.includes(word)));
  //end
  const spanRef = useRef(null);
  let lastValue: any;
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let totalque: any = 0;
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useIonViewWillEnter(() => {
    getExercise();
    setLength(1);
    slidesRef.current?.getActiveIndex().then((index) => {
      if (itemE5slist.length == index + 1) {
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    });
    setShowResults(1);
  });
  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   duration: 1000,
    // });
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        setAllExcies(json.Result);
        setitems(json.Result);
        setE5stems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, itemE5slist.length]);
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index - 1 === -1) {
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          //SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            setLength(-1);
            // saveContent();
          }
        });
      }
    }
  };

  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、——])|(?=[（『「〝“])/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“"];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };

  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };

  const test = (text, textpy, answer) => {
    // const characters1 = text.split("");
    // const characters2 = answer.split("");
    // const charPy = textpy.split(" ");
    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];

    const characters1 = text
      ?.split("")
      .filter((item) => !spChar.includes(item) && item !== " ");

    const charactersShow = wordSplitter(text);
    const text2 = answer;
    const characters2 = text2?.split("");
    const charPy = pySplitter(textpy);

    const result = characters1.map((text, i) => ({
      Text: charactersShow[i],
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2?.includes(text),
    }));
    dismiss();
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {word["Piniyan"]}
          </p>
        </li>
      </React.Fragment>
    ));
    return fragments;
  };
  // undefined
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname:
          backpageValue === 2
            ? "/tabs/progress/practiceresult"
            : "/tabs/mainhome/practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E5s",
          backpageValue: backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={gobackpage}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end"></IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemE5slist.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
        >
          {itemE5slist.map((itemse5, indexe5) => {
            return (
              <IonSlide>
                <IonList className="ion-no-padding bottomfix record">
                  <IonRow className="Practice ion-padding-horizontal">
                    <IonCol>
                      <IonText>{t("e5s.record_sentence")}</IonText>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="test-audio ion-text-left whitebg"
                    >
                      <div className="customaudioplayer">
                        <CustomAudioPlayer src={itemse5?.AudioUrl} />
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer ion-padding-horizontal">
                    <IonCol size="12">
                      <IonCard className="select">
                        <IonCardContent className="Lessonpracticeaudio">
                          <ul className="lession-icon">
                            {test(
                              itemse5.WordCh,
                              itemse5.WordPy,
                              itemse5.Answer
                            )}
                          </ul>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonList>
                <IonList className="ion-text-center ion-padding-horizontal">
                  <IonRow>
                    <IonCol size="12" className="back-forward">
                      <div className="audio-record" />
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow>
          <IonList className="list">
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e5s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>
                  Simp.
                </IonText>
                <IonText placeholder={undefined}>Trad.</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e5s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e5s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e5s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a>
                <span className="speed">1.0</span>
                <a href="#">&gt;</a>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(E5sResultDetails);
