import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCard,
  IonCardContent,
  IonList,
  IonCol,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./Dialoguedone.scss";
import {
  chevronBack,
  close,
  lockClosed,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DialoguedoneProps extends OwnProps {}

const Dialoguedone: React.FC<DialoguedoneProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemRecentlist, setRecentitems] = React.useState<any>();
  const [showImage, setShowImage] = useState(false);
  const historyss = useHistory<{
    themeArray: any;
    index: string;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
    title: string;
    videoUrl: string;
    thumb: string;
    role: string;
    allrole: string;
    rolename: string;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const thumb = historyss.location.state ? historyss.location.state.thumb : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";

  const string1 = String(localStorage.getItem("pagecode1"));
  const btn1 = string1.replace(/"/g, "");

  const string2 = String(localStorage.getItem("pagecode2"));
  const btn2 = string2.replace(/"/g, "");

  const Subscribe = async () => {
    history.replace("/tabs/Subscribe", { direction: "none" });
  };
  let string = allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getthemes();
  });

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson?type=C", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json));
      if (json.StatusCode == 1) {
        let test: any = [];

        const array1 = json.Result.Recentlys.Lessons;
        const array2 = json.Result.Recentlys.Dialogues;
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["Type"] = "lessons";
            array1[i]["Number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["Type"] = "dialogues";
            array2[i]["Number"] = i + 1;
            test.push(array2[i]);
          }
        }

        setRecentitems(test);
        setShowImage(true);
        // setRecentShow(false);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} />
            </div>
            <p>{t("dialoguedone.submit.subscribe_to_unlock")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                }}
                className="alertcancel w-150"
              >
                {t("dialoguedone.submit.subscribe")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const submit2 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body rolebox">
            <p className="mt0">{t("dialoguedone.submit2.message")}</p>
            <div className="react-confirm-alert-button-group">
              <div className="boxwidth">
                <div
                  onClick={() => {
                    goDialoguepractice(one, 0);
                    onClose();
                  }}
                  className="usercircle"
                >
                  {one}
                </div>
                <p>Staff</p>
              </div>
              <div className="boxwidth">
                <div
                  onClick={() => {
                    goDialoguepractice(two, 1);
                    onClose();
                  }}
                  className="usercircle"
                >
                  {two}
                </div>
                <p>Guest</p>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const goDialogueresult = () => {
    history.replace({
      pathname:
        backpageValue === 2
          ? "/tabs/progress/dialogueresult"
          : "/tabs/mainhome/dialogueresult",
      state: {
        themeArray: themeArray,
        videoUrl: videoUrl,
        thumb: thumb,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        rolename: rolename,
        role: role,
        allrole: one + "," + two,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };

  const goDialoguepractice = (rolename, index) => {
    // presenttost({
    //   message: 'Practices not available',
    //   duration: 1500,
    //   position: 'bottom'
    // });

    history.replace({
      pathname:
        backpageValue === 2
          ? "/tabs/progress/dialoguepractice"
          : "/tabs/mainhome/dialoguepractice",
      state: {
        themeArray: themeArray,
        videoUrl: videoUrl,
        thumb: thumb,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        role: index,
        rolename: rolename,
        allrole: one + "," + two,
        backpage: "Dialoguedone",
        backpageValue: backpageValue,
      },
    });
  };

  const back = () => {
    if (btn2 === "Practice again") {
      history.replace({
        pathname:
          backpageValue === 2
            ? "/tabs/progress/dialoguepractice"
            : "/tabs/mainhome/dialoguepractice",
        state: {
          themeArray: themeArray,
          index: index2,
          themeId: themeIddb,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: dialogueId,
          rolename: rolename,
          role: role,
          allrole: one + "," + two,
          videoUrl: videoUrl,
          thumb: thumb,
          backpage: backpage,
          backpageValue: backpageValue,
        },
      });
    } else {
      history.replace({
        pathname:
          backpageValue === 2
            ? "/tabs/progress/dialoguevideo"
            : "/tabs/mainhome/dialoguevideo",
        state: {
          themeArray: themeArray,
          index: index2,
          themeId: themeIddb,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: dialogueId,
          rolename: rolename,
          role: role,
          allrole: one + "," + two,
          videoUrl: videoUrl,
          thumb: thumb,
          backpage: backpage,
          backpageValue: backpageValue,
        },
      });
      localStorage.removeItem("pagecode1");
      localStorage.removeItem("pagecode1");
    }
  };

  const goLessonPage = (val, index) => {
    //history.replace('/tabs/mainhome/themesports/5');

    history.replace({
      pathname: "/tabs/mainhome/lesson",
      state: {
        themeArray: itemRecentlist,
        themeId: val.ThemeGuid,
        lessonname: val.Title,
        index: index,
        lessonsId: val.BookGuid,
        bookId: val.BookId,
        themename: "",
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: backpage,
        // backpageValue: backpageValue === 2 ? 9 : 6,
        backpageValue: 0,
        originalBackValue: backpageValue,
      },
    });
  };

  const goDialoguePage = (val, index) => {
    const stringSpac = val.Types; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    if (val.IsLocked == false) {
      history.replace({
        pathname: "/tabs/mainhome/dialoguevideo",
        state: {
          themeArray: itemRecentlist,
          themeId: val.ThemeGuid,
          index: index,
          dialogueId: val.Id,
          title: val.Title,
          themename: "",
          levelId: levelId,
          level: level,
          levname: levname,
          videoUrl: val.VideoUrl,
          role: stringWtot,
          backpage: backpage,
          // backpageValue: backpageValue === 2 ? 9 : 6,
          backpageValue: 0,
          originalBackValue: backpageValue,
        },
      });
    }
  };

  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={back}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("dialoguedone.dialogue")} : {t("dialoguedone.practice")}{" "}
                <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              {/* <IonButton color='primary'>
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal">
        <IonList className="completed ion-text-center">
          <IonCol size="12">
            <img src="./assets/images/Dialogue.svg" alt="" />
            <p>
              {btn1 === "Practice"
                ? t("dialoguedone.dialogue_completed")
                : t("dialoguedone.practice_completed")}
            </p>
          </IonCol>
          <IonCol size="12">
            {/* <IonButton color="primary" onClick={submit2} expand='block'>See Result</IonButton>
            <IonButton expand='full' shape="round" onClick={back} className='blueborder'>Redo Practice</IonButton>  */}
            <IonButton
              onClick={btn1 === "Practice" ? submit2 : goDialogueresult}
              expand="block"
            >
              {btn1}
            </IonButton>
            <IonButton
              expand="full"
              shape="round"
              onClick={back}
              className="blueborder"
            >
              {btn2}
            </IonButton>
          </IonCol>
        </IonList>

        {/* <IonList className="themes bordertop">
          <IonCol>
            <IonText>{t("dialoguedone.check_this_out")}</IonText>
          </IonCol>
        </IonList> */}

        <IonList className="themes bordertop">
          <IonCol>
            <IonText>{t("dialoguedone.check_this_out")}</IonText>
          </IonCol>
        </IonList>

        {!showImage ? (
          <span style={{ display: "flex" }}>
            <IonSkeletonText
              animated
              style={{
                width: "100%",
                height: "194px",
                marginRight: "7px",
                borderRadius: "15px",
              }}
              placeholder={undefined}
            />
            <IonSkeletonText
              animated
              style={{
                width: "100%",
                height: "194px",
                marginRight: "7px",
                borderRadius: "15px",
              }}
              placeholder={undefined}
            />
          </span>
        ) : (
          <IonList className="recommended-listing checkout swiper-no-swiping">
            {itemRecentlist?.map((items, index) => {
              return (
                <>
                  {items.Type === "lessons" ? (
                    <>
                      <IonCard
                        key={index}
                        onClick={() =>
                          items.Type === "lessons"
                            ? goLessonPage(items, index)
                            : ""
                        }
                        placeholder={undefined}
                      >
                        {showImage ? (
                          <div className="imglist">
                            <img
                              src={items.ImageUrl}
                              alt="Silhouette of mountains"
                            />
                          </div>
                        ) : (
                          <IonSkeletonText
                            animated
                            style={{
                              width: "100%",
                              height: "138px",
                              margin: "0px",
                            }}
                            placeholder={undefined}
                          />
                        )}
                        <IonCardContent
                          className="ion-text-left"
                          placeholder={undefined}
                        >
                          <span>{items.Title}</span>
                        </IonCardContent>
                      </IonCard>
                    </>
                  ) : (
                    <>
                      <IonCard
                        key={index}
                        onClick={() =>
                          items.Type !== "lessons"
                            ? goDialoguePage(items, index)
                            : ""
                        }
                        placeholder={undefined}
                      >
                        {showImage ? (
                          <div className="imglist">
                            <img
                              src={items.ImageUrl}
                              alt="Silhouette of mountains"
                            />
                          </div>
                        ) : (
                          <IonSkeletonText
                            animated
                            style={{
                              width: "100%",
                              height: "138px",
                              margin: "0px",
                            }}
                            placeholder={undefined}
                          />
                        )}
                        <IonCardContent
                          className="ion-text-left"
                          placeholder={undefined}
                        >
                          <span>{items.ContentEn}</span>
                        </IonCardContent>
                      </IonCard>
                    </>
                  )}
                </>
              );
            })}
          </IonList>
        )}
        {/* <IonList className="recommended-listing checkout">
          <IonCard>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} />
            <div className="imglist">
              <img src="./assets/images/img1.svg" alt="" />
            </div>
            <IonCardContent>{t("dialoguedone.dialogue")}</IonCardContent>
          </IonCard>
          <IonCard>
            <div className="overlay" onClick={submit}></div>
            <IonIcon className="lock" icon={lockClosedOutline} />
            <div className="imglist">
              <img src="./assets/images/img2.svg" alt="" />
            </div>
            <IonCardContent>{t("dialoguedone.lesson")}</IonCardContent>
          </IonCard>
        </IonList> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Dialoguedone);
