import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonSearchbar,
  IonButtons,
  IonButton,
  IonList,
  IonCol,
  IonItem,
  IonText,
  IonCard,
  IonImg,
  IonCardContent,
  IonContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  IonAccordionGroup,
  IonAccordion,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonLoading,
  IonModal,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import "./Vocabularyrecap.scss";
import {
  chevronBack,
  chevronForward,
  close,
  playCircle,
  search,
  starSharp,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import ReactAudioPlayer from "react-audio-player";

interface OwnProps extends RouteComponentProps { };

interface StarredvocabularyProps extends OwnProps { };


const Starredvocabulary: React.FC<StarredvocabularyProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const [itemlist, setitems] = React.useState<any>([]);
  const [itemTheamlist, setTheamitems] = React.useState<any>([]);
  const [activeText, setActiveText] = React.useState<any>("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [activeDetails, setActiveDetails] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [itemlevellist, setlevelitems] = React.useState<any>({});

  const [isNewestval, setisNewest] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeThemeId, setTheamId] = React.useState<any>("");
  const [dichistorylist, setdichistory] = React.useState<any>([]);
  const [searchVal, setsearchVal] = React.useState<any>("");
  const [isSearch, setisSearch] = useState(false);
  const audioRef = useRef<any>();
  const historyss = useHistory<{
    themeId: string;
    index: number;
    itemTheamlist: any;
  }>();
  let themeId = historyss.location.state
    ? historyss.location.state.themeId
    : 0;
  let index = historyss.location.state
    ? historyss.location.state.index
    : 0;
  let itemTheamlists = historyss.location.state
    ? historyss.location.state.itemTheamlist
    : 0;

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    //themeId
    let obj = {
      ThemeId: themeId
    }
    setTheamitems(itemTheamlists);
    handleClickResult(obj, index, '', true);
    //  GetUserVocabulary();
    getuserdichistory()
  });

  const getuserdichistory = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/getuserdichistory", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        setdichistory(json.Result);


      } else if (json.StatusCode === 2) {
        // refreshtoken();
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleClickResult = async (item, indexs, searchTerm, isNew) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    if (searchTerm) {
      setisSearch(true)
    } else {
      setisSearch(false)
      present({
        message: "",
        cssClass: "custom-spinner",
        duration: 1000,
      });
    }

     
    setTheamId(item ? item.ThemeId : 0);
    const data = { themeId: item ? item.ThemeId : 0, word: searchTerm, isStarred: true, isNewest: isNew, };
    setActiveText(indexs);
     
    try {
      const response = await fetch(
        url + "/User/GetUserVocabulary",
        //url + "/User/GetVocabularyBank?word=" + word,
        {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "POST",
        }
      );

      const json = await response.json();

      dismiss();
      if (json.StatusCode == 1) {
        if (json.Result.Themes) {
          setTheamitems(json.Result.Themes);
        }

        // setitems(json.Result);
        setitems(json.Result.Vocabularies);
        let vocab = json.Result.Vocabularies;

        let tmparray = json.Result.Vocabularies;
        for (let i = 0; i < vocab.length; i++) {
          if (vocab[i].Vocabs) {
            const jsonArray = JSON.parse(vocab[i].Vocabs);
            const groupedObjects = {};
            for (const item of jsonArray) {
              const type = item.Type.toLowerCase();
              if (!groupedObjects[type]) {
                groupedObjects[type] = [];
              }
              groupedObjects[type].push({
                type: type,
                Example: item.Example,
                Value: item.Value,
                Id: item.Id,
              });
              tmparray[i]["groupedObjects"] = groupedObjects;
            }

          }
        }

         
      } else if (json.StatusCode == 2) {
        //  refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const shortby = (type) => {
    if (type == 1) {
      // Sort in ascending order (A to Z) based on the 'name' property
      const ascendingOrder = itemlist.slice().sort((a, b) => a.Pinyin.localeCompare(b.Pinyin));
      setitems(ascendingOrder);
      closeModal()
    } else if (type == 2) {
      // Sort in descending order (Z to A) based on the 'name' property
      const descendingOrder = itemlist.slice().sort((a, b) => b.Pinyin.localeCompare(a.Pinyin));
      setitems(descendingOrder);
      closeModal()
    } else if (type == 3) {

      setisNewest(true);
      let obj = {
        ThemeId: activeThemeId
      }
      handleClickResult(obj, index, '', true);
      closeModal()
    } else if (type == 4) {
      setisNewest(false);
      let obj = {
        ThemeId: activeThemeId
      }
      handleClickResult(obj, index, '', false);
      closeModal()
    } else {

    }
  }


  const searchhs = () => {
    if (activeSearch == true || activeDetails == true) {
      setActiveSearch(false);
      setActiveDetails(false);
      handleClickResult("", 0, '',true);
      setisSearch(false)
    } else {
      setActiveSearch(true);
    }
    // if (activeSearch == true || activeDetails == true) {
    //   setActiveSearch(false);
    //   setActiveDetails(false);
    // } else {
    //   setActiveSearch(true);
    //   history.replace({
    //     pathname: "/tabs/mainhome/vocabularyrecap",
    //   });
    // }
  };

  const back = () => {
    history.replace({
      pathname: "/tabs/mainhome/vocabularyrecap",
    });
  }
  const handleSearch = (event: CustomEvent) => {
    const searchTerm = event.detail.value;
    // setShowLoader(true);
    setsearchVal(searchTerm)
    setisSearch(false)
    // Perform search logic here
    handleClickResult('', 0, searchTerm, true);
    //  setShowLoader(false);
  };
  let levid;


  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(1); // Initial selected value

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const handleRadioChange = (event: CustomEvent) => {
    setSelectedValue(event.detail.value);
    shortby(event.detail.value)
  };



  // const audioRef = useRef<any>();

  const handlePlayPause = () => {
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const audioEnd = () => {
    setIsPlaying(false);
  };
  const SearchHist = (val) => {
    setsearchVal(val.Name)
    handleClickResult('', 0, val.Name, true);
  }
  const favouriteWord = async (val) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    const data = {
      referenceId: val.DictionaryId,
      isFavourite: val.IsFavourite === true ? false : true,
      typeId: 2,
    };
    // Update decitemlist with the new IsFavourite value
    const updatedDecItemList = itemlist.map((item) =>
      item.DictionaryId === val.DictionaryId
        ? { ...item, IsFavourite: !item.IsFavourite }
        : item
    );
    setitems(updatedDecItemList);
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
     // getQuestions();
      if (json.StatusCode === 1) {
        // Update decitemlist with the new IsFavourite value
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div style={{ alignItems: "center" }}>
            {activeSearch == true || activeDetails == true ? (
              <IonButtons onClick={searchhs} slot="start">
                <IonButton color="primary">
                  <IonIcon icon={chevronBack} />
                </IonButton>
              </IonButtons>
            ) : (
              <IonButtons onClick={back} slot="start">
                <IonButton color="primary">
                  <IonIcon icon={chevronBack} />
                </IonButton>
              </IonButtons>
            )}
            {activeSearch == false ? (
              <IonTitle color="primary" className="font20 ion-no-padding">
                <span>{t("starredvocabulary.strd_vocb")}</span>
              </IonTitle>
            ) : (
              ""
            )}
            {activeSearch ? (
              <IonSearchbar value={searchVal} placeholder="Search in English, Chinese or Pinyin" onIonChange={handleSearch}></IonSearchbar>
            ) : (
              ""
            )}

            {activeSearch == false ? (
              <IonButtons onClick={searchhs} slot="end">
                <IonButton color="primary">
                  <IonIcon icon={search} />
                </IonButton>
              </IonButtons>
            ) : (
              ""
            )}
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing">
        {activeSearch && !isSearch ? (
          <div style={{ display: "block", paddingLeft: "24px", paddingRight: "24px", position: "relative", height: "calc(100% - 50px)" }}>
            <IonList className="ion-no-padding">
              {dichistorylist?.map((items, indexs) => {
                return (
                  <IonCol key={indexs} size="12" className="search-selection">
                    <div>
                      <IonImg src="./assets/images/history.svg" alt="" />
                      <IonText>{items.Name}</IonText>
                    </div>
                    <IonImg onClick={() => SearchHist(items)} src="./assets/images/crossarrow.svg" alt="" />
                  </IonCol>
                );
              })}


              {/* <IonCol size="12" className="search-selection">
                <div>
                  <IonIcon icon={search} />
                  <IonText>蛋白</IonText>
                </div>
                <IonImg src="./assets/images/crossarrow.svg" alt="" />
              </IonCol> */}

            </IonList>
          </div>) : (
          ""
        )}

        {activeSearch == false && activeDetails == false ? (
          <IonList className="themes nopadding">
            <IonCol className="data-selection Detailed">
              {itemTheamlist?.map((items, indexs) => {
                return (
                  <IonText
                    key={indexs}
                    className={activeText === indexs ? "select " : ""}
                    onClick={() => handleClickResult(items, indexs, '', true)}
                    ref={(element) => {
                      if (element && indexs === activeText) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "center",
                        });
                      }
                    }}
                  >
                    {items.Name}
                  </IonText>
                );
              })}

            </IonCol>
          </IonList>
        ) : (
          ""
        )}
        {activeSearch == false && activeDetails == false ? (
          <>
            {itemlist?.length != 0 ? (
              <>
                <div
                  style={{
                    display: "block",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                  }}
                >
                  <IonRow className="sorting">
                    <IonCol size="12">
                      <IonText>
                        Sort by Pinyin: {selectedValue == '1' ? <span>a to z</span> : <span>z to a</span>}
                      </IonText>
                      <span onClick={openModal}>
                        <IonImg src="./assets/images/Sorting.svg" />
                      </span>
                    </IonCol>
                  </IonRow>

                  <IonList className="vocabulary">
                    {itemlist?.map((items, indexs) => {
                      return (
                        <IonAccordionGroup
                          key={indexs}
                          className="accordiongroup vocabularygroup"
                        >
                          <IonAccordion>
                            <IonItem slot="header" color="white">
                              <IonText>
                                <span>{items.Word}</span>({items.Pinyin})
                              </IonText>
                            </IonItem>
                            <div className="w-100 subaccordion" slot="content">
                              <IonList className="list engvschinese">
                                <IonRow className="ion-no-padding">
                                  <IonCol size="12" className="ion-no-padding">
                                    <span>
                                      {items.groupedObjects ? (
                                        <>
                                            {Object.keys(items.groupedObjects).map(
                                              (type) => (
                                                <IonText key={type}>
                                                  <div className="txtupper">
                                                    {type}
                                                  </div>
                                                  <ul>
                                                    {items.groupedObjects[type].map(
                                                      (item, index) => (
                                                        <li key={index}>
                                                          {item.Value}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </IonText>
                                              )
                                            )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>

                                    <div className="more-example">
                                      {items?.SoundUrl && (
                                        <div className="PlayPause">
                                          <ReactAudioPlayer
                                            style={{ border: "none" }}
                                            ref={audioRef}
                                            onEnded={audioEnd}
                                            className="String"
                                            controls={false}
                                            controlsList="nodownload noplaybackrate"
                                            src={items?.SoundUrl}
                                          />
                                          <IonImg
                                            className="playbig"
                                            onClick={handlePlayPause}
                                            src={
                                              isPlaying
                                                ? "./assets/images/Pausebig.svg"
                                                : "./assets/images/playbig.svg"
                                            }
                                          />
                                        </div>
                                      )}
                                      <IonImg
                                        onClick={() => favouriteWord(items)}
                                        src={
                                          items.IsFavourite
                                            ? "./assets/images/starfill.svg"
                                            : "./assets/images/star.svg"
                                        }
                                        placeholder={undefined}
                                      />
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonList>
                            </div>
                          </IonAccordion>
                        </IonAccordionGroup>
                      );
                    })}
                  </IonList>


                </div>
              </>
            ) : (
              <div className="startvocabulary">
                <p>Start your first lesson to build your vocabulary</p>
              </div>
            )}
          </>
        ) : (
          <></>
        )
        }

        {isSearch ? (
          <div
            style={{
              display: "block",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <IonList className="vocabulary">
              {itemlist?.map((items, indexs) => {
                return (
                  <IonAccordionGroup
                    key={indexs}
                    className="accordiongroup vocabularygroup"
                  >
                    <IonAccordion>
                      <IonItem slot="header" color="white">
                        <IonText>
                          <span>{items.Word}</span>({items.Pinyin})
                        </IonText>
                      </IonItem>
                      <div className="w-100 subaccordion" slot="content">
                        <IonList className="list engvschinese">
                          <IonRow className="ion-no-padding">
                            <IonCol size="12" className="ion-no-padding">
                              <span>
                                {items.groupedObjects ? (
                                  <>
                                      {Object.keys(items.groupedObjects).map(
                                        (type) => (
                                          <IonText key={type}>
                                            <div className="txtupper">
                                              {type}
                                            </div>
                                            <ul>
                                              {items.groupedObjects[type].map(
                                                (item, index) => (
                                                  <li key={index}>
                                                    {item.Value}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </IonText>
                                        )
                                      )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>

                              <div className="more-example">
                                {items?.SoundUrl && (
                                  <div className="PlayPause">
                                    <ReactAudioPlayer
                                      style={{ border: "none" }}
                                      ref={audioRef}
                                      onEnded={audioEnd}
                                      className="String"
                                      controls={false}
                                      controlsList="nodownload noplaybackrate"
                                      src={items?.SoundUrl}
                                    />
                                    <IonImg
                                      className="playbig"
                                      onClick={handlePlayPause}
                                      src={
                                        isPlaying
                                          ? "./assets/images/Pausebig.svg"
                                          : "./assets/images/playbig.svg"
                                      }
                                    />
                                  </div>
                                )}
                                
                                <IonImg
                                  onClick={() => favouriteWord(items)}
                                  src={
                                    items.IsFavourite
                                      ? "./assets/images/starfill.svg"
                                      : "./assets/images/star.svg"
                                  }
                                  placeholder={undefined}
                                />
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonList>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                );
              })}
            </IonList>
          </div>
        ) : ("")}

      </IonContent>

      <IonModal
        isOpen={showModal}
        onDidDismiss={closeModal}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      // handleBehavior="cycle"
      >
        <IonRow>
          <IonList className="list accordiongroup languages">
            <IonRadioGroup
              value={selectedValue}
              onIonChange={handleRadioChange}
            >
              <IonItem class="ion-no-padding">
                <IonLabel class="sorting">
                  <h2>
                    Pinyin: <span>a to z</span>
                  </h2>
                  <IonRadio value={1} />
                </IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding">
                <IonLabel class="sorting">
                  <h2>
                    Pinyin: <span>z to a</span>
                  </h2>
                  <IonRadio value={2} />
                </IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding">
                <IonLabel class="sorting">
                  <h2>
                    Pinyin: <span>newest</span>
                  </h2>
                  <IonRadio value={3} />
                </IonLabel>
              </IonItem>
              <IonItem class="ion-no-padding">
                <IonLabel class="sorting">
                  <h2>
                    Pinyin: <span>oldest</span>
                  </h2>
                  <IonRadio value={4} />
                </IonLabel>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </IonRow>
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Starredvocabulary);