import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonRow, IonCol, IonText, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react';
import './Privacypolicy.scss';
import { chevronBack } from 'ionicons/icons';
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

import { RouteComponentProps, useHistory } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface PrivacypolicyProps extends OwnProps {}

const Privacypolicy: React.FC<PrivacypolicyProps> = () => {
  let url: string = CONFIG.API_ENDPOINT;//live
  const history = useHistory();  

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const [termlist, setTermlist] = useState();

  useIonViewWillEnter(() => {
    gettermscondition()
  });

  const gettermscondition = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      duration: 1000
    })

    try {
      const response = await fetch(url + '/account/termscondition', {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      })


      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      dismiss()
      if (json.StatusCode == 1) {
        setTermlist(json.Result)
        // await setIsLoggedIn(true);
        //  await setUsernameAction(username);
        // history.replace('/Beforewestart');
        // localStorage.setItem('logininfo', JSON.stringify(json));

      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const back = () => {
    history.replace({ pathname: "/tabs/settings" }, { direction: "none" });
  };

  return (
    <IonPage className='intro' placeholder={undefined}>
      <IonHeader className='ion-text-center' placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton onClick={back} color='primary' placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding' placeholder={undefined}><span>{t("privacypolicy.prvcy_and_plcy")}</span></IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='spacing' placeholder={undefined}>
        <IonRow placeholder={undefined}>
          <IonCol className="termscondition" size="12" placeholder={undefined}>
            <IonText placeholder={undefined}>
              <p>{termlist}</p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Privacypolicy);