import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonRow,
  IonText,
  IonCol,
  IonCard,
  IonCardContent,
  IonList,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Adjustmylevel.scss";
import { chevronBack} from "ionicons/icons";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
interface OwnProps extends RouteComponentProps {}
interface AdjustmylevelProps extends OwnProps {}
const Adjustmylevel: React.FC<AdjustmylevelProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  let url: string = CONFIG.API_ENDPOINT; //live
  const [activeIndex, setActiveIndex] = useState(1);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemlistremcount, setitemsRemCount] = React.useState<any>([]);
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetProfie();
    GetLevels();
    GetRemainBooksCount();
  });
  const handleClick = (index) => {
    setActiveIndex(index);
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetLevels = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setitems(json.Result);
        handleClick(json.Result.Level);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    const userData: any = localStorage.getItem("userlevel");
    const userConData = JSON.parse(userData);
    const data = {
      oldLevel: itemlist["Level"],
      newLevel: activeIndex,
    };
    try {
      const response = await fetch(url + "/User/SaveLevel", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      GetLevels();
      //dismiss()
      if (json.StatusCode === 1) {
        localStorage.setItem(
          "userlevel",
          JSON.stringify({
            Description: userConData.Description,
            Level: activeIndex,
            LevelGuid: userConData.LevelGuid,
            LevelId: userConData.LevelId,
            Name: userConData.Name,
          })
        );
        // history.replace('/login');
        await history.replace(
          { pathname: "/tabs/profile" },
          { direction: "none" }
        );
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const GetRemainBooksCount = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/Book/GetRemainBooksCount", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setitemsRemCount(json.Result.RemainBooksCount);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const back = () => {
    history.replace(
      { pathname: "/tabs/profile/currentlevel" },
      { direction: "none" }
    );
  };
  return (
    // {t("adjustmylevel")}
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={back} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("adjustmylevel.adj_my_level")}</span>
          </IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing">
        <IonList className="ion-padding-horizontal adjust Subscription">
          <IonCol>
            <IonText>
              {t("adjustmylevel.ur_lvl_des")}
              <span>({t("adjustmylevel.drag_to_adj_level_manually")})</span>
            </IonText>
          </IonCol>
          <IonCard className="adjectcard">
            <IonCardContent class="ion-no-padding">
              <div className="processline">
                <div className="numbers one" onClick={() => handleClick(1)}>
                  <div className="number-border">
                    <span className="dott"></span>
                  </div>
                  {activeIndex === 1 ? (
                    <div className="number-border start">
                      <span className="number-dott">1</span>
                      <IonText>{t("adjustmylevel.beginner")}</IonText>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="numbers two" onClick={() => handleClick(2)}>
                  <div className="number-border">
                    <span className="dott"></span>
                  </div>
                  {activeIndex === 2 ? (
                    <div className="number-border start">
                      <span className="number-dott">2</span>
                      <IonText>{t("adjustmylevel.advanced_beginner")}</IonText>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="numbers three" onClick={() => handleClick(3)}>
                  <div className="number-border">
                    <span className="dott"></span>
                  </div>
                  {activeIndex === 3 ? (
                    <div className="number-border start">
                      <span className="number-dott">3</span>
                      <IonText>{t("adjustmylevel.novice")}</IonText>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="numbers four" onClick={() => handleClick(4)}>
                  <div className="number-border">
                    <span className="dott"></span>
                  </div>
                  {activeIndex === 4 ? (
                    <div className="number-border start">
                      <span className="number-dott">4</span>
                      <IonText>{t("adjustmylevel.intermediate")}</IonText>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="numbers five" onClick={() => handleClick(5)}>
                  <div className="number-border">
                    <span className="dott"></span>
                  </div>
                  {activeIndex === 5 ? (
                    <div className="number-border start">
                      <span className="number-dott">5</span>
                      <IonText>{t("adjustmylevel.proficient")}</IonText>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </IonList>
        <IonRow>
          <IonCol size="12" className="levelsave ion-text-center">
            <IonButton onClick={saveContent} color="primary">
              {t("adjustmylevel.save")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Adjustmylevel);
