import React from 'react';
import { IonHeader, IonToolbar, IonGrid, IonItem, IonText, IonLabel, IonInput, IonImg, IonList, IonContent, IonPage, IonTitle, IonButton, IonCol, IonRow, useIonLoading, useIonToast } from '@ionic/react';
import './Forgotpassemail.scss';
import { CONFIG } from '../App';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { RouteComponentProps } from 'react-router';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { }

interface ForgotpassemailProps extends OwnProps { }

const Forgotpassemail: React.FC<ForgotpassemailProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const { handleSubmit, control, formState: { errors } } = useForm({});

  const forgotemail = async (item: any) => {

    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })

    const data = { email: item.email }
    try {
      const response = await fetch(url + '/account/sendotp', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      dismiss()


      presenttost({
        message: json.Message,
        duration: 1500,
        position: 'bottom'
      });

      if (json.StatusCode == 1) {
        localStorage.setItem('email', item.email);

        history.replace('/forgotpassverify', { direction: 'none' });
      }
    } catch (error) {

      presenttost({
        message: 'Please try again later',
        duration: 1500,
        position: 'bottom'
      });
      dismiss()
      console.error(error)

    }
  }
  return (
    <IonPage className='blue-bg'>
      <IonHeader className='bluescreen'>
        <IonToolbar color='primary'>
          <IonTitle>{t("forgotpassemail.fgt_pass")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleSubmit(data => forgotemail(data))}>
          <IonGrid className='main-box'>
            <IonList className="userform">

              <IonItem color='white' className='subtitle'>
                <IonLabel>
                  <IonText className='nowrap' no-margin>{t("forgotpassemail.enter_registered_email")}</IonText>
                </IonLabel>
              </IonItem>

              <IonCol>
                <IonItem className='tooltiparea forminput' color='white'>
                  <IonLabel>
                    <IonText no-margin>{t("forgotpassemail.email")}</IonText>
                  </IonLabel>
                </IonItem>
                <div className='iconwithinput pr-0'>
                  {/* <IonIcon icon={mail} /> */}
                  <IonImg src="./assets/images/mailicon.svg" alt="" />
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}/>
                    )}
                    control={control}
                    name="email"
                    rules={{
                      required: "This is a required field",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("forgotpassemail.invalid_email_msg")
                      }
                    }}
                  />
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="email"
                as={<div className='inputerror' />}
              />
            </IonList>

            <IonRow>
              <IonCol size="12">
                <IonButton color="white" type="submit" className='whitebtn' expand='block'>{t("forgotpassemail.send")}</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <div className="fixpagination">
          <div className="swiper-pagination swiper-pagination-bullets">
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Forgotpassemail);