import React, { useRef } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  useIonViewWillEnter,
  IonIcon,
  IonText,
  IonCol,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import "./Pinyin.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack } from "ionicons/icons";

interface OwnProps extends RouteComponentProps {}

interface Pinyin extends OwnProps {}

const Pinyin: React.FC<Pinyin> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [length, setLength] = React.useState(-1);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  useIonViewWillEnter(() => {});

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/pinyinpractice" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  return (
    <IonPage className="intro pages-space" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              onClick={() => back()}
              color="primary"
              placeholder={undefined}
            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("pinyin.pinyin")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color="primary" placeholder={undefined}></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing piniyinpage" placeholder={undefined}>
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <div className="pinpage">
              <IonList className="tone tone-main piniyin exe">
                <IonText className="subtitle">{t("pinyin.structure")}</IonText>
              </IonList>
              <IonList className="tone tone-main structure">
                <div>
                  <span>
                    <ul
                      style={{
                        justifyContent: "center",
                        backgroundImage: "none",
                        marginTop: "24px",
                      }}
                    >
                      <li className="primarybtn">
                        <IonText className="subtitle-pinyin">拼音</IonText>
                        <p>{t("pinyin.pinyin")}</p>
                      </li>
                    </ul>
                    <ul
                      style={{
                        justifyContent: "center",
                        backgroundImage: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <li className="plus">=</li>
                    </ul>
                    <ul style={{ backgroundImage: "none" }}>
                      <li className="redbtn">
                        <IonText className="subtitle-pinyin">声母</IonText>
                        <p>{t("pinyin.initials")}</p>
                      </li>
                      <li className="plus">+</li>
                      <li className="yellowbtn">
                        <IonText className="subtitle-pinyin">韵母</IonText>
                        <p>{t("pinyin.finals")}</p>
                      </li>
                      <li className="plus">+</li>
                      <li className="bluebtn">
                        <IonText className="subtitle-pinyin">声调</IonText>
                        <p>{t("pinyin.tones")}</p>
                      </li>
                    </ul>
                  </span>
                </div>
              </IonList>

              <IonList className="tone tone-main initials">
                <div className="borderline"></div>
              </IonList>

              <IonList className="tone tone-main piniyin exe">
                <IonText className="subtitle example">
                  {t("pinyin.examples")} 爸.
                </IonText>
              </IonList>
              <IonList className="tone tone-main piniyin1st">
                <div style={{ position: "relative", zIndex: "9" }}>
                  <IonImg
                    className="subtitle-pinyin ba"
                    src="./assets/images/ba.svg"
                    alt=""
                  />
                </div>
              </IonList>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="pinpage">
              <IonList className="tone-main initials">
                <IonCol class="recap" size="12">
                  <IonText>{t("pinyin.int+sing")}</IonText>
                </IonCol>
              </IonList>
              <IonList className="tone tone-main">
                <div>
                  <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      {/* <div>妈</div> */}
                      <IonImg className="subtitle-pinyin" src="./assets/images/mabig.svg" alt="" />
                    </IonText>
                    <IonImg
                      className="subtitle-pinyin"
                      src="./assets/images/ma.svg"
                      alt=""
                    />
                  </span>
                </div>
              </IonList>
              <IonList className="tone tone-main piniyin">
                <IonText className="subtitle">{t("pinyin.tone_mark1")}</IonText>
              </IonList>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="pinpage">
              <IonList className="tone-main initials">
                <IonCol class="recap" size="12">
                  <IonText>{t("pinyin.int+nasl")}</IonText>
                </IonCol>
              </IonList>
              <IonList className="tone tone-main initials">
                <div>
                  <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      {/* <div>星</div> */}
                      <IonImg className="subtitle-pinyin" src="./assets/images/xingbig.svg" alt="" />
                    </IonText>
                    <IonImg
                      className="subtitle-pinyin"
                      src="./assets/images/xing.svg"
                      alt=""
                    />
                  </span>
                </div>
              </IonList>
              <IonList className="tone tone-main piniyin">
                <IonText className="subtitle">{t("pinyin.tone_mark2")}</IonText>
              </IonList>
            </div>
          </IonSlide>

          <IonSlide>
            <div className="pinpage">
              <IonList className="tone-main initials">
                <IonCol class="recap" size="12">
                  <IonText>{t("pinyin.int+comp")}</IonText>
                </IonCol>
              </IonList>
              <IonList className="tone tone-main initials">
                <div>
                  <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      {/* <div>说</div> */}
                      <IonImg className="subtitle-pinyin" src="./assets/images/shuobig.svg" alt="" />
                    </IonText>
                    <IonImg
                      className="subtitle-pinyin"
                      src="./assets/images/shuo.svg"
                      alt=""
                    />
                  </span>
                </div>
              </IonList>
              <IonList className="tone tone-main piniyin">
                <IonText className="subtitle">
                  Special rule: If there are two vowels, and the first vowel
                  letter is “i”, “u”, or “ü”, the tone mark will be placed on
                  the following vowel.
                </IonText>
              </IonList>
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Pinyin);
