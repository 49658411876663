import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
  IonList,
  IonIcon,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  useIonViewDidEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./Themesports.scss";
import {
  close,
  chevronBack,
  lockClosed,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { CONFIG } from "../App";
import { array } from "yargs";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {
  id: string;
}

interface ThemesportsProps extends OwnProps {}

const Themesports: React.FC<ThemesportsProps> = ({ history, match }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const StringData: any = localStorage.getItem("ThemeData");
  const ThemeData = JSON.parse(StringData);
  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemvideolist, setVideoitems] = React.useState<any[]>([]);
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [isDataAvailable, setDataAvailable] = useState<any>(true);
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    index: string;
  }>();

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  // setthemeIddb(0)
  // setthemeIddb(themeIddb)
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";

  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const index = historyss.location.state ? historyss.location.state.index : "";

  let token = "";
  let resfreshtoken = "";
  const levelJSON: any = localStorage.getItem("levelinfo");
  const levalData = JSON.parse(levelJSON);
  useIonViewDidEnter(() => {});
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    //  const history = useHistory<any>();

    getthemes();
    // getVideoTutorials()
  });

  const getthemes = async () => {
    try {
      const response = await fetch(
        url +
          "/Book/GetLessons?themeGuid=" +
          (themeIddb ? themeIddb : ThemeData.Theme_guid) +
          "&level=" +
          levalData?.Leval,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      if (json.StatusCode == 1) {
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        if (json.Result.Lessons.length > 0) {
          setDataAvailable(true);
        } else {
          setDataAvailable(false);
        }
        let test: any = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        const sortedData = test.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        setitems(sortedData);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const Subscribe = async () => {
    history.replace("/tabs/Subscribe", { direction: "none" });
  };
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              <IonImg src="./assets/images/rocet.svg" placeholder={undefined} />
            </div>
            <p>{t("themesports.submit.level")} 5: Proficient</p>
            <p className="subtext">{t("themesports.submit.desc")}</p>
          </div>
        );
      },
    });
  };

  const submit1 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} placeholder={undefined} />
            </div>
            <p>{t("themesports.submit1.sub_t_unlk")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton
                onClick={() => {
                  Subscribe();
                  onClose();
                }}
                className="alertcancel w-150"
                placeholder={undefined}
              >
                {t("themesports.submit1.subscribe")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const submit2 = (item, index) => {
    let string = item.Types;
    let one = "";
    let two = "";
    if (string) {
      let result = string.split(",");
      one = result[0];
      two = result[1];
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body rolebox">
            <p className="mt0">{t("themesports.submit2.pick_role")}</p>
            <div className="react-confirm-alert-button-group">
              <div className="boxwidth">
                {item.RoleAImageUrl ? (
                  <div
                    className="usercircle"
                    onClick={() => {
                      goDialoguepractice(item, 0, index);
                      onClose();
                    }}
                  >
                    {/* <IonSkeletonText animated placeholder={undefined} /> */}

                    <IonImg src={item.RoleAImageUrl} alt="" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      goDialoguepractice(item, 0, index);
                      onClose();
                    }}
                    className="usercircle"
                  >
                    {one}
                  </div>
                )}
                <p>{one}</p>
              </div>
              <div className="boxwidth">
                {item.RoleBImageUrl ? (
                  <div
                    className="usercircle"
                    onClick={() => {
                      goDialoguepractice(item, 1, index);
                      onClose();
                    }}
                  >
                    <IonImg src={item.RoleBImageUrl} alt="" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      goDialoguepractice(item, 1, index);
                      onClose();
                    }}
                    className="usercircle"
                  >
                    {two}
                  </div>
                )}

                <p>{two}</p>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const gonextPage = async (val, index) => {
    //history.replace('/tabs/mainhome/themesports/5');
    if (val.IsLocked === false) {
      history.replace({
        pathname: "/tabs/mainhome/lesson",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          firstDescValue: val.ContentId,
          bookId: val.Bookid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "Themesports",
          backpageValue: 0,
        },
      });
    }
  };

  const goDialoguepractice = (item, value, index) => {
    const str = item.Types;
    const trimmedStr = str.replace(/\s/g, "");
    let string = trimmedStr;
    let one = "";
    let two = "";
    if (string) {
      let result = string.split(",");
      one = result[0];
      two = result[1];
    }

    history.replace({
      pathname: "/tabs/mainhome/dialoguepractice",
      state: {
        themeArray: itemlist,
        index: index,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: item.Id,
        role: value,
        rolename: value === 0 ? one : two,
        allrole: trimmedStr,
        backpage: "Themesports",
        backpageValue: 0,
      },
    });
  };

  const goDialoguePage = (items, index) => {
    const stringSpac = items.Types; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    if (items.IsLocked == false) {
      history.replace({
        pathname: "/tabs/mainhome/dialoguevideo",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          index: index,
          dialogueId: items.Id,
          title: items.Title_English,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          videoUrl: items.VideoUrl,
          thumb: items.ThumbnailImageUrl,
          role: stringWtot,
          backpage: "Themesports",
          backpageValue: 0,
        },
      });
    }
  };

  const gobackPageproficient = () => {
    //history.replace('/tabs/mainhome/themesports/5');
    //history.goBack()
    history.replace({
      pathname: "/tabs/mainhome/proficient",
      state: { levelId: levelId, level: level, levname: levname, index: index },
    });
    // localStorage.removeItem("itemlist");
  };

  const gotoPractice = (val, index) => {
    if (val.IsExistExercise == 1) {
      history.replace({
        pathname: "/tabs/mainhome/Practicequestion",
        state: {
          themeArray: itemlist,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "Themesports",
          backpageValue: 0,
        },
      });
      // history.go(0);
      // window.location.reload();
      localStorage.removeItem("answers");
      localStorage.removeItem("practice");
    } else {
      presenttost({
        message: "Exercise not available",
        duration: 1500,
        position: "bottom",
      });
    }
  };

  const savebookmark = (val, index, type) => {
    //BookGuid
    //IsFavourite
    if (itemlist[index].IsFavourite == 0) {
      itemlist[index].IsFavourite = 1;
      setBookmarkindex(index);
      saveContent(val, true, type);
    } else {
      itemlist[index].IsFavourite = 0;
      setBookmarkindex(-1);
      saveContent(val, false, type);
    }

    setitems([...itemlist]); // Use spread operator to create a new array reference
  };

  const saveContent = async (val, isFavourite, type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const data = {
      referenceId: type == 1 ? val.BookId : val.Id,
      isFavourite: isFavourite,
      typeId: type,
    };
    //dismiss()

    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              color="primary"
              onClick={gobackPageproficient}
              placeholder={undefined}
            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>
              {/* {t("themesports.frnt_dsk")} */}
              {themename ? themename : ThemeData?.DisplayName}
            </span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* onClick={submit} */}
            {/* <IonButton color="primary" placeholder={undefined}>
             <IonIcon icon={informationCircleOutline} />
              <IonImg
                className="info"
                src="./assets/images/bang-circle-blue.svg"
                alt="" placeholder={undefined}/>
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent
        className="ion-padding-horizontal themepage"
        placeholder={undefined}
      >
        <IonList className="themes reports" placeholder={undefined}>
          {/* <IonCol>
            <IonText>Lessons</IonText>
          </IonCol> */}
          {itemlist.length == 0 ? (
            <>
              {isDataAvailable ? (
                <>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      <div className="lessongbox">
                        <div className="themelesson">
                          <IonSkeletonText animated placeholder={undefined} />
                        </div>
                        <div className="details">
                          <h4>
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </h4>
                          <span className="gamename">
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </span>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      <div className="lessongbox">
                        <div className="themelesson">
                          <IonSkeletonText animated placeholder={undefined} />
                        </div>
                        <div className="details">
                          <h4>
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </h4>
                          <span className="gamename">
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </span>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      <div className="lessongbox">
                        <div className="themelesson">
                          <IonSkeletonText animated placeholder={undefined} />
                        </div>
                        <div className="details">
                          <h4>
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </h4>
                          <span className="gamename">
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </span>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      <div className="lessongbox">
                        <div className="themelesson">
                          <IonSkeletonText animated placeholder={undefined} />
                        </div>
                        <div className="details">
                          <h4>
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </h4>
                          <span className="gamename">
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </span>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      <div className="lessongbox">
                        <div className="themelesson">
                          <IonSkeletonText animated placeholder={undefined} />
                        </div>
                        <div className="details">
                          <h4>
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </h4>
                          <span className="gamename">
                            {" "}
                            <IonSkeletonText animated placeholder={undefined} />
                          </span>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </>
              ) : (
                <>
                  <p className="nodata ion-text-center ion-no-margin">
                    No data available
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              {itemlist.map((items, index) => {
                return (
                  <div key={index}>
                    {items.type === "lesson" ? (
                      <>
                        <IonCard placeholder={undefined}>
                          <IonCardContent
                            class="ion-no-padding"
                            placeholder={undefined}
                          >
                            <div
                              className="lessongbox"
                              // onClick={() => lessonFuc(items, index)}
                              onClick={() => gonextPage(items, index)}
                            >
                              <div className="themelesson">
                                <IonImg
                                  src={items.ImageUrl}
                                  placeholder={undefined}
                                />
                              </div>
                              <div className="details">
                                {/* <h4>Lesson {index + 1} - {items.BookId} - {items.IsExistExercise}</h4> */}
                                <h4>
                                  {t("themesports.lesson")} {items.number}{" "}
                                </h4>
                                <span className="gamename">{items.Title}</span>
                                {items.Progress !== 0 ? (
                                  <div className="line">
                                    <ProgressBar
                                      bgColor="var(--ion-color-primary)"
                                      height="9px"
                                      className="process"
                                      completed={items.Progress}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {items.IsLocked === false ? (
                              <div className="icons">
                                <IonImg
                                  onClick={() => savebookmark(items, index, 1)}
                                  className="ion-padding-bottom bookmark"
                                  src={
                                    items.IsFavourite
                                      ? "./assets/images/Bookmarkfill.svg"
                                      : "./assets/images/Bookmark.svg"
                                  }
                                  alt=""
                                  placeholder={undefined}
                                />
                                <IonImg
                                  onClick={() => gotoPractice(items, index)}
                                  className="pen"
                                  src="./assets/images/pen.svg"
                                  alt=""
                                  placeholder={undefined}
                                />
                              </div>
                            ) : (
                              <div className="icons">
                                <IonIcon
                                  color="primary"
                                  icon={lockClosedOutline}
                                  placeholder={undefined}
                                />
                              </div>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </>
                    ) : (
                      <>
                        <IonCard placeholder={undefined}>
                          <IonCardContent
                            class="ion-no-padding"
                            placeholder={undefined}
                          >
                            <div
                              className="lessongbox"
                              onClick={() => goDialoguePage(items, index)}
                            >
                              <div className="themelesson">
                                <IonImg
                                  src={
                                    items.ImageUrl !== ""
                                      ? items.ImageUrl
                                      : "./assets/images/noimg.jpg"
                                  }
                                  placeholder={undefined}
                                />
                              </div>
                              <div className="details">
                                <h4 className="title">{items.Title}</h4>
                                <h4>
                                  {t("themesports.dialogue")} {items.number}{" "}
                                </h4>
                                <span className="gamename">
                                  {items.ContentEn}
                                </span>
                                <div className="line">
                                  <ProgressBar
                                    bgColor="var(--ion-color-primary)"
                                    height="9px"
                                    className="process"
                                    completed={80}
                                  />
                                </div>
                              </div>
                            </div>

                            {items.IsLocked === false ? (
                              <div className="icons">
                                <IonImg
                                  onClick={() => savebookmark(items, index, 4)}
                                  className="ion-padding-bottom bookmark"
                                  src={
                                    items.IsFavourite
                                      ? "./assets/images/Bookmarkfill.svg"
                                      : "./assets/images/Bookmark.svg"
                                  }
                                  alt=""
                                  placeholder={undefined}
                                />
                                <IonImg
                                  onClick={() => {
                                    submit2(items, index);
                                  }}
                                  className="pen"
                                  src="./assets/images/pen.svg"
                                  alt=""
                                  placeholder={undefined}
                                />
                              </div>
                            ) : (
                              <div className="icons">
                                <IonIcon
                                  color="primary"
                                  icon={lockClosedOutline}
                                  placeholder={undefined}
                                />
                              </div>
                            )}
                          </IonCardContent>
                        </IonCard>
                      </>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Themesports);
