import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonText,
  IonCol,
  IonCard,
  IonRow,
  IonCardContent,
  IonImg,
  IonList,
  IonIcon,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSkeletonText,
} from "@ionic/react";
import "./Proficient.scss";
import { chevronBack, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface ProficientProps extends OwnProps {}

const Proficient: React.FC<ProficientProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemRecentlist, setRecentitems] = React.useState<any[]>([]);
  const [itemThemeslist, setThemesitems] = React.useState<any[]>([]);
  const [recentShow, setRecentShow] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [itemlogin, setLogindata] = React.useState<any>({});
  const [userLevels, setUserLevels] = useState<any>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const levelJSON: any = localStorage.getItem("levelinfo");
  const levalData = JSON.parse(levelJSON);

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const level: any = historyss.location.state
    ? historyss.location.state.level
    : "";

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  let token = "";
  let resfreshtoken = "";
  let logindata: any;
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    //userdata = logindata.Result
    setLogindata(logindata.Result);
    const ThemesAndRecLesson: any = localStorage.getItem("ThemesAndRecLesson");
    const data = JSON.parse(ThemesAndRecLesson);
    // getthemes();
    getUserLevels();
    if (data === null) {
      getthemes();
    } else {
      setThemesitems(data.Result.Themes);
      let test: any = [];

      const array1 = data?.Result?.Recentlys?.Lessons;
      const array2 = data?.Result?.Recentlys?.Dialogues;
      const maxLength = Math.max(array1?.length, array2?.length);

      for (let i = 0; i < maxLength; i++) {
        // Your logic here
        if (array1[i]) {
          array1[i]["Type"] = "lessons";
          array1[i]["Number"] = i + 1;
          test.push(array1[i]);
        }
        if (array2[i]) {
          array2[i]["Type"] = "dialogues";
          array2[i]["Number"] = i + 1;
          test.push(array2[i]);
        }
      }

      setRecentitems(test);
      setShowImage(true);
      setRecentShow(false);
      getthemesLocalstorage();
    }
  });
  const getthemesLocalstorage = async () => {
    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson?type=C", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json));
      setShowImage(true);
      if (json.StatusCode == 1) {
        setThemesitems(json.Result.Themes);

        let test: any = [];

        const array1 = json.Result.Recentlys.Lessons;
        const array2 = json.Result.Recentlys.Dialogues;
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["Type"] = "lessons";
            array1[i]["Number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["Type"] = "dialogues";
            array2[i]["Number"] = i + 1;
            test.push(array2[i]);
          }
        }


        setRecentitems(test);
        setRecentShow(false);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const getUserLevels = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetLevels", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode == 1) {
        setUserLevels(json.Result);
         
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson?type=C", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json));
      setShowImage(true);
      if (json.StatusCode == 1) {
        setThemesitems(json.Result.Themes);

        let test: any = [];

        const array1 = json.Result.Recentlys.Lessons;
        const array2 = json.Result.Recentlys.Dialogues;
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["Type"] = "lessons";
            array1[i]["Number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["Type"] = "dialogues";
            array2[i]["Number"] = i + 1;
            test.push(array2[i]);
          }
        }


        setRecentitems(test);
        setRecentShow(false);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   //duration: 3000
    // });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const gonextPagethemesports = (val) => {
    //history.replace('/tabs/mainhome/themesports/5');
    localStorage.setItem("ThemeData", JSON.stringify(val));
    // history.replace('/tabs/mainhome/themesports/'+val.Theme_guid);
    if (val.IsBookExists == true) {
      history.replace(
        {
          pathname: "/tabs/mainhome/themesports",
          state: {
            themeId: val.Theme_guid,
            themename: val.DisplayName,
            levelId: levelId,
            level: level,
            levname: levname,
            backpage: "proficient",
          },
        },
        { direction: "none" }
      );
      //  history.go(0)
      // window.location.reload();
    } else {
      presenttost({
        message: "Books not available for selected theme and your level.",
        duration: 1500,
        position: "bottom",
      });
    }
  };
  const gobackPagehome = () => {
    history.replace({
      pathname: "/tabs/mainhome/home",
      state: { levelId: levelId, level: level, levname: levname },
    });
    // history.go(0)
    // window.location.reload();
  };

  const goLessonPage = (val, index) => {
    //history.replace('/tabs/mainhome/themesports/5');

    history.replace({
      pathname: "/tabs/mainhome/lesson",
      state: {
        themeArray: itemRecentlist,
        themeId: val.ThemeGuid,
        lessonname: val.Title,
        index: index,
        lessonsId: val.BookGuid,
        bookId: val.BookId,
        themename: "",
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: "proficient",
        backpageValue: 1,
      },
    });
  };

  const goDialoguePage = (val, index) => {
    const stringSpac = val.Types; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    if (val.IsLocked == false) {
      history.replace({
        pathname: "/tabs/mainhome/dialoguevideo",
        state: {
          themeArray: itemRecentlist,
          themeId: val.ThemeGuid,
          index: index,
          dialogueId: val.Id,
          title: val.Title,
          themename: "",
          levelId: levelId,
          level: level,
          levname: levname,
          videoUrl: val.VideoUrl,
          role: stringWtot,
          backpage: "proficient",
          backpageValue: 1,
        },
      });
    }
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              {level === 1 && (
                <IonImg
                  src="./assets/images/shoeprint.svg"
                  placeholder={undefined}
                />
              )}
              {level === 2 && (
                <IonImg
                  src="./assets/images/bicycle.svg"
                  placeholder={undefined}
                />
              )}
              {level === 3 && (
                <IonImg src="./assets/images/car.svg" placeholder={undefined} />
              )}
              {level === 4 && (
                <IonImg
                  src="./assets/images/plane.svg"
                  placeholder={undefined}
                />
              )}
              {level === 5 && (
                <IonImg
                  src="./assets/images/rocet.svg"
                  placeholder={undefined}
                />
              )}
            </div>
            <p>
              {t("proficient.submit.level")} {userLevels[level - 1].LevelType}:{" "}
              {userLevels[level - 1].Name}
            </p>
            <p className="subtext">
              {userLevels[level - 1].Description}
            </p>
          </div>
        );
      },
    });
  };

  //shoeprint.svg
  //bicycle.svg
  //car.svg
  //plane.svg
  //rocet.svg

  // Splitting items into two arrays based on displayValue
  const oddItems: any = [];
  const evenItems: any = [];

  itemThemeslist?.forEach((items, index) => {
    const displayValue = items.DisplayOrder % 2 === 0;
    if (displayValue) {
      evenItems.push(items);
    } else {
      oddItems.push(items);
    }
  });

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader
        className="ion-text-center dubbletitle"
        placeholder={undefined}
      >
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={gobackPagehome}
                placeholder={undefined}
              >
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle
              color="primary"
              className="font20 ion-no-padding"
              placeholder={undefined}
            >
              <span>
                {t("proficient.level")} {level ? level : levalData?.Leval}:{" "}
                {levname ? levname : levalData?.LevalName}
              </span>
            </IonTitle>
            <IonButtons onClick={submit} slot="end" placeholder={undefined}>
              <IonButton color="warning" placeholder={undefined}>
                {/* <IonIcon icon={informationCircleOutline} /> */}
                <IonImg
                  className="info"
                  src="./assets/images/bang-circle-blue.svg"
                  alt=""
                  placeholder={undefined}
                />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-horizontal" placeholder={undefined}>
        {/* {logindata?.Result?.IsCorporate === true && itemRecentlist.length === 0 ? ( */}

        {recentShow ? (
          <span style={{ display: "flex" }}>
            <IonSkeletonText
              animated
              style={{
                width: "100%",
                height: "194px",
                marginRight: "7px",
                borderRadius: "15px",
              }}
              placeholder={undefined}
            />
            <IonSkeletonText
              animated
              style={{
                width: "100%",
                height: "194px",
                marginRight: "7px",
                borderRadius: "15px",
              }}
              placeholder={undefined}
            />
          </span>
        ) : (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>
                  {t("proficient.rcntly_viwed")}
                </IonText>
              </IonCol>
            </IonList>
            <IonList className="recommended-listing" placeholder={undefined}>
              {itemRecentlist?.map((items, index) => {
                return (
                  <div className="divlisting" key={index}>
                    {items.Type === "lessons" ? (
                      <>
                        <IonCard
                          key={index}
                          onClick={() => goLessonPage(items, index)}
                          placeholder={undefined}
                        >
                          {showImage ? (
                            <div className="imglist">
                              <img
                                src={items.ImageUrl}
                                alt="Silhouette of mountains"
                              />
                            </div>
                          ) : (
                            <IonSkeletonText
                              animated
                              style={{
                                width: "100%",
                                height: "138px",
                                margin: "0px",
                              }}
                              placeholder={undefined}
                            />
                          )}
                          <IonCardContent placeholder={undefined}>
                            <span>{items.Title}</span>
                          </IonCardContent>
                        </IonCard>
                      </>
                    ) : (
                      <>
                        <IonCard
                          key={index}
                          onClick={() => goDialoguePage(items, index)}
                          placeholder={undefined}
                        >
                          {showImage ? (
                            <div className="imglist">
                              <img
                                src={items.ImageUrl}
                                alt="Silhouette of mountains"
                              />
                            </div>
                          ) : (
                            <IonSkeletonText
                              animated
                              style={{
                                width: "100%",
                                height: "138px",
                                margin: "0px",
                              }}
                              placeholder={undefined}
                            />
                          )}
                          <IonCardContent placeholder={undefined}>
                            <span>{items.ContentEn}</span>
                          </IonCardContent>
                        </IonCard>
                      </>
                    )}
                  </div>
                );
              })}
            </IonList>
          </>
        )}

        {/* {logindata?.Result?.IsCorporate === true? (
          <>
          </>
        ) : (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>{t("proficient.rcmnd_fr_u")}</IonText>
              </IonCol>
            </IonList>
            <IonList className="recommended-listing" placeholder={undefined}>
              { itemRecentlist.length === 0 ? (
                <IonSkeletonText animated style={{ width: "79px", height: "138px", marginRight: "7px" }} placeholder={undefined} />
              ) : (
                <>
                  {itemRecentlist.map((items, index) => {
                    return (
                      <IonCard key={index} onClick={() => goLessonPage(items, index)} placeholder={undefined}>
                        {showImage ? (
                          <div className="imglist">
                            <img src={items.ImageUrl} alt="Silhouette of mountains" />
                          </div>
                        ) : (
                          <IonSkeletonText animated style={{ width: "79px", height: "138px", marginRight: "7px" }} placeholder={undefined} />
                        )}
                        <IonCardContent placeholder={undefined}>
                          <span>{items.Title_tc}</span>
                        </IonCardContent>
                      </IonCard>
                    );
                  })}
                </>
              )}
            </IonList>
          </>
        )} */}

        {itemThemeslist?.length == 0 ? (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>
                  {t("proficient.themes")}
                </IonText>
              </IonCol>
            </IonList>
            <IonRow className="theme-listing" placeholder={undefined}>
              <ul>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "250px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "153px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "153px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "153px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "250px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
                <li>
                  <IonCard placeholder={undefined}>
                    <IonCardContent
                      class="ion-no-padding"
                      placeholder={undefined}
                    >
                      {/* <h4>{items.DisplayName}</h4> */}

                      <IonSkeletonText
                        animated
                        style={{
                          width: "100%",
                          height: "153px",
                          margin: "0px",
                        }}
                        placeholder={undefined}
                      />
                    </IonCardContent>
                  </IonCard>
                </li>
              </ul>
            </IonRow>
          </>
        ) : (
          <>
            <IonList className="themes" placeholder={undefined}>
              <IonCol placeholder={undefined}>
                <IonText placeholder={undefined}>
                  {t("proficient.themes")}
                </IonText>
              </IonCol>
            </IonList>

            <IonRow className="theme-listing" placeholder={undefined}>
                <div className="proficienttheme">
                  <ul>
                    {oddItems.map((items, index) => (
                      <li
                        key={index}
                        onClick={() => gonextPagethemesports(items)}
                        placeholder={undefined}
                      >
                        <IonCardContent
                          class="ion-no-padding"
                          placeholder={undefined}
                        >
                          <h4>{items.DisplayName}</h4>
                          {items.ImageUrl ? (
                            <>
                              {!imageLoaded && (
                                <IonSkeletonText
                                  animated
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "0px",
                                  }}
                                  placeholder={undefined}
                                />
                              )}
                              <img
                                src={
                                  items.ImageUrl
                                    ? items.ImageUrl
                                    : "./assets/images/sport.svg"
                                }
                                onLoad={handleImageLoad}
                                style={{
                                  display: imageLoaded ? "block" : "none",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <IonSkeletonText
                                animated
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  margin: "0px",
                                }}
                                placeholder={undefined}
                              />
                            </>
                          )}
                        </IonCardContent>
                      </li>
                    ))}
                    </ul>
                    
                    <ul>
                    {evenItems.map((items, index) => (
                      <li
                        key={index}
                        onClick={() => gonextPagethemesports(items)}
                        placeholder={undefined}
                      >
                        <IonCardContent
                          class="ion-no-padding"
                          placeholder={undefined}
                        >
                          <h4>{items.DisplayName}</h4>
                          {items.ImageUrl ? (
                            <>
                              {!imageLoaded && (
                                <IonSkeletonText
                                  animated
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    margin: "0px",
                                  }}
                                  placeholder={undefined}
                                />
                              )}
                              <img
                                src={
                                  items.ImageUrl
                                    ? items.ImageUrl
                                    : "./assets/images/sport.svg"
                                }
                                onLoad={handleImageLoad}
                                style={{
                                  display: imageLoaded ? "block" : "none",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <IonSkeletonText
                                animated
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  margin: "0px",
                                }}
                                placeholder={undefined}
                              />
                            </>
                          )}
                        </IonCardContent>
                      </li>
                    ))}
                  </ul>
                </div>
              {/* <ul>
                {itemThemeslist?.map((items, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => gonextPagethemesports(items)}
                      placeholder={undefined}
                    >
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <h4>{items.DisplayName}</h4>
                        {items.ImageUrl ? (
                          <>
                            {!imageLoaded && (
                              <IonSkeletonText
                                animated
                                style={{
                                  width: "100%",
                                  height: "138px",
                                  margin: "0px",
                                }}
                                placeholder={undefined}
                              />
                            )}
                            <img
                              src={
                                items.ImageUrl
                                  ? items.ImageUrl
                                  : "./assets/images/sport.svg"
                              }
                              onLoad={handleImageLoad}
                              style={{
                                display: imageLoaded ? "block" : "none",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <IonSkeletonText
                              animated
                              style={{
                                width: "100%",
                                height: "138px",
                                margin: "0px",
                              }}
                              placeholder={undefined}
                            />
                          </>
                        )}
                      </IonCardContent>
                    </li>
                  );
                })}
              </ul> */}
            </IonRow>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Proficient);
