import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonList,
  IonGrid,
  IonImg,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { setMenuEnabled } from "../data/sessions/sessions.actions";
import { setHasSeenTutorial } from "../data/user/user.actions";
import "./Testquestion.scss";
import { RouteComponentProps } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
  setMenuEnabled: typeof setMenuEnabled;
}

interface Testquestion extends OwnProps, DispatchProps {}

const Testquestion: React.FC<Testquestion> = ({ history, setMenuEnabled }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showResults, setShowResults] = React.useState(1);
  const [lengthque, setLengthQue] = React.useState(1);
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [quesdata, setQuestion] = React.useState<any[]>([]);
  const [length, setLength] = React.useState(-1);

  let token = "";
  let resfreshtoken = "";
  let dd: any[] = [];

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="icon">
              <IonImg src="./assets/images/info.svg" alt="" placeholder={undefined} />
            </div>
            <p>
            {t("testquestion.submit.cnfrm_skp_tst")}
            </p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel" placeholder={undefined}>
              {t("testquestion.submit.cancel")}
              </IonButton>
              <IonButton
                onClick={() => {
                  continuebtn();
                  onClose();
                } }
                className="alertsubmit" placeholder={undefined}>
                {t("testquestion.submit.continue")}
              </IonButton>
            </div>
          </div>
        );
      },
      /*confirmAlert({
        title: 'Confirm to submit',
        message: 'You will not get your suggested level if you skip the test, are you sure?',
        buttons: [
          {
            label: 'Yes',
          //  onClick: () => alert('Click Yes')
          },
          {
            label: 'No',
          //  onClick: () => alert('Click No')
          }
        ]*/
    });
  };
  useIonViewWillEnter(() => {
    // setMenuEnabled(false);
    setLength(-1);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setShowResults(1);
    getproficiencyquestion();
  });

  const getproficiencyquestion = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/account/getproficiencyquestion", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();

      dismiss();

      if (json.StatusCode == 1) {
        setitems(json.Result);
        setLengthQue(json.Result.length);

        // setitems(prevArray => [...json.Result, itemlist])
        // setVehicleData([...vehicleData, ...json.Result]);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gototestdone = async () => {
    // await setHasSeenTutorial(true);
    // await setMenuEnabled(true);
    history.replace("/testdone", { direction: "none" });
  };

  let ansques: any;
  const gotonextques = async (qus: any, type) => {
    //1 for Understand and 2= dont understand

    let data = itemlist[showResults - 1];
    if (data) {
      setShowResults(qus);
      setLength(showResults);
      if (type == 1) {
        //1 for Understand
        ansques = data.QuestionId + "," + type;

        //setQuestion(quesdata => ([...quesdata, ...ansques]));

        setQuestion([...quesdata, ansques]);
        //dd.push(ansques)
      } else if (type == 2) {
        //2= dont understand
        ansques = data.QuestionId + "," + type;
        setQuestion([...quesdata, ansques]);
        dd.push(ansques);
      } else {
      }
    }
    // history.replace('/testdone', { direction: 'none' });
  };

  useEffect(() => {



    if (length == lengthque) {

      setLength(-1);
      SaveOptionAnswer();
    }
    
  });

  const continuebtn = async () => {
    history.replace("/tabs/mainhome", { direction: "none" });
  };
  const gotoskip = async () => {
    submit();
  };
  //    history.replace('/tabs/mainhome', { direction: 'none' });

  const SaveOptionAnswer = async () => {
    //   present({
    //     message: '',
    //  })
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let beforeOptionslist = "";
    let proficiencyOptionslist = quesdata.join("#");
    setLength(-1);
    //return


    const data = {
      age: "",
      beforeOptions: beforeOptionslist,
      proficiencyOptions: proficiencyOptionslist,
    };
    
    try {
      const response = await fetch(url + "/account/SaveOptionAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      if (json.StatusCode == 1) {
        setLength(-1);
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
        history.replace("/testdone", { direction: "none" });
      } else if (json.StatusCode == 3) {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  return (
    <IonPage className="pages-space" id="tutorial-page" placeholder={undefined}>
      <IonHeader className="noback" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color="primary" placeholder={undefined}>
            <IonRow placeholder={undefined}>
              <IonCol size="6" className="ion-text-left ion-no-padding" placeholder={undefined}>
                <span>Q{showResults}</span>
              </IonCol>
              <IonCol
                onClick={gotoskip}
                size="6"
                className="ion-text-right pointer ion-no-padding font18" placeholder={undefined}>
                {t("testquestion.skip")}
              </IonCol>
            </IonRow>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen placeholder={undefined}>
        <IonList className="testpage question height100" placeholder={undefined}>
          {itemlist.map((items, index) => {
            return (
              <IonGrid
                style={{ display: showResults == index + 1 ? "block" : "none" }}
                className="content" placeholder={undefined}>
                <IonRow placeholder={undefined}>
                  <IonCol size="12" className="ion-text-center" placeholder={undefined}>
                    <p className="ion-no-padding no-margin">{items.Question}</p>
                  </IonCol>

                  {items.QuestionType == "TEXT" ? (
                    <IonCol size="12" className="que-height ion-text-left" placeholder={undefined}>
                      <div className="textbox">{items.FilePath}</div>
                    </IonCol>
                  ) : (
                    <IonCol
                        size="12"
                        className="test-audio ion-text-left whitebg que-height" placeholder={undefined}>
                      <ReactAudioPlayer
                        className="String"
                        controls
                        controlsList="nodownload noplaybackrate"
                      >
                        <source src={items.FilePath} type="audio/mpeg"></source>
                        {/* <source src="/assets/audio.mp3" type="audio/mpeg"></source> */}
                      </ReactAudioPlayer>
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            );
          })}
          {/* <IonGrid style={{ display: showResults != 1 ? "block" : "none" }} className='content'>
            <IonRow>
              <IonCol size="12" className='ion-text-center'>
                <p className='ion-no-padding no-margin'>Listen to the following audio</p>
              </IonCol>
              <IonCol size="12" className='test-audio ion-text-left whitebg que-height'>
                <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                  <source src="/assets/audio.mp3" type="audio/mpeg"></source>
                </ReactAudioPlayer>
              </IonCol>
            </IonRow>
          </IonGrid> */}
          <IonRow class="skiptestpage testquestion" placeholder={undefined}>
            <IonCol size="6" className="understand" placeholder={undefined}>
              <IonButton
                onClick={() => gotonextques(showResults + 1, 1)}
                class="white"
                color="white"
                fill="outline"
                shape="round"
                expand="block" placeholder={undefined}>
                {t("testquestion.understand")}
              </IonButton>
            </IonCol>
            {/* <IonCol size='6' style={{ display: showResults == lengthque ? "block" : "none" }}>
              <IonButton onClick={gototestdone} class='white' color='white' fill="outline" shape="round" expand="block">I understand</IonButton>
            </IonCol> */}
            <IonCol size="6" className="notunderstand" placeholder={undefined}>
              <IonButton
                onClick={() => gotonextques(showResults + 1, 2)}
                class="white"
                color="white"
                fill="outline"
                shape="round"
                expand="block" placeholder={undefined}>
                {t("testquestion.not_understand")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default Testquestion;
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: ({
//     setHasSeenTutorial,
//     setMenuEnabled
//   }),
//   component: Beforewestart
// });
