import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonGrid, IonContent, IonImg, IonPage, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText, useIonLoading, IonToast, useIonToast } from '@ionic/react';
import { setIsLoggedIn, setUsername } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { info } from 'console';
import { object, string } from 'yup';
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form';

import { ErrorMessage } from "@hookform/error-message";
import { CONFIG } from '../App';

import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { }

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface SignupProps extends OwnProps, DispatchProps { }

const Signup: React.FC<SignupProps> = ({ setIsLoggedIn, history, setUsername: setUsernameAction }) => {
  //const { register, formState: { errors } } = useForm();
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const { handleSubmit, control, formState: { errors } } = useForm({});

  var setmessage = ''
  // const onSubmit = (data: any) => {
  //    
  //   alert(JSON.stringify(data, null, 2));
  // };

  const signup = async (item: any) => {


    if (item.pwd != item.confpwd) {
      presenttost({
        message: 'Passwords does not match',
        duration: 5000,
        position: 'bottom'
      });
      return
    }
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    var encodedString = btoa(item.pwd);

    const data = { username: item.username, email: item.email, password: encodedString, signUpType: 'Normal' }
    try {
      const response = await fetch(url + '/account/registeruser', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      dismiss()
      setmessage = json.Message
      presenttost({
        message: setmessage,
        duration: 1500,
        position: 'bottom'
      });

      if (json.StatusCode == 1) {
        localStorage.setItem('userID', json.Result.UserId)
        history.replace('/Termscondition', { direction: 'none' });
      }
    } catch (error) {
      console.error(error)
      dismiss()
    }
  }


  const checkemailexits = async (item: any) => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })


    const data = { email: item.email }
    try {
      const response = await fetch(url + '/account/checkemailexist', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()
      if (json.StatusCode == 1) {
        // await setIsLoggedIn(true);
        //  await setUsernameAction(username);
        history.replace('/Beforewestart');
        localStorage.setItem('logininfo', JSON.stringify(json));

      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }
      // useEffect(() => {
      //   localStorage.setItem('items', JSON.stringify(json));
      // }, [items]);


      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const togglePasswordVisiblity = () => {

    setIsRevealPwd(isRevealPwd ? false : true);
  };
  const togglePasswordVisiblityconf = () => {


    setIsRevealPwdconf(isRevealPwdconf ? false : true);
  };

  const onChangeemail = () => {

  }


  return (
    <IonPage className='blue-bg' id="signup-page" placeholder={undefined}>

      <IonHeader className='bluescreen' placeholder={undefined}>
        <IonToolbar color='primary' placeholder={undefined}>
          <IonTitle placeholder={undefined}>{t("signup.register")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent placeholder={undefined}>
        <form onSubmit={handleSubmit(data => signup(data))}>
          <IonGrid className='main-box' placeholder={undefined}>
            <IonList className="userform register" placeholder={undefined}>

              <IonCol placeholder={undefined}>
                <IonLabel placeholder={undefined}>{t("signup.name")}</IonLabel>
                <div className='iconwithinput pr-0'>
                  {/* <IonIcon icon={person} /> */}
                  <IonImg src="./assets/images/usericon.png" alt="" placeholder={undefined} />
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                    )}
                    control={control}
                    name="username"
                    rules={{
                      required: t("signup.required"),
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "invalid username address"
                      // }
                    }}
                  />
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="username"
                as={<div className='inputerror' />}
              />

              <IonCol placeholder={undefined}>
                <IonItem className='tooltiparea forminput' color='white' placeholder={undefined}>
                  <IonLabel placeholder={undefined}>
                    <IonText no-margin placeholder={undefined}>{t("signup.email")}</IonText>
                  </IonLabel>
                  <div className='labeltitle'>
                    {/* <IonIcon icon={informationCircleOutline} /> */}
                    <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                    <div className="tooltip">{t("signup.tltp_reg_email")}</div>
                  </div>
                </IonItem>
                <div className='iconwithinput pr-0'>
                  {/* <IonIcon icon={mail} /> */}
                  <IonImg src="./assets/images/mailicon.svg" alt="" placeholder={undefined} />
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput onBlur={onBlur}
                      onIonChange={onChange}
                      //  onIonChange={e => {onChangeemail() }}
                      value={value} placeholder={undefined}/>
                    )}
                    control={control}
                    name="email"
                    rules={{
                      required: t("signup.required"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("signup.email_in")
                      }
                    }}
                  />
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="email"
                as={<div className='inputerror' />}
              />

              <IonCol placeholder={undefined}>
                <IonItem className='tooltiparea forminput' color='white' placeholder={undefined}>
                  <IonLabel placeholder={undefined}>
                    <IonText no-margin placeholder={undefined}>{t("signup.password")} {isRevealPwd}</IonText>
                  </IonLabel>
                  <div className='labeltitle'>
                    {/* <IonIcon icon={informationCircleOutline} /> */}
                    <IonImg src="./assets/images/bang-in-circle.png" alt="" placeholder={undefined} />
                    <div className="tooltip">{t("signup.pass_tltp")}</div>
                  </div>
                </IonItem>
                <div className='iconwithinput'>
                  {/* <IonIcon icon={lockClosed} /> */}
                  <IonImg src="./assets/images/lock.svg" alt="" placeholder={undefined} />
                  {/* <IonInput name="pwd" onIonChange={(e: any) => setPwd(e.target.value)} value={pwd} type={isRevealPwd ? "text" : "password"} required></IonInput>
                <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} /> */}

                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwd ? "text" : "password"} onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                    )}
                    control={control}
                    name="pwd"
                    rules={{
                      required: t("signup.required"),
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "invalid pwd address"
                      // }
                    }}
                  />
                  <IonImg onClick={() => togglePasswordVisiblity()} src={isRevealPwd ? '/assets/images/showpassword.svg' : './assets/images/hidepassword.svg'} alt="" placeholder={undefined} />
                  {/* <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} /> */}
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="pwd"
                as={<div className='inputerror' />}
              />
              <IonCol placeholder={undefined}>
                <IonLabel placeholder={undefined}>{t("signup.cnfrm_pass")}</IonLabel>
                <div className='iconwithinput'>
                  {/* <IonIcon icon={lockClosed} /> */}
                  <IonImg src="./assets/images/lock.svg" alt="" placeholder={undefined} />
                  {/* <IonInput name="confpwd" onIonChange={(e: any) => confsetPwd(e.target.value)} value={confpwd} type={isRevealPwdconf ? "text" : "password"} required></IonInput> */}
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput type={isRevealPwdconf ? "text" : "password"} onBlur={onBlur}
                      onIonChange={onChange}
                      value={value} placeholder={undefined}/>
                    )}
                    control={control}
                    name="confpwd"
                    rules={{
                      required: t("signup.required"),
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      //   message: "invalid confpwd address"
                      // }
                    }}
                  />
                  <IonImg onClick={() => togglePasswordVisiblityconf()} src={isRevealPwdconf ? '/assets/images/showpassword.svg' : './assets/images/hidepassword.svg'} alt="" placeholder={undefined} />
                  {/* <IonIcon onClick={() => togglePasswordVisiblityconf()} icon={isRevealPwdconf ? eye : eyeOffOutline} /> */}
                </div>
              </IonCol>
              <ErrorMessage
                errors={errors}
                name="confpwd"
                as={<div className='inputerror' />}
              />

              <IonToast
                isOpen={isOpen}
                message={setmessage}
                onDidDismiss={() => setIsOpen(false)}
                duration={5000}
              ></IonToast>


              {/* <IonItem className='tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Name</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonIcon icon={person} />
                <IonInput name="username" type="text" required onIonChange={e => {
                  setUsername(e.detail.value!);
                  setUsernameError(false);
                }}></IonInput>
              </IonItem>
              {formSubmitted && usernameError && <IonText color="danger">
                <p className="ion-padding-start">
                  Username is required
                </p>
              </IonText>} */}

              {/* <IonItem className='tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Email</IonText>
                </IonLabel>
                <div className='labeltitle'>
                  <IonIcon icon={informationCircleOutline} />
                  <div className="tooltip">Registered email <b>cannot</b> be changed afterwards</div>
                </div>
              </IonItem>

              <IonItem className='inputwithicon twoicon'>
                <IonIcon icon={mail} />
                <IonInput name="email" type="text" onIonChange={e => {
                  setEmail(e.detail.value!);
                  setEmailError(false);
                }} required></IonInput>
              </IonItem>
              {formSubmitted && emailError && <IonText color="danger">
                <p className="ion-padding-start">
                  Email is required
                </p>
              </IonText>} */}

              {/* <IonItem className='tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Password</IonText>
                </IonLabel>
                <div className='labeltitle'>
                  <IonIcon icon={informationCircleOutline} />
                  <div className="tooltip">Must contain at least 8 characters.</div>
                </div>
              </IonItem>

              <IonItem className='inputwithicon twoicon'>
                <IonIcon icon={lockClosed} />
                <IonInput name="confpwd" onIonChange={(e: any) => confsetPwd(e.target.value)} value={confpwd} type={isRevealPwdconf ? "text" : "password"} required></IonInput>
                <IonIcon onClick={() => togglePasswordVisiblityconf()} icon={isRevealPwdconf ? eye : eyeOffOutline} />
              </IonItem>
              {formSubmitted && passwordError && <IonText color="danger">
                <p className="ion-padding-start">
                  Password is required
                </p>
              </IonText>} */}
              {/* 
              <IonItem className='tooltiparea' color='white'>
                <IonLabel>
                  <IonText no-margin>Confirm Password</IonText>
                </IonLabel>
              </IonItem>
              <IonItem className='inputwithicon twoicon'>
                <IonIcon icon={lockClosed} />
                <IonInput name="pwd" onIonChange={(e: any) => setPwd(e.target.value)} value={pwd} type={isRevealPwd ? "text" : "password"} required></IonInput>
                <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} />
              </IonItem> */}

            </IonList>

            <IonRow placeholder={undefined}>
              <IonCol size="12" placeholder={undefined}>
                <IonButton color="white" type="submit" className='whitebtn' expand='block' placeholder={undefined}>{t("signup.reg_btn")}</IonButton>
              </IonCol>
              <IonCol size="12" className='ion-text-center' placeholder={undefined}>
                <IonText className='multioption' color='white' placeholder={undefined}>{t("signup.or")}</IonText>
              </IonCol>
              <IonCol size="12" className='socialbtn' placeholder={undefined}>
                <IonButton expand='full' placeholder={undefined}><div className='ragisterbtn'><img src="./assets/images/AppleLogo.svg" />{t("signup.reg_wt_apple")}</div></IonButton>
              </IonCol>
              <IonCol size="12" className='socialbtn' placeholder={undefined}>
                <IonButton expand='full' placeholder={undefined}><div className='ragisterbtn'><img src="./assets/images/google.svg" />{t("signup.reg_wt_google")}</div></IonButton>
              </IonCol>
              <IonCol size="12" className='ion-text-center linktext' placeholder={undefined}>
                <a href="/login" color="light">{t("signup.alrdy_hv_acc")} <br /> <strong>{t("signup.sign_in")}</strong> {t("signup.here")}</a>
                {/* <IonButton routerLink="/signup" color="light" expand="block">Signup</IonButton> */}
              </IonCol>
            </IonRow>

          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};
export default Signup


// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: {
//     setIsLoggedIn,
//     setUsername
//   },
//   component: Login
// })