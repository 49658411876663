import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonTitle,
  IonText,
  IonButton,
  IonCol,
  IonRow,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Termscondition.scss";
import { CONFIG } from "../App";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps { }

interface TermsconditionProps extends OwnProps { }

const Termscondition: React.FC<TermsconditionProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const [termlist, setTermlist] = useState();

  useIonViewWillEnter(() => {
    gettermscondition();
  });

  const gettermscondition = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/account/termscondition", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
      });

      if (!response.ok) {
        // throw Error(response.statusText);
      }
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        setTermlist(json.Result);
        // await setIsLoggedIn(true);
        //  await setUsernameAction(username);
        // history.replace('/Beforewestart');
        // localStorage.setItem('logininfo', JSON.stringify(json));
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const accepttermcon = async () => {
    var userid = localStorage.getItem("userID");

    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });

    const data = { userId: userid };
    try {
      const response = await fetch(url + "/account/accepttermscondition", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        history.replace("/login");
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
      // useEffect(() => {
      //   localStorage.setItem('items', JSON.stringify(json));
      // }, [items]);

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  return (
    <IonPage className="pages-space" placeholder={undefined}>
      <IonHeader className="noback" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonTitle color="primary" placeholder={undefined}>{t("termscondition.termscondition")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent class="ion-padding spacing" placeholder={undefined}>
        <IonRow placeholder={undefined}>
          <IonCol className="termscondition" size="12" placeholder={undefined}>
            <IonText placeholder={undefined}>
              <p>{termlist}</p>
            </IonText>
            <div className="agree">
              <IonButton
                routerLink="/"
                class="white"
                color="white"
                fill="outline"
                shape="round"
                expand="block" placeholder={undefined}>
                {t("termscondition.disgr")}
              </IonButton>
              <IonButton
                onClick={accepttermcon}
                color="primary"
                shape="round"
                expand="block" placeholder={undefined}>
                {t("termscondition.agr")}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>

        {/* <IonRow class="mmx" placeholder={undefined}>
          
        </IonRow> */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Termscondition);
