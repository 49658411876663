import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonImg,
  IonRow,
  IonCol,
  IonText,
  IonIcon,
  IonPage,
  IonTitle,
} from "@ionic/react";
import "./Wittywizard.scss";
import { chevronBack } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface WittywizardProps extends OwnProps {}

const Wittywizard: React.FC<WittywizardProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();  

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome" }, { direction: "none" });
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              color="primary"
              onClick={back}
              placeholder={undefined}
            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("wittywizard.witty_wizard")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing messages" placeholder={undefined}>
        <IonList
          className="ion-padding-horizontal date"
          placeholder={undefined}
        >
          <IonCol size="12" placeholder={undefined}>
            <IonText placeholder={undefined}>Today, 15:03</IonText>
          </IonCol>
          <IonRow class="ion-padding-top" placeholder={undefined}>
            <IonCol size="12" className="speech-right" placeholder={undefined}>
              <div className="user">
                <IonImg
                  src="./assets/images/unicone.jpg"
                  placeholder={undefined}
                />
              </div>
              <div className="bg-highlight">
                <div className="speech-bubble">
                  <p>{t("wittywizard.hello_message")}</p>
                </div>
                <div className="speech-bubble">
                  <span>{t("wittywizard.level_descriptions")}</span>
                  <span>{t("wittywizard.sub_topic")}</span>
                  <span>{t("wittywizard.lesson_related_questions")}</span>
                </div>
              </div>
            </IonCol>
            <IonCol size="12" className="speech-box" placeholder={undefined}>
              <div className="speech-bubble color-black">
                <span>{t("wittywizard.subscription")}</span>
              </div>
              <div className="user">
                <IonImg
                  src="./assets/images/Ellipse.svg"
                  placeholder={undefined}
                />
              </div>
            </IonCol>
            <IonCol size="12" className="speech-right" placeholder={undefined}>
              <div className="user">
                <IonImg
                  src="./assets/images/Ellipse.svg"
                  placeholder={undefined}
                />
              </div>
              <div className="speech-bubble bg-highlight">
                <p>&bull; &bull; &bull;</p>
              </div>
            </IonCol>
          </IonRow>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Wittywizard);
