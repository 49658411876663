import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonCard,
  IonText,
  IonCardContent,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  getPlatforms,
  IonIcon,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import "./Tonestwo.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import {
  chatboxOutline,
  chevronBack,
  close,
  notificationsOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import ReactAudioPlayer from "react-audio-player";

interface OwnProps extends RouteComponentProps {}

interface Tonestwo extends OwnProps {}

const Tonestwo: React.FC<Tonestwo> = ({}) => {
  const history = useHistory();

  const { t } = useTranslation();

  let url: string = CONFIG.API_ENDPOINT; //live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  const [length, setLength] = React.useState(-1);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";

  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const userData: any = localStorage.getItem("userlevel");
    const data = JSON.parse(userData);
    if (data === null) {
      getUserLevel();
    } else {
      setitems(data);
    }
    setLength(1);
    // GetProfie();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
  let userdata = logindata.Result;

  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        setitems(json.Result);
        const userLevel = json.Result;
        localStorage.setItem("userlevel", JSON.stringify(userLevel));
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // setLoginitems(data)

  const GetProfie = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();

      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    // if (audioRef.current) {
    //   const audioElement = audioRef.current.audioEl.current;
    //   if (isPlaying) {
    //     audioElement.pause();
    //     setIsPlaying(false);
    //   }
    // }
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const handleSlideChange = () => {
    const refs = [audioRef1, audioRef2, audioRef3, audioRef4, audioRef5];
    const setIsPlaying = [
      setIsPlaying1,
      setIsPlaying2,
      setIsPlaying3,
      setIsPlaying4,
      setIsPlaying5,
    ];

    refs.forEach((ref, index) => {
      if (ref.current) {
        const audioElement = ref.current.audioEl.current;
        audioElement.pause();
        setIsPlaying[index](false);
      }
    });
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/toneslasttwo" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" });
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="imgview">
              {itemlist.Level === 1 && (
                <IonImg src="./assets/images/shoeprint.svg" />
              )}
              {itemlist.Level === 2 && (
                <IonImg src="./assets/images/bicycle.svg" />
              )}
              {itemlist.Level === 3 && <IonImg src="./assets/images/car.svg" />}
              {itemlist.Level === 4 && (
                <IonImg src="./assets/images/plane.svg" />
              )}
              {itemlist.Level === 5 && (
                <IonImg src="./assets/images/rocet.svg" />
              )}
            </div>
            <p>
              {t("home.submit.level")} {itemlist.Level}: {itemlist.Name}
            </p>
            <p className="subtext">{t("home.submit.subtext")}</p>
          </div>
        );
      },
    });
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [isPlaying5, setIsPlaying5] = useState(false);
  const audioRef1 = useRef<any>();
  const audioRef2 = useRef<any>();
  const audioRef3 = useRef<any>();
  const audioRef4 = useRef<any>();
  const audioRef5 = useRef<any>();

  const handlePlayPause = (id) => {
    const refs = [audioRef1, audioRef2, audioRef3, audioRef4, audioRef5];
    const isPlaying = [
      isPlaying1,
      isPlaying2,
      isPlaying3,
      isPlaying4,
      isPlaying5,
    ];
    const setIsPlaying = [
      setIsPlaying1,
      setIsPlaying2,
      setIsPlaying3,
      setIsPlaying4,
      setIsPlaying5,
    ];

    refs.forEach((ref, index) => {
      if (ref.current) {
        const audioElement = ref.current.audioEl.current;
        if (index === id - 1) {
          if (isPlaying[index]) {
            audioElement.pause();
          } else {
            audioElement.play();
          }
          setIsPlaying[index](!isPlaying[index]);
        } else {
          audioElement.pause();
          setIsPlaying[index](false);
        }
      }
    });
  };

  const audioEnd = (id) => {
    const setIsPlaying = [
      setIsPlaying1,
      setIsPlaying2,
      setIsPlaying3,
      setIsPlaying4,
      setIsPlaying5,
    ];

    setIsPlaying[id - 1](false);
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("tonestwo.tones2")}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary"></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing tonetwo tonestwomain pagespace">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideDidChange={handleSlideChange} // <-- Bind slide change event
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">
                {t("tonestwo.neutral_tone")}
              </IonText>
              <div className="spacing">
                <IonText className="subtitle">
                  {t("tonestwo.slide1_desc")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/nihaoma.svg" alt="" />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">
                {t("tonestwo.neutral_tone")}
              </IonText>
              <div className="spacing">
                <IonText className="subtitle">
                  {t("tonestwo.slide2_desc")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/xiexie.svg" alt="" />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">
                {t("tonestwo.neutral_tone")}
              </IonText>
              <div className="spacing">
                <IonText className="subtitle">
                  {t("tonestwo.slide3_desc")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/shenme.svg" alt="" />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("tonestwo.3rd")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">
                  {t("tonestwo.slide4_desc1")} <br />
                  <br />
                  {t("tonestwo.slide4_desc2")}
                </IonText>
                <div className="bigone">
                  <img
                    src="./assets/images/3nihao.svg"
                    style={{ width: "32.77vh" }}
                    alt=""
                  />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main tonechange">
              <IonText className="bigtitle">
                {t("tonestwo.tone_changes")}
              </IonText>
              <div className="spacing">
                <img src="./assets/images/butone.svg" alt="" />
                <IonText className="subtitle">
                  {t("tonestwo.slide5_desc")} <br />
                  <br />
                  {t("tonestwo.for_ex")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/wobukan.svg" alt="" />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main tonechange">
              <IonText className="bigtitle">
                {t("tonestwo.tone_changes")}
              </IonText>
              <div className="spacing">
                <img src="./assets/images/yitone.svg" alt="" />
                <IonText className="subtitle">
                  {t("tonestwo.slide6_desc")}
                  <br />
                  <br />
                  <b>{t("tonestwo.first_tone")}</b> {t("tonestwo.it_number")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/yishiyi.svg" alt="" />
                  {/* <span>
                    <IonText className="subtitle-chinese piniyinfont">
                      小
                    </IonText>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/xiǎo.svg" alt="" />
                    </IonText>
                  </span> */}
                </div>
                <hr />
                <IonText className="subtitle">
                  <b>{t("tonestwo.second_tone")}</b> {t("tonestwo.fourth_tone")}
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/measureword.svg" alt="" />
                </div>
                <hr />
                <IonText className="subtitle">
                  <b>Fourth tone:</b> When followed by a first, second or third
                  tone.
                </IonText>
                <div className="bigone">
                  <img src="./assets/images/sometogether.svg" alt="" />
                </div>
              </div>
            </IonList>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Tonestwo);
