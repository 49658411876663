import React, { useRef, useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonCard,
  IonCardContent,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonItem,
  IonLabel,
  IonAccordionGroup,
  IonAccordion,
  getPlatforms,
  IonIcon,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";
import "./InitIntro.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import {
  chatboxOutline,
  chevronBack,
  chevronDown,
  chevronForward,
  chevronUp,
  close,
  notificationsOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";

interface OwnProps extends RouteComponentProps {}

interface InitIntro extends OwnProps {}

const InitIntro: React.FC<InitIntro> = ({}) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const history = useHistory();
  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [oddItems, setOddItems] = React.useState<any>({});
  const [evenItems, setEvenItems] = React.useState<any>({});
  const [openItem1, setOpenItem1] = useState(true);
  const [openItem2, setOpenItem2] = useState(true);

  const handleItemClick = () => {
    if (openItem1 === true) {
      setOpenItem1(false);
      setOpenItem2(true);
    } else if (openItem2 === true) {
      setOpenItem1(true);
      setOpenItem2(false);
    }
  };

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";

  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";

  let token = "";
  let resfreshtoken = "";
  // const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setOpenItem1(true);
    setOpenItem2(true);
    GetBasicBooks();
  });

  const GetBasicBooks = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/Book/GetBasicBooks", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        // setitems(json.Result.Lessons);
        let test: any = [];

        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        const maxLength = Math.max(array1.length, array2.length);

        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["Type"] = "Lessons";
            array1[i]["Number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["Type"] = "Dialogues";
            array2[i]["Number"] = i + 1;
            test.push(array2[i]);
          }
        }

        setitems(test);

        let oddItems: any = [];
        let evenItems: any = [];

        test?.forEach((items, index) => {
          const displayValue = items?.DisplayOrder % 2 === 0;
          if (displayValue) {
            evenItems.push(items);
          } else {
            oddItems.push(items);
          }
        });
        setOddItems(oddItems);
        setEvenItems(evenItems);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gonextPage = (value) => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" + value });
    // history.go(0)
  };

  const goLessonsPage = (items, index) => {
    history.replace({
      pathname: "/tabs/mainhome/lesson",
      state: {
        themeArray: itemlist,
        themeId: items.ThemeGuid,
        lessonname: items.Title,
        index: index,
        lessonsId: items.BookGuid,
        bookId: items.Bookid,
        themename: items.Theme,
        levelId: "",
        level: items.Level,
        levname: levname,
        backpage: "initintro",
        backpageValue: 3,
      },
    });
  };

  const goDialoguesPage = (items, index) => {
    const stringSpac = items.Roles; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    // if (items.IsLocked == false) {
    history.replace({
      pathname: "/tabs/mainhome/dialoguevideo",
      state: {
        themeArray: itemlist,
        themeId: items.ThemeGuid,
        index: index,
        dialogueId: items.Id,
        title: items.Title_English,
        themename: items.Theme,
        levelId: "",
        level: items.level,
        levname: levname,
        videoUrl: items.VideoUrl,
        role: stringWtot,
        backpage: "initintro",
        backpageValue: 3,
      },
    });
    // }
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome" });
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="imgview"></div>
            <p></p>
            <p className="subtext">{t("home.submit.subtext")}</p>
          </div>
        );
      },
    });
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("initintro.intro")}</span>
          </IonTitle>
          <IonButtons onClick={submit} slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing levelstart">
        <IonList className="Introductionpage">
          <IonItem onClick={() => handleItemClick()}>
            <IonLabel>
              <h2>{t("initintro.pinyin")}</h2>
            </IonLabel>
            <IonIcon
              icon={chevronUp}
              className={openItem1 ? "rotate-up" : "rotate-down"}
            />
          </IonItem>
          {openItem1 && (
            <div className="subaccordion w-100">
              <IonList className="ion-no-padding">
                <IonCard
                  onClick={() => gonextPage("tones")}
                  className="Introduction Tones"
                >
                  <IonCardContent class="ion-no-padding">
                    <div className="numberview">
                      <span>{t("initintro.tones")}</span>
                    </div>
                    <IonImg src="./assets/images/Tones.gif" />
                  </IonCardContent>
                </IonCard>
                <IonCard
                  onClick={() => gonextPage("initials")}
                  className="Introduction InitialsFinals"
                >
                  <IonCardContent class="ion-no-padding">
                    <div className="numberview">
                      <span>{t("initintro.initials")}</span>
                    </div>
                    <IonImg src="./assets/images/initials.gif" />
                  </IonCardContent>
                </IonCard>
                <IonCard
                  onClick={() => gonextPage("finals")}
                  className="Introduction Finals"
                >
                  <IonCardContent class="ion-no-padding">
                    <div className="numberview">
                      <span>{t("initintro.finals")}</span>
                    </div>
                    <IonImg src="./assets/images/Finals.gif" />
                  </IonCardContent>
                </IonCard>
                <IonCard
                  onClick={() => gonextPage("pinyin")}
                  className="Introduction Pinyin"
                >
                  <IonCardContent class="ion-no-padding">
                    <div className="numberview">
                      <span>{t("initintro.pinyin")}</span>
                    </div>
                    <IonImg src="./assets/images/Pinyin.gif" />
                  </IonCardContent>
                </IonCard>
                <IonCard
                  onClick={() => gonextPage("tonestwo")}
                  className="Introduction Tones2"
                >
                  <IonCardContent class="ion-no-padding">
                    <div className="numberview">
                      <span>{t("initintro.tones2")}</span>
                    </div>
                    <IonImg src="./assets/images/Tones.gif" />
                  </IonCardContent>
                </IonCard>
              </IonList>
            </div>
          )}
          <IonItem onClick={() => handleItemClick()}>
            <IonLabel>
              <h2>{t("initintro.basics")}</h2>
            </IonLabel>
            <IonIcon
              icon={chevronUp}
              className={openItem2 ? "rotate-up" : "rotate-down"}
            />
          </IonItem>
          {openItem2 === true && (
            <div className="subaccordion w-100">
              <IonList className="ion-no-padding Introduction theme-listing">
                {/* <ul>
                  {Array.isArray(itemlist) &&
                    itemlist?.map((items, index) => {
                      return (
                        <li
                          onClick={() => [
                            items?.Type === "Lessons"
                              ? goLessonsPage(items, index)
                              : goDialoguesPage(items, index),
                          ]}
                        >
                          <div className="numberview">
                            <span>
                              {items?.Type === "Lessons"
                                ? items?.Title
                                : items?.ContentEn}
                            </span>
                          </div>
                          <IonImg src={items?.ImageUrl} />
                        </li>
                      );
                    })}
                </ul> */}
                <ul>
                  {Array.isArray(oddItems) &&
                    oddItems?.map((items, index) => (
                      <li
                        onClick={() => [
                          items?.Type === "Lessons"
                            ? goLessonsPage(items, index)
                            : goDialoguesPage(items, index),
                        ]}
                      >
                        <div className="numberview">
                          <span>
                            {items?.Type === "Lessons"
                              ? items?.Title
                              : items?.ContentEn}
                          </span>
                        </div>
                        <IonImg src={items?.ImageUrl} />
                      </li>
                    ))}
                </ul>

                <ul>
                  {Array.isArray(evenItems) &&
                    evenItems?.map((items, index) => (
                      <li
                        onClick={() => [
                          items?.Type === "Lessons"
                            ? goLessonsPage(items, index)
                            : goDialoguesPage(items, index),
                        ]}
                      >
                        <div className="numberview">
                          <span>
                            {items?.Type === "Lessons"
                              ? items?.Title
                              : items?.ContentEn}
                          </span>
                        </div>
                        <IonImg src={items?.ImageUrl} />
                      </li>
                    ))}
                </ul>
              </IonList>
            </div>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(InitIntro);
