import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonList,
  IonCard,
  IonCardContent,
  useIonViewWillEnter,
  IonIcon,
  IonText,
  IonCol,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import "./Finalspractice.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack, options } from "ionicons/icons";
import CustomAudioPlayer from "./CustomAudioPlayer";
import audioJSON from "./json/audio.json";
import dataDescription from "./json/letterDesc.json";

interface OwnProps extends RouteComponentProps {}

interface Finalspractice extends OwnProps {}

const Finalspractice: React.FC<Finalspractice> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<any[]>([]);
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState<string[]>(
    []
  );
  const [isCorrectArray, setIsCorrectArray] = useState<boolean[]>([]);
  const [clickedSlides, setClickedSlides] = useState<number[]>([]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [length, setLength] = React.useState(-1);
  const [items, setItems] = useState<any>();
  // json imported below
  const audioSrc: any = audioJSON;
  const audio: any = audioSrc?.audioSrc;
  const description: any = dataDescription;
  const data: any = description.description;
  let item: any = [
    {
      WordCh1: "e",
      WordCh2: "i",
      WordCh3: "ee",
      WordCh4: "a",
      AudioUrl: audio.e,
      Answer: "e",
    },
    {
      WordCh1: "a",
      WordCh2: "ue",
      WordCh3: "ia",
      WordCh4: "ua",
      AudioUrl: audio.ua,
      Answer: "ua",
    },
    {
      WordCh1: "o",
      WordCh2: "ong",
      WordCh3: "iong",
      WordCh4: "iang",
      AudioUrl: audio.iong,
      Answer: "iong",
    },
  ];
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(
    Array(item.length).fill("")
  );
  useIonViewWillEnter(() => {
    let tmpoption: any = [];
    let results = item;
    results.forEach((axq: any[], indexq: number) => {
      let qq = [
        {
          Word: results[indexq].WordCh1,
          questionno: 1,
        },
        {
          Word: results[indexq].WordCh2,
          questionno: 2,
        },
        {
          Word: results[indexq].WordCh3,
          questionno: 3,
        },
        {
          Word: results[indexq].WordCh4,
          questionno: 4,
        },
      ];
      let final = {
        Options: qq,
        Question: results[indexq].AudioUrl,
        Answer: results[indexq].Answer,
      };
      tmpoption.push(final);
    });
    setItems(tmpoption);
  });
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        slidesRef.current?.getActiveIndex().then(async (index) => {
          if (index - 1 === length) {
            back();
          }
        });
      } else {
        slidesRef.current?.getActiveIndex().then(async (index) => {
          if (index + 1 === length) {
            gonextPage();
          }
        });
      }
    }
  };
  const handleSlideDidStart = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const currentIndex = swiper.activeIndex;
      // Check if the current slide index is in the list of clicked slides
      if (!clickedSlides.includes(currentIndex)) {
        // Randomize options only if the slide hasn't been clicked
        const randomizedOptions = [...items[currentIndex].Options];
        for (let i = randomizedOptions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [randomizedOptions[i], randomizedOptions[j]] = [
            randomizedOptions[j],
            randomizedOptions[i],
          ];
        }
        // Update itemList with randomizedOptions
        const updatedItemList = [...items]; // Create a copy of itemList
        updatedItemList[currentIndex] = {
          ...updatedItemList[currentIndex],
          Options: randomizedOptions,
        };
        // Update itemList in state
        setItems(updatedItemList);
      }
    }
  };
  const gonextPage = () => {
    history.replace({
      pathname: "/tabs/mainhome/initintro/finalstwo",
    });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/finals" });
  };
  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const updatedSelectedAnswers = [...prevSelectedAnswers];
      updatedSelectedAnswers[index] = selectedOption;
      return updatedSelectedAnswers;
    });

    // true & false condition for each
    const isAnswerCorrect = selectedOption === correctAnswer;
    setIsCorrectArray((prevIsCorrectArray) => {
      const newIsCorrectArray = [...prevIsCorrectArray];
      newIsCorrectArray[index] = isAnswerCorrect;
      return newIsCorrectArray;
    });

    // selection option
    const selectedIndex = items[index].Options.findIndex(
      (option) => option.Word === selectedOption
    );

    setSelectedOptionsIndex((prevSelcted) => {
      const newSelectedOptions = [...prevSelcted];
      newSelectedOptions[index] = selectedIndex;
      return newSelectedOptions;
    });

    // Set the correct answer index
    const correctIndex = items[index].Options.findIndex(
      (option) => option.Word === correctAnswer
    );
    setCorrectAnswerIndex((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });
    // Add the index of the clicked slide to clickedSlides
    setClickedSlides((prevClickedSlides) => [...prevClickedSlides, index]);
  };
  return (
    <IonPage className="pages-space intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton
              onClick={() => back()}
              color="primary"
              placeholder={undefined}
            >
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("finalspractice.let_prac")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color="primary" placeholder={undefined}></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing practice" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            options,
          }}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionStart={handleSlideDidStart}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef}
          placeholder={undefined}
        >
          {items?.map((itemData, index) => (
            <IonSlide key={index}>
              <IonList className="ion-no-padding height100">
                <IonRow className="Practice leftrightspace ion-padding-horizontal">
                  <IonCol>
                    <IonText>{t("finalspractice.chos_what")}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow className="Practice spacing ion-padding-horizontal">
                  <IonCol size="12">
                    <div className="customaudioplayer">
                      <CustomAudioPlayer src={itemData.Question} />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow className="answer spacing ion-padding-horizontal">
                  {itemData?.Options?.map((datav, indexnew) => (
                    <IonCol size="6" key={indexnew}>
                      <IonCard
                        id={datav.questionno}
                        style={{
                          borderColor:
                            isCorrectArray[index] !== null &&
                            isCorrectArray[index] &&
                            // datav.Word === selectedAnswer
                            indexnew === selectedOptionsIndex[index]
                              ? "var(--ion-color-success)" // Green outline for the correct answer
                              : // correctAnswerIndex[index] !== null &&
                              indexnew === correctAnswerIndex[index]
                              ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                              : isCorrectArray[index] !== null &&
                                !isCorrectArray[index] &&
                                // datav.Word === selectedAnswer
                                indexnew === selectedOptionsIndex[index]
                              ? "var(--ion-color-danger)" // Red outline for the wrong answer
                              : "",
                        }}
                        onClick={() =>
                          selectedAnswers[index] === ""
                            ? handleAnswerSelection(
                                datav.Word,
                                itemData.Answer,
                                index
                              )
                            : ""
                        }
                      >
                        <IonCardContent class="ion-no-padding">
                          <div className="min184">
                            <IonText>{datav.Word}</IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonList>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Finalspractice);
