import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonItem,
  IonLabel,
  IonButton,
  IonContent,
  IonText,
  IonList,
  IonToggle,
  IonCol,
  IonImg,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
  IonSkeletonText,
  getPlatforms,
  IonModal,
} from "@ionic/react";
import "./LessonAutoPlay.scss";
import {
  chevronBack,
  ellipsisVertical,
  close,
  bookmark,
  playCircle,
} from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import ReactBottomDrawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import ReactAudioPlayer from "react-audio-player";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import CustomAudioPlayer from "./CustomAudioPlayer";
interface OwnProps extends RouteComponentProps {
  onHold?: () => void;
  onTap?: (event: any) => void;
  holdDuration?: number;
}
interface LessonAutoPlayProps extends OwnProps {}
const LessonAutoPlay: React.FC<LessonAutoPlayProps> = ({
  history,
  onHold,
  onTap,
  holdDuration,
  ...rest
}) => {
  const platforms = getPlatforms();
  const themeData: any = localStorage.getItem("itemlist");

  const tDataCon = JSON.parse(themeData);
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>();
  const [showResults, setShowResults] = React.useState(1);
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);
  const [showImage, setShowImage] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayer, setShowPlayer] = useState(true);
  const historyss = useHistory<{
    themeArray: any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    backpageValue: number;
  }>();
  let themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const bookId = historyss.location.state
    ? historyss.location.state.bookId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const audioRef = useRef<any>();
  const [platform, setPlatform] = React.useState<any>();
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    if (platforms.includes("ios")) {
      setPlatform(true);
    } else if (platforms.includes("android")) {
      setPlatform(false);
    } else {
      setPlatform(false);
    }
    // Disable the Android hardware back button
    document.addEventListener("ionBackButton", (e: any) => {
      e.detail.register(0, () => {
        // Do nothing to disable the back button
      });
    });
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setShowImage(false);
    // getthemes();

    setLength(-1);
    setShowResults(1);
    getQuestions();
  });

  const getQuestions = async () => {
    try {
      const response = await fetch(
        url + "/Book/GetQuestions?lessonGuid=" + lessonsId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setShowImage(true);
        setitems(json.Result);
        setLengthQue(json.Result.length);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gobackLesson = () => {
    history.replace(
      {
        pathname:
          backpageValue === 2
            ? "/tabs/progress/lesson"
            : "/tabs/mainhome/lesson",
        state: {
          themeArray: themeArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpageValue: backpageValue,
        },
      },
      { direction: "none" }
    );
  };

  // useEffect(() => {
  //   // Check if audioRef.current exists and if it's not already playing
  //   if (audioRef.current && !isPlaying) {
  //     const audioElement = audioRef.current.audioEl.current;
  //     audioElement.play();
  //     setIsPlaying(true);
  //   }
  // }, [itemlist, activeIndex]);

  useEffect(() => {
    const playNextAudio = async () => {
      if (audioRef.current) {
        const audioElement = audioRef.current.audioEl.current;
        if (isPlaying) {
          audioElement.pause();
        }
        audioElement.play();
        setIsPlaying(true);
      }
    };

    // Pause current audio and play next audio when activeIndex changes
    playNextAudio();

    // Cleanup function
    return () => {
      if (audioRef.current) {
        const audioElement = audioRef.current.audioEl.current;
        audioElement.pause();
        setIsPlaying(false);
      }
    };
  }, [activeIndex]);

  const ClickAutoPlay = (index) => {
    setActiveIndex(index);
    setShowPlayer(true);
  };

  const handleNextPrev = (value) => {
    if (value === 1 && activeIndex + 1 < itemlist.length) {
      setActiveIndex(activeIndex + 1);
    } else if (value === 0 && activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、—— ])|(?=[（『「〝“] ")/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“", '"'];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };
  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };
  const test_main = (text, textpy) => {
    // srcText = text;
    const characters1 = wordSplitter(text);
    const charPy = pySplitter(textpy);

    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
    }));

    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li
          style={{
            color: index === activeIndex ? "var(--ion-color-primary)" : "black",
          }}
        >
          <div>
            <span className="ion-text-center">{word["Text"]}</span>
            <p className="wordpy">{word["Piniyan"]}</p>
          </div>
        </li>
      </React.Fragment>
    ));
    return fragments;
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;
      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const audioEnd = () => {
    setIsPlaying(false); // Pause the player
    // Check if there's a next item to play
    if (activeIndex < itemlist.length - 1) {
      // Increment the activeIndex to point to the next item
      setActiveIndex((prevIndex) => prevIndex + 1);
      // Automatically start playing the next audio
      setIsPlaying(true);
    } else {
      // If it's the last item, stop playing and show appropriate UI
      setShowPlayer(false);
      setActiveIndex(null);
    }
  };

  const showAudioPlayer = () => {
    setShowPlayer(false);
    setActiveIndex(null);
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;
      if (isPlaying) {
        audioElement.pause();
      }
      setIsPlaying(false);
    }
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader
        className="ion-text-center dubbletitle"
        placeholder={undefined}
      >
        <IonToolbar placeholder={undefined}>
          <div className="ion-title-scroll">
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={gobackLesson}
                placeholder={undefined}
              >
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle
              color="primary"
              className="font20 ion-no-padding"
              placeholder={undefined}
            >
              <span>
                {t("lessonautoplay.lesson")}{" "}
                {themeArray ? themeArray[index]?.number : ""}
              </span>{" "}
              <p className="ion-no-margin">{lessonname}</p>
            </IonTitle>
            <IonButtons id="open-modal" slot="end" placeholder={undefined}>
              <IonButton
                className="ellipsisVertical"
                color="primary"
                placeholder={undefined}
              >
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        <IonList className={showPlayer ? "heightset" : ""}>
          <IonCol
            size="12"
            className="ion-text-left audio-content"
            placeholder={undefined}
          >
            {itemlist &&
              itemlist.map((item, index) => {
                return (
                  <IonRow
                    className={
                      "lession-autoplay lession-page" +
                      (index === activeIndex ? " active" : "")
                    }
                    placeholder={undefined}
                    onClick={() => ClickAutoPlay(index)}
                    ref={(element) => {
                      if (element && index === activeIndex) {
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "end",
                        });
                      }
                    }}
                  >
                    <div className="iconarea">
                      {index === activeIndex ? (
                        <IonImg src="./assets/images/headphones.svg" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="ion-no-padding"
                      style={{ display: "block" }}
                    >
                      <div>
                        <ul className="lession-icon decitemlist ">
                          {test_main(item.Content_tc, item.Content_py)}
                        </ul>
                      </div>
                      <div className="lession-icon decitemlist">
                        <p className="translatetext">{item.Content_en}</p>
                      </div>
                    </div>
                  </IonRow>
                );
              })}
          </IonCol>
        </IonList>
        {showPlayer && (
          <IonList className="audioauto">
            <IonItem class="ion-no-padding playbook" placeholder={undefined}>
              <div>
                <img
                  src={itemlist && itemlist[activeIndex]?.ImageUrl}
                  alt="NO IMAGE"
                  height={"40px"}
                  width={"40px"}
                />
              </div>
              <div className="details ion-padding-start">
                <h4>
                  {t("lessonautoplay.lesson")} {themeArray[index]?.number}:
                </h4>
                <h4>
                  {themeArray[index]?.Title}{" "}
                  <span>
                    ({activeIndex + 1}/{itemlist?.length})
                  </span>
                </h4>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding playbook" placeholder={undefined}>
              <div className="autoplay">
                <IonImg
                  src="./assets/images/back.svg"
                  onClick={() => handleNextPrev(0)}
                  placeholder={undefined}
                />
                <span
                  style={{ margin: "0px", padding: "0px" }}
                  onClick={handlePlayPause}
                >
                  {isPlaying ? (
                    <IonImg
                      src="./assets/images/PlayPauseicon.svg"
                      placeholder={undefined}
                    />
                  ) : (
                    <IonImg
                      src="./assets/images/autoplayicon.svg"
                      placeholder={undefined}
                    />
                  )}
                </span>
                <IonImg
                  src="./assets/images/next.svg"
                  onClick={() => handleNextPrev(1)}
                  placeholder={undefined}
                />
                <IonIcon icon={close} onClick={showAudioPlayer} />
              </div>
            </IonItem>
            <span>
              <ReactAudioPlayer
                style={{ border: "none", display: "none" }}
                ref={audioRef}
                onEnded={audioEnd}
                className="String"
                controls={false}
                controlsList="nodownload noplaybackrate"
                src={itemlist && itemlist[activeIndex]?.SoundUrl}
              />
            </span>
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};
export default React.memo(LessonAutoPlay);
