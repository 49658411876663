import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonItem,
  IonLabel,
  IonButton,
  IonContent,
  IonText,
  IonList,
  IonToggle,
  IonCol,
  IonImg,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
  IonSkeletonText,
  IonModal,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Lesson.scss";
import { chevronBack, ellipsisVertical, playCircle } from "ionicons/icons";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import ReactAudioPlayer from "react-audio-player";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { Tooltip as ReactTooltip } from "react-tooltip";
interface OwnProps extends RouteComponentProps {
  onHold?: () => void;
  onTap?: (event: any) => void;
  holdDuration?: number;
}
interface LessonProps extends OwnProps {}
const Lesson: React.FC<LessonProps> = ({
  history,
  onHold,
  onTap,
  holdDuration,
  ...rest
}) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [decitemlist, setdecitems] = React.useState<any>([]);
  const [showResults, setShowResults] = React.useState(1);
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);
  const [lengthnext, setnextLength] = React.useState(0);
  const [listDictionary, setDictionary] = React.useState<any[]>([]);
  const [showImage, setShowImage] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDeciPlaying, setIsDeciPlaying] = useState(false);
  //Setting
  const [openModal, setOpenModal] = useState<any>(false);
  const [fszChine, setFszChine] = useState(20);
  const [fszPiniyin, setFszPiniyin] = useState(18);
  const [fszTrans, setFszTrans] = useState(18);
  const [clickedUp, setClickedUp] = useState(false);
  const [clickedDown, setClickedDown] = useState(false);
  const [cheChar, setCheChar] = useState<any>(true);
  const [piniyin, setPiniyin] = useState<any>(true);
  const [isTransChecked, setIsTransChecked] = useState<any>(true);
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [playbackSpeed, setPlaybackSpeed] = useState<any>(1.0);
  //End setting
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeIndexDc, setActiveIndexDC] = React.useState<any[]>([]);
  const [getThemes, setThemes] = useState<any>();
  const [isSwiping, setIsSwiping] = useState(false);
  const levelJSON: any = localStorage.getItem("levelinfo");
  const levalData = JSON.parse(levelJSON);
  const audioRef = useRef<any>();
  const deciAudio = useRef<any>();
  const elementRef = useRef(null);
  const startX = useRef(0);
  const boxRef = useRef<HTMLDivElement>(null);
  let allThemes: any[] = [];
  let token = "";
  let resfreshtoken = "";
  let nextindex: any = 0;
  const historyss = useHistory<{
    themeArray: any;
    themeId: string;
    lessonsId: string;
    firstDescValue: any;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    backpageValue: number;
    originalBackValue: number;
  }>();
  let themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const firstDescValue = historyss.location.state
    ? historyss.location.state.firstDescValue
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const originalBackValue = historyss.location.state
    ? historyss.location.state.originalBackValue
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";

  useIonViewWillEnter(() => {
    // Disable the Android hardware back button
    document.addEventListener("ionBackButton", (e: any) => {
      e.detail.register(0, () => {
        // Do nothing to disable the back button
      });
    });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    setShowImage(false);
    setLength(-1);
    setShowResults(1);
    getQuestions();
    GetSettings();
    if (firstDescValue) {
      getDictionary(firstDescValue);
    }
  });
  useIonViewWillLeave(() => {
    // Call your function here
    localStorage.removeItem("dictionary_next");
    localStorage.removeItem("dictionary_pre");
  });
  const getQuestions = async () => {
    try {
      let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
      if (currentUser) {
        token = currentUser.JwtToken;
        resfreshtoken = currentUser.RefreshToken;
      }
      const response = await fetch(
        url + "/Book/GetQuestions?lessonGuid=" + lessonsId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      // dismiss();
      // getDictionary(json.Result[0].ContentId);
      if (json.StatusCode === 1) {
        setShowImage(true);
        setitems(json.Result);
        let next_element = 0;
        // let prev_element = 0;
        // let currentIndex = 0;
        //Get the next element
        if (json.Result[1].ContentId) {
          next_element = json.Result[1].ContentId;
          getDictionary_next(next_element);
        }
        //Get the previous element
        // if (itemlist[currentIndex - 1]) {
        //   prev_element = itemlist[currentIndex - 1].ContentId
        //   getDictionary_pre(prev_element);
        // }
        saveContent(json.Result, 1, 1);
        setLengthQue(json.Result.length);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    try {
      const response = await fetch(
        url +
          "/Book/GetLessons?themeGuid=" +
          themeId +
          "&level=" +
          levalData?.Leval,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      if (json.StatusCode == 1) {
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        allThemes = test;
        setThemes(test);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  //Silder
  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setnextLength(index);
      // setLength((index - 1))
      // resetstate()
      // setShowResults((index-1))
    });
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      //setLength((index + 1))
      // resetstate()
      if (showResults !== 0) {
        setShowResults(index + 1);
        setShowPopover(false);
        setActiveIndex(-1);
      }
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    setPlaybackSpeed(1.0);
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;
      if (isPlaying) {
        audioElement.pause();
        setIsPlaying(false);
      }
    }
    const swiper = await slidesRef.current?.getSwiper();
    const swipeDirection = swiper?.swipeDirection;
    const currentIndex = swiper?.activeIndex;
    setLength(currentIndex);
    setActiveIndexDC([]);
    // getDictionary(itemlist[currentIndex].ContentId);
    setShowPopover(false);
    if (swipeDirection === "next") {
      //set next dictionary
      let deccurrrent_next = localStorage.getItem("dictionary_next") || "{}";
      if (deccurrrent_next) {
        let tmparrays = JSON.parse(deccurrrent_next);
        setDictionary(tmparrays);
      }
    }
    if (swipeDirection === "prev") {
      //set previous dictionary
      let deccurrrent_prev = localStorage.getItem("dictionary_pre") || "{}";
      if (deccurrrent_prev) {
        let tmparrays = JSON.parse(deccurrrent_prev);
        setDictionary(tmparrays);
      }
    }
    let next_element = 0;
    let prev_element = 0;
    //Get the next element
    if (itemlist[currentIndex + 1]) {
      next_element = itemlist[currentIndex + 1].ContentId;
      getDictionary_next(next_element);
    }
    //Get the previous element
    if (itemlist[currentIndex - 1]) {
      prev_element = itemlist[currentIndex - 1].ContentId;
      getDictionary_pre(prev_element);
    }
    //end
    if (swiper) {
      if (swipeDirection === "prev") {
      } else {
        if (showResults !== 0) {
          // setLength(currentIndex);
          setShowResults(currentIndex + 1);
          saveContent(itemlist, currentIndex + 1, 1);
          if (itemlist.length === 1) {
            // setLength(-1);
            setActiveIndex(-1);
            setnextLength(0);
            gotonextPage();
          } else {
            if (itemlist.length < lengthnext) {
              setLength(-1);
              setActiveIndex(-1);
              setnextLength(0);
              gotonextPage();
            }
          }
        }
      }
    }
  };
  //End
  const saveContent = async (val, index, type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    const data = {
      contentPageCode:
        type === 1 ? val[0].TemplateCode : itemlist[val].TemplateCode,
      type: "lesson-read",
      bookId: itemlist.length > 0 ? itemlist[0].BookId : val[0].BookId,
      contentPageId:
        type === 1 ? val[0].ContentPageId : itemlist[val].ContentPageId,
      answer: "",
      score: 0,
      options: "",
      pageRead: index,
    };
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode === 1) {
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  useEffect(() => {
    if (length === lengthque) {
      // if (showResults !== 0) {
      //   setLength(-1)
      //   gotonextPage()
      // }
    }
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  });
  const gotonextPage = () => {
    if (length !== 0) {
      history.replace({
        pathname:
          backpageValue === 2
            ? "/tabs/progress/lessoncompleted"
            : "/tabs/mainhome/lessoncompleted",
        state: {
          themeArray: itemArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "lesson",
          backpageValue: backpageValue,
        },
      });
    }
  };
  const favouriteWord = async (val) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })
    const data = {
      referenceId: val.DictionaryId,
      isFavourite: val.IsFavourite === true ? false : true,
      typeId: 2,
    };
    // Update decitemlist with the new IsFavourite value
    const updatedDecItemList = decitemlist.map((item) =>
      item.DictionaryId === val.DictionaryId
        ? { ...item, IsFavourite: !item.IsFavourite }
        : item
    );
    setdecitems(updatedDecItemList);
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      getQuestions();
      if (json.StatusCode === 1) {
        // Update decitemlist with the new IsFavourite value
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  ///Desctiory
  let popup = false;
  const handleClick = (index, text_dc) => {
    popup = true;
    const spChar = [
      "；",
      "！",
      "/",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];
    const sentence = text_dc;
    const word_dc = sentence.split(" ");
    const data: any = [];
    let currentIndex = 0;
    let currentWord = "";
    for (let i = 0; i < word_dc.length; i++) {
      const word = word_dc[i];
      const wordWithoutSpecialChars = word.replace(
        new RegExp(`[${spChar.join("\\")}]`, "g"),
        ""
      );
      if (!isNaN(wordWithoutSpecialChars)) {
        // If the word is numerical, add it to the currentWord
        currentWord += wordWithoutSpecialChars;
      } else {
        // If it's not numerical, add the previous numerical word as one element
        if (currentWord !== "") {
          data.push({ word: currentWord, index: [currentIndex] });
          currentIndex++;
          currentWord = "";
        }
        // Add the current word
        const indices: any = [];
        for (let j = 0; j < wordWithoutSpecialChars.length; j++) {
          indices.push(currentIndex++);
        }
        data.push({ word: wordWithoutSpecialChars, index: indices });
      }
    }
    // Add the last numerical word if any
    if (currentWord !== "") {
      data.push({ word: currentWord, index: [currentIndex] });
    }
    const foundElement = data?.find((item) => item.index.includes(index));
    if (foundElement === 0 || null || undefined) {
      setShowPopover(false);
      // presenttost({
      //   message: "No results found",
      //   duration: 1500,
      //   position: "bottom",
      // });
    } else {
      setActiveIndexDC(
        foundElement === null || undefined ? null : foundElement?.index
      );
      checkdictionary(foundElement?.word);
    }
    setActiveIndex(index === activeIndex ? -1 : index);
    document.addEventListener("click", handleDocumentClick);
  };
  const checkdictionary = async (word) => {
    //words_py
    setdecitems([]);
    if (
      listDictionary?.length === 0 ||
      listDictionary.length === undefined ||
      listDictionary.length === null
    ) {
      setShowPopover(false);
      // presenttost({
      //   message: "No results found",
      //   duration: 1500,
      //   position: "bottom",
      // });
      return;
    }
    let wordt = IsTranLangSim ? "WordSc" : "Word";
    // let type = IsTranLangSim ? "Tc" : "Sc";
    let filteredItems: any;
    if (listDictionary?.length > 0) {
      filteredItems = listDictionary?.filter(
        (item) => item[wordt] && item[wordt].startsWith(word)
      );
    }
    //WordSc
    if (
      filteredItems.length === 0 ||
      filteredItems.length === undefined ||
      filteredItems.length === null
    ) {
      setShowPopover(false);
      // presenttost({
      //   message: "No results found",
      //   duration: 1500,
      //   position: "bottom",
      // });
    } else {
      setdecitems(filteredItems);
      setShowPopover(true);
    }
  };
  const getDictionary = async (contentId) => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // const chineseSymbol = word;
    // const encodedSymbol = encodeURIComponent(chineseSymbol);
    // const url = `https://example.com/path/${encodedSymbol}`;
    try {
      const response = await fetch(
        url + "/User/Dictionary?contentId=" + contentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setDictionary(json.Result);
        let vocab = json.Result;
        let tmparray = json.Result;
        for (let i = 0; i < vocab.length; i++) {
          if (vocab[i].Vocabs) {
            const jsonArray = JSON.parse(vocab[i].Vocabs);
            jsonArray.sort((a, b) => {
              if (a.IsSelected && !b.IsSelected) {
                return -1; // a is selected and b is not, so a should come first
              }
              if (!a.IsSelected && b.IsSelected) {
                return 1; // b is selected and a is not, so b should come first
              }
              return 0; // a and b have the same selection status, keep the order unchanged
            });
            const groupedObjects = {};
            for (const item of jsonArray) {
              const type = item.Type.toLowerCase();
              if (!groupedObjects[type]) {
                groupedObjects[type] = [];
              }
              groupedObjects[type].push({
                type: type,
                Example: item.Example,
                Value: item.Value,
                Id: item.Id,
              });
              tmparray[i]["groupedObjects"] = groupedObjects;
            }
            setDictionary(tmparray);
          }
        }
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const getDictionary_next = async (contentId) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(
        url + "/User/Dictionary?contentId=" + contentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        // setDictionary(json.Result);
        let vocab = json.Result;
        let tmparray = json.Result;
        for (let i = 0; i < vocab.length; i++) {
          if (vocab[i].Vocabs) {
            const jsonArray = JSON.parse(vocab[i].Vocabs);
            const groupedObjects = {};
            for (const item of jsonArray) {
              const type = item.Type.toLowerCase();
              if (!groupedObjects[type]) {
                groupedObjects[type] = [];
              }
              groupedObjects[type].push({
                type: type,
                Example: item.Example,
                Value: item.Value,
                Id: item.Id,
              });
              tmparray[i]["groupedObjects"] = groupedObjects;
            }
            localStorage.setItem("dictionary_next", JSON.stringify(tmparray));
          }
        }
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const getDictionary_pre = async (contentId) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(
        url + "/User/Dictionary?contentId=" + contentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        //setDictionary(json.Result);
        let vocab = json.Result;
        let tmparray = json.Result;
        for (let i = 0; i < vocab.length; i++) {
          if (vocab[i].Vocabs) {
            const jsonArray = JSON.parse(vocab[i].Vocabs);
            const groupedObjects = {};
            for (const item of jsonArray) {
              const type = item.Type.toLowerCase();
              if (!groupedObjects[type]) {
                groupedObjects[type] = [];
              }
              groupedObjects[type].push({
                type: type,
                Example: item.Example,
                Value: item.Value,
                Id: item.Id,
              });
              tmparray[i]["groupedObjects"] = groupedObjects;
            }
            localStorage.setItem("dictionary_pre", JSON.stringify(tmparray));
            //setDictionary(tmparray);
          }
        }
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const handleTouchStart = (event) => {
    startX.current = event.touches[0].clientX;
    setIsSwiping(true);
  };
  const handleTouchEnd = (event) => {
    setIsSwiping(false);
    const deltaX = event.changedTouches[0].clientX - startX.current;
    const threshold = 50; // Adjust threshold for swipe sensitivity
    if (Math.abs(deltaX) > threshold) {
      if (deltaX > 0) {
        // Handle swipe left event
        if (slidesRef.current) {
          slidesRef.current.slidePrev();
        }
      } else {
        // Handle swipe right event
        if (slidesRef.current) {
          slidesRef.current.slideNext();
        }
      }
    } else {
      // Handle click event
      if (boxRef.current && boxRef.current.contains(event.target as Node)) {
        // Click occurred inside the box, perform desired action
      } else {
        // Click occurred outside the box, perform desired action
        dismissPopover();
      }
    }
  };

  const deciHandleTouchStart = (event) => {
    startX.current = event.touches[0].clientX;
    setIsSwiping(true);
  };
  const deciHandleTouchEnd = (event) => {
    setIsSwiping(false);
    const deltaX = event.changedTouches[0].clientX - startX.current;
    const threshold = 50; // Adjust threshold for swipe sensitivity
    if (Math.abs(deltaX) > threshold) {
      if (deltaX > 0) {
        // Handle swipe left event
        if (slidesRef.current) {
          slidesRef.current.slidePrev();
        }
      } else {
        // Handle swipe right event
        if (slidesRef.current) {
          slidesRef.current.slideNext();
        }
      }
    } else {
      // Handle click event
      dismissPopover();
    }
  };
  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、—— ])|(?=[（『「〝“] ")/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“", '"'];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };
  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };
  const [showPopover, setShowPopover] = useState(false);
  const dismissPopover = () => {
    setActiveIndexDC([]);
    setShowPopover(false);
    setActiveIndex(-1);
  };
  const textset_tc = (data_tc, data_py, data_dc) => {
    const html_tc = data_tc;
    const html_py = data_py;
    const html_dc = data_dc;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const fragment_dc = range.createContextualFragment(html_dc);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const textreturn_dc = fragment_dc.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const text_dc = textreturn_dc;
    const fragmentsa: any = [];
    if (text_tc && text_py) {
      const words_tc = wordSplitter(text_tc);
      const words_py = pySplitter(text_py);
      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            {activeIndex === index && showPopover ? (
              <ReactTooltip
                id={`tooltip_${index}`}
                isOpen={showPopover}
                data-tooltip-delay-hide={false}
                clickable={true}
              >
                <div className="popup-modal" ref={boxRef}>
                  {/* <div className="downarrow"></div> */}
                  <div className="ion-padding popup-Popover">
                    <div className="mainsection">
                      <div>
                        <span>
                          {IsTranLangSim
                            ? decitemlist[0]?.WordSc
                            : decitemlist[0]?.Word}
                        </span>
                        {decitemlist[0]?.SoundUrl !== "" && (
                          <IonImg
                            onClick={deciHandlePlayPause}
                            className="playbig"
                            src={
                              isDeciPlaying
                                ? "./assets/images/Pausebig.svg"
                                : "./assets/images/playbig.svg"
                            }
                          />
                        )}
                        <ReactAudioPlayer
                          onEnded={deciAudioEnd}
                          ref={deciAudio}
                          src={decitemlist[0]?.SoundUrl}
                          controls={false}
                        />
                      </div>
                      <IonImg
                        onClick={() => favouriteWord(decitemlist[0])}
                        src={
                          decitemlist[0]?.IsFavourite
                            ? "./assets/images/starfill.svg"
                            : "./assets/images/star.svg"
                        }
                        placeholder={undefined}
                      />
                    </div>
                    <div className="mainsubtitle">
                      {t("lesson.pinyin")} <span>{decitemlist[0]?.Pinyin}</span>
                    </div>
                    <hr />
                    <div className="datadiv">
                      <p className="subtitle">
                        <span>
                          {decitemlist[0]?.groupedObjects ? (
                            <>
                              {Object.keys(decitemlist[0]?.groupedObjects).map(
                                (type) => (
                                  <IonText key={type}>
                                    <div className="txtupper">{type}</div>
                                    <ul>
                                      {decitemlist[0]?.groupedObjects[type].map(
                                        (item, index) => (
                                          <li key={index}>
                                            &bull; {item.Value}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </IonText>
                                )
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </ReactTooltip>
            ) : (
              ""
            )}
            <li
              onClick={() => handleClick(index, text_dc)}
              className={
                activeIndexDc?.includes(index) && showPopover ? "active" : ""
                // + " click"
              }
              data-tooltip-id={`tooltip_${index}`}
            >
              <div className="textdata">
                {cheChar ? (
                  <span
                    style={{ fontSize: `${fszChine}px` }}
                    className={"ion-text-center click"}
                    id="top-center"
                  >
                    {word["words_tc"]}
                  </span>
                ) : (
                  ""
                )}
                {piniyin ? (
                  <p
                    style={{
                      fontSize: `${cheChar ? fszPiniyin : fszPiniyin + 3}px`,
                    }}
                    className={"wordpy click"}
                  >
                    {word["words_py"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </li>
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1?.length, arr2?.length);
        const paddedArr1 = arr1.concat(
          Array(maxLength - arr1?.length).fill("")
        );
        const paddedArr2 = arr2.concat(
          Array(maxLength - arr2?.length).fill("")
        );
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li
              onClick={() => handleClick(index, text_dc)}
              className={
                activeIndexDc?.includes(index) && showPopover ? "active" : ""
              }
            >
              <div>
                {cheChar ? (
                  <span
                    style={{ fontSize: `${fszChine}px` }}
                    className={"ion-text-center click"}
                    id="top-center"
                  >
                    {word["words_tc"]}
                  </span>
                ) : (
                  ""
                )}
                {piniyin ? (
                  <p
                    style={{
                      fontSize: `${cheChar ? fszPiniyin : fszPiniyin + 3}px`,
                    }}
                    className={"wordpy click"}
                  >
                    {word["words_py"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li
            onClick={() => handleClick(index, text_dc)}
            className={
              activeIndexDc?.includes(index) && showPopover ? "active" : ""
            }
          >
            {cheChar ? (
              <span
                style={{ fontSize: `${fszChine}px` }}
                className={"ion-text-center click"}
                id="top-center"
              >
                {word["words_tc"]}
              </span>
            ) : (
              ""
            )}
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
      for (let i = 0; i < words_py.length; i++) {
        const word = words_py[i] as never;
        let data = {
          words_tc: "",
          words_py: word,
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li
            onClick={() => handleClick(index, text_dc)}
            className={
              activeIndexDc?.includes(index) && showPopover ? "active" : ""
            }
          >
            {piniyin ? (
              <p
                style={{
                  fontSize: `${cheChar ? fszPiniyin : fszPiniyin + 3}px`,
                }}
                className={
                  activeIndexDc.includes(index) ? "wordpy red" : "wordpy"
                }
              >
                {word["words_py"]}
              </p>
            ) : (
              ""
            )}
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    }
    //const htmls = { __html: spans.join('') };
  };
  const [divPosition, setDivPosition] = useState({ x: 0, y: 0 });
  const handleDocumentClick = (event: MouseEvent) => {
    setDivPosition({ x: event.clientX, y: event.clientY });
    // setShowDiv(true);
  };
  const getIndex = async (event: any) => {
    let index: number = 0;
    await event.target.getActiveIndex().then((value: any) => (index = value));
    nextindex = index;
    if (itemlist.length === index + 1) {
      nextindex = index + 2;
      setnextLength(nextindex);
    }
  };
  const gobackPage = () => {
    const paths = {
      0: "/tabs/mainhome/themesports",
      1: "/tabs/mainhome/proficient",
      2: "/tabs/progress",
      3: "/tabs/mainhome/initintro",
      4: "/tabs/mainhome/lessoncompleted",
      5: "/tabs/mainhome/practicecompleted",
      6: "/tabs/mainhome/dialoguedone",
      7: "/tabs/progress/lessoncompleted",
      8: "/tabs/progress/practicecompleted",
      9: "/tabs/progress/dialoguedone",
    };
    const pathname = paths.hasOwnProperty(backpageValue)
      ? paths[backpageValue]
      : paths[0];
    history.replace(
      {
        pathname,
        state: {
          themeArray: themeArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpageValue:
            originalBackValue === 1
              ? 1
              : originalBackValue === 3
              ? 3
              : backpageValue === 4 || 5 || 6
              ? 0
              : backpageValue === 7 || 8 || 9
              ? 2
              : 0,
        },
      },
      { direction: "back" }
    );
  };
  const AutoPlay = () => {
    setOpenModal(false);
    history.replace(
      {
        pathname:
          backpageValue === 2
            ? "/tabs/progress/lessonautoplay"
            : "/tabs/mainhome/lessonautoplay",
        state: {
          themeArray: themeArray,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpageValue: backpageValue,
        },
      },
      { direction: "none" }
    );
  };
  const handlePlayPause = () => {
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;

      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const audioEnd = () => {
    setIsPlaying(false);
  };
  //Setting Code
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();
  const setIsLangueTrans = (value, type) => {
    if (type === 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 3000,
    // });
    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const fontsize = (data) => {
    if (data === 0) {
      if (fszChine > 20 && fszPiniyin > 18 && fszTrans > 18) {
        let fontChine = fszChine - 4;
        setFszChine(fontChine);
        let fontPin = fszPiniyin - 2;
        setFszPiniyin(fontPin);
        let fontTra = fszTrans - 2;
        setFszTrans(fontTra);
        setClickedDown(true);
      }
    } else if (data === 1) {
      if (fszChine < 28 && fszPiniyin < 22 && fszTrans < 22) {
        let fontChine = fszChine + 4;
        setFszChine(fontChine);
        let fontPin = fszPiniyin + 2;
        setFszPiniyin(fontPin);
        let fontTra = fszTrans + 2;
        setFszTrans(fontTra);
        setClickedUp(true);
      }
    }
    // Remove the 'clicked' class after a short delay (adjust as needed)
    setTimeout(() => {
      setClickedUp(false);
      setClickedDown(false);
    }, 300);
  };
  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
        setIsTranLangSim(
          (json.Result.LessonLanguage === "Chinese, Simplified") === true
        );
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      let newpiniyin: any;
      if (newCheChar === false) {
        setPiniyin(true);
        newpiniyin = true;
      } else {
        newpiniyin = null;
      }
      SaveUserSettings(
        newCheChar,
        newpiniyin !== null ? newpiniyin : piniyin,
        isTransChecked
      ); // Save the new state if needed
      return newCheChar;
    });
  };
  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      let newchechar: any;
      if (newPiniyin === false) {
        setCheChar(true);
        newchechar = true;
      } else {
        newchechar = null;
      }
      SaveUserSettings(
        newchechar !== null ? newchechar : cheChar,
        newPiniyin,
        isTransChecked
      ); // Save the new state if needed
      return newPiniyin;
    });
  };
  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };
  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;
    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update
    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }
    // Log the updated themeArray
    themeArray = updatedThemeArray;
  };
  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode === 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const decreasePlaybackSpeed = () => {
    const speeds = [0.75, 1.0, 1.25, 1.5];
    const currentIndex = speeds.indexOf(playbackSpeed);
    if (currentIndex > 0) {
      setPlaybackSpeed(speeds[currentIndex - 1]);
      changePlaybackSpeed(speeds[currentIndex - 1]);
    }
  };

  const increasePlaybackSpeed = () => {
    const speeds = [0.75, 1.0, 1.25, 1.5];
    const currentIndex = speeds.indexOf(playbackSpeed);
    if (currentIndex < speeds.length - 1) {
      setPlaybackSpeed(speeds[currentIndex + 1]);
      changePlaybackSpeed(speeds[currentIndex + 1]);
    }
  };

  const changePlaybackSpeed = (speed: number) => {
    if (audioRef.current) {
      const audioElement = audioRef.current.audioEl.current;
      if (audioElement) {
        audioElement.playbackRate = speed;
      }
    }
  };
  //End Setting

  const deciHandlePlayPause = () => {
    if (deciAudio) {
      const audioElement = deciAudio.current.audioEl.current;

      if (isPlaying) {
        audioElement.pause();
      } else {
        audioElement.play();
      }
      setIsDeciPlaying(!isPlaying);
    }
  };
  const deciAudioEnd = () => {
    setIsDeciPlaying(false);
  };

  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader
        className="ion-text-center dubbletitle"
        placeholder={undefined}
      >
        <IonToolbar placeholder={undefined}>
          <div className="ion-title-scroll">
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={gobackPage}
                placeholder={undefined}
              >
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle
              color="primary"
              className="font20 ion-no-padding"
              placeholder={undefined}
            >
              <span>
                {t("lesson.lesson")}{" "}
                {themeArray ? themeArray[index]?.number : ""}
              </span>{" "}
              <p className="ion-no-margin">{lessonname}</p>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}>
              <IonButton
                className="ellipsisVertical"
                onClick={() => setOpenModal(true)}
                color="primary"
                placeholder={undefined}
              >
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing lessonpage" placeholder={undefined}>
        {/* <div onClick={handleclosepopup}> */}
        {itemlist.length === 0 ? (
          <IonList className="ion-no-padding" placeholder={undefined}>
            <IonRow placeholder={undefined}>
              <IonCol
                size="12"
                className="ion-no-padding lesson-img"
                placeholder={undefined}
              >
                <IonSkeletonText
                  animated
                  style={{ width: "100%", height: "100%" }}
                  placeholder={undefined}
                />
              </IonCol>
            </IonRow>
          </IonList>
        ) : (
          ""
        )}
        <IonRow className="cornarbox">
          <IonCol size="12" className="ion-no-padding"></IonCol>
        </IonRow>
        <IonSlides
          pager={true}
          scrollbar={true}
          options={options}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideDidChange={(event: any) => getIndex(event)}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef}
          placeholder={undefined}
        >
          {itemlist.map((items, index) => {
            return (
              <IonSlide key={index} placeholder={undefined}>
                <IonList className="ion-no-padding" placeholder={undefined}>
                  <IonRow onClick={dismissPopover} placeholder={undefined}>
                    <IonCol
                      size="12"
                      className="ion-no-padding lesson-img"
                      placeholder={undefined}
                    >
                      {showImage ? (
                        <img
                          src={
                            items.ImageUrl
                              ? items.ImageUrl
                              : "./assets/images/unicone.jpg"
                          }
                          placeholder={undefined}
                        />
                      ) : (
                        <IonSkeletonText
                          animated
                          style={{ width: "100%", height: "100%" }}
                          placeholder={undefined}
                        />
                      )}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
        {itemlist.length > 0 ? (
          <IonRow
            className="lession-details lession-page"
            placeholder={undefined}
          >
            <div
              className="touch-lession"
              onTouchStart={deciHandleTouchStart}
              onTouchEnd={deciHandleTouchEnd}
            />
            <>
              {itemlist[length === -1 ? 0 : length].IsImageText === true ? (
                <>
                  <IonCol className="removeicon">
                    <div className="ion-padding-start ion-padding-bottom no-margin">
                      <div className="lession-icon decitemlist">
                        <p
                          className="translatetext"
                          style={{ fontSize: `${fszTrans}px` }}
                          dangerouslySetInnerHTML={{
                            __html: `${
                              itemlist.length > 0
                                ? itemlist[length === -1 ? 0 : length]
                                    ?.Content_en
                                : ""
                            }`,
                          }}
                        ></p>
                      </div>
                    </div>
                  </IonCol>
                </>
              ) : (
                <>
                  <IonCol
                    size="12"
                    className={
                      cheChar && piniyin
                        ? "ion-text-left audio-content char-pin-show "
                        : "" || (cheChar && !piniyin)
                        ? "ion-text-left audio-content char-show "
                        : "" || (!cheChar && piniyin)
                        ? "ion-text-left audio-content pin-show "
                        : ""
                    }
                    placeholder={undefined}
                  >
                    <ReactAudioPlayer
                      ref={audioRef}
                      onEnded={audioEnd}
                      controls={false}
                      src={
                        itemlist.length > 0
                          ? itemlist[length === -1 ? 0 : length]?.SoundUrl
                          : ""
                      }
                    />
                    <div className="PlayPause" onClick={handlePlayPause}>
                      <IonImg
                        src={
                          isPlaying
                            ? "./assets/images/PlayPauseiconyellow.svg"
                            : "./assets/images/autoplayiconyellow.svg"
                        }
                        placeholder={undefined}
                      />
                    </div>
                    <div
                      className="ion-padding-start ion-padding-bottom"
                      ref={elementRef}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleTouchEnd}
                    >
                      <ul
                        className={
                          "lession-icon decitemlist " +
                          (IsTranLangSim === true ? "simp-char" : "trad-char")
                        }
                      >
                        {itemlist.length === 0 ? (
                          <>
                            <IonSkeletonText animated placeholder={undefined} />
                          </>
                        ) : (
                          <></>
                        )}
                        {textset_tc(
                          itemlist.length > 0
                            ? IsTranLangSim
                              ? itemlist[length === -1 ? 0 : length]
                                  ?.Content_sc === null
                                ? ""
                                : itemlist[length === -1 ? 0 : length]
                                    ?.Content_sc
                              : itemlist[length === -1 ? 0 : length]
                                  ?.Content_tc === null
                              ? ""
                              : itemlist[length === -1 ? 0 : length]?.Content_tc
                            : "",
                          itemlist.length > 0
                            ? itemlist[length === -1 ? 0 : length]?.Content_py
                            : "",
                          itemlist.length > 0
                            ? IsTranLangSim
                              ? itemlist[length === -1 ? 0 : length]
                                  ?.ContentDicSc === null
                                ? ""
                                : itemlist[length === -1 ? 0 : length]
                                    ?.ContentDicSc
                              : itemlist[length === -1 ? 0 : length]
                                  ?.ContentDicTc === null
                              ? ""
                              : itemlist[length === -1 ? 0 : length]
                                  ?.ContentDicTc
                            : ""
                        )}
                      </ul>
                    </div>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="ion-padding-bottom ion-text-left"
                    placeholder={undefined}
                  >
                    {isTransChecked ? (
                      <>
                        <IonImg
                          src="./assets/images/transliteration-yellow.svg"
                          alt=""
                          placeholder={undefined}
                        />
                        <div className="ion-padding-start ion-padding-bottom no-margin">
                          <div className="lession-icon decitemlist">
                            <p
                              className="translatetext"
                              style={{ fontSize: `${fszTrans}px` }}
                              dangerouslySetInnerHTML={{
                                __html: `${
                                  itemlist.length > 0
                                    ? itemlist[length === -1 ? 0 : length]
                                        ?.Content_en
                                    : ""
                                }`,
                              }}
                            ></p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </IonCol>
                </>
              )}
            </>
          </IonRow>
        ) : (
          ""
        )}
        {/* </div> */}
      </IonContent>
      <IonModal
        isOpen={openModal}
        onDidDismiss={() => setOpenModal(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem
              class="ion-no-padding playbook"
              placeholder={undefined}
              onClick={() => AutoPlay()}
            >
              <div>
                <IonImg className="playlesson"
                  src="./assets/images/playlesson.svg"
                  placeholder={undefined}
                />
                <IonImg
                  src="./assets/images/play.svg"
                  placeholder={undefined}
                />
              </div>
              <div className="details ion-padding-start">
                <h4>{t("lesson.drawer.play_book")}</h4>
                <span>{t("lesson.drawer.atoply_bok_ado")}</span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("lesson.drawer.book_language")}</IonLabel>
              <div className="right-space">
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Simplified", 1)}
                  style={{
                    color:
                      IsTranLangSim === true
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Simp.
                </IonText>
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Traditional", 2)}
                  style={{
                    color:
                      IsTranLangSim === false
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Trad.
                </IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.pinyin")}
              </IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.translation")}
              </IonLabel>
              <IonToggle
                onClick={translationkModeToggle}
                checked={isTransChecked}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.font_size")}
              </IonLabel>
              <div className="right-space">
                <div
                  className={`fontselect ${clickedDown ? "clickedown" : ""}`}
                  onClick={() => {
                    fontsize(0);
                  }}
                >
                  <IonImg
                    className={fszChine <= 20 ? "active" : ""}
                    src="./assets/images/FontdownGray.svg"
                    placeholder={undefined}
                  />
                  <IonImg
                    className={fszChine > 20 ? "active" : ""}
                    src="./assets/images/Fontdown.svg"
                    placeholder={undefined}
                  />
                </div>
                <div
                  className={`fontselect ${clickedUp ? "clickedup" : ""}`}
                  onClick={() => {
                    fontsize(1);
                  }}
                >
                  <IonImg
                    className={fszChine >= 28 ? "active" : ""}
                    src="./assets/images/FontupGray.svg"
                    placeholder={undefined}
                  />
                  <IonImg
                    className={fszChine < 28 ? "active" : ""}
                    src="./assets/images/Fontup.svg"
                    placeholder={undefined}
                  />
                </div>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding preview" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.preview")}
              </IonLabel>
              <div className="right-space">
                <span
                  style={{
                    fontSize: "24px",
                    color: fszChine === 20 ? "black" : "rgba(0, 0, 0, 0.1)",
                    padding: "0 0 0 10px",
                  }}
                >
                  字
                </span>
                <span
                  style={{
                    fontSize: "28px",
                    color: fszChine === 24 ? "black" : "rgba(0, 0, 0, 0.1)",
                    padding: "0 0 0 10px",
                  }}
                >
                  字
                </span>
                <span
                  style={{
                    fontSize: "32px",
                    color: fszChine === 28 ? "black" : "rgba(0, 0, 0, 0.1)",
                    padding: "0 0 0 10px",
                  }}
                >
                  字
                </span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.playback_speed")}
              </IonLabel>
              <div className="leftright">
                <span
                  onClick={decreasePlaybackSpeed}
                  style={{
                    cursor: playbackSpeed > 0.75 ? "pointer" : "not-allowed",
                  }}
                >
                  &lt;
                </span>
                <span className="speed">
                  {playbackSpeed.toFixed(
                    playbackSpeed === 1.0 ? 1 : playbackSpeed === 1.5 ? 1 : 2
                  )}
                </span>
                <span
                  onClick={increasePlaybackSpeed}
                  style={{
                    cursor: playbackSpeed < 1.5 ? "pointer" : "not-allowed",
                  }}
                >
                  &gt;
                </span>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.bookmark")}
              </IonLabel>
              <div className="right-space">
                <IonImg
                  onClick={() => savebookmark(themeArray, index)}
                  className="bookmark"
                  src={
                    (themeArray ? themeArray[index]?.IsFavourite : "")
                      ? "./assets/images/Bookmarkfill.svg"
                      : "./assets/images/Bookmark.svg"
                  }
                  alt=""
                  placeholder={undefined}
                />
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Lesson);
