import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonRow,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  getPlatforms,
  IonIcon,
  IonSkeletonText,
} from "@ionic/react";
import "./MainHome.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import {
  chatboxOutline,
  chevronBack,
  close,
  notificationsOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";

interface OwnProps extends RouteComponentProps {}

interface MainHome extends OwnProps {}

const MainHome: React.FC<MainHome> = ({}) => {
  const history = useHistory();

  const { t } = useTranslation();

  let url: string = CONFIG.API_ENDPOINT; //live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [logininfo, setLoginitems] = React.useState<any>({});

  const [itemlist, setitems] = React.useState<any>({});

  const [itemThemeslist, setThemesitems] = React.useState<any>([]);
  const [itemThemesList, setThemesItems] = React.useState<any>([]);
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  const [showskleton, setShowskleton] = useState(true);
  const [IsCorporate, setIsCorporate] = useState(true);
  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";

  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";

  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    let userdata = logindata.Result;
    setIsCorporate(userdata?.IsCorporate);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;

      // let color_primary = "var(--ion-color-primary)"
      // let color_primary_rgb = "#e9def2"
      // let color_primary_contrast = "#ffffff"

      // let color_primary_shade = "#eee9f1"
      // let color_primary_tint = "#4c8dff"
      // //Dynaimc color set
      // document.documentElement.style.setProperty('--primary-color', color_primary);
      // document.documentElement.style.setProperty('--primary-color-rgb', color_primary_rgb);
      // document.documentElement.style.setProperty('--primary-color-contrast', color_primary_contrast);
      // document.documentElement.style.setProperty('--primary-color-shade', color_primary_shade);
      // document.documentElement.style.setProperty('--primary-color-tint', color_primary_tint);
    }

    const userData: any = localStorage.getItem("userlevel");
    const data = JSON.parse(userData);
    if (data === null) {
      getUserLevel();
    } else {
      setitems(data);
    }
    getthemes();
    GetProfie();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
  let userdata = logindata.Result;
  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        setitems(json.Result);
        const userLevel = json.Result;
        localStorage.setItem("userlevel", JSON.stringify(userLevel));
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const getthemes = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/book/GetThemesAndRecLesson", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      localStorage.setItem("ThemesAndRecLesson", JSON.stringify(json));

      if (json.StatusCode === 1) {

        setThemesitems(json.Result)

        // // Removing the element at index 0 from the Themes array

        // const removeThemeByName = (themes, nameToRemove) => {
        //   return themes.filter(theme => theme.Name !== nameToRemove);
        // };

        // const updatedThemes = removeThemeByName(json.Result.Themes, "Basics");

        // // Store the updated JSON with removed theme in a new variable
        // const updatedJson = {
        //   ...json,
        //   Result: {
        //     ...json.Result,
        //     Themes: updatedThemes,
        //   },
        // };

        // setThemesitems(updatedJson.Result);

        if (itemThemeslist?.Themes?.length !== 0) {
          setShowskleton(false);
        }
        // setTimeout(() => {
        //   setShowskleton(false);
        // }, 2700);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // setLoginitems(data)

  const GetProfie = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();

      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gonextPage = (value) => {
    history.replace({ pathname: "/tabs/mainhome/" + value });
    // history.go(0);
  };

  const Vocabularyrecap = (item, activeindex) => {
    history.replace({
      pathname: "/tabs/mainhome/vocabularyrecap",
      state: {
        themeId: item.ThemeId,
        index: activeindex,
        itemTheamlist: itemThemeslist?.Themes,
      },
    });
  };

  const goToHead = (value) => {
    history.replace(
      { pathname: "/tabs/mainhome/" + value },
      { direction: "none" }
    );
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader>
        <IonToolbar className="homeheader">
          <IonTitle color="primary" className="hometitle">
            <span>
              {t("mainhome.hello")} {itemprofilelist?.Name},<br />
              <span>
                {t("mainhome.welcome_back")}
                {/* {t('home.you_are_now_at_level')} {itemlist.Level}. */}
              </span>
            </span>
          </IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color="primary" onClick={()=>goToHead("wittywizard")}>
               <IonImg src="./assets/images/message.svg" alt="" /> 
            </IonButton> */}
            <IonButton
              color="primary"
              onClick={() => goToHead("notificationbell")}
            >
              {/* <IonIcon icon={notificationsOutline} /> */}
              <IonImg src="./assets/images/bell.svg" alt="" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing levelstart">
        <IonList className="ion-padding-horizontal">
          {IsCorporate == true ? (
            <>
              <IonCard
                onClick={() => gonextPage("initintro")}
                className="Introduction"
              >
                <IonCardContent class="ion-no-padding">
                  <div className="numberview">
                    <span>{t("mainhome.intro")}</span>
                  </div>
                  <IonImg
                    className="ion-float-right"
                    src="./assets/images/Introduction.svg"
                  />
                </IonCardContent>
              </IonCard>
            </>
          ) : (
            ""
          )}

          <IonCard onClick={() => gonextPage("home")} className="Introduction">
            <IonCardContent class="ion-no-padding">
              <div className="numberview">
                <span>{t("mainhome.levels")}</span>
              </div>
              <IonImg
                className="ion-float-right"
                src="./assets/images/Levels.svg"
              />
            </IonCardContent>
          </IonCard>
        </IonList>

        <IonList className="mainvocabularyrecap ion-padding-horizontal">
          <IonRow>
            <IonCol class="recap" size="12">
              <IonText>{t("mainhome.vocab_bank")}</IonText>
            </IonCol>
            {/* itemThemeslist?.Themes?.length === 0 && */}
            {showskleton ? (
              <>
                <IonSkeletonText
                  animated
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  placeholder={undefined}
                />
                <IonSkeletonText
                  animated
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  placeholder={undefined}
                />
                <IonSkeletonText
                  animated
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  placeholder={undefined}
                />
                <IonSkeletonText
                  animated
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  placeholder={undefined}
                />
                <IonSkeletonText
                  animated
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "20px",
                  }}
                  placeholder={undefined}
                />
              </>
            ) : (
              <>
                {itemThemeslist?.Themes?.map((items, index) => {
                  return (
                    <IonCol
                      key={index}
                      onClick={() => Vocabularyrecap(items, index)}
                      size="12"
                    >
                      {items?.Name && <IonText>{items?.Name}</IonText>}
                      <IonImg
                        className="ion-float-right"
                        src="./assets/images/Go.svg"
                      />
                    </IonCol>
                  );
                })}
              </>
            )}
          </IonRow>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(MainHome);
