import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonList,
  IonText,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
  IonModal,
} from "@ionic/react";
import "./Practicequestion.scss";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import VocabularyE1 from "./VocabularyE1"; // 4 images and 1 text
import VocabularyE2 from "./VocabularyE2"; // 1 image and 4 text
import VocabularyE3 from "./VocabularyE3"; // Writing
import VocabularyE4 from "./VocabularyE4"; // 1 audio and 4 text
import VocabularyE5 from "./VocabularyE5"; // recoding
import VocabularyE6 from "./VocabularyE6"; // rearrage word
interface OwnProps extends RouteComponentProps {}
interface VocabularypracticeProps extends OwnProps {
  isSuccess: boolean;
  isWrong: boolean;
}
interface IObjectKeys {
  [key: string]: string | number | boolean | null;
}
interface MyObject {
  id: number;
  name: string;
  type: string;
  pagecouter: number;
  totalque: number;
  back: number;
}
interface Answer extends IObjectKeys {
  q1: string | null;
  q2: string | null;
  q3: string | null;
}
interface Result extends IObjectKeys {
  q1: boolean | null;
  q2: boolean | null;
  q3: boolean | null;
  score: number | null;
}
const Vocabularypractice: React.FC<VocabularypracticeProps> = ({
  history,
  isSuccess,
  isWrong,
}) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemE4EV1list, setE4EV1tems] = React.useState<any[]>([]);
  const [itemE5EV1list, setE5EV1tems] = React.useState<any[]>([]);
  const [itemE6EV1list, setE6EV1tems] = React.useState<any[]>([]);
  const [itemE7EV1list, setE7EV1tems] = React.useState<any[]>([]);
  const [itemE8EV1list, setE8EV1tems] = React.useState<any[]>([]);
  const [isitemE4EV1, setE4EV1] = useState<boolean | undefined>(false);
  const [isitemE5EV1, setE5EV1] = useState<boolean | undefined>(false);
  const [isitemE6EV1, setE6EV1] = useState<boolean | undefined>(false);
  const [isitemE7EV1, setE7EV1] = useState<boolean | undefined>(false);
  const [isitemE8EV1, setE8EV1] = useState<boolean | undefined>(false);
  const [sete4, setE4data] = useState({ total: 0, attendans: 0 });
  const [sete5, setE5data] = useState({ total: 0, attendans: 0 });
  const [sete6, setE6data] = useState({ total: 0, attendans: 0 });
  const [sete7, setE7data] = useState({ total: 0, attendans: 0 });
  const [sete8, setE8data] = useState({ total: 0, attendans: 0 });
  let te4: any = "";
  let te5: any = "";
  let te6: any = "";
  let te7: any = "";
  let te8: any = "";
  const myStateRef = useRef(sete6);
  //New Code
  //Setting
  const [fontSize, setFontSize] = useState(18);
  const [clickedUp, setClickedUp] = useState(false);
  const [clickedDown, setClickedDown] = useState(false);
  const [cheChar, setCheChar] = useState<any>();
  const [piniyin, setPiniyin] = useState<any>();
  const [isTransChecked, setIsTransChecked] = useState<any>();
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [getThemes, setThemes] = useState<any>();
  let allThemes: any[] = [];
  let rap;
  //End setting
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE1slist, setE1stems] = React.useState<any[]>([]);
  const [itemE2slist, setE2stems] = React.useState<any[]>([]);
  const [itemE3slist, setE3stems] = React.useState<any[]>([]);
  const [itemE4slist, setE4stems] = React.useState<any[]>([]);
  const [itemE5slist, setE5stems] = React.useState<any[]>([]);
  const [itemE6slist, setE6stems] = React.useState<any[]>([]);
  const [isitemE1s, setE1s] = useState<boolean | undefined>(false);
  const [isitemE2s, setE2s] = useState<boolean | undefined>(false);
  const [isitemE3s, setE3s] = useState<boolean | undefined>(false);
  const [isitemE4s, setE4s] = useState<boolean | undefined>(false);
  const [isitemE5s, setE5s] = useState<boolean | undefined>(false);
  const [isitemE6s, setE6s] = useState<boolean | undefined>(false);
  const [seteE1, setE1sdata] = useState({ total: 0, attendans: 0 });
  const [seteE2, setE2sdata] = useState({ total: 0, attendans: 0 });
  const [seteE3, setE3sdata] = useState({ total: 0, attendans: 0 });
  const [seteE4, setE4sdata] = useState({ total: 0, attendans: 0 });
  const [seteE5, setE5sdata] = useState({ total: 0, attendans: 0 });
  const [seteE6, setE6sdata] = useState({ total: 0, attendans: 0 });
  const [backValue, setBackValue] = useState<any>();
  let tE1s: any = "";
  let tE2s: any = "";
  let tE3s: any = "";
  let tE4s: any = "";
  let tE5s: any = "";
  let tE6s: any = "";
  //End
  //Commaon Code
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    type: "",
    pagecouter: 0,
    totalque: 0,
    back: 0,
  });
  async function handleMyObjectChange(newObject: MyObject) {
    setMyObject(newObject);
    setAPIcall(false);

    if (newObject.back == 1) {
      // this is for retrun to back page conditon
      setBackValue(1);
      if (newObject.type === "e2s") {
        if (itemE1slist.length !== 0) {
          setE1s(true);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        }
      } else if (newObject.type === "e3s") {
        if (itemE2slist.length !== 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE1slist.length !== 0) {
          setE1s(true);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        }
      } else if (newObject.type === "e4s") {
        if (itemE3slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE2slist.length !== 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE1slist.length !== 0) {
          setE1s(true);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        }
      } else if (newObject.type === "e5s") {
        if (itemE4slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE4s = newObject.pagecouter;
          setE4sdata({
            total: itemE4slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE3slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE2slist.length !== 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE1slist.length !== 0) {
          setE1s(true);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        }
      } else if (newObject.type === "e6s") {
        if (itemE5slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE5s = newObject.pagecouter;
          setE5sdata({
            total: itemE5slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE4slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE4s = newObject.pagecouter;
          setE4sdata({
            total: itemE4slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE3slist.length !== 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE2slist.length !== 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE1slist.length !== 0) {
          setE1s(true);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        }
      }
    } else if (newObject.back == 0) {
      // this is for retrun to forward page conditon

      setBackValue(0);
      if (newObject.type === "e1s" && itemE1slist.length > 0) {
        // E1s Page Joints
        if (itemE2slist.length > 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE3slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE4slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE5slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE6slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(true);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
        } else if (itemE2slist.length === 0) {
          setE1s(false);
          setE2s(true);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE1s = newObject.pagecouter;
          setE1sdata({
            total: itemE1slist.length,
            attendans: newObject.pagecouter,
          });
          gopracticecompleted(newObject);
        }
      } else if (newObject.type === "e2s" && itemE2slist.length > 0) {
        // E2s Page Joints
        if (itemE3slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
          // gopracticecompleted(newObject);
        } else if (itemE4slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
          // gopracticecompleted(newObject);
        } else if (itemE5slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
          // gopracticecompleted(newObject);
        } else if (itemE6slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(true);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
          // gopracticecompleted(newObject);
        } else if (itemE3slist.length === 0) {
          setE1s(false);
          setE2s(false);
          setE3s(true);
          setE4s(false);
          setE5s(false);
          setE6s(false);
          tE2s = newObject.pagecouter;
          setE2sdata({
            total: itemE2slist.length,
            attendans: newObject.pagecouter,
          });
          //getExercise(1);
          gopracticecompleted(newObject);
        }
      } else if (newObject.type === "e3s" && itemE3slist.length > 0) {
        // E3s Page Joints
        if (itemE4slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          // getExercise(1);
        } else if (itemE5slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          // getExercise(1);
        } else if (itemE6slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          // getExercise(1);
        } else if (itemE4slist.length === 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(true);
          setE5s(false);
          setE6s(false);
          tE3s = newObject.pagecouter;
          setE3sdata({
            total: itemE3slist.length,
            attendans: newObject.pagecouter,
          });
          gopracticecompleted(newObject);
        }
      } else if (newObject.type === "e4s" && itemE4slist.length > 0) {
        // E4s Page Joints
        if (itemE5slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE4s = newObject.pagecouter;
          setE4sdata({
            total: itemE4slist.length,
            attendans: newObject.pagecouter,
          });
          getExercise(1);
        } else if (itemE6slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(true);
          tE4s = newObject.pagecouter;
          setE4sdata({
            total: itemE4slist.length,
            attendans: newObject.pagecouter,
          });
          getExercise(1);
        } else if (itemE5slist.length === 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(true);
          setE6s(false);
          tE4s = newObject.pagecouter;
          setE4sdata({
            total: itemE4slist.length,
            attendans: newObject.pagecouter,
          });
          getExercise(1);
          gopracticecompleted(newObject);
        }
      } else if (newObject.type === "e5s" && itemE5slist.length > 0) {
        // E5s Page Joints
        if (itemE6slist.length > 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(true);
          tE5s = newObject.pagecouter;
          setE5sdata({
            total: itemE5slist.length,
            attendans: newObject.pagecouter,
          });
          getExercise(1);
        } else if (itemE6slist.length === 0) {
          setE1s(false);
          setE2s(false);
          setE3s(false);
          setE4s(false);
          setE5s(false);
          setE6s(true);
          tE5s = newObject.pagecouter;
          setE5sdata({
            total: itemE5slist.length,
            attendans: newObject.pagecouter,
          });
          getExercise(1);
          gopracticecompleted(newObject);
        }
      } else if (newObject.type === "e6s" && itemE6slist.length > 0) {
        // E6s Page Joints

        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        tE6s = newObject.pagecouter;
        setE6sdata({
          total: itemE6slist.length,
          attendans: newObject.pagecouter,
        });
        getExercise(1);
        gopracticecompleted(newObject);
      } else {
        // Default Page Joints

        setE1s(false);
        setE2s(false);
        setE3s(false);
        setE4s(false);
        setE5s(false);
        setE6s(false);
        gopracticecompleted("");
      }
    }
  }
  useEffect(() => {
    myStateRef.current = sete6;
    if (isApicall) {
      getExercise(0);
    }
  });
  const [length, setLength] = React.useState(-1);
  const [lengthque, setLengthQue] = React.useState(1);
  const result: Result = { q1: false, q2: false, q3: false, score: 0 };
  const levelJSON: any = localStorage.getItem("levelinfo");
  const levalData = JSON.parse(levelJSON);
  //CompareSentences
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
  }>();
  let themeArray: any = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  let token = "";
  let resfreshtoken = "";
  const location = useLocation();

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetSettings();
    setLength(-1);
  });
  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    try {
      const response = await fetch(
        url +
          "/Book/GetLessons?themeGuid=" +
          themeId +
          "&level=" +
          levalData?.Leval,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      if (json.StatusCode == 1) {
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        allThemes = test;
        setThemes(test);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const getExercise = async (type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/Book/getvocabpractice", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();

      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        setAllExcies(json.Result);
        setAPIcall(false);
        if (type == 0) {
          //When oringal code then open this code
          if (json.Result.P1s.length > 0) {
            setE1s(true);
          } else if (json.Result.P2s.length > 0) {
            setE2s(true);
          } else if (json.Result.P3s.length > 0) {
            setE3s(true);
          } else if (json.Result.P4s.length > 0) {
            setE4s(true);
          } else if (json.Result.P5s.length > 0) {
            setE5s(true);
          } else if (json.Result.P6s.length > 0) {
            setE6s(true);
          }
          //For testing code
          // setE3s(true);
        }

        //New Code
        // setE1stems(json.Result.P1s);
        // setE2stems(json.Result.P2s);
        setE3stems(json.Result.P3s);
        // setE4stems(json.Result.P4s);
        setE5stems(json.Result.P5s);
        setE6stems(json.Result.P6s);

        //Exercise E1s  4 images and 1 text logic set
        let tmpoptione1s: any = [];
        let resulteE1s = json.Result.P1s;
        resulteE1s.forEach((axq: any[], indexq: number) => {
          let qq = [
            {
              Word: resulteE1s[indexq].ImageUrl1,
              questionno: 1,
            },
            {
              Word: resulteE1s[indexq].ImageUrl2,
              questionno: 2,
            },
            {
              Word: resulteE1s[indexq].ImageUrl3,
              questionno: 3,
            },
            {
              Word: resulteE1s[indexq].ImageUrl4,
              questionno: 4,
            },
          ];
          const random_o = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          let final = {
            EId: resulteE1s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE1s[indexq].TemplateCode,
            PageId: resulteE1s[indexq].PageId,
            BookId: resulteE1s[indexq].BookId,
            // Options: random_o,
            Options: qq,
            Question:
              resulteE1s[indexq].WordCh + " " + resulteE1s[indexq].WordPy,
            WordCh: resulteE1s[indexq].WordCh,
            WordSc: resulteE1s[indexq].WordSc,
            WordPy: resulteE1s[indexq].WordPy,
            answer: resulteE1s[indexq].CorrectImgUrl,
            score: false,
          };
          tmpoptione1s.push(final);
        });
        // setitems(tmpoptione1s);
        setE1stems(tmpoptione1s);
        // End E1s

        //Exercise E2s 1 images and 4 text logic set
        let tmpoptione2s: any = [];
        let resulteE2s = json.Result.P2s;
        resulteE2s.forEach((axq: any[], indexq: number) => {
          let qq = [
            {
              WordCh: resulteE2s[indexq].WordCh1,
              WordSc: resulteE2s[indexq].WordSc1,
              WordPy: resulteE2s[indexq].WordPy1,
              questionno: 1,
            },
            {
              WordCh: resulteE2s[indexq].WordCh2,
              WordSc: resulteE2s[indexq].WordSc2,
              WordPy: resulteE2s[indexq].WordPy2,
              questionno: 2,
            },
            {
              WordCh: resulteE2s[indexq].WordCh3,
              WordSc: resulteE2s[indexq].WordSc3,
              WordPy: resulteE2s[indexq].WordPy3,
              questionno: 3,
            },
            {
              WordCh: resulteE2s[indexq].WordCh4,
              WordSc: resulteE2s[indexq].WordSc4,
              WordPy: resulteE2s[indexq].WordPy4,
              questionno: 4,
            },
          ];
          const random_b = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          let final = {
            EId: resulteE2s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE2s[indexq].TemplateCode,
            PageId: resulteE2s[indexq].PageId,
            BookId: resulteE2s[indexq].BookId,
            // Options: random_b,
            Options: qq,
            Question: resulteE2s[indexq].ImageUrl,
            // WordCh: resulteE2s[indexq].WordCh,
            // WordPy: resulteE2s[indexq].WordPy,
            answerCh: resulteE2s[indexq].CorrectCh,
            answerSc: resulteE2s[indexq].CorrectSc,
            score: false,
          };
          tmpoptione2s.push(final);
        });
        // setitems(tmpoptione2s);
        setE2stems(tmpoptione2s);
        // End E2s

        //Exercise E4s 1 audio and 4 text logic set
        let tmpoptione4s: any = [];
        let resulteE4s = json.Result.P4s;
        resulteE4s.forEach((axq: any[], indexq: number) => {
          let qq = [
            {
              WordCh: resulteE4s[indexq].WordCh1,
              WordSc: resulteE4s[indexq].WordSc1,
              WordPy: resulteE4s[indexq].WordPy1,
              questionno: 1,
            },
            {
              WordCh: resulteE4s[indexq].WordCh2,
              WordSc: resulteE4s[indexq].WordSc2,
              WordPy: resulteE4s[indexq].WordPy2,
              questionno: 2,
            },
            {
              WordCh: resulteE4s[indexq].WordCh3,
              WordSc: resulteE4s[indexq].WordSc3,
              WordPy: resulteE4s[indexq].WordPy3,
              questionno: 3,
            },
            {
              WordCh: resulteE4s[indexq].WordCh4,
              WordSc: resulteE4s[indexq].WordSc4,
              WordPy: resulteE4s[indexq].WordPy4,
              questionno: 4,
            },
          ];
          const random_c = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          let final = {
            EId: resulteE4s[indexq].EId,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE4s[indexq].TemplateCode,
            PageId: resulteE4s[indexq].PageId,
            BookId: resulteE4s[indexq].BookId,
            // Options: random_c,
            Options: qq,
            Question: resulteE4s[indexq].AudioUrl,
            // WordCh: resulteE2s[indexq].WordCh,
            // WordPy: resulteE2s[indexq].WordPy,
            answerCh: resulteE4s[indexq].CorrectCh,
            answerSc: resulteE4s[indexq].CorrectSc,
            score: false,
          };
          tmpoptione4s.push(final);
        });
        // setitems(tmpoptione2s);
        setE4stems(tmpoptione4s);
        // End E2s

        const data = {
          E1s: tmpoptione1s,
          E2s: tmpoptione2s,
          E3s: json.Result.P3s,
          E4s: tmpoptione4s,
          E5s: json.Result.P5s,
          E6s: json.Result.P6s,
        };

        localStorage.setItem("practice", JSON.stringify(data));

        //Old Code
        setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    if (length > lengthque) {
      // setE4EV1(false);
      // setE5EV1(true);
      setLength(-1);
    }
  });
  const gobackpage = () => {
    //history.replace('/tabs/mainhome/themesports/5');
    history.replace({
      pathname: "/tabs/mainhome/vocabularyrecap",
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpageValue: backpageValue,
      },
    });
    // history.go(0);
    // window.location.reload();
    localStorage.removeItem("vocabAnswers");
    // localStorage.removeItem("practice");
  };
  const gopracticecompleted = (newObject) => {
    //history.replace('/tabs/mainhome/themesports/5');

    if (tE1s == "") {
      tE1s = seteE1.attendans;
    }
    if (tE2s == "") {
      tE2s = seteE2.attendans;
    }
    if (tE3s == "") {
      tE3s = seteE3.attendans;
    }
    if (tE4s == "") {
      tE4s = seteE4.attendans;
    }
    if (tE5s == "") {
      tE5s = seteE5.attendans;
    }
    if (tE6s == "") {
      tE6s = seteE6.attendans;
    }
    if (tE1s && tE2s && tE3s && tE4s && tE5s && tE6s) {
      if (
        tE1s == itemE1slist.length &&
        tE2s == itemE2slist.length &&
        tE3s == itemE3slist.length &&
        tE4s == itemE4slist.length &&
        tE5s == itemE5slist.length &&
        tE6s == itemE6slist.length
      ) {
        LessonComplete();
      }
      //tE1s
    } else if (tE1s && tE2s) {
      if (tE1s == itemE1slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE3s) {
      if (tE1s == itemE1slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE4s) {
      if (tE1s == itemE1slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE5s) {
      if (tE1s == itemE1slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE1s && tE6s) {
      if (tE1s == itemE1slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
      //tE2s
    } else if (tE2s && tE1s) {
      if (tE2s == itemE2slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE3s) {
      if (tE2s == itemE2slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE4s) {
      if (tE2s == itemE2slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE5s) {
      if (tE2s == itemE2slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE2s && tE6s) {
      if (tE2s == itemE2slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE3s
    else if (tE3s && tE1s) {
      if (tE3s == itemE3slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE2s) {
      if (tE3s == itemE3slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE4s) {
      if (tE3s == itemE3slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE5s) {
      if (tE3s == itemE3slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE3s && tE6s) {
      if (tE3s == itemE3slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE4s
    else if (tE4s && tE1s) {
      if (tE4s == itemE3slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE2s) {
      if (tE4s == itemE3slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE3s) {
      if (tE4s == itemE3slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE5s) {
      if (tE4s == itemE3slist.length && tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE4s && tE6s) {
      if (tE4s == itemE3slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE5s
    else if (tE5s && tE1s) {
      if (tE5s == itemE5slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE2s) {
      if (tE5s == itemE5slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE3s) {
      if (tE5s == itemE5slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE4s) {
      if (tE5s == itemE5slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE5s && tE6s) {
      if (tE5s == itemE5slist.length && tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    //tE5s
    else if (tE6s && tE1s) {
      if (tE6s == itemE6slist.length && tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE2s) {
      if (tE6s == itemE6slist.length && tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE3s) {
      if (tE6s == itemE6slist.length && tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE4s) {
      if (tE6s == itemE6slist.length && tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE6s && tE5s) {
      if (tE6s == itemE6slist.length && tE6s == itemE5slist.length) {
        LessonComplete();
      }
    }
    //singal
    else if (tE1s) {
      if (tE1s == itemE1slist.length) {
        LessonComplete();
      }
    } else if (tE2s) {
      if (tE2s == itemE2slist.length) {
        LessonComplete();
      }
    } else if (tE3s) {
      if (tE3s == itemE3slist.length) {
        LessonComplete();
      }
    } else if (tE4s) {
      if (tE4s == itemE4slist.length) {
        LessonComplete();
      }
    } else if (tE5s) {
      if (tE5s == itemE5slist.length) {
        LessonComplete();
      }
    } else if (tE6s) {
      if (tE6s == itemE6slist.length) {
        LessonComplete();
      }
    }
    // setE6data({ total: itemE6EV1list.length, attendans: newObject.pagecouter })

    history.replace({
      pathname: "/tabs/mainhome/vocabularypracticecomplete",
      state: {
        themeArray: themeArray,
        themeId: themeId,
        lessonname: lessonname,
        index: index,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };
  const LessonComplete = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    const data = {
      bookGuid: lessonsId,
    };
    try {
      const response = await fetch(url + "/Book/LessonComplete", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  useIonViewDidLeave(() => {
    // setE4EV1(false);
    // setE5EV1(false);
    // setE6EV1(false);
    // setE7EV1(false);
    // setE8EV1(false);
    //New
    setE1s(false);
    setE2s(false);
    setE3s(false);
    setE4s(false);
    setE5s(false);
    setE6s(false);
  });
  //Setting Code
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();
  const setIsLangueTrans = (value, type) => {
    if (type == 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 3000,
    // });
    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const fontsize = (data) => {
    if (data == 0) {
      if (fontSize > 18) {
        let font = fontSize - 3;
        setFontSize(font);
        setClickedDown(true);
      }
    } else if (data == 1) {
      if (fontSize < 24) {
        let font = fontSize + 3;
        setFontSize(font);
        setClickedUp(true);
      }
    }
    // Remove the 'clicked' class after a short delay (adjust as needed)
    setTimeout(() => {
      setClickedUp(false);
      setClickedDown(false);
    }, 300);
  };
  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
        setIsTranLangSim(
          (json.Result.LessonLanguage === "Chinese, Simplified") === true
        );
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      let newpiniyin: any;
      if (newCheChar === false) {
        setPiniyin(true);
        newpiniyin = true;
      } else {
        newpiniyin = null;
      }
      SaveUserSettings(
        newCheChar,
        newpiniyin !== null ? newpiniyin : piniyin,
        isTransChecked
      ); // Save the new state if needed
      return newCheChar;
    });
  };
  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      let newchechar: any;
      if (newPiniyin === false) {
        setCheChar(true);
        newchechar = true;
      } else {
        newchechar = null;
      }
      SaveUserSettings(
        newchechar !== null ? newchechar : cheChar,
        newPiniyin,
        isTransChecked
      ); // Save the new state if needed
      return newPiniyin;
    });
  };
  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };
  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;
    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update
    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }
    // Log the updated themeArray
    themeArray = updatedThemeArray;
  };
  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode == 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const decreasePlaybackSpeed = () => {
    if (playbackSpeed > 1) {
      changePlaybackSpeed(-1);
    }
  };
  const increasePlaybackSpeed = () => {
    if (playbackSpeed < 15) {
      changePlaybackSpeed(1);
    }
  };
  const changePlaybackSpeed = (speed: number) => {
    if (rap) {
      const audioElement = rap.audioEl.current;
      if (audioElement) {
        audioElement.playbackRate += speed;
        // Ensure playback speed is within bounds (1 to 15)
        if (audioElement.playbackRate < 1) {
          audioElement.playbackRate = 1;
        } else if (audioElement.playbackRate > 15) {
          audioElement.playbackRate = 15;
        }
        setPlaybackSpeed(audioElement.playbackRate);
      }
    }
  };

  useIonViewDidLeave(() => {
    localStorage.removeItem("vocabAnswers");
  });
  //End Setting
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader
        className="ion-text-center dubbletitle"
        placeholder={undefined}
      >
        <IonToolbar placeholder={undefined}>
          <div>
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={gobackpage}
                placeholder={undefined}
              >
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle
              color="primary"
              className="font20 ion-no-padding"
              placeholder={undefined}
            >
              <span>
                Vocabulary {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
                {isitemE2s}
              </span>
            </IonTitle>
            <IonButtons id="open-modal" slot="end" placeholder={undefined}>
              <IonButton
                className="ellipsisVertical"
                id="open-modal"
                onClick={openDrawer}
                color="primary"
                placeholder={undefined}
              >
                <IonIcon icon={ellipsisVertical} placeholder={undefined} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing Practicequestion" placeholder={undefined}>
        {/* E1s 4 images and 1 text */}
        {isitemE1s && itemE1slist.length > 0 ? (
          <div className="audiorecord E1s">
            <VocabularyE1
              itemE1s={itemE1slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E2s 1 image and 4 text */}
        {isitemE2s && itemE2slist.length > 0 ? (
          <div className="audiorecord E2s">
            <VocabularyE2
              itemE2s={itemE2slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E3s Writing  */}
        {isitemE3s && itemE3slist.length > 0 ? (
          <div className="audiorecord E3s">
            <VocabularyE3
              itemE3s={itemE3slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E4s 1 audio and 4 text */}
        {isitemE4s && itemE4slist.length > 0 ? (
          <div className="E4s">
            <VocabularyE4
              itemE4s={itemE4slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E5s recoding  */}
        {isitemE5s && itemE5slist.length > 0 ? (
          <div className="audiorecord E5s">
            <VocabularyE5
              itemE5s={itemE5slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
        {/* E6s rearrage word */}
        {isitemE6s && itemE6slist.length > 0 ? (
          <div className="audiorecord E6s">
            <VocabularyE6
              itemE6s={itemE6slist}
              onObjectChange={handleMyObjectChange}
              lastSlide={backValue}
              cheChar={cheChar}
              piniyin={piniyin}
              isTranLangSim={IsTranLangSim}
            />
          </div>
        ) : (
          ""
        )}
        {/*#################################################################################### */}
      </IonContent>
      <IonModal
        trigger="open-modal"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        // handleBehavior="cycle"
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("practicequestion.drawer.lesson_language")}
              </IonLabel>
              <div className="right-space">
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Simplified", 1)}
                  style={{
                    color:
                      IsTranLangSim === true
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Simp.
                </IonText>
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Traditional", 2)}
                  style={{
                    color:
                      IsTranLangSim === false
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Trad.
                </IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("practicequestion.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("practicequestion.drawer.pinyin")}
              </IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
          </IonList>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Vocabularypractice);
