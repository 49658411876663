import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonCard,
  IonCardContent,
  IonCol,
  IonText,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  getPlatforms,
  IonIcon,
} from "@ionic/react";
import "./Initialslast.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import {
  chatboxOutline,
  chevronBack,
  close,
  lockClosedOutline,
  notificationsOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";

interface OwnProps extends RouteComponentProps {}

interface Finalslast extends OwnProps {}

const Finalslast: React.FC<Finalslast> = ({}) => {
  const history = useHistory();

  const { t } = useTranslation();

  let url: string = CONFIG.API_ENDPOINT; //live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [logininfo, setLoginitems] = React.useState<any>({});

  const [itemlist, setitems] = React.useState<any>({});
  const [itemprofilelist, setprofileitems] = React.useState<any>({});

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();

  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";

  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";

  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const userData: any = localStorage.getItem("userlevel");
    const data = JSON.parse(userData);
    if (data === null) {
      getUserLevel();
    } else {
      setitems(data);
    }
    // GetProfie();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
  let userdata = logindata.Result;

  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        setitems(json.Result);
        const userLevel = json.Result;
        localStorage.setItem("userlevel", JSON.stringify(userLevel));
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // setLoginitems(data)

  const GetProfie = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();

      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gonextPage = (value) => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" + value });
  };

  const goBackInst = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/finalspracticetwo" });
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("finalslast.finals")}</span>
          </IonTitle>
          {/*  onClick={submit}  */}
          <IonButtons slot="end">
            <IonButton color="primary">
              {/* <IonIcon icon={informationCircleOutline} /> */}
              {/* <IonImg
                className="info"
                src="./assets/images/bang-circle-blue.svg"
                alt="" /> */}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing ion-padding-horizontal">
        <IonList className="completed ion-text-center">
          <IonCol size="12">
            <IonImg src="./assets/images/Finalslast.svg" alt="" />
            <p className="ion-text-left">
              {t("finalslast.disc1")}
              <br /> <br />
              {t("finalslast.disc2")}
            </p>
          </IonCol>
          <IonCol size="12">
            <div className="rightside">
              <IonButton
                color="warning"
                expand="block"
                onClick={() => goBackInst()}
              >
                {t("finalslast.bckbtn")}
              </IonButton>
            </div>
          </IonCol>
        </IonList>

        <IonList className="themes bordertop swiper-no-swiping">
          <IonCol>
            <IonText>{t("finalslast.check_this_out")}</IonText>
          </IonCol>
        </IonList>

        <IonList className="recommended-listing checkout swiper-no-swiping">
          {/* <IonCard onClick={() => gonextPage("tones")}>
            <div className="imglist">
              <img src="./assets/images/checktones.svg" alt="" />
            </div>
            <IonCardContent className="ion-text-left">
              {t("finalslast.tones")}
            </IonCardContent>
          </IonCard> */}
          {/* <IonCard onClick={() => gonextPage("initials")}>
            <div className="imglist">
              <img src="./assets/images/checkinitials.svg" alt="" />
            </div>
            <IonCardContent className="ion-text-left">
              {t("finalslast.initials")}
            </IonCardContent>
          </IonCard> */}
          {/* <IonCard onClick={() => gonextPage("finals")}>
            <div className="imglist">
              <img src="./assets/images/checkfinals.svg" alt="" />
            </div>
            <IonCardContent className="ion-text-left">
              {t("finalslast.finals")}
            </IonCardContent>
          </IonCard> */}
          <IonCard onClick={() => gonextPage("pinyin")}>
            <div className="imglist">
              <img src="./assets/images/checkpinyin.svg" alt="" />
            </div>
            <IonCardContent className="ion-text-left">
              {t("finalslast.pinyin")}
            </IonCardContent>
          </IonCard>
          <IonCard onClick={() => gonextPage("tonestwo")}>
            <div className="imglist">
              <img src="./assets/images/checktones2.svg" alt="" />
            </div>
            <IonCardContent className="ion-text-left">
              {t("finalslast.tones2")}
            </IonCardContent>
          </IonCard>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Finalslast);
