import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Pages import */
import Login from "./pages/Login";
import MainTabs from "./pages/MainTabs";
import Starting from "./pages/Starting";
import StartingLogReg from "./pages/StartingLogReg";
import Signup from "./pages/Signup";
import Forgotpassemail from "./pages/Forgotpassemail";
import Forgotpassresetpass from "./pages/Forgotpassresetpass";
import Forgotpassverify from "./pages/Forgotpassverify";
import Beforewestart from "./pages/Beforewestart";
import Termscondition from "./pages/Termscondition";
import Pretestone from "./pages/Pretestone";
import Pretesttwo from "./pages/Pretesttwo";
import Testquestion from "./pages/Testquestion";
import Testdone from "./pages/Testdone";
import Support from "./pages/Support";
import Pagelink from "./pages/Pagelink";

setupIonicReact();
export interface AppConfig {
  API_ENDPOINT: string;
}

export const CONFIG: AppConfig = {
  // API_ENDPOINT : "https://api.marcone.com/mobile/api"
  API_ENDPOINT: "https://mmxappapi.azurewebsites.net/api/v1",
};

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet placeholder={undefined}>
        <Route path="/tabs" component={MainTabs}/>
        <Route path="/starting" component={Starting} />
        <Route path="/startinglogreg" component={StartingLogReg} />
        <Route path="/login" component={Login} />
        <Route path="/Signup" component={Signup} />
        <Route path="/forgotpassemail" component={Forgotpassemail} />
        <Route path="/forgotpassverify" component={Forgotpassverify} />
        <Route path="/forgotpassresetpass" component={Forgotpassresetpass} />
        <Route path="/beforewestart" component={Beforewestart} />
        <Route path="/termscondition" component={Termscondition} />
        <Route path="/pretestone" component={Pretestone} />
        <Route path="/pretesttwo" component={Pretesttwo} />
        <Route path="/testquestion" component={Testquestion} />
        <Route path="/testdone" component={Testdone} />
        <Route path="/support" component={Support} />
        <Route path="/Pagelink" component={Pagelink} />

        {/* <Route path="/logout" render={() => {
                  return <RedirectToLogin
                    setIsLoggedIn={setIsLoggedIn}
                    setUsername={setUsername}
                  />;
                }} /> */}

        {/* <Route exact path="/tab1">
            <Tab1 />
          </Route>
          <Route exact path="/tab2">
            <Tab2 />
          </Route>
          <Route path="/tab3">
            <Tab3 />
          </Route>
          <Route exact path="/">
            <Redirect to="/tab1" />
          </Route> */}
        <Route path="/" component={Starting} exact />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
