import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
} from "@ionic/react";
import "./E4ev1vocab.scss";
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from "react-audio-player";
import { CONFIG } from "../App";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { string } from "yup";

interface MyObject {
  id: number;
  name: string;
  type: "e4ev1";
  answ: true;
  pagecouter: 0;
  totalque: 0;
}
interface E4ev1vocabProps {
  itemE4EV1: any;
  onObjectChange: (object: MyObject) => void;
}

const E4ev1vocab: React.FC<E4ev1vocabProps> = (
  props: E4ev1vocabProps,
  { isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const [showMessage, setShowMessage] = useState(false);
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    answ: true,
    type: "e4ev1",
    pagecouter: 0,
    totalque: 0,
  });
  const [length, setLength] = React.useState(-1);
  const [showResults, setShowResults] = React.useState(1);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  let [swiper, setSwiper] = useState<SwiperCore>();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");

  const [pageRead, setpageRead] = useState(0);
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let Ise4ev1: any = true;
  let pagecouter: any = 0;
  let totalque: any = 0;
  let indexdbq: any = -1;
  // let myanswer = ''
  function handleChange() {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      answ: Ise4ev1,
      type: "e4ev1",
      pagecouter: pagecouter,
      totalque: props.itemE4EV1.length,
    };
    props.onObjectChange(myObject);
  }
  useIonViewWillEnter(() => {
    setShowResults(1);
    setLength(1);
    setShowMessage(true);
  });

  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };
  const slideRef = useRef<HTMLIonSlidesElement>(null);

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
      resetstate();
      setShowResults(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
      // resetstate()
      // setShowResults((index-1))
    });
  };

  const handleSlideDidChanged = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then(async (index) => {
      // if (slidesRef.current) {
      //   const ionSlides = await slidesRef.current.getSwiper();
      // }

      SaveExerciseQueAnswer(length);
      if (index + 1 == length) {
        setLength(-1);
        saveContent();
        handleChange();
      }
    });
  };

  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
    } else {
      isSuccess = false;
      isWrong = true;
    }

    //  let data = itemlist[showResults - 1];

    if (items) {
      resetstate();
      setShowResults(index);
      setLength(index);

      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };
  let tmpscore = 0;

  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type,
    indexnew
  ) => {
    // indexdbq = index

    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      // myanswer = props.itemE4EV1[index].Options?.[indexnew].Word
      setmyanswer("");
      setmyanswer(props.itemE4EV1[index].Options?.[indexnew].Word);

      if (!props.itemE4EV1[index].answer) {
        props.itemE4EV1[index].answer = type;
        props.itemE4EV1[index].score = true;
        tmpscore += tmpscore;
      }
    } else {
      isSuccess = false;
      isWrong = true;
      setRightAnsOption(true);
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(props.itemE4EV1[index].Options?.[indexnew].Word);
      if (!props.itemE4EV1[index].answer) {
        props.itemE4EV1[index].answer = type;
        props.itemE4EV1[index].score = false;
      }
    }
  };

  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };
  useEffect(() => {
    if (length > props.itemE4EV1.length) {
      setLength(-1);
      saveContent();
      handleChange();
    }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })

    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < props.itemE4EV1.length; index++) {
      const element = props.itemE4EV1[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;

      anw[propName] = propValue.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }

    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");

    const myArray = Object.entries(finalanswer);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }

    const data = {
      contentPageCode: props.itemE4EV1[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE4EV1[0].Book_id,
      contentPageId: props.itemE4EV1[0].Page_id,
      answer: stringWithoutBackslashes,
      score: tmscore,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // let rigthans = '';
    // for (let index = 0; index < props.itemE4EV1.length; index++) {
    //   const element = props.itemE4EV1[index];
    //   for (let j = 0; j < element.Options.length; j++) {
    //     const elementans = element.Options[j];
    //     if (props.itemE4EV1[indexdb - 1]?.Question?.[0].soundno == elementans.questionno) {
    //       rigthans = elementans.Word;
    //     }
    //   }
    // }

    const array = props.itemE4EV1[indexdb - 1].Options;

    const targetValue = props.itemE4EV1[indexdb - 1]?.Question?.[0].soundno;

    const foundValue = array.find(
      (element) => element.questionno === targetValue
    );

    const data = {
      ev_id: props.itemE4EV1[0].Ev_Id,
      templateCode: props.itemE4EV1[indexdb - 1].TemplateCode,
      question: props.itemE4EV1[indexdb - 1]?.Question?.[0].SoundUrl,
      rightAnswer: foundValue.Word,
      answer: myanswer,
    };

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  return (
    <IonPage className="pages-space intro">
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={options}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef}
        >
          {props.itemE4EV1.map((items, index) => {
            return (
              <IonSlide>
                <IonList key={index} className="ion-no-padding height100">
                  <IonRow className="Practice leftrightspace ion-padding-horizontal">
                    <IonCol>
                      <IonText>Choose the correct answer</IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow className="Practice ion-padding-horizontal">
                    <IonCol size="12">
                      {/* {index + 1}
                      {items?.Question?.[0].SoundUrl} */}
                      {showMessage ? (
                        <ReactAudioPlayer
                          className="String"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source
                            src={items?.Question?.[0].SoundUrl}
                            type="audio/mpeg"
                          ></source>
                        </ReactAudioPlayer>
                      ) : (
                        ""
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer ion-padding-horizontal">
                    {props.itemE4EV1?.[index].Options?.map(
                      (datav, indexnew) => {
                        return (
                          <IonCol size="6" key={indexnew}>
                            <IonCard
                              id={datav.questionno}
                              style={{
                                borderColor:
                                  setRightAns &&
                                  items?.Question?.[0].soundno ===
                                    datav.questionno
                                    ? "var(--ion-color-success)"
                                    : "",
                              }}
                              className={
                                successOption &&
                                selectedDiv === datav.questionno
                                  ? "answer-right"
                                  : wrongOption &&
                                    selectedDiv === datav.questionno
                                  ? "answer-wrong"
                                  : ""
                              }
                              onClick={() =>
                                handleQuestionCheckClick(
                                  items,
                                  index,
                                  showResults + 1,
                                  datav.questionno,
                                  indexnew
                                )
                              }
                            >
                              <IonCardContent class="ion-no-padding">
                                <div>
                                  <IonText>{datav.Word} </IonText>
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </IonCol>
                        );
                      }
                    )}
                    <IonCol size="12">
                      {/* {showImage && <IonImg className='slide' src="./assets/images/swipe-left-right.gif" />} */}
                      {/* <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton> */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      {/* <IonContent className='listing'>
        {props.itemE4EV1.map((items, index) => {
          return (
            <IonList key={index} style={{ display: showResults == index + 1 ? "block" : "none" }} className="ion-no-padding height100">
              <IonRow className='Practice ion-padding-horizontal'>
                <IonCol size='12'>
                  {showMessage ?
                    <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                      <source src={items?.Question?.[0].SoundUrl} type="audio/mpeg"></source>
                    </ReactAudioPlayer> : ''}
                </IonCol>
              </IonRow>


              <IonRow className='answer ion-padding-horizontal'>
                {props.itemE4EV1?.[index].Options?.map((datav, indexnew) => {
                  return (
                    <IonCol size='6' key={indexnew}>
                      <IonCard id={datav.questionno} style={{ borderColor: setRightAns && (items?.Question?.[0].soundno === datav.questionno) ? 'var(--ion-color-success)' : '' }} className={successOption && (selectedDiv === datav.questionno) ? 'answer-right' : wrongOption && (selectedDiv === datav.questionno) ? 'answer-wrong' : ''} onClick={() => handleQuestionCheckClick(items, index, showResults + 1, datav.questionno)} >
                        <IonCardContent class="ion-no-padding">
                          <div>
                            <IonText>{datav.Word} </IonText>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  )
                })}
                <IonCol size='12'>
                  <IonButton expand="block" onClick={() => gotonextques(items, showResults + 1, 1)}>Next</IonButton>
                </IonCol>
              </IonRow>
            </IonList>
          )
        })}
      </IonContent> */}

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow>
          <IonList className="list">
            {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg className="playlesson"
                  src="./assets/images/playlesson.svg"
                  placeholder={undefined}
                />
                <IonImg
                  src="./assets/images/play.svg"
                  placeholder={undefined}
                />
              </div>
              <div className="details ion-padding-start">
                <h4>Play book</h4>
                <span>Autoplay book audio</span>
              </div>
            </IonItem> */}
            <IonItem class="ion-no-padding">
              <IonLabel>Lesson language</IonLabel>
              <div className="right-space">
                <IonText color="primary" placeholder={undefined}>
                  Simp.
                </IonText>
                <IonText placeholder={undefined}>Trad.</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Chinese characters</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Pinyin</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Translation</IonLabel>
              <IonToggle slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>Font size</IonLabel>
              <IonImg src="./assets/images/Fontdown.svg" />
              <IonImg src="./assets/images/Fontup.svg" />
            </IonItem> */}
            <IonItem class="ion-no-padding">
              <IonLabel>Playback speed</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a>
                <span className="speed">1.0</span>
                <a href="#">&gt;</a>
              </div>
            </IonItem>
            {/* <IonItem class="ion-no-padding">
              <IonLabel>Bookmark</IonLabel>
              <IonIcon color='primary' icon={bookmark} />
            </IonItem> */}
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};

export default React.memo(E4ev1vocab);
