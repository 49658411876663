import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonText,
  IonCol,
  useIonViewWillEnter,
  IonIcon,
  IonSlides,
  IonSlide,
  IonModal,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Finals.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack } from "ionicons/icons";
import audioJSON from "./json/audio.json";
import dataDescription from "./json/letterDesc.json";

interface OwnProps extends RouteComponentProps {}

interface Finals extends OwnProps {}

const Finals: React.FC<Finals> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState<any>(false);
  const [openModal, setOpenModal] = useState<any>(false);
  const [myArray, setMyArray] = useState<any>([]);
  const [length, setLength] = React.useState(-1);
  const [value, setValue] = useState<any>();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const audioRef = useRef<any>();
  const audioRefEr = useRef<any>();
  // json imported below
  const audioSrc: any = audioJSON;
  const audioPath: any = audioSrc?.audioSrc;
  const description: any = dataDescription;
  const data: any = description.description;

  useIonViewWillEnter(() => {
    const getData: any = localStorage.getItem("myArray");
    const storedArray = JSON.parse(getData) || [];
    setMyArray(storedArray);
  });

  useIonViewWillLeave(() => {
    localStorage.removeItem("myArray");
  });

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/finalspractice" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro" });
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
        // setIsPlaying(false);
      } else {
        audio.play();
        // setIsPlaying(true);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleEnd = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const PopUp = (Value: any) => {
    setValue(Value);
    setOpenModal(true);

    if (Value && !myArray.includes(Value)) {
      // Update state and localStorage
      const updatedArray: any = [...myArray, Value];
      setMyArray(updatedArray);
      localStorage.setItem("myArray", JSON.stringify(updatedArray));
    }
    setTimeout(() => {
      isElementHidden(Value);
    }, 3000);
  };

  const specialFinal = (Value: any) => {
    if (Value && !myArray.includes(Value)) {
      // Update state and localStorage
      const updatedArray: any = [...myArray, Value];
      setMyArray(updatedArray);
      localStorage.setItem("myArray", JSON.stringify(updatedArray));
    }

    // Play audio when popup is clicked
    const audio = audioRefEr.current;
    if (audio) {
      audio.src = audioPath[Value]; // Set audio source
      audio.play(); // Play audio
    }

    setTimeout(() => {
      isElementHidden(Value);
    }, 3000);
  };

  const isElementHidden = (element) => {
    return myArray.includes(element);
  };

  const ionCloseEvent = () => {
    setOpenModal(false);
    setIsPlaying(false);
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("finals.finals")}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary"></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing pagespace">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <IonList className="tone tone-main">
              <div className="spacing">
                <IonText className="subtitle">{t("finals.slide1")}</IonText>
                <div style={{ marginTop: "45px" }}>
                  <span>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/ni.svg" alt="" />
                    </IonText>
                    <IonText className="subtitle-chinese piniyinfont">
                      <div>你</div>
                    </IonText>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main initials">
              <IonCol class="recap" size="12">
                <IonText>35 {t("finals.finals")}</IonText>
              </IonCol>
              <div className="linetext">
                <span>
                  <ul>
                    <li onClick={() => PopUp("a")}>
                      <div className="dott-symbol">
                        <span>a</span>
                        <span className="sonar-wrapper"
                          style={{
                            display: isElementHidden("a") ? "none" : "inline",
                            zIndex: "-20",
                          }}
                        >
                          <div className="sonar-emitter">
                            <div className="sonar-wave"></div>
                          </div>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                          >
                            <g>
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                                fill-opacity="0.7"
                              />
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                              />
                            </g>
                          </svg> */}
                        </span>
                      </div>
                    </li>
                    <li onClick={() => PopUp("o")}>o</li>
                    <li onClick={() => PopUp("e")}>e</li>
                    <li></li>
                  </ul>
                  <ul className="listingtext">
                    <li onClick={() => PopUp("i")}>i</li>
                    <li onClick={() => PopUp("u")}>u</li>
                    <li onClick={() => PopUp("ü")}>ü</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ai")}>ai</li>
                    <li onClick={() => PopUp("ei")}>ei</li>
                    <li onClick={() => PopUp("ao")}>ao</li>
                    <li onClick={() => PopUp("ou")}>ou</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ia")}>ia</li>
                    <li onClick={() => PopUp("ie")}>ie</li>
                    <li onClick={() => PopUp("ua")}>ua</li>
                    <li onClick={() => PopUp("uo")}>uo</li>
                  </ul>
                  <ul className="listingtext">
                    <li onClick={() => PopUp("üe")}>üe</li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("iao")}>iao</li>
                    <li onClick={() => PopUp("iou")}>iou</li>
                    <li onClick={() => PopUp("uai")}>uai</li>
                    <li onClick={() => PopUp("uei")}>uei</li>
                  </ul>
                </span>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main initials">
              <IonCol class="recap" size="12">
                <IonText>35 {t("finals.finals")}</IonText>
              </IonCol>
              <div className="threename linetext">
                <span>
                  <ul>
                    <li onClick={() => PopUp("an")}>
                      <div className="dott-symbol">
                        <span>an</span>
                        <span className="sonar-wrapper"
                          style={{
                            display: isElementHidden("an") ? "none" : "inline",
                            right: "-20px",
                            zIndex: "-20",
                          }}
                        >
                          <div className="sonar-emitter">
                            <div className="sonar-wave"></div>
                          </div>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                          >
                            <g>
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                                fill-opacity="0.7"
                              />
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                              />
                            </g>
                          </svg> */}
                        </span>
                      </div>
                    </li>
                    <li onClick={() => PopUp("en")}>en</li>
                    <li onClick={() => PopUp("in")}>in</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ian")}>ian</li>
                    <li onClick={() => PopUp("uan")}>uan</li>
                    <li onClick={() => PopUp("uen")}>uen</li>
                  </ul>
                  <ul className="listingtext">
                    <li onClick={() => PopUp("üan")}>üan</li>
                    <li onClick={() => PopUp("ün")}>ün</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ang")}>ang</li>
                    <li onClick={() => PopUp("eng")}>eng</li>
                    <li onClick={() => PopUp("ong")}>ong</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ing")}>ing</li>
                    <li onClick={() => PopUp("iang")}>iang</li>
                    <li onClick={() => PopUp("iong")}>iong</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("uang")}>uang</li>
                    <li onClick={() => PopUp("ueng")}>ueng</li>
                    <li></li>
                  </ul>
                </span>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main initials">
              <IonCol class="recap" size="12">
                <IonText>Special Final</IonText>
              </IonCol>
              <div className="dott-symbol" onClick={() => specialFinal("er")}>
                <span className="textsymbol">
                  er
                  <span className="sonar-wrapper"
                    style={{
                      display: isElementHidden("er") ? "none" : "inline",
                      right: "-15px",
                      bottom: "-10px",
                      position: "absolute",
                      zIndex: "-20",
                    }}
                  >
                    <div className="sonar-emitter">
                      <div className="sonar-wave"></div>
                    </div>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                    >
                      <g>
                        <circle
                          cx="24.9999"
                          cy="25.0004"
                          r="10.1351"
                          fill="#F4DEA5"
                          fill-opacity="0.7"
                        />
                        <circle
                          cx="24.9999"
                          cy="25.0004"
                          r="10.1351"
                          fill="#F4DEA5"
                        />
                      </g>
                    </svg> */}
                  </span>
                </span>
              </div>
              <audio
                // src={`./assets/IntroAudio/${value}.mp3`}
                controls={false}
                ref={audioRefEr}
                onEnded={handleEnd}
              />
              <IonText className="subtitle">
                {/* This is a special final. “er” in most cases are used as retroflexed suffix, and are written as “’r” after a final. For example, flower 花儿 — huā'er. */}
                This is a special final. “er” in most cases is used as retroflexed suffix, and is written as “er” or “’r” after a final. For example, flower 花儿 — huā'er.
              </IonText>
            </IonList>
          </IonSlide>
        </IonSlides>
      </IonContent>

      <IonModal
        className="initials-popup"
        isOpen={openModal}
        onDidDismiss={ionCloseEvent}
      >
        <div className="react-confirm-alert-body play-popup">
          <IonText className="bigtitle">{value}</IonText>
          <div onClick={togglePlay}>
            <audio
              src={audioPath[value]}
              controls={false}
              ref={audioRef}
              onEnded={handleEnd}
            />
            <IonImg
              className="playbig"
              src={
                isPlaying
                  ? "./assets/images/Pausebig.svg"
                  : "./assets/images/playbig.svg"
              }
            />
          </div>
          <p className="subtext popuppadding">{data[value]}</p>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default React.memo(Finals);
