import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonText,
  IonList,
  IonCol,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlide,
  IonSlides,
  IonImg,
  IonModal,
} from "@ionic/react";
import "./Dialoguevideo.scss";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {
  id: string;
}
interface DialoguevideoProps extends OwnProps {}
const Dialoguevideo: React.FC<DialoguevideoProps> = ({ history }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  // const params = useParams<{id: string}>();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  // setting start
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();
  const [progress, setProgress] = useState({});
  const [isPlaying, setIsPlaying] = useState({});
  // const audioRefs = useRef<any>({});
  // const audioSpeedRef = useRef<any>();
  const audioRefs = useRef<any[]>([]); // Define audioRefs as an array
  const [cheChar, setCheChar] = useState<any>();
  const [piniyin, setPiniyin] = useState<any>();
  const [isTransChecked, setIsTransChecked] = useState<any>();
  const [bookmarkindex, setBookmarkindex] = React.useState<any>(-1);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const levelJSON: any = localStorage.getItem("levelinfo");
  const levalData = JSON.parse(levelJSON);
  // setting end
  const [showImage, setShowImage] = useState(false);
  const historyss = useHistory<{
    themeArray: any;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
    originalBackValue: number;
    title: string;
    videoUrl: string;
    thumb: string;
    role: string;
    rolename: string;
    allrole: string;
    index: string;
  }>();
  let themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const originalBackValue = historyss.location.state
    ? historyss.location.state.originalBackValue
    : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const thumb = historyss.location.state ? historyss.location.state.thumb : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  let string = role || allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }
  const [index, setIndex] = useState(0);
  const [displayedItems, setDisplayedItems] = useState<any[]>([]);
  const ionListRef = useRef<any>(null);
  const videoRef = useRef<any>(null);
  // const audioRef = useRef<any>(null);
  const [showDialogues, setShowDialogues] = useState(false);
  const [isVideoAutoplaying, setVideoIsAutoplaying] = useState(true);
  const index3 = index + 1;
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  let token = "";
  let resfreshtoken = "";
  let allThemes: any[] = [];
  const [getThemes, setThemes] = useState<any>();
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    localStorage.setItem("pagecode1", JSON.stringify("Practice"));
    localStorage.setItem("pagecode2", JSON.stringify("Read again"));
    GetSettings();
    // getdialogue()
  });
  const getthemes = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });
    try {
      const response = await fetch(
        url +
          "/Book/GetLessons?themeGuid=" +
          themeIddb +
          "&level=" +
          levalData?.Leval,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      if (json.StatusCode == 1) {
        for (let index = 0; index < json.Result.Lessons.length; index++) {
          const element = json.Result.Lessons[index];
        }
        let test: any[] = [];
        const array1 = json.Result.Lessons;
        const array2 = json.Result.Dialogues;
        // const combinedArray = array1.concat(array2);
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
          // Your logic here
          if (array1[i]) {
            array1[i]["type"] = "lesson";
            array1[i]["number"] = i + 1;
            test.push(array1[i]);
          }
          if (array2[i]) {
            array2[i]["type"] = "dialogues";
            array2[i]["number"] = i + 1;
            test.push(array2[i]);
          }
        }
        localStorage.setItem("itemlist", JSON.stringify(test));
        allThemes = test;
        setThemes(test);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const scrollToBottom = () => {
    if (ionListRef.current) {
      // ionListRef.current.lastChild.scrollIntoView({behavior: 'smooth'});
      ionListRef.current.scrollIntoView({ behavior: "smooth" });
      const scrollHeight = ionListRef.current.scrollHeight;
      const clientHeight = ionListRef.current.clientHeight;
      ionListRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };
  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      // scrollToBottom();
    }, 1);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [displayedItems]);
  useEffect(() => {
    // Add touchstart event listener to video element
    const videoElement = videoRef.current;
    const handleTouchStart = () => {
      // Toggle video play/pause state
      if (videoElement?.paused) {
        videoElement?.play();
        if (showDialogues) {
          allaudiostop();
        }
      } else {
        videoElement?.pause();
      }
    };
    // Attach touchstart event listener to video element
    videoElement?.addEventListener("touchstart", handleTouchStart);
    // Clean up event listener when the component is unmounted
    return () => {
      videoElement?.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);
  const toggleVideoPlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play(); // Play the video
      if (showDialogues) {
        allaudiostop();
      }
    } else {
      video.pause(); // Pause the video
    }
  };
  const [isVideoPlay, setIsVideoPlay] = useState<any>();
  const [isVideoPlayShow, setIsVideoPlayShow] = useState<any>(true);
  const [showThumb, setShowThumb] = useState<any>(true);
  const [buffer, setBuffer] = useState<any>(false);
  const onPlayVideo = () => {
    if (showDialogues) {
      allaudiostop();
    }
    setIsVideoPlay(true);
    setShowThumb(false);
    videoBtnTimer();
  };
  const onPauseVideo = () => {
    setIsVideoPlay(false);
    setShowThumb(true);
    videoBtnTimer();
  };
  const handleBufferStart = () => {
    // Function to call when buffering starts
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    // });
    setBuffer(true);
  };
  const handleBufferStop = () => {
    // Function to call when buffering stops
    dismiss();
    setBuffer(false);
  };
  const videoBtnTimer = () => {
    setIsVideoPlayShow(true);
    if (!isVideoPlay) {
      setTimeout(() => {
        setIsVideoPlayShow(false);
      }, 1000);
    } else {
      setIsVideoPlayShow(true);
    }
  };
  const toggleFullScreen = async () => {
    const video = videoRef.current;
    await ScreenOrientation.lock({ orientation: "landscape" });
    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  };
  const exitFullScreen = async () => {
    const video = videoRef.current;
    if (video) {
      await ScreenOrientation.lock({ orientation: "portrait" });
    }
  };
  document.addEventListener("fullscreenchange", () => {
    if (!document.fullscreenElement) {
      exitFullScreen();
    }
  });
  const allaudiostop = () => {
    displayedItems.forEach((item) => {
      // Call your function here with the item as an argument
      const itemId = item.Id;
      audiostopbyid(itemId);
    });
  };
  const audiostopbyid = (itemId) => {
    // Call your function here with the item as an argument
    const audioElement = document.getElementById(
      `${itemId}`
    ) as HTMLAudioElement;
    audioElement.pause();
  };
  const handleSkipVideo = () => {
    setShowDialogues(true);
  };
  const handleVideoEnd = () => {
    setShowDialogues(true);
  };
  const handleAudioEnd = (index, id) => {
    return () => {
      // scrollToBottom();
      if (index3 !== itemlist.length) {
        // scrollToBottom();
        setTimeout(() => {
          // handleClickNext();
          // setActivePlayer(index);
          // You can perform actions related to this audio here.
          const prevItems = itemlist[index + 1];
          const lastIndex = displayedItems.length - 1;
          if (index === lastIndex) {
            setDisplayedItems([...displayedItems, prevItems]); // Add item.Id to displayedItems array
            setIndex((prevIndex) => prevIndex + 1);
          }
        }, 3000); // Adjust the delay as needed
      }
      if (index3 === itemlist.length) {
        // scrollToBottom();
        setShowImage(true);
      }
      setIsPlaying((prevIsPlaying) => ({ ...prevIsPlaying, [id]: false }));
    };
  };
  const [targetId, setTargetId] = useState<any>();
  const audioClick = (id) => {
    return () => {
      // Change playback speed for the clicked audio
      changePlaybackSpeed(id, playbackSpeed);
      // handleAudioPlay(id);
      setIsPlaying((prevIsPlaying) => ({ ...prevIsPlaying, [id]: true }));
      // scrollToBottom();
      setTargetId(id);
      const video = videoRef.current;
      video.pause(); // Pause video
      displayedItems.forEach((item) => {
        if (id !== item?.Id) {
          // Call your function here with the item as an argument
          const audioElement = document.getElementById(
            `${item?.Id}`
          ) as HTMLAudioElement;
          audioElement.pause();
        }
      });
      if (index3 === itemlist.length) {
        // scrollToBottom();
        setShowImage(true);
      }
    };
  };
  const handleClickNext = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
      } else {
        // if (index < itemlist.length) {
        //   // setDisplayedItems([...displayedItems, itemlist[index]]);
        //   setDisplayedItems((prevItems) => [...prevItems, itemlist[index]]);
        //   setIndex((prevIndex) => prevIndex + 1);
        //   scrollToBottom();
        // }
        const Index = index + 1;
        // if (Index === itemlist.length) {
          history.replace({
            pathname:
              backpageValue === 2
                ? "/tabs/progress/dialoguedone"
                : "/tabs/mainhome/dialoguedone",
            state: {
              themeArray: themeArray,
              index: index2,
              themeId: themeIddb,
              themename: themename,
              levelId: levelId,
              level: level,
              levname: levname,
              dialogueId: dialogueId,
              videoUrl: videoUrl,
              thumb: thumb,
              rolename: rolename,
              role: role,
              allrole: one + "," + two,
              backpage: "dialoguevideo",
              backpageValue: backpageValue,
            },
          });
        // }
      }
    }
  };
  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、——])|(?=[（『「〝“])/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“"];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };
  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };
  const textset_tc = (data_tc, data_py) => {
    const html_tc = data_tc;
    const html_py = data_py;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    if (text_tc && text_py) {
      const words_tc = wordSplitter(text_tc);
      const words_py = pySplitter(text_py);
      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              {cheChar ? (
                <span className="ion-text-center" id="top-center">
                  {data_tc ? word["words_tc"] : ""}
                </span>
              ) : (
                ""
              )}
              {piniyin ? <p className="wordpy">{word["words_py"]}</p> : ""}
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {data_tc ? word["words_tc"] : ""}
              </span>
              <p className="wordpy">{word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {data_tc ? word["words_tc"] : ""}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
      for (let i = 0; i < words_py.length; i++) {
        const word = words_py[i] as never;
        let data = {
          words_tc: "",
          words_py: word,
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <p className="wordpy">{piniyin && word["words_py"]}</p>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    }
    //const htmls = { __html: spans.join('') };
  };
  const back = () => {
    const paths = {
      0: "/tabs/mainhome/themesports",
      1: "/tabs/mainhome/proficient",
      2: "/tabs/progress",
      3: "/tabs/mainhome/initintro",
      4: "/tabs/mainhome/lessoncompleted",
      5: "/tabs/mainhome/practicecompleted",
      6: "/tabs/mainhome/dialoguedone",
      7: "/tabs/progress/lessoncompleted",
      8: "/tabs/progress/practicecompleted",
      9: "/tabs/progress/dialoguedone",
    };
    const pathname = paths.hasOwnProperty(backpageValue)
      ? paths[backpageValue]
      : paths[0];
    history.replace({
      pathname,
      state: {
        themeArray: themeArray,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpageValue:
          originalBackValue === 1
            ? 1
            : originalBackValue === 3
            ? 3
            : backpageValue === 4 || 5 || 6
            ? 0
            : backpageValue === 7 || 8 || 9
            ? 2
            : 0,
      },
    });
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    const fetchData = async () => {
      try {
        const response = await fetch(
          url + "/Book/GetDialogueQueAns?dialogueId=" + dialogueId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            method: "GET",
          }
        );
        const json = await response.json();
        dismiss();
        if (json.StatusCode === 1) {
          setitems(json.Result);
          // setDisplayedItems(json.Result);
          setDisplayedItems([json.Result[0]]);
          // handleAudioEnd(0)
          // handleClickNext();
        } else if (json.StatusCode === 2) {
          // refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };
    fetchData();
  }, []);
  const savebookmark = (val, index) => {
    // Toggle the bookmark status of the current lesson
    themeArray[index].IsFavourite = !themeArray[index].IsFavourite;
    // Create a copy of the themeArray with the updated bookmark status
    const updatedThemeArray = [...themeArray];
    updatedThemeArray[index] = { ...themeArray[index] }; // Create a new object to trigger a state update
    if (themeArray[index].IsFavourite === 0) {
      // If the lesson is no longer a favorite, update the state
      setBookmarkindex(-1);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    } else {
      // If the lesson is now a favorite, update the state
      setBookmarkindex(index);
      saveFav(val, updatedThemeArray[index]?.IsFavourite);
    }
    // Log the updated themeArray
    themeArray = updatedThemeArray;
  };
  const saveFav = async (val, isFavourite: boolean) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const data = {
      referenceId: themeArray[index].BookId,
      isFavourite: isFavourite,
      typeId: 1,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode == 1) {
        getthemes();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  //settings start
  const setIsLangueTrans = (value, type) => {
    if (type == 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 3000,
    // });
    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const SaveUserSettings = async (char: any, piniyin: any, trans: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isPinyin: piniyin,
      isTranslation: trans,
      isChineseCharacter: char,
    };
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
        setIsTranLangSim(
          (json.Result.LessonLanguage === "Chinese, Simplified") === true
        );
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      let newpiniyin: any;
      if (newCheChar === false) {
        setPiniyin(true);
        newpiniyin = true;
      } else {
        newpiniyin = null;
      }
      SaveUserSettings(
        newCheChar,
        newpiniyin !== null ? newpiniyin : piniyin,
        isTransChecked
      ); // Save the new state if needed
      return newCheChar;
    });
  };
  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      let newchechar: any;
      if (newPiniyin === false) {
        setCheChar(true);
        newchechar = true;
      } else {
        newchechar = null;
      }
      SaveUserSettings(
        newchechar !== null ? newchechar : cheChar,
        newPiniyin,
        isTransChecked
      ); // Save the new state if needed
      return newPiniyin;
    });
  };
  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      SaveUserSettings(cheChar, piniyin, newIsTransChecked); // Save the new state if needed
      return newIsTransChecked;
    });
  };
  const decreasePlaybackSpeed = () => {
    const speeds = [0.75, 1.0, 1.25, 1.5];
    const currentIndex = speeds.indexOf(playbackSpeed);
    if (currentIndex > 0) {
      setPlaybackSpeed(speeds[currentIndex - 1]);
      changePlaybackSpeed(
        Object.keys(audioRefs.current),
        speeds[currentIndex - 1]
      );
    }
  };
  const increasePlaybackSpeed = () => {
    const speeds = [0.75, 1.0, 1.25, 1.5];
    const currentIndex = speeds.indexOf(playbackSpeed);
    if (currentIndex < speeds.length - 1) {
      setPlaybackSpeed(speeds[currentIndex + 1]);
      changePlaybackSpeed(
        Object.keys(audioRefs.current),
        speeds[currentIndex + 1]
      );
    }
  };
  const changePlaybackSpeed = (id, speed: number) => {
    const audio = audioRefs.current[id];
    if (audio && audio.playbackRate) {
      
      audio.playbackRate = speed;
    }
  };
  //settings end
  const handleProgress = (id) => {
    return () => {
      const audioElement = audioRefs.current[id];
      const percent = (audioElement.currentTime / audioElement.duration) * 100;
      setProgress((prevProgress) => ({ ...prevProgress, [id]: percent }));
    };
  };
  const togglePlay = (id) => {
    return () => {
      const audio = audioRefs.current[id];
      if (audio) {
        if (isPlaying[id]) {
          audio.pause();
        } else {
          audio.play();
        }
        setIsPlaying((prevIsPlaying) => ({
          ...prevIsPlaying,
          [id]: !prevIsPlaying[id],
        }));
      }
    };
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={back}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("dialoguevideo.dialogue")}
                <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons id="open-modal" slot="end">
              <IonButton
                className="ellipsisVertical"
                onClick={openDrawer}
                color="primary"
              >
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing">
        <div style={{ position: "relative" }}>
          <div className="playPauseicon" onClick={toggleVideoPlayPause}>
            {isVideoPlayShow ? (
              <IonImg
                src={
                  isVideoPlay
                    ? "./assets/images/Pauseicon.png"
                    : "./assets/images/Playicon.svg"
                }
              />
            ) : (
              ""
            )}
          </div>
          <div className="fullscreen" onClick={toggleFullScreen}>
            <img
              style={{ height: "22px", width: "22px" }}
              src="./assets/images/Full-Screen.svg"
              alt=""
            />
          </div>
          <div className="videooverlay" onClick={toggleVideoPlayPause}></div>
          {buffer && (
            <img
              src={"./assets/images/gifmaker_me.gif"}
              alt=""
              style={{
                zIndex: "10",
                position: "absolute",
                margin: "0px auto",
                right: "0",
                left: "0",
              }}
            />
          )}
          {showThumb && (
            <img src={thumb} alt="" style={{ aspectRatio: "16 / 9" }} />
          )}
          <video
            webkit-playsinline
            playsInline
            autoPlay={isVideoAutoplaying}
            onPlay={onPlayVideo}
            onPause={onPauseVideo}
            onWaiting={handleBufferStart}
            onPlaying={handleBufferStop}
            ref={videoRef}
            onEnded={handleVideoEnd}
            className="video-player"
            src={videoUrl}
            controls={false}
            controlsList="nodownload noplaybackrate"
          />
        </div>
        <div className="video-button">
          {!showDialogues && (
            <IonButton className="w-100 skip-button" onClick={handleSkipVideo}>
              {t("dialoguevideo.skip_video")}
            </IonButton>
          )}
        </div>
        {showDialogues && (
          <>
            <IonSlides
              pager={false}
              onIonSlideTransitionEnd={handleClickNext}
              ref={slidesRef}
            >
              {itemlist && itemlist.length > 0 && (
                <IonSlide>
                  <IonList className="leftrightspace ion-padding-horizontal dialogue">
                    <IonRow>
                      {itemlist && itemlist.length > 0 ? (
                        <>
                          {displayedItems.map((items, index) => {
                            return (
                              <IonCol
                                className={items?.Type === two ? "play" : ""}
                                size="12"
                                key={index}
                              >
                                <div className="user">
                                  {items?.Type === two ? (
                                    <>
                                      {items?.TypeImage ? (
                                        <div className="usercircle">
                                          <IonImg
                                            src={items.TypeImage}
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <div className="usercircle">
                                          {items?.Type}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  className="dialoguebox"
                                  ref={(element) => {
                                    if (element && items?.Id === targetId) {
                                      element.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                    }
                                  }}
                                >
                                  <ul
                                    className={
                                      "lession-icon " +
                                      (IsTranLangSim === true
                                        ? "simp-char"
                                        : "trad-char")
                                    }
                                  >
                                    {textset_tc(
                                      IsTranLangSim
                                        ? items?.ContentSc
                                        : items?.ContentTc,
                                      items?.ContentPy
                                    )}
                                  </ul>
                                  <p>{items?.ContentEn}</p>
                                  <div className="test-audio ion-text-left player">
                                    <audio
                                      id={`${items?.Id}`}
                                      ref={
                                        (ref) =>
                                          (audioRefs.current[items?.Id] = ref) // Store the ref in audioRefs array
                                      }
                                      autoPlay
                                      onPlay={audioClick(items?.Id)}
                                      className="String"
                                      controls={false}
                                      controlsList="nodownload noplaybackrate"
                                      onEnded={handleAudioEnd(index, items?.Id)}
                                      src={items?.FileUrl}
                                      onTimeUpdate={handleProgress(items?.Id)}
                                    />
                                    <button onClick={togglePlay(items?.Id)}>
                                      <IonImg
                                        src={
                                          isPlaying[items?.Id]
                                            ? "./assets/images/PlayPauseicon.svg"
                                            : "./assets/images/autoplayicon.svg"
                                        }
                                        placeholder={undefined}
                                      />
                                    </button>
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "3px",
                                        background:
                                          "var(--ion-color-secondary)",
                                        borderRadius: "2px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: `${progress[items?.Id]}%`,
                                          height: "100%",
                                          background:
                                            "var(--ion-color-primary)",
                                          borderRadius: "5px",
                                          transition: "left 0.5s ease-in-out", // Add transition property
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            left: `${progress[items?.Id]}%`,
                                            top: "-3px",
                                            width: "10px",
                                            height: "10px",
                                            background:
                                              "var(--ion-color-primary)",
                                            borderRadius: "50%",
                                            transform: "translateX(-50%)",
                                            transition:
                                              "left 0.01s ease-in-out", // Add transition property
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="user">
                                  {items?.Type === one ? (
                                    <>
                                      {items?.TypeImage ? (
                                        <div className="usercircle">
                                          <IonImg
                                            src={items.TypeImage}
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <div className="usercircle">
                                          {items?.Type}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </IonCol>
                            );
                          })}
                        </>
                      ) : (
                        <p></p>
                      )}
                    </IonRow>
                  </IonList>
                </IonSlide>
              )}
            </IonSlides>
          </>
        )}
        <div ref={ionListRef} />
      </IonContent>
      <IonModal
        trigger="open-modal"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        // handleBehavior="cycle"
      >
        <IonRow>
          <IonList className="list">
            <IonItem class="ion-no-padding">
              <IonLabel>{t("dialoguevideo.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Simplified", 1)}
                  style={{
                    color:
                      IsTranLangSim === true
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Simp.
                </IonText>
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Traditional", 2)}
                  style={{
                    color:
                      IsTranLangSim === false
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Trad.
                </IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>
                {t("dialoguevideo.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end"
              ></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("dialoguevideo.drawer.pinyin")}</IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end"
              ></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("lesson.drawer.playback_speed")}
              </IonLabel>
              <div className="leftright">
                <span
                  onClick={decreasePlaybackSpeed}
                  style={{
                    cursor: playbackSpeed > 0.75 ? "pointer" : "not-allowed",
                  }}
                >
                  &lt;
                </span>
                <span className="speed">
                  {playbackSpeed.toFixed(
                    playbackSpeed === 1.0 ? 1 : playbackSpeed === 1.5 ? 1 : 2
                  )}
                </span>
                <span
                  onClick={increasePlaybackSpeed}
                  style={{
                    cursor: playbackSpeed < 1.5 ? "pointer" : "not-allowed",
                  }}
                >
                  &gt;
                </span>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Dialoguevideo);
