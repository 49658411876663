import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonRow,
  IonCol,
  IonToolbar,
  IonButton,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { setHasSeenTutorial } from "../data/user/user.actions";
import "./Beforewestart.scss";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { RouteComponentProps } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setHasSeenTutorial: typeof setHasSeenTutorial;
}

interface BeforewestartProps extends OwnProps, DispatchProps {}

const Beforewestart: React.FC<BeforewestartProps> = ({
  history,
  setHasSeenTutorial,
}) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();

  const [beforlist, setBeforelist] = useState<any[]>([]);

  const [showSkip, setShowSkip] = useState(true);
  let [swiper, setSwiper] = useState<SwiperCore>();
  const [queslist, setQuestiondata] = React.useState<any[]>([]);
  const [lengthbefque, setLengthBefor] = React.useState(0);
  const [length, setLength] = React.useState(-1);
  const [agetitle, setAgeTitle] = React.useState("");

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    setLength(-1);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getbeforwestart();
  });

  const getbeforwestart = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/account/getbeforestartoption", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        setBeforelist(json.Result);
        setLengthBefor(json.Result.length);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  let ansques: any;
  const selectOption = async (params: any, indexval) => {
    let val = beforlist[indexval - 1];
    if (val.Title == "Age") {
      setAgeTitle(params.Title);
      //Title
      //OptionTitle
    }

    setLength(indexval);

    if (indexval == lengthbefque) {
      ansques = params.OptionId;
      setQuestiondata([...queslist, ansques]);
    } else {
      ansques = params.OptionId;
      setQuestiondata([...queslist, ansques]);

      slidesRef.current?.slideNext();
    }
  };
  useEffect(() => {
    // let val = beforlist[length - 1]
    // if (val.Title == 'Age') {

    // }

    if (length == lengthbefque) {
      SaveOptionAnswer();
      history.replace("/pretestone", { direction: "none" });
    }
  });

  const startApp = async () => {
    await setHasSeenTutorial(true);
    history.replace("/tabs/mainhome", { direction: "none" });
  };
  const gotoPretestone = async () => {
    history.replace("/pretestone", { direction: "none" });
  };

  const handleSlideChangeStart = () => {
    if (!swiper) return;
    setShowSkip(!swiper.isEnd);
  };

  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };

  const slideWillChange = () => {
  };

  const SaveOptionAnswer = async () => {
    //   present({
    //     message: '',
    //  })
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let beforeOptionslist = queslist.join("#");
    let proficiencyOptionslist = "";
    setLength(-1);
    //return


    const data = {
      age: agetitle,
      beforeOptions: beforeOptionslist,
      proficiencyOptions: proficiencyOptionslist,
    };
    // return
    try {
      const response = await fetch(url + "/account/SaveOptionAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        setLength(-1);
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      } else if (json.StatusCode == 3) {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  return (
    <IonPage className="pages-space" id="tutorial-page">
      <IonHeader className="noback">
        <IonToolbar>
          <IonTitle color="primary">
            <span>{t("beforewestart.before_we_start")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonSlides
          pager={true}
          scrollbar={true}
          options={options}
          onIonSlideWillChange={() => slideWillChange()}
          ref={slidesRef}>
          {beforlist.map((items, index) => {
            return (
              <IonSlide>
                <h5>{items.Title}</h5>
                <IonRow className="select-option">
                  {items.Options.map((option) => {
                    return (
                      <IonCol
                        key={option.DisplayPosition}
                        size="12"
                        className="ion-text-center">
                        <IonButton
                          expand="block"
                          onClick={() => {
                            selectOption(option, index + 1);
                          } }
                          fill="outline"
                          className="next"
                          shape="round">
                          {option.Title}
                        </IonButton>
                      </IonCol>
                    );
                  })}
                </IonRow>
              </IonSlide>
            );
          })}
        </IonSlides>

        {/* <Swiper navigation={{
            nextEl: ".next"
          }} onSwiper={setSwiper} slideToClickedSlide={true} loop={true} pagination={true} modules={[Pagination]} onSlideChange={() => onSlideChange()} onSlideChangeTransitionStart={handleSlideChangeStart}>

            {beforlist.map(items => {
              return (
                <SwiperSlide>
                  <h5>{items.Title}</h5>
                  <IonRow className='select-option'>
                    {items.Options.map(option => {
                      return (
                        <IonCol key={option.DisplayPosition} size='12' className='ion-text-center'>
                          <IonButton expand="block" onClick={() => { selectOption(option.OptionId) }} fill="outline" className="next" shape="round">{option.OptionTitle}</IonButton>
                        </IonCol>
                        // onClick={() => { selectOption(option.OptionId) }} 
                      )
                    })}
                  </IonRow>
                </SwiperSlide>
              )
            })} 
             </Swiper> */}
      </IonContent>
    </IonPage>
  );
};

export default Beforewestart;
// export default connect<OwnProps, {}, DispatchProps>({
//   mapDispatchToProps: ({
//     setHasSeenTutorial,
//     setMenuEnabled
//   }),
//   component: Beforewestart
// });
