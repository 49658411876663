import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  IonImg,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonSkeletonText,
} from "@ionic/react";
import "./E2s.scss";
import { CONFIG } from "../App";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface MyObject {
  id: number;
  name: string;
  type: "e2s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E2sProps {
  itemE2s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}
const E2s: React.FC<E2sProps> = (props: E2sProps, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [presenttost] = useIonToast();
  const [length, setLength] = React.useState(-1);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [isCorrectArray, setIsCorrectArray] = useState<boolean[]>([]);
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState<number[]>(
    []
  );
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number[]>([]);
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  const [clickedIndices, setClickedIndices] = useState<any>([]);
  const [clickedSlides, setClickedSlides] = useState<number[]>([]);
  const [answers, setAnswers] = React.useState<any[]>([]);
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;

  // localStorage Variable
  const practiceData: any = localStorage.getItem("practice");
  const practice = JSON.parse(practiceData);
  const JSONdata: any = localStorage.getItem("answers");
  const data = JSON.parse(JSONdata);
  // State variable for itemList
  const [itemList, setItemList] = useState<any[]>(() => {
    return practice.E2s !== null ? practice.E2s : props.itemE2s;
  });

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e2s",
      pagecouter: pagecouter,
      back: back,
      totalque: itemList?.length,
    };
    props.onObjectChange(myObject);
  }
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  useIonViewWillEnter(() => {
    setLength(1);
    // setShowResults(1);
    if (practice.E2s !== null) {
      setShowImage(true);
    } else if (props.itemE2s !== null) {
      setShowImage(true);
    }
    // Extract indices from dataAnswers and set them in clickedIndices
    if (data?.E2s) {
      const indices = data?.E2s?.map((answer, i) => {
        // Add index to data object
        answer.index = i;
        return answer.index;
      });
      setClickedIndices(indices);
      // Call handleAnswerSelection fucntion if we have E1s data in local storage
      data?.E2s?.forEach((item, index) => {
        const { selected, answer, index: itemIndex } = item;
        handleAnswerSelection(selected, answer, itemIndex);
      });
    }
  });
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlidePrevStarted = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {});
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    // setShowImage(false);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex === -1) {
          handleChange(1);
        }
      } else {
        // setSelected(false);
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          SaveExerciseQueAnswer(currentIndex);
          if (index + 1 === length) {
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }
  };

  const handleSlideDidStart = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const currentIndex = swiper.activeIndex;
      // Check if the current slide index is in the list of clicked slides
      if (!clickedSlides.includes(currentIndex)) {
        // Randomize options only if the slide hasn't been clicked
        const randomizedOptions = [...itemList[currentIndex].Options];
        for (let i = randomizedOptions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [randomizedOptions[i], randomizedOptions[j]] = [
            randomizedOptions[j],
            randomizedOptions[i],
          ];
        }
        // Update itemList with randomizedOptions
        const updatedItemList = [...itemList]; // Create a copy of itemList
        updatedItemList[currentIndex] = {
          ...updatedItemList[currentIndex],
          Options: randomizedOptions,
        };
        // Update itemList in state
        setItemList(updatedItemList);
      }
    }
  };

  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    const data = {
      selected: selectedOption,
      answer: correctAnswer,
      index,
    };
    setAnswers((oldData) => [...oldData, data]);
    if (selectedOption === correctAnswer) {
      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setmyanswer("");
      setmyanswer(selectedOption);
    }

    // true & false condition for each
    const isAnswerCorrect = selectedOption === correctAnswer;
    setIsCorrectArray((prevIsCorrectArray) => {
      const newIsCorrectArray = [...prevIsCorrectArray];
      newIsCorrectArray[index] = isAnswerCorrect;
      return newIsCorrectArray;
    });

    // selection option
    const selectedIndex = itemList[index]?.Options?.findIndex(
      (option) =>
        (props.isTranLangSim ? option.WordSc : option.WordTc) === selectedOption
    );
    setSelectedOptionsIndex((prevSelcted) => {
      const newSelectedOptions = [...prevSelcted];
      newSelectedOptions[index] = selectedIndex;
      return newSelectedOptions;
    });

    // Set the correct answer index
    const correctIndex = itemList[index]?.Options?.findIndex(
      (option) =>
        (props.isTranLangSim ? option.WordSc : option.WordTc) === correctAnswer
    );
    setCorrectAnswerIndex((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });

    // Add the index of the clicked slide to clickedSlides
    setClickedSlides((prevClickedSlides) => [...prevClickedSlides, index]);
  };
  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);
  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("answers");
    const data = JSON.parse(JSONdata);
    const formattedData = {
      E1s: data?.E1s,
      E2s: answers,
      E3s: data?.E3s,
      E4s: data?.E4s,
      E5s: data?.E5s,
      E6s: data?.E6s,
    };
    localStorage.setItem("answers", JSON.stringify(formattedData));
    // setDataAnswers(formattedData);
  };
  useEffect(() => {
    // if (length > (itemList).length) {
    //   setLength(-1)
    //   saveContent()
    //   handleChange()
    // }
    const timeoutId = setTimeout(() => {
      // setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, itemList?.length]);
  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const data = {
      eId: itemList[ansIndex].EId,
      eType: itemList[ansIndex].TemplateCode,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0,
    };
    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < itemList?.length; index++) {
      const element = itemList[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;
      anw[propName] = propValue?.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    const myArray = Object.entries(finalanswer);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    const data = {
      contentPageCode: itemList[0].TemplateCode,
      type: "exercise-score",
      bookId: itemList[0].BookId,
      contentPageId: itemList[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  return (
    <IonPage className="intro">
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemList?.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlidePrevStart={handleSlidePrevStarted}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionStart={() =>
            itemList?.length - 1 && props.lastSlide === 1
              ? ""
              : handleSlideDidStart()
          }
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          ref={slidesRef}
        >
          {itemList?.map((items, index) => {
            return (
              <IonSlide key={index}>
                <IonList className="ion-no-padding height100">
                  <IonRow className="Practice leftrightspace ion-padding-horizontal">
                    <IonCol>
                      <IonText>{t("e2s.pick_chinese_match_image")}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="bigbox">
                    <IonCol size="12" className="ion-no-padding lesson-img">
                      {showImage ? (
                        <IonImg
                          src={items?.Question}
                          onLoad={handleImageLoad}
                        />
                      ) : (
                        <IonSkeletonText
                          animated
                          style={{ width: "100%", height: "360px" }}
                        />
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer leftrightspace datakey ion-padding-horizontal">
                    {itemList?.[index].Options?.map((datav, indexnew) => {
                      return (
                        <IonCol key={indexnew} size="6">
                          {showImage ? (
                            <IonCard
                              id={datav.questionno}
                              style={{
                                borderColor:
                                  isCorrectArray[index] !== null &&
                                  isCorrectArray[index] &&
                                  // datav.Word === selectedAnswer
                                  indexnew === selectedOptionsIndex[index]
                                    ? "var(--ion-color-success)" // Green outline for the correct answer
                                    : // correctAnswerIndex[index] !== null &&
                                    indexnew === correctAnswerIndex[index]
                                    ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                    : isCorrectArray[index] !== null &&
                                      !isCorrectArray[index] &&
                                      // datav.Word === selectedAnswer
                                      indexnew === selectedOptionsIndex[index]
                                    ? "var(--ion-color-danger)" // Red outline for the wrong answer
                                    : "",
                              }}
                              onClick={() =>
                                showImage && !clickedIndices?.includes(index)
                                  ? (() => {
                                      setClickedIndices((prevIndices: any) => [
                                        ...prevIndices,
                                        index,
                                      ]);
                                      handleAnswerSelection(
                                        props.isTranLangSim
                                          ? datav.WordSc
                                          : datav.WordTc,
                                        props.isTranLangSim
                                          ? items.answerSc
                                          : items.answerTc,
                                        index
                                      );
                                    })()
                                  : ""
                              }
                            >
                              <IonCardContent class="ion-no-padding">
                                <div className="min184">
                                  <IonText>
                                    {props.cheChar &&
                                      (props.isTranLangSim
                                        ? datav.WordSc
                                        : datav.WordTc)}
                                  </IonText>
                                  <p>{props.piniyin && datav.WordPy}</p>
                                </div>
                              </IonCardContent>
                            </IonCard>
                          ) : (
                            <IonSkeletonText
                              animated
                              style={{
                                width: "100%",
                                height: "138px",
                                margin: "0px",
                                border: "var(--ion-color-secondary) solid 2px",
                                borderRadius: "20px",
                              }}
                            />
                          )}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(E2s);
