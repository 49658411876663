import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonList,
  IonText,
  IonCol,
  useIonViewWillEnter,
  IonIcon,
  IonSlides,
  IonSlide,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Finalstwo.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack } from "ionicons/icons";
import audioJSON from "./json/audio.json";
import dataDescription from "./json/letterDesc.json";

interface OwnProps extends RouteComponentProps {}

interface Finalstwo extends OwnProps {}

const Finalstwo: React.FC<Finalstwo> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [length, setLength] = React.useState(-1);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [myArray, setMyArray] = useState<any>([]);
  const audioRef = useRef<any>();
  const audioSrc: any = audioJSON;
  const audioPath: any = audioSrc?.audioSrc;
  useIonViewWillEnter(() => {
    const getData: any = localStorage.getItem("myArray");
    const storedArray = JSON.parse(getData) || [];
    setMyArray(storedArray);
  });

  useIonViewWillLeave(() => {
    localStorage.removeItem("myArray");
  });

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/finalspracticetwo" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/finalspractice" });
  };

  const handleEnd = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      // setIsPlaying(false);
    }
  };

  const PopUp = (Value: any) => {
    if (Value && !myArray.includes(Value)) {
      // Update state and localStorage
      const updatedArray: any = [...myArray, Value];
      setMyArray(updatedArray);
      localStorage.setItem("myArray", JSON.stringify(updatedArray));
    }

    // Play audio when popup is clicked
    const audio = audioRef.current;
    if (audio) {
      audio.src = audioPath[Value]; // Set audio source
      audio.play(); // Play audio
    }

    // setTimeout(() => {
    //   isElementHidden(Value);
    // }, 3000);
  };

  const isElementHidden = (element) => {
    return myArray.includes(element);
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("finalstwo.finals")}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary"></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing pagespace">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <IonList className="tone tone-main">
              <IonText className="bigtitle">{t("finalstwo.whole")}</IonText>
              <div className="spacing">
                <IonText className="subtitle">{t("finalstwo.slide4")}</IonText>
                <IonText className="subtitle">{t("finalstwo.slide41")}</IonText>
                <div style={{ marginTop: "45px" }}>
                  <span>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/zhi.svg" alt="" />
                    </IonText>
                    <IonText className="subtitle-chinese piniyinfont">
                      <img src="./assets/images/Whole-zhi.svg" alt="" />
                      {/* <div>知</div> */}
                    </IonText>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main initials">
              <IonCol class="recap" size="12">
                <IonText>
                  16 <span>{t("finalstwo.whole")}</span>
                </IonText>
              </IonCol>
              <div className="linetext">
                <span>
                  <ul>
                    <li onClick={() => PopUp("zhi")}>
                      <div className="dott-symbol">
                        <span>zhi</span>
                        <span
                          className="sonar-wrapper"
                          style={{
                            display: isElementHidden("zhi") ? "none" : "inline",
                            right: "-24px",
                            bottom: "5px",
                            zIndex: "-20",
                          }}
                        >
                          <div className="sonar-emitter">
                            <div className="sonar-wave"></div>
                          </div>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                          >
                            <g>
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                                fill-opacity="0.7"
                              />
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                              />
                            </g>
                          </svg> */}
                        </span>
                      </div>
                    </li>
                    <li onClick={() => PopUp("chi")}>chi</li>
                    <li onClick={() => PopUp("shi")}>shi</li>
                    <li onClick={() => PopUp("ri")}>ri</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("zi")}>zi</li>
                    <li onClick={() => PopUp("ci")}>ci</li>
                    <li onClick={() => PopUp("si")}>si</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("yi")}>yi</li>
                    <li onClick={() => PopUp("wu")}>wu</li>
                    <li onClick={() => PopUp("yu")}>yu</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("ye")}>ye</li>
                    <li onClick={() => PopUp("yue")}>yue</li>
                    <li onClick={() => PopUp("yuan")}>yuan</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("yin")}>yin</li>
                    <li onClick={() => PopUp("yun")}>yun</li>
                    <li onClick={() => PopUp("ying")}>ying</li>
                    <li></li>
                  </ul>
                </span>
              </div>
            </IonList>
            <audio
              // src={`./assets/IntroAudio/${value}.mp3`}
              controls={false}
              ref={audioRef}
              onEnded={handleEnd}
            />
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Finalstwo);
