import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonText,
  IonCol,
  useIonViewWillEnter,
  IonIcon,
  IonSlides,
  IonSlide,
  IonModal,
  useIonViewWillLeave,
} from "@ionic/react";
import "./Initials.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chevronBack } from "ionicons/icons";
import audioJSON from "./json/audio.json";
import dataDescription from "./json/letterDesc.json";

interface OwnProps extends RouteComponentProps {}

interface Initilas extends OwnProps {}

const Initilas: React.FC<Initilas> = ({}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<any>(false);
  const [isPlaying, setIsPlaying] = useState<any>(false);
  const [length, setLength] = React.useState(-1);
  const [myArray, setMyArray] = useState<any>([]);
  const [value, setValue] = useState<any>();
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const audioRef = useRef<any>();
  // json imported below
  const audioSrc: any = audioJSON;
  const audio: any = audioSrc?.audioSrc;
  const description: any = dataDescription;
  const data: any = description.description;
  useIonViewWillEnter(() => {
    const getData: any = localStorage.getItem("myArray");
    const storedArray = JSON.parse(getData) || [];
    setMyArray(storedArray);
  });
  // useIonViewWillLeave(() => {
  //   localStorage.setItem("myArray", JSON.stringify(myArray));
  // });

  useIonViewWillLeave(() => {
    localStorage.removeItem("myArray");
  });

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection === "prev") {
      } else {
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index + 1 === length) {
            setLength(-1);
            gonextPage();
          }
        });
      }
    }
  };

  const gonextPage = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/initialspractice" });
  };

  const back = () => {
    history.replace({ pathname: "/tabs/mainhome/initintro/" });
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
        // setIsPlaying(false);
      } else {
        audio.play();
        // setIsPlaying(true);
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleEnd = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const PopUp = (Value: any) => {
    setValue(Value);
    setOpenModal(true);

    if (Value && !myArray.includes(Value)) {
      // Update state and localStorage
      const updatedArray: any = [...myArray, Value];
      setMyArray(updatedArray);
      localStorage.setItem("myArray", JSON.stringify(updatedArray));
    }
    setTimeout(() => {
      isElementHidden(Value);
    }, 3000);
  };

  const isElementHidden = (element) => {
    return myArray.includes(element);
  };

  const ionCloseEvent = () => {
    setOpenModal(false);
    setIsPlaying(false);
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };

  return (
    <IonPage className="intro pages-space">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => back()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("initials.initials")}</span>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color="primary"></IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing pagespace">
        <IonSlides
          pager={true}
          options={slideOpts}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          <IonSlide>
            <IonList className="tone tone-main">
              <div className="spacing">
                <IonText className="subtitle">{t("initials.slide1")}</IonText>
                <div style={{ marginTop: "45px" }}>
                  <span>
                    <IonText className="subtitle-pinyin">
                      <img src="./assets/images/ni1.svg" alt="" />
                    </IonText>
                    <IonText className="subtitle-chinese piniyinfont">
                      <div>你</div>
                    </IonText>
                  </span>
                </div>
              </div>
            </IonList>
          </IonSlide>

          <IonSlide>
            <IonList className="tone tone-main initials">
              <IonCol class="recap" size="12">
                <IonText>21 {t("initials.initials")}</IonText>
              </IonCol>
              <div>
                <span>
                  <ul>
                    <li onClick={() => PopUp("b")}>
                      <div className="dott-symbol">
                        <span>b</span>
                        <span
                          className="sonar-wrapper"
                          style={{
                            display: isElementHidden("b") ? "none" : "inline",
                            zIndex: "-20",
                          }}
                        >
                          <div className="sonar-emitter">
                            <div className="sonar-wave"></div>
                          </div>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                          >
                            <g>
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                                fill-opacity="0.7"
                              />
                              <circle
                                cx="24.9999"
                                cy="25.0004"
                                r="10.1351"
                                fill="#F4DEA5"
                              />
                            </g>
                          </svg> */}
                        </span>
                      </div>
                    </li>
                    <li onClick={() => PopUp("p")}>p</li>
                    <li onClick={() => PopUp("m")}>m</li>
                    <li onClick={() => PopUp("f")}>f</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("d")}>d</li>
                    <li onClick={() => PopUp("t")}>t</li>
                    <li onClick={() => PopUp("n")}>n</li>
                    <li onClick={() => PopUp("l")}>l</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("g")}>g</li>
                    <li onClick={() => PopUp("k")}>k</li>
                    <li onClick={() => PopUp("h")}>h</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("j")}>j</li>
                    <li onClick={() => PopUp("q")}>q</li>
                    <li onClick={() => PopUp("x")}>x</li>
                    <li></li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("zh")}>zh</li>
                    <li onClick={() => PopUp("ch")}>ch</li>
                    <li onClick={() => PopUp("sh")}>sh</li>
                    <li onClick={() => PopUp("r")}>r</li>
                  </ul>
                  <ul>
                    <li onClick={() => PopUp("z")}>z</li>
                    <li onClick={() => PopUp("c")}>c</li>
                    <li onClick={() => PopUp("s")}>s</li>
                    <li></li>
                  </ul>
                </span>
              </div>
            </IonList>
          </IonSlide>
        </IonSlides>
      </IonContent>
      <IonModal
        className="initials-popup"
        isOpen={openModal}
        onDidDismiss={ionCloseEvent}
      >
        <div className="react-confirm-alert-body play-popup">
          <IonText className="bigtitle">{value}</IonText>
          <div onClick={togglePlay}>
            <audio
              src={audio[value]}
              controls={false}
              ref={audioRef}
              onEnded={handleEnd}
            />
            <IonImg
              className="playbig"
              src={
                isPlaying
                  ? "./assets/images/Pausebig.svg"
                  : "./assets/images/playbig.svg"
              }
            />
          </div>
          <p className="subtext popuppadding">{data[value]}</p>
        </div>
      </IonModal>
    </IonPage>
  );
};

export default React.memo(Initilas);
