import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./E3s.scss";
import { close, videocam } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import HanziWriter, { CharacterJson } from "hanzi-writer";
import "./VocabularyE3.scss";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface MyObject {
  id: number;
  name: string;
  type: "e3s";
  pagecouter: number;
  totalque: number;
  back: number;
}

interface VocabularyE3Props {
  itemE3s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}

const VocabularyE3: React.FC<VocabularyE3Props> = (
  props: VocabularyE3Props
) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [presenttost] = useIonToast();
  const [showImage, setShowImage] = useState(false);
  const [showAnimation, setShowAnimation] = useState(true);
  const [index, setIndex] = useState<number>(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const [length, setLength] = React.useState(-1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [ansIndex, setAnsSlideIndex] = useState<number>(0);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [scoreAns, setScoreAns] = useState<any>();
  const [answer, setAnswer] = useState<any>();
  const [JSONAnswer, setAnswerJSON] = useState<any>();
  const [JSONScore, setScoreJSON] = useState<any>();
  const [FinalScore, setFinalScore] = useState<any>(0);
  const [answers, setAnswers] = React.useState<any[]>([]);
  let scoreArray: any[] = [];
  let answerArray: any[] = [];
  let answerArrayJSON: any[] = [];
  let scoreArrayJSON: any[] = [];
  let lastValue: any;
  let pagecouter: any = 0;
  let token = "";
  let resfreshtoken = "";
  const [itemE3slist, setE3stems] = React.useState<any[]>([]);

  useIonViewWillEnter(() => {
    const JSONdata: any = localStorage.getItem("vocabAnswers");
    const data = JSON.parse(JSONdata);
    if (data?.P3s) {
      setE3stems(data.E3s);
    }
  });

  React.useEffect(() => {
    props.itemE3s.forEach(async (item, i) => {
      const containerId = `hanziWriterContainer_${i}`;
      // Remove existing hanziWriter
      const existingContainer = document.getElementById(containerId);
      if (existingContainer) {
        existingContainer.innerHTML = "";
      }
      // Create a new hanziWriter
      const newContainer = document.createElement("div");
      newContainer.id = containerId;
      document
        .getElementById(`hanziWriterContainer_${i}`)
        ?.appendChild(newContainer);
      // Load character data from the HanziWriter library
      const charData = (await HanziWriter.loadCharacterData(
        props.isTranLangSim ? item.WordSc : item.WordCh
      )) as CharacterJson;
      // Access the strokes array in charData
      const strokes = charData.strokes;
      // Log or use the strokes data as needed

      HanziWriter.create(
        containerId,
        props.isTranLangSim ? item.WordSc : item.WordCh,
        {
          width: 235,
          height: 235,
          showCharacter: false,
          showOutline: false,
          showHintAfterMisses: 1,
          highlightOnComplete: true,
          padding: 5,
          drawingWidth: 50,
        }
      ).quiz({
        onCorrectStroke: function (strokeData) {
          const answerArray = {
            index: i,
            strokeNumber: strokeData.strokeNum,
            mistakes: strokeData.mistakesOnStroke,
            totalMistakes: strokeData.totalMistakes,
            remaingStroke: strokeData.strokesRemaining,
            totalStroke: strokes.length,
          };
          const finalAnswer = answerArray;
          const totalStrokes = strokes.length;

          handleScoreAndAnswer(finalAnswer, totalStrokes, i);
          handleSaveContent(finalAnswer, totalStrokes, i);
        },
      });
    });

    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.itemE3s]);

  //TEST

  React.useEffect(() => {
    itemE3slist.forEach(async (item, i) => {
      const containerId = `hanziWriterContainerlocal_${i}`;
      // Remove existing hanziWriter
      const existingContainer = document.getElementById(containerId);
      if (existingContainer) {
        existingContainer.innerHTML = "";
      }
      // Create a new hanziWriter
      const newContainer = document.createElement("div");
      newContainer.id = containerId;
      document
        .getElementById(`hanziWriterContainerlocal_${i}`)
        ?.appendChild(newContainer);
      // Load character data from the HanziWriter library
      const charData = (await HanziWriter.loadCharacterData(
        props.isTranLangSim ? item.WordSc : item.WordCh
      )) as CharacterJson;
      // Access the strokes array in charData
      const strokes = charData.strokes;
      // Log or use the strokes data as needed

      //New code
      function renderFanningStrokes(target, strokes) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.style.width = "235px";
        svg.style.height = "235px";
        // svg.style.border = '1px solid #EEE'
        svg.style.marginRight = "3px";
        target.appendChild(svg);
        var group = document.createElementNS("http://www.w3.org/2000/svg", "g");

        // set the transform property on the g element so the character renders at 75x75
        var transformData = HanziWriter.getScalingTransform(235, 235);
        group.setAttributeNS(null, "transform", transformData.transform);
        svg.appendChild(group);

        strokes.forEach(function (strokePath, index) {
          var path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
          );
          path.setAttributeNS(null, "d", strokePath);

          // style the character paths
          // item.Answer[index].mistakes
          // item.Answer[index].strokeNumber
          const arrayData = JSON.parse(item.Answer);

          // let ans = item.Answer.length;
          // var strokeNum = arrayData[index].strokeNumber;
          // var mistakesOnStroke = arrayData[index].mistakes;
          var strokeNum = arrayData[index].strokeNumber;
          var mistakesOnStroke =
            arrayData[index].mistakes >= 1 ? arrayData[index].strokeNumber : "";
          if (index < strokeNum || index === mistakesOnStroke) {
            path.style.fill = "#ffa5a5";
          } else {
            path.style.fill = "#555";
          }
          group.appendChild(path);
        });
      }
      HanziWriter.loadCharacterData(
        props.isTranLangSim ? item.WordSc : item.WordCh
      ).then(function (charData: any) {
        //var target = document.getElementById('character-target-1');
        var target = document.getElementById(`hanziWriterContainerlocal_${i}`);
        var strokesPortion = charData.strokes.slice(0, charData.strokes.length);
        renderFanningStrokes(target, strokesPortion);
      });
    });

    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [itemE3slist]);

  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);

  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("vocabAnswers");
    const data = JSON.parse(JSONdata);
    let param = [
      {
        EId: 4500,
        EType: "E2EV1",
        Answer: JSON.stringify(answers),
        Score: 29,
        WordCh: "我",
        WordPy: "Wǒ",
      },
    ];
    const formattedData = {
      P1s: data?.P1s,
      P2s: data?.P2s,
      P3s: param,
      P4s: data?.P4s,
      P5s: data?.P5s,
      P6s: data?.P6s,
    };
    //localStorage.setItem("vocabAnswers", JSON.stringify(formattedData));
    // setDataAnswers(formattedData);
  };

  const handleScoreAndAnswer = (
    finalAnswer: any,
    totalStrokes: any,
    index: number
  ) => {
    if (lastValue === index) {
      // If index remains the same, push data into arrays
      answerArray.push(finalAnswer);
      scoreArray.push(finalAnswer);
    } else {
      // If index changes, clear arrays and push new data
      answerArray = [finalAnswer];
      scoreArray = [finalAnswer];
    }

    const answerJSON = JSON.stringify(answerArray);
    setAnswer(answerJSON);

    const zeroMistakesCount = scoreArray.filter(
      (entry) => entry.mistakes === 0
    ).length;
    const eachStrokeValue = 100 / totalStrokes;
    const score = zeroMistakesCount * eachStrokeValue;
    const finalScore = parseFloat(score.toFixed(0));
    setScoreAns(finalScore);

    lastValue = index; // Update lastValue for the next iteration
  };

  const handleSaveContent = (
    finalAnswer: any,
    totalStrokes: any,
    index: number
  ) => {
    answerArrayJSON.push(finalAnswer);
    scoreArrayJSON.push(finalAnswer);

    let formattedAnswer = {};
    let currentIndex = -1;

    // Iterate through the answerArrayJSON
    answerArrayJSON.forEach((entry) => {
      // If index changes, create a new key in formattedAnswer
      if (currentIndex !== entry.index) {
        currentIndex = entry.index;
        formattedAnswer[`q${currentIndex + 1}`] = [];
      }

      // Push the entry into the corresponding array in formattedAnswer
      formattedAnswer[`q${currentIndex + 1}`].push(entry);
    });

    setAnswers([formattedAnswer]);

    // Convert the formattedAnswer to a JSON string
    const answerJSON = JSON.stringify(formattedAnswer);
    setAnswerJSON(answerJSON);

    // Total Score
    const zeroMistakesCount = scoreArrayJSON.filter(
      (entry) => entry.mistakes === 0
    ).length;
    const eachStrokeValue = 100 / totalStrokes;
    const score = zeroMistakesCount * eachStrokeValue;
    const finalScore = parseFloat(score.toFixed(0));
    setScoreJSON(finalScore);
  };

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e3s",
      back: back,
      pagecouter: pagecouter,
      totalque: props.itemE3s.length,
    };
    props.onObjectChange(myObject);
  }
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      setLength(index + 1);
      setActiveSlideIndex(index);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    setLength(length - 1);
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      setLength(index);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();

    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex === -1) {
          handleChange(1);
        }
        // slidesRef.current?.getActiveIndex().then((index) => {
        //   if (index - 1 === -1) {
        //     handleChange(1);
        //   }
        // });
      } else {
        const JSONdata: any = localStorage.getItem("vocabAnswers");
        const data = JSON.parse(JSONdata);
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          setIndex(index);

          if (data?.P3s != undefined ? data?.P3s[currentIndex] : null) {
          } else {
            // SaveExerciseQueAnswer(answer);
          }
          //SaveExerciseQueAnswer(answer);

          if (props.itemE3s.length === 1) {
            setLength(-1);
            // saveContent(JSONScore, JSONAnswer);
            handleChange(0);
          } else {
            if (index + 1 === length) {
              setLength(-1);
              // saveContent(JSONScore, JSONAnswer);
              handleChange(0);
            }
          }
        });
      }
    }
  };
  let sc: any = [];
  const SaveExerciseQueAnswer = async (answer) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const data = {
      eId: props.itemE3s[ansIndex].EId,
      eType: props.itemE3s[ansIndex].TemplateCode,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: answer,
      writingScore: scoreAns,
    };
    let final = FinalScore + scoreAns;

    localStorage.setItem("sc", final);

    setFinalScore(final);
    let tpmarray: any = [];
    const JSONdata: any = localStorage.getItem("vocabAnswers");
    const datals = JSON.parse(JSONdata);

    if (datals?.E3s != undefined) {
      for (let i = 0; i < datals?.E3s.length; i++) {
        tpmarray.push(datals?.E3s[i]);
      }
    }

    let param = {
      EId: props.itemE3s[ansIndex].EId,
      EType: props.itemE3s[ansIndex].TemplateCode,
      Answer: answer,
      Score: scoreAns,
      WordCh: props.itemE3s[ansIndex].WordCh,
      WordPy: props.itemE3s[ansIndex].WordPy,
      ansIndex: ansIndex,
    };

    tpmarray.push(param);
    const formattedData = {
      P1s: datals?.P1s,
      P2s: datals?.P2s,
      P3s: tpmarray,
      P4s: datals?.P4s,
      P5s: datals?.P5s,
      P6s: datals?.P6s,
    };
    localStorage.setItem("vocabAnswers", JSON.stringify(formattedData));

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveContent = async (JSONScore, JSONAnswer) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // present({
    //   message: '',
    // })
    pagecouter = 0;

    for (let index = 0; index < props.itemE3s.length; index++) {
      pagecouter++;
    }
    let finalsc = localStorage.getItem("sc");

    const data = {
      contentPageCode: props.itemE3s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE3s[0].BookId,
      contentPageId: props.itemE3s[0].PageId,
      answer: JSONAnswer,
      score: Number(finalsc),
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      localStorage.setItem("sc", "0");
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  useEffect(() => {
    const data = {
      JSONAnswer,
      JSONScore,
      scoreAns,
      answer,
    };
  }, [JSONAnswer, JSONScore, scoreAns, answer]);

  const hanziWriter = (i) => {
    const data_ch: string = props.itemE3s[i].WordCh;
    const containerId = `hwContainer_${i}`;
    HanziWriter.create(containerId, data_ch, {
      width: 200,
      height: 200,
      padding: 5,
      showOutline: true,
    }).loopCharacterAnimation();
    setShowAnimation(false);
    // .animateCharacter();
  };

  const submit = (i) => {
    const timer = setTimeout(() => {
      hanziWriter(i);
    }, 100); // Adjust the delay as needed
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body popupimg">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="ion-padding-top">
              {/* {showAnimation ? (
                <IonSkeletonText animated style={{ width: "100%", height: "250px", margin: "0px" }} />
              ) : (<></>)} */}

              <div id={`hwContainer_${i}`} />
              {/* <img src={props.itemE3s[index].ImageUrl} alt="No Data Found API Error" /> */}
            </div>
          </div>
        );
      },
    });
    return () => clearTimeout(timer);
  };
  const gotoskip = async (index) => {
    submit(index);
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 3000); // Delay in milliseconds (e.g. 2000ms = 2 seconds)

    return () => clearTimeout(timer);
  }, []);

  return (
    <IonPage className="intro">
      <IonContent className="listing bigbox">
        <IonSlides
          options={{
            initialSlide:
              props.lastSlide === 1
                ? props.itemE3s.length - 1
                : activeSlideIndex,
            // on: {
            //   slideNextTransitionEnd: handleSlideNextEnded,
            //   slidePrevTransitionEnd: handleSlidePrevEnded,
            //   slideChange: handleSlideDidChanged,
            // },
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
        >
          {props.itemE3s.map((item, i) => (
            <IonSlide key={i}>
              <IonList className="ion-no-padding height100">
                <IonRow className="Practice leftrightspace ion-padding-horizontal">
                  <IonCol>
                    <IonText className="ion-text-center">
                      {t("e3s.stroke_order")}
                    </IonText>
                  </IonCol>
                  <IonCol size="12" className="ion-text-center">
                    <ul className="lession-icon">
                      <li>
                        {/* <span>{itemse8.Word}</span>{itemse8.WordPy} */}
                        <span>
                          {props.cheChar &&
                            (props.isTranLangSim ? item.WordSc : item.WordCh)}
                        </span>
                        {props.piniyin && item.WordPy}
                      </li>
                    </ul>
                  </IonCol>
                </IonRow>

                {/* <IonRow className='bigbox'>
                              <IonCol size="12" className="ion-no-padding ion-text-center">
                                <IonImg src="./assets/images/cake.png"/>
                              </IonCol>
                            </IonRow> */}

                <IonRow className="answer gray ion-padding-horizontal practie swiper-no-swiping">
                  <IonCol size="12">
                    <IonButton
                      expand="full"
                      shape="round"
                      onClick={() => gotoskip(i)}
                      className="practie-btn"
                    >
                      <IonIcon icon={videocam} className="ion-padding-end" />{" "}
                      Guide
                    </IonButton>
                  </IonCol>
                  <IonCol size="12">
                    <IonCard class="bg-write">
                      <div className="horizontal-line"></div>
                      <div className="vertical-line"></div>
                      <IonCardContent class="ion-no-padding">
                        {/* <div id="character-target-1"></div> */}
                        {/* <div className="hanzi-writer" id={`hanziWriterContainer_${i}`}></div> */}
                        {itemE3slist[i]?.Answer ? (
                          <div id={`hanziWriterContainerlocal_${i}`} />
                        ) : (
                          <div id={`hanziWriterContainer_${i}`} />
                        )}

                        {/* Use a unique ID for each hanziWriterContainer */}
                        {/* <div>
                                      <p>dàngāo</p>
                                      <IonText>蛋糕</IonText>
                                    </div> */}
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  {/* <IonCol size="12">
                    {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif"
                      />
                    )}
                  </IonCol> */}
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    {/* {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif" />
                    )} */}
                  </IonCol>
                </IonRow>
              </IonList>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(VocabularyE3);
