import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import "./E4s.scss";
import { CONFIG } from "../App";
import { Swiper as SwiperCore, Pagination, Navigation, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import CustomAudioPlayer from "./CustomAudioPlayer";

interface MyObject {
  id: number;
  name: string;
  type: "e4s";
  answ: true;
  pagecouter: 0;
  totalque: number;
  back: number;
}
interface E4sProps {
  itemE4s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}

const E4s: React.FC<E4sProps> = (props: E4sProps, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [presenttost] = useIonToast();
  const [showMessage, setShowMessage] = useState(false);
  const [myObject, setMyObject] = useState<MyObject>({
    id: 0,
    name: "",
    answ: true,
    type: "e4s",
    pagecouter: 0,
    totalque: 0,
    back: 0,
  });
  const [length, setLength] = React.useState(-1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [isCorrectArray, setIsCorrectArray] = useState<boolean[]>([]);
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState<number[]>(
    []
  );
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number[]>([]);
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [clickedIndices, setClickedIndices] = useState<any>([]);
  const [clickedSlides, setClickedSlides] = useState<number[]>([]);
  const [answers, setAnswers] = React.useState<any[]>([]);
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let Ise4ev1: any = true;
  let pagecouter: any = 0;

  // localStorage Variable
  const practiceData: any = localStorage.getItem("practice");
  const practice = JSON.parse(practiceData);
  const JSONdata: any = localStorage.getItem("answers");
  const data = JSON.parse(JSONdata);
  // let itemList: any[] = practice.E4s !== null ? practice.E4s : props.itemE4s;
  const [itemList, setItemList] = useState<any[]>(() => {
    return practice.E4s !== null ? practice.E4s : props.itemE4s;
  });

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      answ: Ise4ev1,
      type: "e4s",
      pagecouter: pagecouter,
      back: back,
      totalque: itemList?.length,
    };
    props.onObjectChange(myObject);
  }
  useIonViewWillEnter(() => {
    // setShowResults(1);
    setLength(1);

    setShowMessage(true);
    if (practice.E4s !== null) {
      setShowImage(true);
    } else {
      if (props.itemE4s !== null) {
        setShowImage(true);
      }
    }
    // Extract indices from dataAnswers and set them in clickedIndices
    if (data?.E4s) {
      const indices = data?.E4s?.map((answer) => answer.index);
      setClickedIndices(indices);

      // Call handleAnswerSelection fucntion if we have E1s data in local storage
      data?.E4s?.forEach((item) => {
        const { selected, answer, index: itemIndex } = item;
        handleAnswerSelection(selected, answer, itemIndex);
      });
    }
  });

  SwiperCore.use([Navigation, Pagination, A11y]);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    // setShowImage(false);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;

      if (swipeDirection === "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex === -1) {
          handleChange(1);
        }
        // slidesRef.current?.getActiveIndex().then((index) => {
        //   if (index - 1 === -1) {
        //     handleChange(1);
        //   }
        // });
      } else {
        setSelected(false);
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then(async (index) => {
          SaveExerciseQueAnswer(currentIndex);

          if (index + 1 === length) {
            setLength(-1);
            saveContent();

            handleChange(0);
          }
        });
      }
    }
  };

  const handleSlideDidStart = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const currentIndex = swiper.activeIndex;
      // Check if the current slide index is in the list of clicked slides
      if (!clickedSlides.includes(currentIndex)) {
        // Randomize options only if the slide hasn't been clicked
        const randomizedOptions = [...itemList[currentIndex].Options];
        for (let i = randomizedOptions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [randomizedOptions[i], randomizedOptions[j]] = [
            randomizedOptions[j],
            randomizedOptions[i],
          ];
        }
        // Update itemList with randomizedOptions
        const updatedItemList = [...itemList]; // Create a copy of itemList
        updatedItemList[currentIndex] = {
          ...updatedItemList[currentIndex],
          Options: randomizedOptions,
        };
        // Update itemList in state
        setItemList(updatedItemList);
      }
    }
  };

  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    const data = {
      selected: selectedOption,
      answer: correctAnswer,
      index,
    };
    setAnswers((oldData) => [...oldData, data]);

    if (selectedOption === correctAnswer) {
      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setmyanswer("");
      setmyanswer(selectedOption);
    }
    // true & false condition for each
    const isAnswerCorrect = selectedOption === correctAnswer;
    setIsCorrectArray((prevIsCorrectArray) => {
      const newIsCorrectArray = [...prevIsCorrectArray];
      newIsCorrectArray[index] = isAnswerCorrect;
      return newIsCorrectArray;
    });

    // selection option
    const selectedIndex = itemList[index]?.Options?.findIndex(
      (option) =>
        (props.isTranLangSim ? option?.WordSc : option?.WordTc) ===
        selectedOption
    );
    setSelectedOptionsIndex((prevSelcted) => {
      const newSelectedOptions = [...prevSelcted];
      newSelectedOptions[index] = selectedIndex;
      return newSelectedOptions;
    });

    // Set the correct answer index
    const correctIndex = itemList[index]?.Options?.findIndex(
      (option) =>
        (props.isTranLangSim ? option?.WordSc : option?.WordTc) ===
        correctAnswer
    );
    setCorrectAnswerIndex((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });

    // Add the index of the clicked slide to clickedSlides
    setClickedSlides((prevClickedSlides) => [...prevClickedSlides, index]);
  };

  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);

  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("answers");
    const data = JSON.parse(JSONdata);
    const formattedData = {
      E1s: data?.E1s,
      E2s: data?.E2s,
      E3s: data?.E3s,
      E4s: answers,
      E5s: data?.E5s,
      E6s: data?.E6s,
    };
    localStorage.setItem("answers", JSON.stringify(formattedData));
    // setDataAnswers(formattedData);
  };
  useEffect(() => {
    if (length > itemList?.length) {
      setLength(-1);
      saveContent();
      // handleChange(0);
    }
    const timeoutId = setTimeout(() => {
      // setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, itemList?.length]);

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })

    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < itemList?.length; index++) {
      const element = itemList[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = props.isTranLangSim
        ? element.answerSc
        : element.answerTc;

      if (propValue) {
        anw[propName] = propValue.toString();
        finalanswer = anw;
      }

      if (element.score) {
        tmscore += element.score;
      }
    }

    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");

    const myArray = Object.entries(finalanswer);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }

    const data = {
      contentPageCode: itemList[0].TemplateCode,
      type: "exercise-score",
      bookId: itemList[0].BookId,
      contentPageId: itemList[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const data = {
      eId: itemList[ansIndex].EId,
      eType: itemList[ansIndex].TemplateCode,
      //question: (itemList)[indexdb - 1]?.Question?.[0].ImageUrl,
      //rightAnswer: foundValue.Choice,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0,
    };

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonPage className="pages-space intro">
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemList?.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          onIonSlideTransitionStart={() =>
            itemList?.length - 1 && props.lastSlide === 1
              ? ""
              : handleSlideDidStart()
          }
          onIonSlideTransitionEnd={handleSlideDidChanged}
          ref={slidesRef} // initialSlide={activeSlideIndex}
        >
          {itemList?.map((items, index) => {
            return (
              <IonSlide key={index}>
                <IonList key={index} className="ion-no-padding height100">
                  <IonRow className="Practice leftrightspace ion-padding-horizontal">
                    <IonCol>
                      <IonText>{t("e4s.choose_correct_answer")}</IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow className="Practice spacing ion-padding-horizontal">
                    <IonCol size="12">
                      {showMessage ? (
                        <>
                          <div className="customaudioplayer">
                            <CustomAudioPlayer src={items?.Question} />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer spacing ion-padding-horizontal">
                    {itemList[index]?.Options?.map((datav, indexnew) => {
                      return (
                        <IonCol size="6" key={indexnew}>
                          <IonCard
                            id={datav.questionno}
                            style={{
                              borderColor:
                                isCorrectArray[index] !== null &&
                                isCorrectArray[index] &&
                                // datav.Word === selectedAnswer
                                indexnew === selectedOptionsIndex[index]
                                  ? "var(--ion-color-success)" // Green outline for the correct answer
                                  : // correctAnswerIndex[index] !== null &&
                                  indexnew === correctAnswerIndex[index]
                                  ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                  : isCorrectArray[index] !== null &&
                                    !isCorrectArray[index] &&
                                    // datav.Word === selectedAnswer
                                    indexnew === selectedOptionsIndex[index]
                                  ? "var(--ion-color-danger)" // Red outline for the wrong answer
                                  : "",
                            }}
                            onClick={() =>
                              !clickedIndices?.includes(index)
                                ? (() => {
                                    setClickedIndices((prevIndices: any) => [
                                      ...prevIndices,
                                      index,
                                    ]);
                                    handleAnswerSelection(
                                      props.isTranLangSim
                                        ? datav.WordSc
                                        : datav.WordTc,
                                      props.isTranLangSim
                                        ? items.answerSc
                                        : items.answerTc,
                                      index
                                    );
                                  })()
                                : ""
                            }
                          >
                            <IonCardContent class="ion-no-padding">
                              <div className="min184">
                                <IonText>
                                  {props.cheChar &&
                                    (props.isTranLangSim
                                      ? datav.WordSc
                                      : datav.WordTc)}
                                </IonText>
                                <p>{props.piniyin && datav.WordPy}</p>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      );
                    })}
                    <IonCol size="12">
                      {/* {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif"/>
                      )} */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(E4s);
