import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonList, IonItem, IonImg, IonButton, IonToggle, IonLabel, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, useIonViewWillEnter } from '@ionic/react';
import './Settings.scss';
import { chevronForward } from 'ionicons/icons';
import { confirmAlert } from "react-confirm-alert";
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps } from 'react-router';

interface OwnProps extends RouteComponentProps {}

interface SettingsProps extends OwnProps { }

const Settings: React.FC<SettingsProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT;//live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([])
  const [isapicall, setApiCall] = useState(false);

  const [cheChar, setCheChar] = useState<any>(true);
  const [piniyin, setPiniyin] = useState<any>(true);
  const [isTransChecked, setIsTransChecked] = useState<any>(true);
  const [isAutoPlay, setIsAutoPlay] = useState<any>(true);

  let token = ''
  let resfreshtoken = ''
  let rap

  // const handleToggleChangePy = async (event: CustomEvent) => {
  //   for (var key in itemlist) {
  //     if (key == 'IsPinyin') {
  //       itemlist['IsPinyin'] = itemlist['IsPinyin'] == false ? true : false
  //     }
  //   }
  //   setApiCall(false)

  //   saveSetting()

  // };
  // const handleToggleChangeTc = async (event: CustomEvent) => {

  //   for (var key in itemlist) {
  //     if (key == 'IsTranslation') {
  //       itemlist['IsTranslation'] = itemlist['IsTranslation'] == false ? true : false
  //     }
  //   }
  //   saveSetting()

  // };
  // const handleToggleChangeAp = async (event: CustomEvent) => {

  //   for (var key in itemlist) {
  //     if (key == 'IsAutoPlay') {
  //       itemlist['IsAutoPlay'] = itemlist['IsAutoPlay'] == false ? true : false
  //     }
  //   }
  //   saveSetting()
  // };


  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    getSettings()
  });


  const getSettings = async () => {
    // present({
    //   message: '',
    //   cssClass: 'custom-spinner',
    //   duration: 1000
    // })

    try {
      const response = await fetch(url + '/User/GetSetting', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        method: "GET",
      })

      const json = await response.json()

      dismiss()
      if (json.StatusCode == 1) {
        // set flag to true after initial settings have been loaded
        setitems(json.Result)

        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTransChecked(json.Result.IsTranslation);
        setIsAutoPlay(json.Result.IsAutoPlay)

      } else if (json.StatusCode == 2) {
        refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }


    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const saveSetting = async (char: any, piniyin: any, trans: any, autoplay:any) => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })


    const data = {
      "isChineseCharacter": char,
      "isPinyin": piniyin,
      "isTranslation": trans,
      "isAutoPlay": autoplay
    }
    // if (!isapicall) {
      try {
        const response = await fetch(url + '/User/SaveSetting', {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "POST",
        })

        const json = await response.json()
        if (json.StatusCode == 1) {
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }

        dismiss()

      } catch (error) {
        console.error(error)
      }
    // }

  }

  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      let newpiniyin: any
      if (newCheChar === false) {
        setPiniyin(true)
        newpiniyin = true
      } else {
        newpiniyin = null
      }
      saveSetting(newCheChar, newpiniyin !== null ? newpiniyin : piniyin, isTransChecked,isAutoPlay); // Save the new state if needed
      return newCheChar;
    });
  };
  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      let newchechar: any
      if (newPiniyin === false) {
        setCheChar(true)
        newchechar = true
      } else {
        newchechar = null
      }
      saveSetting(newchechar !== null ? newchechar : cheChar, newPiniyin, isTransChecked,isAutoPlay); // Save the new state if needed
      return newPiniyin;
    });
  };
  const translationkModeToggle = () => {
    setIsTransChecked((prevIsTransChecked) => {
      const newIsTransChecked = !prevIsTransChecked;
      saveSetting(cheChar, piniyin, newIsTransChecked,isAutoPlay); // Save the new state if needed
      return newIsTransChecked;
    });
  };

  const toggleAutoplay = () => {
    setIsAutoPlay((prevIsAutoplay) => {
      const newIsAutoplay = !prevIsAutoplay;
      saveSetting(cheChar, piniyin, isTransChecked, newIsAutoplay); // Save the new state if needed
      return newIsAutoplay;
    });
  };

  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  useEffect(() => {
    setApiCall(false)

    const getSettings = async () => {
      let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
      if (currentUser) {
        token = currentUser.JwtToken
        resfreshtoken = currentUser.RefreshToken
      }
      // present({
      //   message: '',
      //   cssClass: 'custom-spinner',
      //   duration: 1000
      // })
      setApiCall(true)

      try {
        const response = await fetch(url + '/User/GetSetting', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "GET",
        })

        const json = await response.json()

        dismiss()
        if (json.StatusCode == 1) {
          // set flag to true after initial settings have been loaded

          setitems(json.Result)


          // setitems(json.Result)
        } else if (json.StatusCode == 2) {
          refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }


      } catch (error) {
        console.error(error)
        dismiss()
      }
    }
    getSettings();
  }, []);

  //conformation popup code
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="icon">
              <IonImg src="./assets/images/bigsignout.svg" alt="" />
            </div>
            <p className="popuppadding">{t("profile.submit.msg")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel">
              {t("profile.submit.cancel")}
              </IonButton>
              <IonButton
                onClick={() => {
                  onSignout();
                  onClose();
                } }
                className="alertsubmit">
                {t("profile.submit.sgn_out")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const onSignout = () => {
    localStorage.clear();
    history.replace("/", { direction: "none" });
  };

  const goToSettings = (value) => {
    history.replace({ pathname: "/tabs/settings/"+ value }, { direction: "none" });
  };

  return (
    <IonPage className='intro'>
      <IonHeader className='ion-text-center'>
        <IonToolbar>
          <IonTitle color='primary' className='font20'><span>{t("settings.settings")}</span></IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing spacingset'>
        <IonList className='list'>
          <IonItem class="ion-no-padding" onClick={()=>goToSettings("languages")}>
            <IonLabel>{t("settings.languages")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} />
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("settings.chinese_characters")}</IonLabel>
            <IonToggle checked={cheChar} onClick={toggleChe} slot="end" />
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("settings.pinyin")}</IonLabel>
            <IonToggle checked={piniyin} onClick={togglepiniyin} slot="end" />
            {/* <IonToggle checked slot="end"></IonToggle> */}
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("settings.translation")}</IonLabel>
            {/* <IonToggle slot="end"></IonToggle> */}
            <IonToggle onClick={translationkModeToggle} checked={isTransChecked} slot="end" />
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("settings.autoplay")}</IonLabel>
            {/* <IonToggle slot="end"></IonToggle> */}
            <IonToggle onClick={toggleAutoplay} checked={isAutoPlay} slot="end" />
          </IonItem>
          <IonItem class="ion-no-padding" onClick={()=>goToSettings("notifications")}>
            <IonLabel>{t("settings.noti")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} />
          </IonItem>
          <IonItem class="ion-no-padding" onClick={()=>goToSettings("faqs")}>
            <IonLabel>{t("settings.freq_askd_que")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} />
          </IonItem>
          <IonItem class="ion-no-padding" onClick={()=>goToSettings("privacypolicy")}>
            <IonLabel>{t("settings.privcy_plcy")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} />
          </IonItem>
          <IonItem class="ion-no-padding" onClick={()=>goToSettings("changepassword")}>
            <IonLabel>{t("settings.chng_pass")}</IonLabel>
            <IonIcon color='primary' className="rightpadding" icon={chevronForward} />
          </IonItem>
          <IonItem onClick={submit} class="ion-no-padding pointer">
            <IonLabel>{t("profile.sgn_out")}</IonLabel>
            {/* <IonIcon color='primary' icon={logOutOutline} /> */}
            <IonImg src="./assets/images/Signout.svg" alt="" />
          </IonItem>
        </IonList>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Settings);