import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonImg,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSkeletonText,
} from "@ionic/react";
import "./Detailedreports.scss";
import { chatboxEllipsesOutline, chevronBack, headset } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {}
interface ProgressProps extends OwnProps {}
const Progress: React.FC<ProgressProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [newItemList, setNewItems] = React.useState<any>();
  const [newThemeId, setNewThemeId] = useState<any>(0);
  const [newThemeIndex, setNewThemeIndex] = useState<any>(0);
  let ThemesId: number = 0;
  const [newThemeList, setNewThemeList] = React.useState<any>();
  const [showSkeleton, setShowSkeleton] = useState<any>(true);
  const [isDataAvailable, setDataAvailable] = useState<any>(true);
  const [itemlevellist, setlevelitems] = React.useState<any>({});
  const historyss = useHistory<{
    backpageValue: any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
  }>();
  const index = historyss.location.state ? historyss.location.state.index : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    handleClick(0, "Basics");
    if (newThemeId === 0) {
      GetLevelBooks();
    }
    GetLevels();
  });
  const GetLevelBooks = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 2000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(
        url + "/Book/GetDetailReport?themeId=" + (ThemesId || 0),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      // setActiveLevel(1);
      dismiss();
      if (json.StatusCode == 1) {
        if (json.Result.Themes) {
          setNewThemeList(json.Result.Themes);
        }
        const lessons = json.Result.Lessons || [];
        const dialogues = json.Result.Dialogues || [];

        if (lessons.length === 0 && dialogues.length === 0) {
          setDataAvailable(false);
        } else {
          setDataAvailable(true);

          let test: any = [];
          const array1 = lessons;
          const array2 = dialogues;
          const maxLength = Math.max(array1.length, array2.length);

          for (let i = 0; i < maxLength; i++) {
            // Your logic here
            if (array1[i]) {
              array1[i]["type"] = "lessons";
              array1[i]["number"] = i + 1;
              test.push(array1[i]);
            }
            if (array2[i]) {
              array2[i]["type"] = "dialogues";
              array2[i]["number"] = i + 1;
              test.push(array2[i]);
            }
          }

          setNewItems(test);
          setShowSkeleton(false);
        }
      } else if (json.StatusCode == 2) {
        //   refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [ThemeId, setThemeId] = useState<any>();
  const [ThemeName, setThemeName] = useState<any>();

  const handleClick = async (index, name) => {
    ThemesId = index;
    setNewThemeId(index);
    if (index == 0) {
      setNewThemeIndex(0);
    } else {
      setNewThemeIndex(-1);
    }
    setShowSkeleton(true);
    setDataAvailable(true);
    await GetLevelBooks();

    setThemeId(index);
    setThemeName(name);

    // Scroll to the selected element
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight / 2;
    }
  };
  const GetLevels = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        setlevelitems(json.Result);
      } else if (json.StatusCode == 2) {
        //refreshtoken()
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const goBack = () => {
    history.replace({
      pathname: "/tabs/progress",
    });
  };

  const routeLessonResult = async (itemlist, index) => {
    if (
      itemlist.Listening === 0 &&
      itemlist.Reading === 0 &&
      itemlist.Speaking === 0 &&
      itemlist.Writing === 0
    ) {
    } else {
      history.replace({
        pathname: "/tabs/progress/practiceresult",
        state: {
          themeArray: newItemList,
          themeId: ThemeId,
          lessonname: itemlist.Name,
          index: index,
          lessonsId: itemlist.Id,
          themename: itemlist?.Name,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "Detailedreports",
          backpageValue: 2,
        },
      });
    }
  };

  const routeDialogueresult = (itemlist, index) => {
    if (
      itemlist.Listening === 0 &&
      itemlist.Reading === 0 &&
      itemlist.Speaking === 0 &&
      itemlist.Writing === 0
    ) {
    } else {
      history.replace({
        pathname: "/tabs/progress/dialogueresult",
        state: {
          themeArray: newItemList,
          index: index,
          themeId: ThemeId,
          themename: ThemeName,
          lessonname: itemlist.Name,
          levelId: levelId,
          level: level,
          levname: levname,
          dialogueId: itemlist.Id,
          backpage: "Detailedreports",
          backpageValue: 2,
        },
      });
    }
  };

  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={goBack} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("detailreports.detailed_reports")}</span>
          </IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="detailedreports">
        <IonList className="themes nopadding" style={{ margin: "0 0 8px 0" }}>
          <IonCol className="data-selection Detailed">
            {newThemeList?.map((items, index) => (
              <IonText
                key={index}
                onClick={() => handleClick(items.Id, items.Name)}
                className={
                  items.Id === newThemeId
                    ? "select"
                    : index === newThemeIndex
                    ? "select"
                    : ""
                }
                ref={(element) => {
                  if (element && items.Id === newThemeId) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "center",
                    });
                  }
                }}
              >
                {items.Name}
              </IonText>
            ))}
          </IonCol>
        </IonList>
        <div className="ion-padding-horizontal">
          <IonList className="themes reports">
            {showSkeleton ? (
              <>
                {isDataAvailable ? (
                  <>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonSkeletonText animated placeholder={undefined} />
                          </div>
                          <div className="details">
                            <h4>
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </h4>
                            <span className="gamename">
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonSkeletonText animated placeholder={undefined} />
                          </div>
                          <div className="details">
                            <h4>
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </h4>
                            <span className="gamename">
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonSkeletonText animated placeholder={undefined} />
                          </div>
                          <div className="details">
                            <h4>
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </h4>
                            <span className="gamename">
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonSkeletonText animated placeholder={undefined} />
                          </div>
                          <div className="details">
                            <h4>
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </h4>
                            <span className="gamename">
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                    <IonCard placeholder={undefined}>
                      <IonCardContent
                        class="ion-no-padding"
                        placeholder={undefined}
                      >
                        <div className="lessongbox">
                          <div className="themelesson">
                            <IonSkeletonText animated placeholder={undefined} />
                          </div>
                          <div className="details">
                            <h4>
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </h4>
                            <span className="gamename">
                              {" "}
                              <IonSkeletonText
                                animated
                                placeholder={undefined}
                              />
                            </span>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </>
                ) : (
                  <>
                    <p className="nodata ion-text-center ion-no-margin">
                      No data available
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                {newItemList?.map((items, index) => {
                  return (
                    <div key={index}>
                      {items.type === "lessons" ? (
                        <>
                          <IonCard>
                            <IonCardContent
                              onClick={() => routeLessonResult(items, index)}
                              class="ion-no-padding"
                            >
                              <div className="themelesson">
                                <IonImg src={items.ImageUrl} alt="" />
                              </div>
                              <div className="details">
                                <h4>
                                  {/* {items.Theme}:  */}
                                  {t("detailreports.lesson")} {items.number}
                                </h4>
                                <span>{items.Name}</span>
                                <ul>
                                  <li>
                                    <IonImg
                                      src={
                                        items.Reading === 0
                                          ? "./assets/images/Reader-light.svg"
                                          : "./assets/images/Reader.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Reading === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Reading !== 0
                                        ? items.Reading
                                        : "--"}
                                    </span>
                                  </li>
                                  <li>
                                    <IonImg
                                      src={
                                        items.Writing === 0
                                          ? "./assets/images/Writing-light.svg"
                                          : "./assets/images/Writing-dark.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Writing === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Writing !== 0
                                        ? items.Writing
                                        : "--"}
                                    </span>
                                  </li>
                                  <li>
                                    <IonImg
                                      src={
                                        items.Listening === 0
                                          ? "./assets/images/Listening-light20.svg"
                                          : "./assets/images/ListeningOrg20.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Listening === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Listening !== 0
                                        ? items.Listening
                                        : "--"}
                                    </span>
                                  </li>
                                  <li>
                                    <IonImg
                                      src={
                                        items.Speaking === 0
                                          ? "./assets/images/Speaking-light.svg"
                                          : "./assets/images/SpeakingOrg.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Speaking === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Speaking !== 0
                                        ? items.Speaking
                                        : "--"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </>
                      ) : (
                        <>
                          <IonCard>
                            <IonCardContent
                              onClick={() => routeDialogueresult(items, index)}
                              class="ion-no-padding"
                            >
                              <div className="themelesson">
                                <IonImg src={items.ImageUrl} alt="" />
                              </div>
                              <div className="details">
                                <h4>
                                  {/* {items.Theme}:  */}
                                  {t("detailreports.dialogue")} {items.number}
                                </h4>
                                <span>{items.Name}</span>
                                <ul>
                                  {/* <li>
                                    <IonImg
                                      src={
                                        items.Reading === 0
                                          ? "./assets/images/Reader-light.svg"
                                          : "./assets/images/Reader.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Reading === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Reading !== 0
                                        ? items.Reading
                                        : "--"}
                                    </span>
                                  </li> */}
                                  {/* <li>
                                    <IonImg
                                      src={
                                        items.Writing === 0
                                          ? "./assets/images/Writing-light.svg"
                                          : "./assets/images/Writing-dark.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Writing === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Writing !== 0
                                        ? items.Writing
                                        : "--"}
                                    </span>
                                  </li> */}
                                  {/* <li>
                                    <IonImg
                                      src={
                                        items.Listening === 0
                                          ? "./assets/images/Listening-light20.svg"
                                          : "./assets/images/ListeningOrg20.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Listening === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Listening !== 0
                                        ? items.Listening
                                        : "--"}
                                    </span>
                                  </li> */}
                                  <li>
                                    <IonImg
                                      src={
                                        items.Speaking === 0
                                          ? "./assets/images/Speaking-light.svg"
                                          : "./assets/images/SpeakingOrg.svg"
                                      }
                                      alt=""
                                    />{" "}
                                    <span
                                      style={{
                                        color:
                                          items.Speaking === 0
                                            ? "var(--ion-color-primary-shade)"
                                            : "var(--ion-color-primary)",
                                      }}
                                    >
                                      {items.Speaking !== 0
                                        ? items.Speaking
                                        : "--"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </IonCardContent>
                          </IonCard>
                        </>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Progress);
