import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonList,
  IonText,
  IonItem,
  IonPage,
  IonLabel,
  IonTitle,
  IonCol,
  IonRow,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import "./Forgotpassverify.scss";
import OtpInput from "react-otp-input";
import { RouteComponentProps } from "react-router";
import Countdown from "react-countdown";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}

interface ForgotpassverifyProps extends OwnProps {}

const Forgotpassverify: React.FC<ForgotpassverifyProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const Completionist = () => <span>You are good to go!</span>;
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [cursorPosition, setCursorPosition] = useState();
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => {
    setOtp(value);
    if (value.length == 6) {
      verifyotp(value);
      //history.replace('/forgotpassresetpass', { direction: 'none' });
    }
  };

  const verifyotp = async (item: any) => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    var email = localStorage.getItem("email");
    const data = { email: email, otp: item.toUpperCase() };

    try {
      const response = await fetch(url + "/account/verifyotp", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      dismiss();

      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });

      if (json.StatusCode == 1) {
        history.replace("/forgotpassresetpass", { direction: "none" });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  const inputChangeHandler = async (e: any) => {
    setCursorPosition(e);
    // if(!isNaN(e.target.value)){
    // setOtp(e.target.value);
    // props.otpHandler(otp)
    // }
  };

  return (
    <IonPage className="blue-bg">
      <IonHeader className="bluescreen">
        <IonToolbar color="primary">
          <IonTitle>{t("forgotpassverify.verfi_code")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form>
          <IonGrid className="main-box">
            <IonList className="userform verification">
              <IonItem color="white" className="subtitle">
                <IonLabel>
                  <IonText className="nowrap" no-margin>
                    {t("forgotpassverify.enter_verfi_code")}
                    <br />{" "}
                    <b>
                      (
                      <Countdown
                        autoStart={true}
                        daysInHours={true}
                        zeroPadTime={1}
                        date={Date.now() + 50000}
                      >
                        <Completionist />
                      </Countdown>
                      )
                    </b>
                  </IonText>
                </IonLabel>
              </IonItem>

              <IonItem className="inputwithicon twoicon otp">
                <OtpInput
                  value={otp}
                  onChange={onChange}
                  isInputNum={false}
                  className={"test"}
                  shouldAutoFocus={true}
                  /*focusStyle={{ background: 'white' }}*/ numInputs={6}
                />
              </IonItem>

              {/* <IonItem>
                <div className='inputwithicon twoicon otp'>
                  <div className='field'><IonInput maxlength={1} onIonChange={e => inputChangeHandler(e.detail.value)} name="number" autocapitalize="off" required></IonInput></div>
                  <div className='field'><IonInput maxlength={1} name="number" type="tel" autocapitalize="off" required></IonInput></div>
                  <div className='field'><IonInput maxlength={1} name="number" type="tel" autocapitalize="off" required></IonInput></div>
                  <div className='field'><IonInput maxlength={1} name="number" type="tel" autocapitalize="off" required></IonInput></div>
                  <div className='field'><IonInput maxlength={1} name="number" type="tel" autocapitalize="off" required></IonInput></div>
                  <div className='field'><IonInput maxlength={1} name="number" type="tel" autocapitalize="off" required></IonInput></div>
                </div>
              </IonItem> */}
            </IonList>

            <IonRow>
              <IonCol size="12" className="ion-text-center linktext resend">
                <a href="/forgotpassresetpass" color="light">
                  {t("forgotpassverify.receive_message")}
                  <br />
                  {t("forgotpassverify.resend")}
                </a>
                {/* <IonButton color="white" routerLink="/forgotpassresetpass" className='whitebtn' expand='block'>RESEND</IonButton> */}
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
        <div className="fixpagination">
          <div className="swiper-pagination swiper-pagination-bullets">
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Forgotpassverify);
