import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
  IonItem,
  IonList,
  IonIcon,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  IonAccordionGroup,
  IonAccordion,
  IonRadioGroup,
  IonRadio,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Languages.scss";
import { chevronForward, chevronBack } from "ionicons/icons";
import { CONFIG } from "../App";
import { json } from "stream/consumers";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
// import { setLanguage } from "./i18n/i18n"

interface OwnProps extends RouteComponentProps {}

interface LanguagesProps extends OwnProps {}

const Languages: React.FC<LanguagesProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t, i18n } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [itemlistLang, setitemsLang] = React.useState<any[]>([]);
  const [IsLang, setIsLang] = useState(false);
  const [IsLessonLang, setIsLessonLang] = useState(false);
  const [IsTranLang, setIsTranLang] = useState(false);
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();

  let token = "";
  let resfreshtoken = "";

  useIonViewWillEnter(() => {
    setIsLang(true);
    setIsTranLang(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    getSettings();
    getLanguages();
  });

  const setIsLangue = (value: boolean, type) => {
    // if (type == 1) {
    //   setIsLang(false);
    //   setIsLessonLang(true);
    //   setIsTranLang(false);
    // } else {
    //   setIsLang(false);
    //   setIsLessonLang(false);
    //   setIsTranLang(true);
    // }
    // getSettings();
    // getLanguages();
  };

  useEffect(() => {
    const getSettings = async () => {
      let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
      if (currentUser) {
        token = currentUser.JwtToken;
        resfreshtoken = currentUser.RefreshToken;
      }

      // present({
      //   message: "",
      //   cssClass: 'custom-spinner',
      //   duration: 1000,
      // });

      try {
        const response = await fetch(url + "/User/GetSetting", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        });

        const json = await response.json();

        // dismiss();
        if (json.StatusCode == 1) {
          // set flag to true after initial settings have been loaded
          if (json.Result.LessonLanguage == "Chinese, Traditional") {
            setIsTranLangSim(true);
          } else {
            setIsTranLangSim(false);
          }

          // setToggleValueTc(json.Result.IsTranslation);
          // setToggleValuePy(json.Result.IsPinyin)
          // setToggleValueAp(json.Result.IsAutoPlay)
          // setSettingsLoaded(false);

          setitems(json.Result);
        } else if (json.StatusCode == 2) {
          refreshtoken();
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        // dismiss();
      }
    };
    getSettings();
  }, []);

  const getSettings = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode == 1) {
        // set flag to true after initial settings have been loaded

        if (json.Result.LessonLanguage == "Chinese, Traditional") {
          setIsTranLangSim(false);
        } else {
          setIsTranLangSim(true);
        }

        // setToggleValueTc(json.Result.IsTranslation);
        // setToggleValuePy(json.Result.IsPinyin)
        // setToggleValueAp(json.Result.IsAutoPlay)
        // setSettingsLoaded(false);

        setitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const getLanguages = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    // present({
    //   message: '',
    //   duration: 1000
    // })

    try {
      const response = await fetch(url + "/User/GetLanguages", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode == 1) {
        setitemsLang(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  const backspace = () => {
    getSettings();
    if (IsLang) {
      history.replace("/tabs/settings", { direction: "none" });

      //setIsLangue(false,1)
    } else if (IsLessonLang) {
      setIsLang(true);
      setIsLessonLang(false);
      setIsTranLang(false);
    } else if (IsTranLang) {
      setIsLang(true);
      setIsLessonLang(false);
      setIsTranLang(false);
    }
  };
  const setIsLangueTrans = (type) => {
    if (type === 1) {
      setIsTranLangSim(true);
      saveLangue("Chinese, Simplified", true, -1);
    } else if (type === 2) {
      setIsTranLangSim(false);
      saveLangue("Chinese, Traditional", true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    // if (isLesson == false) {
    //   let tmp: any = [];
    //   for (let i = 0; i < itemlistLang.length; i++) {
    //     const element = itemlistLang[i];
    //     let data = {};
    //     if (index == i) {
    //       data = {
    //         Code: element.Code,
    //         IsSelected: true,
    //         Name: element.Name,
    //       };
    //       const languageCode = element.Code; // replace with the actual language code you want to set
    //       localStorage.setItem("language" , languageCode)
    //       i18n.changeLanguage(languageCode)
    //       // setLanguage(languageCode);
    //     } else {
    //       data = {
    //         Code: element.Code,
    //         IsSelected: false,
    //         Name: element.Name,
    //       };
    //     }

    //     tmp.push(data);
    //   }
    //   setitemsLang(tmp);
    // }

    if (isLesson === false) {
      let tmp = itemlistLang.map((element, i) => {
        const data = {
          Code: element.Code,
          IsSelected: i === index,
          Name: element.Name,
        };

        if (i === index) {
          const languageCode = element.Code;
          localStorage.setItem("language", languageCode);
          i18n.changeLanguage(languageCode);
        }

        return data;
      });

      setitemsLang(tmp);
    }

    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 3000,
    // });

    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();

      // dismiss();

      if (json.StatusCode == 1) {
        // history.replace('/login');
        getSettings();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const handleRadioChange = (value, index) => {
    // Update items.IsSelected based on the selected value
    const updatedItems = itemlistLang.map((item, i) => ({
      ...item,
      IsSelected: i === index && value,
    }));

    setitemsLang(updatedItems);

    // Call saveLangue function with the updated value
    if (value) {
      saveLangue(itemlistLang[index].Code, false, index);
    }
  };

  const handleLabelClick = (code, index) => {
    // Update items.IsSelected to true for the clicked item
    const updatedItems = itemlistLang.map((item, i) => ({
      ...item,
      IsSelected: i === index && code,
    }));

    setitemsLang(updatedItems);
    saveLangue(code, false, index);
  };

  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => backspace()} color="primary">
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("languages.languages")}</span>
          </IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color='success'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing">
        <IonAccordionGroup className="accordiongroup languages">
          <IonAccordion onClick={() => setIsLangue(IsLessonLang, 1)}>
            <IonItem slot="header" color="white">
              <IonLabel>
                <h2>
                  {t("languages.lesson_language")}{" "}
                  <span>{itemlist["LessonLanguage"]} </span>
                </h2>
              </IonLabel>
            </IonItem>
            <div className="subaccordion" slot="content">
              <IonList className="list engvschinese">
                <IonItem>
                  <IonRadioGroup
                    value={IsTranLangSim ? "1" : "2"}
                    onIonChange={(e) =>
                      setIsTranLangSim(e.detail.value === "1")
                    }
                  >
                    <IonLabel onClick={() => setIsLangueTrans(1)}>
                      <h2>
                        {t("languages.chinese_simplified")}{" "}
                        <span className="chinese">简体中文</span>
                      </h2>
                      <IonRadio value="1" />
                    </IonLabel>
                    <IonLabel onClick={() => setIsLangueTrans(2)}>
                      <h2>
                        {t("languages.chinese_traditional")}{" "}
                        <span className="traditional">繁體中文</span>
                      </h2>
                      <IonRadio value="2" />
                    </IonLabel>
                  </IonRadioGroup>
                </IonItem>
              </IonList>
            </div>
          </IonAccordion>
          <IonAccordion onClick={() => setIsLangue(IsTranLang, 2)}>
            <IonItem slot="header" color="white">
              <IonLabel>
                <h2>
                  {t("languages.translation_language")}{" "}
                  <span>{itemlist["TranslateLanguage"]}</span>
                </h2>
              </IonLabel>
            </IonItem>
            <div className="subaccordion" slot="content">
              <IonList className="list engvschinese">
                {itemlistLang.map((items, index) => (
                  <IonItem key={index}>
                    <IonRadioGroup
                      value={items.IsSelected}
                      onIonChange={(e) =>
                        handleRadioChange(e.detail.value, index)
                      }
                    >
                      <IonLabel
                        onClick={() => handleLabelClick(items.Code, index)}
                      >
                        <h2>{items.Name}</h2>
                        <IonRadio value={true} />
                      </IonLabel>
                    </IonRadioGroup>
                  </IonItem>
                ))}
              </IonList>
            </div>
          </IonAccordion>
        </IonAccordionGroup>

        {/* {IsLang ? (
          <IonList className="list">
            <IonItem class="ion-no-padding">
              <IonLabel onClick={() => setIsLangue(IsLessonLang, 1)}>
                <h2>{t("languages.lesson_language")}</h2>
                <div>
                  <span>{itemlist["LessonLanguage"]} </span>
                  <IonIcon icon={chevronForward} />
                </div>
              </IonLabel>
            </IonItem>

            <IonItem class="ion-no-padding">
              <IonLabel onClick={() => setIsLangue(IsTranLang, 2)}>
                <h2>{t("languages.translation_language")}</h2>
                <div>
                  <span>{itemlist["TranslateLanguage"]}</span>
                  <IonIcon icon={chevronForward} />
                </div>
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )} */}

        {/* {IsLessonLang ? (
          <IonList className="list engvschinese">
            <IonItem class="ion-no-padding">
              <IonLabel>
                <h2>{t("languages.lesson_language")}</h2>
              </IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel
                onClick={() => setIsLangueTrans("Chinese, Simplified", 1)}>
                <h2>
                {t("languages.chinese_simplified")} <span className="chinese">简体中文</span>
                </h2>
                {IsTranLangSim ? (
                  <div>
                    <IonImg src="./assets/images/Icon.png" alt="" />
                  </div>
                ) : (
                  ""
                )}
              </IonLabel>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel
                onClick={() => setIsLangueTrans("Chinese, Traditional", 2)}>
                <h2>
                {t("languages.chinese_traditional")} <span className="chinese">繁體中文</span>
                </h2>
                {!IsTranLangSim ? (
                  <div>
                    <IonImg src="./assets/images/Icon.png" alt="" />
                  </div>
                ) : (
                  ""
                )}
              </IonLabel>
            </IonItem>
          </IonList>
        ) : (
          ""
        )} */}

        {/* {IsTranLang ? (
          <IonList className="list engvschinese">
            <IonItem class="ion-no-padding">
              <IonLabel>
                <h2>{t("languages.translation_language")}</h2>
              </IonLabel>
            </IonItem>

            {itemlistLang.map((items, index) => {
              return (
                <IonItem key={index} class="ion-no-padding">
                  <IonLabel
                    onClick={() => saveLangue(items.Code, false, index)}>
                    <h2>{items.Name}</h2>
                    {items.IsSelected ? (
                      <div>
                        <IonImg src="./assets/images/Icon.png" alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
        ) : (
          ""
        )} */}
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Languages);
