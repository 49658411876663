import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonButton,
  IonPage,
  IonTitle,
  IonImg,
  IonList,
  IonCard,
  IonCardContent,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  getPlatforms,
  IonIcon,
} from "@ionic/react";
import "./Numberspractice.scss";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { chatboxOutline, chevronBack, close, notificationsOutline } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";

interface OwnProps extends RouteComponentProps {}

interface Numberspractice extends OwnProps {}

const Numberspractice: React.FC<Numberspractice> = ({  }) => {
  const history = useHistory();

  const { t } = useTranslation();

  let url: string = CONFIG.API_ENDPOINT; //live

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [logininfo, setLoginitems] = React.useState<any>({});

  const [itemlist, setitems] = React.useState<any>({});
  const [itemprofilelist, setprofileitems] = React.useState<any>({});

  const historyss = useHistory<{
    themeArray: string;
    levelId: string;
    levname: string;
    level: string;
  }>();

  const levname = historyss.location.state
  ? historyss.location.state.levname
  : "";

  const level = historyss.location.state
    ? Number(historyss.location.state.level)
    : "";


  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const userData : any = localStorage.getItem("userlevel")
    const data = JSON.parse(userData)
    if( data === null){
      getUserLevel();
    }else{
      setitems(data);
    }
    // GetProfie();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
  let userdata = logindata.Result;

  const getUserLevel = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      if (json.StatusCode === 1) {
        setitems(json.Result);
        const userLevel = json.Result
        localStorage.setItem("userlevel",JSON.stringify(userLevel))
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // setLoginitems(data)

  const GetProfie = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();

      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const gonextPage = () => {
        history.replace({ pathname: "/tabs/mainhome/initintro/numberslast", });
  };

  const back = () =>{
    history.replace({ pathname: "/tabs/mainhome/initintro/numbers", } );
  }

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} placeholder={undefined} />
            </div>
            <div className="imgview">
              {itemlist.Level === 1 && <IonImg src="./assets/images/shoeprint.svg" placeholder={undefined} />}
              {itemlist.Level === 2 && <IonImg src="./assets/images/bicycle.svg" placeholder={undefined} />}
              {itemlist.Level === 3 && <IonImg src="./assets/images/car.svg" placeholder={undefined} />}
              {itemlist.Level === 4 && <IonImg src="./assets/images/plane.svg" placeholder={undefined} />}
              {itemlist.Level === 5 && <IonImg src="./assets/images/rocet.svg" placeholder={undefined} />}
            </div>
            <p>
              {t("home.submit.level")} {itemlist.Level}: {itemlist.Name}
            </p>
            <p className="subtext">
              {t("home.submit.subtext")}
            </p>
          </div>
        );
      },
    });
  };

  return (
    <IonPage className="intro pages-space" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton onClick={() => back()} color="primary"  placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle color="primary" className="font20 ion-no-padding" placeholder={undefined}>
            <span>
              {/* {t("home.level")} */}
              Numbers Practice
            </span>
          </IonTitle>
          {/*  onClick={submit}  */}
          <IonButtons slot="end" placeholder={undefined}>
            <IonButton color="primary" placeholder={undefined}>
              {/* <IonIcon icon={informationCircleOutline} /> */}
              {/* <IonImg
                className="info"
                src="./assets/images/bang-circle-blue.svg"
                alt="" placeholder={undefined} /> */}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="listing levelstart" placeholder={undefined}>
        <IonList className="ion-padding-horizontal" placeholder={undefined}>
           <IonCard
            className={itemlist.Level >= 1 ? "leveldone" : "levelselect opacity"}
            onClick={() => gonextPage()} placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className="numberview">
                <span className="circle ion-float-left">1</span>
                <h4 className="ion-float-left">{t('home.beginner')}</h4>
              </div>
              <IonImg
                className="ion-float-right"
                src="./assets/images/shoeprint.svg" placeholder={undefined}/>
            </IonCardContent>
          </IonCard>
          {/* <IonCard
            className={itemlist.Level >= 2 ? "leveldone" : "levelselect opacity"}
            onClick={() => gonextPage(itemlist, 2, "Advanced Beginner")} placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className="numberview">
                <span className="circle">2</span>
                <h4>{t('home.advanced_beginner')}</h4>
              </div>
              <IonImg src="./assets/images/bicycle.svg" placeholder={undefined} />
            </IonCardContent>
          </IonCard>
          <IonCard
            className={itemlist.Level >= 3 ? "leveldone" : "levelselect opacity"}
            onClick={() => gonextPage(itemlist, 3, "Novice")} placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className="numberview">
                <span className="circle">3</span>
                <h4>{t('home.novice')}</h4>
              </div>
              <IonImg src="./assets/images/car.svg" placeholder={undefined} />
            </IonCardContent>
          </IonCard>
          <IonCard
            className={itemlist.Level >= 4 ? "leveldone" : "levelselect opacity"}
            onClick={() => gonextPage(itemlist, 4, "Intermediate")} placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className="numberview">
                <span className="circle">4</span>
                <h4>{t('home.intermediate')}</h4>
              </div>
              <IonImg src="./assets/images/plane.svg" placeholder={undefined} />
            </IonCardContent>
          </IonCard>
          <IonCard
            className={itemlist.Level >= 5 ? "leveldone" : "levelselect opacity"}
            onClick={() => gonextPage(itemlist, 5, "Proficient")} placeholder={undefined}>
            <IonCardContent class="ion-no-padding" placeholder={undefined}>
              <div className="numberview">
                <span className="circle">5</span>
                <h4>{t('home.proficient')}</h4>
              </div>
              <IonImg src="./assets/images/rocet.svg" placeholder={undefined} />
            </IonCardContent>
          </IonCard> */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Numberspractice);
