import React, { useEffect, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  IonSlide,
  IonSlides,
  useIonToast,
  useIonViewWillEnter,
  useIonLoading,
} from "@ionic/react";
import "./E3s.scss";
import { chevronBack, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import HanziWriter, { CharacterJson } from "hanzi-writer";
import "./E3s.scss";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
interface OwnProps extends RouteComponentProps {}
interface MyObject {
  id: number;
  name: string;
  type: "e3s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E3s1Props {
  itemE3s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E3sResultDetails: React.FC<E3s1Props> = (
  props: E3s1Props,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [itemE3slist, setE3stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showImage, setShowImage] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [length, setLength] = React.useState(-1);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const [ansIndex, setAnsSlideIndex] = useState<number>(0);
  let token = "";
  let resfreshtoken = "";
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useIonViewWillEnter(() => {
    getExercise();
  });
  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();

      dismiss();
      if (json.StatusCode === 1) {
        setAllExcies(json.Result);
        setitems(json.Result);
        setE3stems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  React.useEffect(() => {
    itemE3slist.forEach(async (item, i) => {
      const containerId = `hanziWriterContainer_${i}`;
      // Remove existing hanziWriter
      const existingContainer = document.getElementById(containerId);
      if (existingContainer) {
        existingContainer.innerHTML = "";
      }
      // Create a new hanziWriter
      const newContainer = document.createElement("div");
      newContainer.id = containerId;
      document
        .getElementById(`hanziWriterContainer_${i}`)
        ?.appendChild(newContainer);
      // Load character data from the HanziWriter library
      const charData = (await HanziWriter.loadCharacterData(
        item.WordCh
      )) as CharacterJson;
      // Access the strokes array in charData
      const strokes = charData.strokes;
      // Log or use the strokes data as needed

      // HanziWriter.create(containerId, item.WordCh, {
      //   width: 235,
      //   height: 235,
      //   showCharacter: false,
      //   showOutline: false,
      //   showHintAfterMisses: 1,
      //   highlightOnComplete: true,
      //   padding: 5,
      //   drawingWidth: 50,
      // }).quiz({
      //   onMistake: function (strokeData) {
      //     const answerArray = {
      //       index: i,
      //       strokeNumber: strokeData.strokeNum,
      //       mistakes: strokeData.mistakesOnStroke,
      //       totalMistakes: strokeData.totalMistakes,
      //       remaingStroke: strokeData.strokesRemaining,
      //     };
      //   },
      //   onCorrectStroke: function (strokeData) {
      //     const answerArray = {
      //       index: i,
      //       strokeNumber: strokeData.strokeNum,
      //       mistakes: strokeData.mistakesOnStroke,
      //       totalMistakes: strokeData.totalMistakes,
      //       remaingStroke: strokeData.strokesRemaining,
      //       totalStroke: strokes.length,
      //     };
      //     const finalAnswer = answerArray;
      //     const totalStrokes = strokes.length;
      //     handleScoreAndAnswer(finalAnswer, totalStrokes, i);
      //     handleSaveContent(finalAnswer, totalStrokes, i);
      //   },
      //   onComplete: function (summaryData) {
      //     const finalAnswerArray = {
      //       index: i,
      //       character: summaryData.character,
      //       totalMistakes: summaryData.totalMistakes,
      //     };
      //   },
      // });

      //New code
      function renderFanningStrokes(target, strokes) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.style.width = "235px";
        svg.style.height = "235px";
        // svg.style.border = '1px solid #EEE'
        svg.style.marginRight = "3px";
        target.appendChild(svg);
        var group = document.createElementNS("http://www.w3.org/2000/svg", "g");

        // set the transform property on the g element so the character renders at 75x75
        var transformData = HanziWriter.getScalingTransform(235, 235);
        group.setAttributeNS(null, "transform", transformData.transform);
        svg.appendChild(group);

        strokes.forEach(function (strokePath, index) {
          var path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
          );
          path.setAttributeNS(null, "d", strokePath);

          // style the character paths
          // item.Answer[index].mistakes
          // item.Answer[index].strokeNumber
          const arrayData = JSON.parse(item?.Answer);

          // let ans = item.Answer.length;
          // var strokeNum = arrayData[index].strokeNumber;
          // var mistakesOnStroke = arrayData[index].mistakes;
          var strokeNum = arrayData[index]?.strokeNumber;
          var mistakesOnStroke =
            arrayData[index]?.mistakes >= 1
              ? arrayData[index]?.strokeNumber
              : "";
          if (index < strokeNum || index === mistakesOnStroke) {
            path.style.fill = "#ffa5a5";
          } else {
            path.style.fill = "#555";
          }
          group.appendChild(path);
        });
      }
      HanziWriter.loadCharacterData(item.WordCh).then(function (charData: any) {
        //var target = document.getElementById('character-target-1');
        var target = document.getElementById(`hanziWriterContainer_${i}`);
        var strokesPortion = charData.strokes.slice(0, charData.strokes.length);
        renderFanningStrokes(target, strokesPortion);
      });
    });

    // function renderFanningStrokes(target, strokes) {
    //   var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    //   svg.style.width = '75px';
    //   svg.style.height = '75px';
    //   svg.style.border = '1px solid #EEE'
    //   svg.style.marginRight = '3px'
    //   target.appendChild(svg);
    //   var group = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    //   // set the transform property on the g element so the character renders at 75x75
    //   var transformData = HanziWriter.getScalingTransform(75, 75);
    //   group.setAttributeNS(null, 'transform', transformData.transform);
    //   svg.appendChild(group);

    //   strokes.forEach(function(strokePath) {
    //     var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    //     path.setAttributeNS(null, 'd', strokePath);
    //     // style the character paths
    //     path.style.fill = '#555';
    //     group.appendChild(path);
    //   });
    // }

    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [itemE3slist]);

  function handleChange(back) {
    // const myObject: MyObject = {
    //   id: 1,
    //   name: "My Object",
    //   type: "e3s",
    //   back: back,
    //   pagecouter: pagecouter,
    //   totalque: itemE3slist.length,
    // };
    // props.onObjectChange(myObject);
  }
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      setLength(index + 1);
      setActiveSlideIndex(index);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    setLength(length - 1);
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      setLength(index);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          setIndex(index);
          //

          if (itemE3slist.length === 1) {
            setLength(-1);

            handleChange(0);
          } else {
            if (index + 1 == length) {
              setLength(-1);

              handleChange(0);
            }
          }
        });
      }
    }
  };
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body popupimg">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="ion-padding-top">
              <img src={itemE3slist[index].ImageUrl} />
            </div>
          </div>
        );
      },
    });
  };
  const gotoskip = async () => {
    submit();
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 3000); // Delay in milliseconds (e.g. 2000ms = 2 seconds)
    return () => clearTimeout(timer);
  }, []);
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname:
          backpageValue === 2
            ? "/tabs/progress/practiceresult"
            : "/tabs/mainhome/practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E3s",
          backpageValue: backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={gobackpage}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              {/* <IonButton onClick={openDrawer} color="primary">
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing bigbox">
        <IonSlides
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemE3slist.length - 1 : activeSlideIndex,
            // on: {
            //   slideNextTransitionEnd: handleSlideNextEnded,
            //   slidePrevTransitionEnd: handleSlidePrevEnded,
            //   slideChange: handleSlideDidChanged,
            // },
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
        >
          {itemE3slist.map((item, i) => (
            <IonSlide key={i}>
              <IonList className="ion-no-padding height100">
                <IonRow className="Practice leftrightspace ion-padding-horizontal">
                  <IonCol>
                    <IonText className="ion-text-center">
                      {t("e3s.stroke_order")}
                    </IonText>
                  </IonCol>
                  <IonCol size="12" className="ion-text-center">
                    <ul className="lession-icon">
                      <li>
                        {/* <span>{itemse8.Word}</span>{itemse8.WordPy} */}
                        <span>{item.WordCh}</span>
                        {item.WordPy}
                      </li>
                    </ul>
                  </IonCol>
                </IonRow>
                {/* <IonRow className='bigbox'>
                              <IonCol size="12" className="ion-no-padding ion-text-center">
                                <IonImg src="./assets/images/cake.png"/>
                              </IonCol>
                            </IonRow> */}
                <IonRow className="answer gray ion-padding-horizontal practie swiper-no-swiping">
                  <IonCol size="12">
                    {/* <IonButton
                      expand="full"
                      shape="round"
                      onClick={gotoskip}
                      className="practie-btn"
                    >
                      <IonIcon icon={videocam} className="ion-padding-end" />{" "}
                      Guide
                    </IonButton> */}
                  </IonCol>
                  <IonCol size="12">
                    <IonCard class="bg-write">
                      <div className="horizontal-line"></div>
                      <div className="vertical-line"></div>
                      <IonCardContent class="ion-no-padding">
                        {/* <div id="character-target-1"></div> */}
                        {/* <div className="hanzi-writer" id={`hanziWriterContainer_${i}`}></div> */}
                        <div id={`hanziWriterContainer_${i}`}></div>
                        {/* Use a unique ID for each hanziWriterContainer */}
                        {/* <div>
                                      <p>dàngāo</p>
                                      <IonText>蛋糕</IonText>
                                    </div> */}
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  {/* <IonCol size="12">
                    {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif"
                      />
                    )}
                  </IonCol> */}
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    {/* {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif"/>
                    )} */}
                  </IonCol>
                </IonRow>
              </IonList>
            </IonSlide>
          ))}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(E3sResultDetails);
function dismiss() {
  throw new Error("Function not implemented.");
}
function present(arg0: { message: string }) {
  throw new Error("Function not implemented.");
}
