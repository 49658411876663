import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton, IonContent, IonItem, IonLabel, IonToggle, IonList, IonCol, IonText, IonRow, IonCard, IonCardContent, IonIcon, IonPage, IonTitle, IonModal } from '@ionic/react';
import './Dragdroplesson.scss';
import { chevronBack, ellipsisVertical } from 'ionicons/icons';
import Drawer from "react-bottom-drawer";
import ReactAudioPlayer from 'react-audio-player';

interface LessonpracticeProps { }

const Lessonpractice: React.FC<LessonpracticeProps> = () => {
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  return (
    <IonPage className='intro'>
      <IonHeader className='ion-text-center dubbletitle'>
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color='primary'>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color='primary' className='font20 ion-no-padding'><span>Lesson 23: Practice <p className='ion-no-margin'>Making a Cake</p></span></IonTitle>
            <IonButtons slot="end">
              <IonButton className="ellipsisVertical" onClick={openDrawer} id="open-model" color='primary'>
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing'>
        <IonList className="ion-no-padding bottomfix">

          <IonRow className='Practice leftrightspace ion-padding-horizontal'>
            <IonCol>
              <IonText>Choose the correct answer</IonText>
            </IonCol>
            <IonCol size="12" className='test-audio ion-text-left whitebg'>
              <ReactAudioPlayer className='String' controls controlsList="nodownload noplaybackrate">
                <source src="/assets/audio.mp3" type="audio/mpeg"></source>
              </ReactAudioPlayer>
              {/* <audio controls src="/media/cc0-audio/t-rex-roar.mp3"></audio> */}
              {/* <div className='audio'></div> */}
            </IonCol>
          </IonRow>
          
          <IonRow className='answer single Practiceresultanswer leftrightspace ion-padding-horizontal'>
            <IonCol size='12' className='borderbottom'>
              <IonCard className='answer-right'>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>小明</span>dàngāo</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard className='answer-wrong'>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>是</span>shì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>生日</span>shēngrì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>的</span>de</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>今天</span>jīntiān</li>
                  </ul>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonList>

        <IonList className='ion-text-center'>
          <IonRow className='answer single Practiceresultanswer mt0 leftrightspace ion-padding-horizontal'>
            <IonCol size='12'>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>小明</span>dàngāo</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>是</span>shì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>生日</span>shēngrì</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>的</span>de</li>
                  </ul>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <ul className='lession-icon'>
                    <li><span>今天</span>jīntiān</li>
                  </ul>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonList>
    </IonContent>
    <IonModal
          trigger="open-modal"
          initialBreakpoint= {1}
          breakpoints={[0, 1]}
          // handleBehavior="cycle"
        >
    {/* <Drawer className='bottompopup skateInBottom' isVisible={isVisible} onClose={onClose}> */}
      <IonRow>
        <IonList className='list'>
          {/* <IonItem class="ion-no-padding playbook">
              <div>
                <IonImg className="playlesson"
                  src="./assets/images/playlesson.svg"
                  placeholder={undefined}
                />
                <IonImg
                  src="./assets/images/play.svg"
                  placeholder={undefined}
                />
              </div>
            <div className="details ion-padding-start">
              <h4>Play book</h4>
              <span>Autoplay book audio</span>
            </div>
          </IonItem> */}
          <IonItem class="ion-no-padding">
            <IonLabel>Lesson language</IonLabel>
            <div className='right-space'>
                <IonText color='primary' placeholder={undefined}>Simp.</IonText>
                <IonText placeholder={undefined}>Trad.</IonText>
            </div>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Chinese characters</IonLabel>
            <IonToggle checked slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Pinyin</IonLabel>
            <IonToggle checked slot="end"></IonToggle>
          </IonItem>
          {/* <IonItem class="ion-no-padding">
            <IonLabel>Translation</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>Font size</IonLabel>
            <IonImg src="./assets/images/Fontdown.svg" />
            <IonImg src="./assets/images/Fontup.svg" />
          </IonItem> */}
          <IonItem class="ion-no-padding">
            <IonLabel>Playback speed</IonLabel>
            <div className='leftright'><a href='#'>&lt;</a><span className="speed">1.0</span><a href='#'>&gt;</a></div>
          </IonItem>
          {/* <IonItem class="ion-no-padding">
            <IonLabel>Bookmark</IonLabel>
            <IonIcon color='primary' icon={bookmark} />
          </IonItem> */}
        </IonList>
      </IonRow>
    {/* </Drawer> */}
    </IonModal>

</IonPage>
  );
};

export default React.memo(Lessonpractice);