// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import ja from "./lang/jp.json";
import tc from "./lang/tc.json";

const resources = {
  en: { translation: en },
  ja: { translation: ja },
  tc: { translation: tc },
};

// const setLanguage = (language) => {
//   i18n.use(initReactI18next).init({
//     resources,
//     lng: language,
//     interpolation: {
//       escapeValue: false,
//     },
//   });
// };

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "en",
  interpolation: {
    escapeValue: false,
  },
});

// export { setLanguage };
export default i18n;
