import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonList, IonItem, IonToggle, IonLabel, IonIcon, IonPage, IonTitle, useIonLoading, useIonToast, IonButtons, IonButton } from '@ionic/react';
import './Notifications.scss';
import { chevronBack } from 'ionicons/icons';
import { CONFIG } from '../App';
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

import { RouteComponentProps, useHistory } from "react-router";

interface OwnProps extends RouteComponentProps {}

interface NotificationsProps extends OwnProps {}

const Notifications: React.FC<NotificationsProps> = () => {
  let url: string = CONFIG.API_ENDPOINT;//live
  const history = useHistory();  

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([])
  const [isapicall, setApiCall] = useState(false);

  let token = ''
  let resfreshtoken = ''
  let rap

  const handleToggleChangeSr = async (event: CustomEvent) => {

    for (var key in itemlist) {
      if (key == 'IsStudyReminder') {
        itemlist['IsStudyReminder'] = itemlist['IsStudyReminder'] == false ? true : false
      }
    }
    setApiCall(false)
    saveSetting()


  };
  const handleToggleChangeNp = async (event: CustomEvent) => {

    for (var key in itemlist) {
      if (key == 'IsNewsPromotion') {
        itemlist['IsNewsPromotion'] = itemlist['IsNewsPromotion'] == false ? true : false
      }
    }
    saveSetting()

  };
  const handleToggleChangeAu = async (event: CustomEvent) => {

    for (var key in itemlist) {
      if (key == 'IsApplicationUpdate') {

        itemlist['IsApplicationUpdate'] = itemlist['IsApplicationUpdate'] == false ? true : false
      }
    }
    saveSetting()
  };

  const saveSetting = async () => {
    let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
    if (currentUser) {
      token = currentUser.JwtToken
      resfreshtoken = currentUser.RefreshToken
    }
    // present({
    //   message: '',
    //   //duration: 3000
    // })


    const data = {
      "isStudyReminder": itemlist['IsStudyReminder'],
      "isNewsPromotion": itemlist['IsNewsPromotion'],
      "isApplicationUpdate": itemlist['IsApplicationUpdate']
    }
    if (!isapicall) {
      try {
        const response = await fetch(url + '/User/SaveNotification', {
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "POST",
        })

        const json = await response.json()
        if (json.StatusCode == 1) {


        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }

        dismiss()

      } catch (error) {
        console.error(error)
      }
    }

  }

  useEffect(() => {
    setApiCall(false)
    const getSettings = async () => {
      let currentUser = JSON.parse(localStorage.getItem('alltoken') || '{}');
      if (currentUser) {
        token = currentUser.JwtToken
        resfreshtoken = currentUser.RefreshToken
      }
      present({
        message: '',
        cssClass: 'custom-spinner',
        duration: 1000
      })
      setApiCall(true)
      try {
        const response = await fetch(url + '/User/GetNotification', {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
          method: "GET",
        })

        const json = await response.json()

        dismiss()
        if (json.StatusCode == 1) {
          // set flag to true after initial settings have been loaded

          setitems(json.Result)


          // setitems(json.Result)
        } else if (json.StatusCode == 2) {
          refreshtoken()
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: 'bottom'
          });
        }


      } catch (error) {
        console.error(error)
        dismiss()
      }
    }
    getSettings();
  }, []);

  const refreshtoken = async () => {
    present({
      message: '',
      cssClass: 'custom-spinner',
      //duration: 3000
    })
    const data = { refreshToken: resfreshtoken }
    try {
      const response = await fetch(url + '/account/refreshtoken', {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()

      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken
        }
        localStorage.setItem('alltoken', JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: 'bottom'
        });
      }

      dismiss()

    } catch (error) {
      console.error(error)
      dismiss()
    }
  }

  const back = () => {
    history.replace({ pathname: "/tabs/settings" }, { direction: "none" });
  };

  return (
    <IonPage className='intro'>
      <IonHeader className='ion-text-center'>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={back} color='primary'>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
          <IonTitle color='primary' className='font20 ion-no-padding'><span>{t("notifications.noti")}</span></IonTitle>
          <IonButtons slot="end">
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className='listing spacingset'>
        <IonList className='list'>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("notifications.stdy_rem")}</IonLabel>
            <IonToggle checked={itemlist['IsStudyReminder']} onIonChange={handleToggleChangeSr} slot="end" />
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("notifications.nws_and_promo")}</IonLabel>
            <IonToggle checked={itemlist['IsNewsPromotion']} onIonChange={handleToggleChangeNp} slot="end" />
          </IonItem>
          <IonItem class="ion-no-padding">
            <IonLabel>{t("notifications.app_upt")}</IonLabel>
            <IonToggle checked={itemlist['IsApplicationUpdate']} onIonChange={handleToggleChangeAu} slot="end"/>
          </IonItem>
        </IonList>
      </IonContent>

    </IonPage>
  );
};

export default React.memo(Notifications);