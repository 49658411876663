import React from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonImg,
  IonLabel,
  useIonViewWillEnter,
} from "@ionic/react";
import { RouteComponentProps, Route, Redirect, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
// page imports
import Adjustmylevel from "./Adjustmylevel";
import Changepassword from "./Changepassword";
import Currentlevel from "./Currentlevel";
import Detailedreports from "./Detailedreports";
import Dialoguedone from "./Dialoguedone";
import Dialoguepractice from "./Dialoguepractice";
import Dialogueresult from "./Dialogueresult";
import Dialoguevideo from "./Dialoguevideo";
import Dragdroplesson from "./Dragdroplesson";
import E1sResultDetails from "./E1sResultDetails";
import E2sResultDetails from "./E2sResultDetails";
import E3sResultDetails from "./E3sResultDetails";
import E4ev1vocab from "./E4ev1vocab";
import E4sResultDetails from "./E4sResultDetails";
import E5ev1vocab from "./E5ev1vocab";
import E5sResultDetails from "./E5sResultDetails";
import E6ev1vocab from "./E6ev1vocab";
import E6sResultDetails from "./E6sResultDetails";
import E7ev1 from "./E7ev1";
import Editprofile from "./Editprofile";
import FAQs from "./FAQs";
import Finals from "./Finals";
import Finalslast from "./Finalslast";
import Finalspractice from "./Finalspractice";
import Finalspracticetwo from "./Finalspracticetwo";
import Finalstwo from "./Finalstwo";
import Home from "./Home";
import InitIntro from "./InitIntro";
import Initials from "./Initials";
import Initialslast from "./Initialslast";
import Initialspractice from "./Initialspractice";
import Languages from "./Languages";
import Lesson from "./Lesson";
import LessonAutoPlay from "./LessonAutoPlay";
import Lessoncompleted from "./Lessoncompleted";
import Lessonpractice from "./Lessonpractice";
import Leveldescriprion from "./Leveldescriprion";
import MainHome from "./MainHome";
import Mysubscription from "./Mysubscription";
import Notificationbell from "./Notificationbell";
import Notifications from "./Notifications";
import Numbers from "./Numbers";
import Numberslast from "./Numberslast";
import Numberspractice from "./Numberspractice";
import Pinyin from "./Pinyin";
import Pinyinlast from "./Pinyinlast";
import Pinyinpractice from "./Pinyinpractice";
import Practicecompleted from "./Practicecompleted";
import Practicequestion from "./Practicequestion";
import Practiceresult from "./Practiceresult";
import Practicevocab from "./Practicevocab";
import Privacypolicy from "./Privacypolicy";
import Proficient from "./Proficient";
import Profile from "./Profile";
import Progress from "./Progress";
import Settings from "./Settings";
import Starredvocabulary from "./Starredvocabulary";
import Subscribe from "./Subscribe";
import Themesports from "./Themesports";
import Tones from "./Tones";
import Toneslast from "./Toneslast";
import Toneslasttwo from "./Toneslasttwo";
import Tonespractice from "./Tonespractice";
import Tonespracticetwo from "./Tonespracticetwo";
import Tonestwo from "./Tonestwo";
import Vocabcompleted from "./Vocabcompleted";
import Vocabularybank from "./Vocabularybank";
import Vocabularypractice from "./Vocabularypractice";
import Vocabularypracticecomplete from "./Vocabularypracticecomplete";
import Vocabularyrecap from "./Vocabularyrecap";
import Wittywizard from "./Wittywizard";

interface OwnProps extends RouteComponentProps {}
interface MainTabsProps extends OwnProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  // const history = useHistory();
  document.addEventListener("ionBackButton", (e: any) => {
    e.detail.register(0, () => {
      console.log("Native Back Off");
    });
  });

  useIonViewWillEnter(() => {
    const userData: any = localStorage.getItem("alltoken") || "{}";
    const currentUser = JSON.parse(userData);
    if (Object.keys(currentUser).length === 0) {
      history.replace("/", { direction: "none" });
      // history.go(0)
    }
  });

  const homeClick = () => {
    localStorage.removeItem("answers");
    // localStorage.removeItem("practice");
    localStorage.setItem("resultBack", JSON.stringify(1));
  };

  const progressClick = () => {
    localStorage.setItem("resultBack", JSON.stringify(2));
  };

  // Retrieve the activeTab from localStorage on component initialization
  // const initialActiveTab = localStorage.getItem('activeTab') || 'mainhome';
  // const [activeTab, setActiveTab] = useState(initialActiveTab);
  // const navigateToTab = (tab: string) => {
  //   history.replace(`/tabs/${tab}`);
  //   setActiveTab(tab);
  //   // Save the activeTab in localStorage
  //   localStorage.setItem('activeTab', tab);
  // };
  // useEffect(() => {
  //   // Set the activeTab in localStorage whenever it changes
  //   localStorage.setItem('activeTab', activeTab);
  // }, [activeTab]);
  // useIonViewWillLeave(() => {
  //   // Clear localStorage when leaving the view
  //   localStorage.removeItem('activeTab');
  // });

  return (
    <IonTabs>
      <IonRouterOutlet placeholder={undefined}>
        {/* MainHome */}
        <Redirect exact path="/tabs" to="/tabs/mainhome" />
        <Route path="/tabs/mainhome" component={MainHome} exact={true} />
        <Route
          path="/tabs/mainhome/initintro"
          component={InitIntro}
          exact={true}
        />
        <Route path="/tabs/mainhome/initintro/tones" component={Tones} />
        <Route
          path="/tabs/mainhome/initintro/tonespractice"
          component={Tonespractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/toneslast"
          component={Toneslast}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/initials"
          component={Initials}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/initialspractice"
          component={Initialspractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/initialslast"
          component={Initialslast}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/finals"
          component={Finals}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/finalspractice"
          component={Finalspractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/finalspracticetwo"
          component={Finalspracticetwo}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/finalstwo"
          component={Finalstwo}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/finalslast"
          component={Finalslast}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/pinyin"
          component={Pinyin}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/pinyinpractice"
          component={Pinyinpractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/pinyinlast"
          component={Pinyinlast}
          exact={true}
        />
        <Route path="/tabs/mainhome/initintro/tonestwo" component={Tonestwo} />
        <Route
          path="/tabs/mainhome/initintro/tonespracticetwo"
          component={Tonespracticetwo}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/toneslasttwo"
          component={Toneslasttwo}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/numbers"
          component={Numbers}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/numberspractice"
          component={Numberspractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/initintro/numberslast"
          component={Numberslast}
          exact={true}
        />
        <Route path="/tabs/mainhome/home" component={Home} exact={true} />
        <Route
          path="/tabs/mainhome/notificationbell"
          component={Notificationbell}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/vocabularyrecap"
          component={Vocabularyrecap}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/starredvocabulary"
          component={Starredvocabulary}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/vocabularypractice"
          component={Vocabularypractice}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/vocabularypracticecomplete"
          component={Vocabularypracticecomplete}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/proficient"
          component={Proficient}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/themesports"
          component={Themesports}
          exact={true}
        />
        <Route
          path="/tabs/mainhome/wittywizard"
          component={Wittywizard}
          exact={true}
        />
        {/* <Route path="/tabs/mainhome/Dialogue" component={Dialogue} exact={true}/> */}

        {/* Progres */}
        <Route path="/tabs/progress" component={Progress} exact={true} />
        <Route
          path="/tabs/progress/detailedreports"
          component={Detailedreports}
          exact={true}
        />
        <Route
          path="/tabs/progress/vocabularybank"
          component={Vocabularybank}
          exact={true}
        />

        {/* MainHome & Progress */}
        <Route
          path="/tabs/:tab(mainhome|progress)/lesson"
          component={Lesson}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/lessonautoplay"
          component={LessonAutoPlay}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/lessoncompleted"
          component={Lessoncompleted}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/practicequestion"
          component={Practicequestion}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/practicecompleted"
          component={Practicecompleted}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/practiceresult"
          component={Practiceresult}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E1sResultDetails"
          component={E1sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E2sResultDetails"
          component={E2sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E3sResultDetails"
          component={E3sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E4sResultDetails"
          component={E4sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E5sResultDetails"
          component={E5sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/E6sResultDetails"
          component={E6sResultDetails}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/dialoguevideo"
          component={Dialoguevideo}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/dialoguepractice"
          component={Dialoguepractice}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/dialoguedone"
          component={Dialoguedone}
          exact={true}
        />
        <Route
          path="/tabs/:tab(mainhome|progress)/dialogueresult"
          component={Dialogueresult}
          exact={true}
        />

        {/* Profile */}
        <Route path="/tabs/profile" component={Profile} exact={true} />
        <Route
          path="/tabs/profile/adjustmylevel"
          component={Adjustmylevel}
          exact={true}
        />
        <Route
          path="/tabs/profile/currentlevel"
          component={Currentlevel}
          exact={true}
        />
        <Route
          path="/tabs/profile/editprofile"
          component={Editprofile}
          exact={true}
        />
        <Route
          path="/tabs/profile/leveldescriprion"
          component={Leveldescriprion}
          exact={true}
        />
        <Route
          path="/tabs/profile/mysubscription"
          component={Mysubscription}
          exact={true}
        />
        <Route
          path="/tabs/profile/subscribe"
          component={Subscribe}
          exact={true}
        />

        {/* Settings */}
        <Route path="/tabs/settings" component={Settings} exact={true} />
        <Route
          path="/tabs/settings/changepassword"
          component={Changepassword}
          exact={true}
        />
        <Route path="/tabs/settings/faqs" component={FAQs} exact={true} />
        <Route
          path="/tabs/settings/languages"
          component={Languages}
          exact={true}
        />
        <Route
          path="/tabs/settings/notifications"
          component={Notifications}
          exact={true}
        />
        <Route
          path="/tabs/settings/privacypolicy"
          component={Privacypolicy}
          exact={true}
        />

        {/* Extra Pages*/}
        <Route
          path="/tabs/Dragdroplesson"
          render={() => <Dragdroplesson />}
          exact={true}
        />
        <Route path="/tabs/E7ev1" component={E7ev1} exact={true} />
        <Route path="/tabs/E4ev1vocab" component={E4ev1vocab} exact={true} />
        <Route path="/tabs/E5ev1vocab" component={E5ev1vocab} exact={true} />
        <Route path="/tabs/E6ev1vocab" component={E6ev1vocab} exact={true} />
        <Route
          path="/tabs/Lessonpractice"
          render={() => <Lessonpractice />}
          exact={true}
        />
        <Route
          path="/tabs/Practicevocab"
          component={Practicevocab}
          exact={true}
        />
        <Route
          path="/tabs/Vocabcompleted"
          component={Vocabcompleted}
          exact={true}
        />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" placeholder={undefined}>
        <IonTabButton tab="home" href="/tabs/mainhome" onClick={homeClick}>
          <IonImg
            className="select"
            src="./assets/images/home-fill.svg"
            alt=""
          />
          <IonImg className="notselect" src="./assets/images/home.svg" alt="" />
          <IonLabel placeholder={undefined}>{t("maintabs.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="progress"
          href="/tabs/progress"
          onClick={progressClick}
        >
          <IonImg
            className="select"
            src="./assets/images/progressmenu-fill.svg"
            alt=""
          />
          <IonImg
            className="notselect"
            src="./assets/images/progressmenu.svg"
            alt=""
          />
          <IonLabel placeholder={undefined}>{t("maintabs.progress")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/tabs/profile">
          <IonImg
            className="select"
            src="./assets/images/profile-fill.svg"
            alt=""
          />
          <IonImg
            className="notselect"
            src="./assets/images/profile.svg"
            alt=""
          />
          <IonLabel placeholder={undefined}>{t("maintabs.profile")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href="/tabs/settings">
          <IonImg
            className="select"
            src="./assets/images/setting-fill.svg"
            alt=""
          />
          <IonImg
            className="notselect"
            src="./assets/images/setting.svg"
            alt=""
          />
          <IonLabel placeholder={undefined}>{t("maintabs.settings")}</IonLabel>
        </IonTabButton>

        <>
          {/* <IonTabButton tab="home" onClick={navigateToHome}>
          <IonImg src={homeIcon === true ? "./assets/images/home-fill.svg" : "./assets/images/home.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="progress" onClick={navigateToProgress}>
          <IonImg src={progressIcon === true ? "./assets/images/progressmenu-fill.svg" : "./assets/images/progressmenu.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.progress")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" onClick={navigateToProfile}>
          <IonImg src={profileIcon === true ? "./assets/images/profile-fill.svg" : "./assets/images/profile.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.profile")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" onClick={navigateToSettings}>
          <IonImg src={settingsIcon === true ? "./assets/images/setting-fill.svg" : "./assets/images/setting.svg"} placeholder={undefined}/>
          <IonLabel color='primary' placeholder={undefined}>{t("maintabs.settings")}</IonLabel>
        </IonTabButton>      
        
        <IonTabButton tab="home" onClick={() => navigateToTab('mainhome')} selected={activeTab === 'mainhome'}>
          <IonImg src={`./assets/images/${activeTab === 'mainhome' ? 'home-fill' : 'home'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.home")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="progress" onClick={() => navigateToTab('progress')} selected={activeTab === 'progress'}>
          <IonImg src={`./assets/images/${activeTab === 'progress' ? 'progressmenu-fill' : 'progressmenu'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.progress")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" onClick={() => navigateToTab('profile')} selected={activeTab === 'profile'}>
          <IonImg src={`./assets/images/${activeTab === 'profile' ? 'profile-fill' : 'profile'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.profile")}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" onClick={() => navigateToTab('settings')} selected={activeTab === 'settings'}>
          <IonImg src={`./assets/images/${activeTab === 'settings' ? 'setting-fill' : 'setting'}.svg`} placeholder={undefined}/>
          <IonLabel placeholder={undefined}>{t("maintabs.settings")}</IonLabel>
        </IonTabButton> */}
        </>
      </IonTabBar>
    </IonTabs>
  );
};
export default MainTabs;
