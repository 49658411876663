import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  IonImg,
  IonCardContent,
  IonList,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonToast,
  useIonLoading,
  useIonViewWillEnter,
  IonModal,
  getPlatforms,
} from "@ionic/react";
import "./Dialogueresult.scss";
import { chevronBack } from "ionicons/icons";
import { Share } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Capacitor, Plugins } from "@capacitor/core";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
const { Permissions } = Plugins;
interface OwnProps extends RouteComponentProps {}
interface DialogueresultProps extends OwnProps {}
const Dialogueresult: React.FC<DialogueresultProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  // Get the platform information
  const platform = getPlatforms();

  const checkPermissions = async () => {
    const result = await Permissions.requestPermissions({
      permissions: ["photos"],
    });

    if (result.photos === "granted") {
    } else {
    }
  };

  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  // const tDataCon = JSON.parse(themeData);
  const historyss = useHistory<{
    themeArray: any;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    backpageValue: number;
    title: string;
    dialogueId: string;
    role: string;
    allrole: string;
    rolename: string;
    videoUrl: string;
    thumb: string;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const bookId = historyss.location.state
    ? historyss.location.state.bookId
    : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const thumb = historyss.location.state ? historyss.location.state.thumb : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const title = historyss.location.state ? historyss.location.state.title : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";

  const getData: any = localStorage.getItem("resultBack");
  const backPageValue = JSON.parse(getData);
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [FileToaster] = useIonToast();
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  const [openModal, setOpenModal] = useState<any>(false);
  const [itemresultlist, setresultitems] = React.useState<any>([]);
  const [RoleAslist, setRoleAs] = React.useState<any[]>([]);
  const [RoleBslist, setRoleBs] = React.useState<any[]>([]);

  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetResult();
    GetProfie();
  });
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetResult = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(
        url + "/Book/GetDialogueResult?dialogueId=" + dialogueId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setresultitems(json.Result);
        setRoleAs(json.Result.RoleAs);
        setRoleBs(json.Result.RoleBs);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // const shareButton = async () => {
  //   FileToaster({
  //     message: 'Which format do you want to share?',
  //     duration: 10000,
  //     position: 'top',
  //     layout: 'stacked',
  //     cssClass: 'custom-toast',
  //     buttons: [
  //       {
  //         text: 'Share Text',
  //         handler: shareGenerateTXT
  //       }, {
  //         text: 'Share Image',
  //         handler: shareGenerateIMG
  //       },
  //     ],
  //   });
  //   onClose()
  // }
  // const shareGenerateTXT = async () => {
  //   const div = document.getElementById('content') as HTMLElement;
  //   const textContent = div ? (div.textContent || div.innerText || '') : '';
  //   const formattedText = textContent.replace(/(%|\/0|:|ing)/g, '$1\n');
  //   try {
  //     await Share.share({
  //       title: 'Share Result',
  //       text: formattedText,
  //       dialogTitle: 'Share via',
  //     });
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  // };
  const shareGenerateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          await Filesystem.requestPermissions();
          const imagePath = await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.Cache,
            path: "Dialogue Result.jpg",
          });
          let imageResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: "Dialogue Result.jpg",
          });
          let imageLink = Capacitor.convertFileSrc(imageResult.uri);
          Share.share({
            title: `Dialogue Result ${
              backPageValue === 2
                ? themeArray && themeArray.length > 0
                  ? themeArray[index ?? 0]?.Name
                  : ""
                : themeArray && themeArray.length > 0
                ? themeArray[index ?? 0]?.ContentEn
                : "Test Theme"
            }`,
            url: imageResult.uri,
          })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing: ", error));
        });
        setOpenModal(false);
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
      await hiddenContent.classList.add("hidden-content");
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };

  const generateIMG = async () => {
    if (platform.includes("android")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imageDataUrl = canvas.toDataURL();
            await Filesystem.requestPermissions();
            const imagePath = await Filesystem.writeFile({
              data: imageDataUrl,
              directory: Directory.ExternalStorage,
              path: `Download/Dialogue Result ${
                backPageValue === 2
                  ? themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.Name
                    : ""
                  : themeArray && themeArray.length > 0
                  ? themeArray[index ?? 0]?.ContentEn
                  : "Test Theme"
              }.jpg`,
            });
            if (imagePath !== null) {
              FileToaster({
                message:
                  "Image save successfully at Location: Download/Dialogue Result.jpg",
                duration: 3000,
                position: "top",
                cssClass: "custom-toast",
                buttons: [{ text: "Ok", handler: () => onClose() }],
              });
            }
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else if (platform.includes("ios")) {
      checkPermissions();
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imageDataUrl = canvas.toDataURL();
            await Filesystem.requestPermissions();
            const imagePath = await Filesystem.writeFile({
              data: imageDataUrl,
              directory: Directory.Cache,
              path: "Dialogue Result.png",
            });
            let imageResult = await Filesystem.getUri({
              directory: Directory.Cache,
              path: "Dialogue Result.png",
            });
            let imageLink = Capacitor.convertFileSrc(imageResult.uri);
            Share.share({
              title: `Dialogue Result ${
                backPageValue === 2
                  ? themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.Name
                    : ""
                  : themeArray && themeArray.length > 0
                  ? themeArray[index ?? 0]?.ContentEn
                  : "Test Theme"
              }.png`,
              url: imageResult.uri,
            })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing: ", error));
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    }
  };

  const generatePDF = async () => {
    if (platform.includes("android")) {
      const hiddenContent = document.getElementById("hidden-content");

      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;

        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;

            const doc = new jsPDF({
              unit: "px",
              format: [imgWidth, imgHeight],
            });
            const imageDataUrl = canvas.toDataURL();
            doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            await Filesystem.requestPermissions();

            const filePath = await Filesystem.writeFile({
              path: `Download/Dialogue Result ${
                backPageValue === 2
                  ? themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.Name
                    : ""
                  : themeArray && themeArray.length > 0
                  ? themeArray[index ?? 0]?.ContentEn
                  : "Test Theme"
              }.pdf`,
              data: doc.output("datauristring"),
              directory: Directory.ExternalStorage,
            });

            if (filePath !== null) {
              FileToaster({
                message:
                  "PDF saved successfully at Location: Download/Dialogue Result.pdf",
                duration: 3000,
                position: "top",
                cssClass: "custom-toast",
                buttons: [{ text: "Ok", handler: () => onClose() }],
              });
            }
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else if (platform.includes("ios")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const doc = new jsPDF({
              unit: "px",
              format: [imgWidth, imgHeight],
            });
            const imageDataUrl = canvas.toDataURL();
            doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            await Filesystem.requestPermissions();
            const filePath = await Filesystem.writeFile({
              path: "Dialogue Result.pdf",
              data: doc.output("datauristring"),
              directory: Directory.Cache,
            });

            let filePathGet = await Filesystem.getUri({
              directory: Directory.Cache,
              path: "Dialogue Result.pdf",
            });
            let imageLink = Capacitor.convertFileSrc(filePathGet.uri);
            Share.share({
              title: `Dialogue Result ${
                backPageValue === 2
                  ? themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.Name
                    : ""
                  : themeArray && themeArray.length > 0
                  ? themeArray[index ?? 0]?.ContentEn
                  : "Test Theme"
              }.pdf`,
              url: filePathGet.uri,
            })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing: ", error));
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    }
  };

  const test = (ContentTc, ContentPy, Answer) => {
    const sentence1 = ContentTc;
    const sentence2 = Answer ? Answer : "";
    const sentencepy = ContentPy;
    const characters1 = sentence1.split("");
    const characters2 = sentence2.split("");
    const charPy = sentencepy.split(" ");
    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];
    charPy.forEach((py, i) => {
      if (spChar.includes(characters1[i])) {
        charPy.splice(i, 0, " "); // Insert space at index i
      }
    });
    const result = characters1.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };
  const back = () => {
    history.replace({
      pathname:
        backPageValue === 2
          ? "/tabs/progress/detailedreports"
          : "/tabs/mainhome/dialoguedone",
      state: {
        themeArray: themeArray,
        videoUrl: videoUrl,
        thumb: thumb,
        index: index,
        themeId: themeId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        role: role,
        rolename: rolename,
        allrole: allrole,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={back}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("dialogueresult.dialogue")}: {t("dialogueresult.practice")}
                <p className="ion-no-margin">
                  {backPageValue === 2
                    ? themeArray && themeArray.length > 0
                      ? themeArray[index ?? 0]?.Name
                      : ""
                    : themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.ContentEn
                    : "Test Theme"}
                </p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                //  onClick={openDrawer}
                onClick={() => setOpenModal(true)}
                color="primary"
              >
                <IonImg src="./assets/images/share.svg" alt="" />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      {/*  ion-padding-horizontal */}
      <IonContent className="listing">
        <div has-bouncing="true" className="psdviewer" id="content">
          <IonRow
            class="blue-bg pdfhedding"
            id="hidden-content"
            className="hidden-content"
          >
            <IonCol size="12">
              <span>
                <h3>{t("dialogueresult.dialogue_report")}</h3>
                <p className="pdflavel">
                  {t("dialogueresult.level")} {level}
                </p>
                <p className="ion-no-margin">
                  {themename}: {t("dialogueresult.dialogue")}{" "}
                  {/* {themeArray[index ? index : 0]?.number} */}
                  {themeArray && themeArray.length > 0
                    ? themeArray[index ?? 0]?.number
                    : ""}
                </p>
                <p className="ion-no-margin themename">{lessonname}</p>
              </span>
              <span className="logoarea">
                <img src="./assets/images/pdfMMXlogo.png" alt="" />
                <p className="ion-no-margin username">
                  <img src="./assets/images/pdfusericon.png" alt="" />
                  {itemprofilelist?.Name}
                </p>
              </span>
            </IonCol>
          </IonRow>
          <IonRow className="count">
            <IonCol size="12">
              <IonCard>
                <IonCardContent class="ion-no-padding">
                  <IonRow>
                    <IonCol size="6">
                      <h5>{t("dialogueresult.overall_score")}</h5>
                      <div>
                        <h1>{itemresultlist?.OverAllPer}%</h1>
                      </div>
                    </IonCol>
                    <IonCol size="6" className="ion-text-right bookfont">
                      {itemresultlist?.CompletedDate === null || 0 ? (
                        ""
                      ) : (
                        <>
                          {t("dialogueresult.completed_date")}:
                          <br />
                          {itemresultlist?.CompletedDate}
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonList className="ion-no-padding dialogueresult">
            {RoleAslist && RoleAslist.length > 0 ? (
              <>
                <IonRow className="answer">
                  <IonCol size="12">
                    <div className="alignment">
                      <div className="roundecircle">
                        {" "}
                        <IonImg src={RoleAslist[0]?.TypeUrl} alt="" />
                      </div>
                      <div className="role">{RoleAslist[0]?.Role}</div>
                    </div>
                  </IonCol>
                  {RoleAslist.map((items, index) => {
                    return (
                      <IonCol size="12" key={index}>
                        <IonCard>
                          <IonCardContent class="ion-no-padding">
                            <div className="listingbox">
                              <ul className="lession-icon">
                                {/* {textset_tc(items.ContentTc, items.ContentPy)} */}
                                {test(
                                  items?.LangType === "cn"
                                    ? items?.ContentSc
                                    : items?.ContentTc,
                                  items?.ContentPy,
                                  items?.Answer
                                )}
                              </ul>
                              <p>{items?.ContentEn}</p>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                  <IonCol size="12" className="scoreview">
                    Your Score: <b>{itemresultlist?.RoleAScore}</b>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <p></p>
            )}
            {RoleBslist && RoleBslist.length > 0 ? (
              <>
                <IonRow className="answer">
                  <IonCol size="12">
                    <div className="alignment">
                      <div className="roundecircle">
                        <IonImg src={RoleBslist[0]?.TypeUrl} alt="" />
                      </div>
                      <div className="role">{RoleBslist[0]?.Role}</div>
                    </div>
                  </IonCol>
                  {RoleBslist.map((items, index) => {
                    return (
                      <IonCol size="12" key={index}>
                        <IonCard>
                          <IonCardContent class="ion-no-padding">
                            <div className="listingbox">
                              <ul className="lession-icon">
                                {/* {textset_tc(items.ContentTc, items.ContentPy)} */}
                                {test(
                                  items?.LangType === "cn"
                                    ? items?.ContentSc
                                    : items?.ContentTc,
                                  items?.ContentPy,
                                  items?.Answer
                                )}
                              </ul>
                              <p>{items?.ContentEn}</p>
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    );
                  })}
                  <IonCol size="12" className="scoreview">
                    Your Score: <b>{itemresultlist?.RoleBScore}</b>
                  </IonCol>
                </IonRow>
              </>
            ) : (
              <p></p>
            )}
            {/* <IonRow className='lession-details'>
            <IonCol size='12' className='ion-padding-bottom ion-text-left'>
              <IonIcon color='primary' icon={play} />
              <div className='ion-padding-start ion-padding-bottom'>
                <ul className='lession-icon'>
                  <li>Nǐ<span>你</span></li>
                  <li>mén<span>们</span></li>
                  <li>zuò<span>做</span></li>
                  <li>dàn<span>做</span></li>
                  <li>gāo<span>蛋</span></li>
                </ul>
              </div>
            </IonCol>
          </IonRow> */}
          </IonList>
        </div>
      </IonContent>
      <IonModal
        className="profilepage"
        isOpen={openModal}
        onDidDismiss={() => setOpenModal(false)}
        // trigger="open-modal"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        // handleBehavior="cycle"
      >
        {/* <Drawer
          className="bottompopup skateInBottom bottompopupresult"
          isVisible={isVisible}
          onClose={onClose}
        > */}
        <IonRow>
          <IonList className="list">
            <IonItem onClick={shareGenerateIMG}>
              {/* <IonIcon icon={share} /> */}
              <IonImg src="./assets/images/editShare.svg" alt="" />
              <IonLabel>{t("dialogueresult.drawer.share_result")}</IonLabel>
            </IonItem>
            <IonItem onClick={generateIMG}>
              {/* <IonIcon icon={image} /> */}
              <IonImg src="./assets/images/Chooseimage.svg" alt="" />
              <IonLabel>{t("dialogueresult.drawer.save_as_photo")}</IonLabel>
            </IonItem>
            <IonItem onClick={generatePDF}>
              {/* <IonIcon icon={download} /> */}
              <IonImg src="./assets/images/download.svg" alt="" />
              <IonLabel>{t("dialogueresult.drawer.save_as_pdf")}</IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Dialogueresult);
