import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonList,
  IonGrid,
  IonText,
  IonInput,
  IonRow,
  IonIcon,
  IonCol,
  IonImg,
  IonItem,
  IonLabel,
  IonButton,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonSlides,
  IonSlide,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonModal,
} from "@ionic/react";
import "./Starting.scss";
import { lockClosed, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import { Controller, useForm, useFormState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}
interface StartingProps extends OwnProps {}

const Starting: React.FC<StartingProps> = ({ history }) => {
  interface StartingProps extends OwnProps {}
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();

  // login Page Variable
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [presenttost] = useIonToast();
  const Completionist = () => <span>You are good to go!</span>;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setloginError] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});
  const [present, dismiss] = useIonLoading();
  const [cursorPosition, setCursorPosition] = useState();
  const [otp, setOtp] = useState("");
  const [items, setItems] = useState([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);

  // Forgotpassemail Page Variable
  // const [present, dismiss] = useIonLoading();
  // const [presenttost] = useIonToast();
  // const { handleSubmit, control, formState: { errors } } = useForm({});
  const [emailValue, setEmailValue] = useState("");
  const { isDirty, isValid } = useFormState({ control });

  // Forgotpassverify Page Variable
  // const Completionist = () => <span>You are good to go!</span>;
  // const [present, dismiss] = useIonLoading();
  // const [presenttost] = useIonToast();
  // const [cursorPosition, setCursorPosition] = useState();
  // const [otp, setOtp] = useState('');

  // Forgotpassresetpass Page Variable
  // const { handleSubmit, control, formState: { errors } } = useForm({});
  // const [isRevealPwd, setIsRevealPwd] = useState(false);
  // const [isRevealPwdconf, setIsRevealPwdconf] = useState(false);

  // Open Close Logic

  const [is2DrawerVisible, setIs2DrawerVisible] = useState(false);
  const [is3DrawerVisible, setIs3DrawerVisible] = useState(false);
  const [is4DrawerVisible, setIs4DrawerVisible] = useState(false);

  // New Open Close Logic
  const [openModalLogin, setOpenModalLogin] = useState<any>(false);
  const [openModalFPEmail, setOpenModalFPEmail] = useState<any>(false);
  const [openModalVerifyOTP, setOpenModalVerifyOTP] = useState<any>(false);
  const [openModalNewPass, setOpenModalNewPass] = useState<any>(false);

  // Function to open the 2 drawer
  const open2Drawer = () => {
    setOpenModalFPEmail(true);
    setOpenModalLogin(false);
  };
  // Function to open the 3 drawer
  const open3Drawer = () => {
    setOpenModalVerifyOTP(true);
    setOpenModalFPEmail(false);
  };
  // Function to open the 4 drawer
  const open4Drawer = () => {
    setOpenModalNewPass(true);
    setOpenModalVerifyOTP(false);
  };

  // Starting Page Logic

  const Starting = async (item: any) => {
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   //duration: 3000
    // });

    const data = { email: item.email };
    try {
      const response = await fetch(url + "/account/sendotp", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      dismiss();

      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });

      if (json.StatusCode == 1) {
        localStorage.setItem("email", item.email);

        history.replace("/Starting", { direction: "none" });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  // Login Page Start

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    
    if (Object.keys(currentUser).length > 0) {
      history.replace("/tabs/mainhome", { direction: "none" });
      // history.go(0)
    }
  });

  const onChange = (value: string) => {
    setOtp(value);
    
    if (value.length == 6) {
      verifyotp(value);
      //history.replace('/forgotpassresetpass', { direction: 'none' });
    }
  };

  const verifyotp = async (item: any) => {
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   //duration: 3000
    // });
    var email = localStorage.getItem("email");
    const data = { email: email, otp: item.toUpperCase() };
    
    try {
      const response = await fetch(url + "/account/verifyotp", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      dismiss();

      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });

      if (json.StatusCode == 1) {
        history.replace("/forgotpassresetpass", { direction: "none" });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  const inputChangeHandler = async (e: any) => {
    
    setCursorPosition(e);
  };

  const liveCheckUsername = (e) => {
    if (e === "") {
      setUsernameError(true);
      setFormSubmitted(true);
      setloginError(false);
    } else {
      setUsernameError(false);
      setloginError(false);
    }
  };

  const liveCheckPassword = (e) => {
    if (e === "") {
      setPasswordError(true);
      setFormSubmitted(true);
      setloginError(false);
    } else {
      setPasswordError(false);
      setloginError(false);
    }
  };

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!username) {
      setUsernameError(true);
      setloginError(false);
    }
    if (!password) {
      setPasswordError(true);
      setloginError(false);
    }
    if (!username && password) {
      setUsernameError(true);
      setPasswordError(false);
      setloginError(false);
    }
    if (username && !password) {
      setUsernameError(false);
      setPasswordError(true);
      setloginError(false);
    }
    if (username && password) {
      signin();
    }
  };

  const signin = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });

    var encodedString = btoa(password);

    const data = { email: username, password: encodedString };
    try {
      const response = await fetch(url + "/account/validateuser", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        // await setIsLoggedIn(true);
        //  await setUsernameAction(username);
        localStorage.setItem("logininfo", JSON.stringify(json));

        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));

        if (json.Result.IsBefore === false) {
          history.replace("/Beforewestart");
        } else if (
          json.Result.IsBefore === true &&
          json.Result.IsProficiency === false
        ) {
          history.replace("/pretestone", { direction: "none" });
        } else if (
          json.Result.IsBefore === true &&
          json.Result.IsProficiency === true
        ) {
          history.replace("/tabs/mainhome", { direction: "none" });
          // history.go(0)
        }
      } else if (json.StatusCode === 3) {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
        localStorage.setItem("userID", json.Result.UserId);
        history.replace("/Termscondition", { direction: "none" });
      } else {
        setloginError(true);
        setUsernameError(false);
        setPasswordError(false);
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
      // useEffect(() => {
      //   localStorage.setItem('items', JSON.stringify(json));
      // }, [items]);

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const togglePasswordVisiblity = () => {
    setIsRevealPwd(isRevealPwd ? false : true);
  };
  const togglePasswordVisiblityconf = () => {
    setIsRevealPwdconf(isRevealPwdconf ? false : true);
  };

  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");

    if (Object.keys(currentUser).length > 0) {
      history.replace("/tabs/mainhome", { direction: "none" });
    }
  });

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  // const onCloseForgotPass = React.useCallback(() => {
  //   setIs2DrawerVisible(false);
  // }, []);
  const onCloseForgotPass2 = React.useCallback(() => {
    setIs2DrawerVisible(false);
  }, []);
  const onCloseForgotPass3 = React.useCallback(() => {
    setIs3DrawerVisible(false);
  }, []);
  const onCloseForgotPass4 = React.useCallback(() => {
    setIs4DrawerVisible(false);
  }, []);

  // Login Page end

  // Forgotpassemail Page Start

  const forgotemail = async (item: any) => {
    present({
      message: "",
      cssClass: "custom-spinner",
      // duration: 3000
    });

    const data = { email: item.email };
    try {
      const response = await fetch(url + "/account/sendotp", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      presenttost({
        message: json.Message,
        duration: 2000,
        position: "bottom",
      });

      if (json.Message !== "Your email is not registered with our system.") {
        open3Drawer();
        dismiss();
      }

      if (json.StatusCode == 1) {
        localStorage.setItem("email", item.email);

        // history.replace("/forgotpassverify", { direction: "none" });
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  // Forgotpassemail Page End

  // Forgotpassverify Page Start

  const onChanged = (value: string) => {
    setOtp(value);
    
    if (value.length == 6) {
      verifyotps(value);
      //history.replace('/forgotpassresetpass', { direction: 'none' });
    }
  };

  const verifyotps = async (item: any) => {
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   //duration: 3000
    // });
    var email = localStorage.getItem("email");
    const data = { email: email, otp: item.toUpperCase() };
    
    try {
      const response = await fetch(url + "/account/verifyotp", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      dismiss();

      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });

      if (json.StatusCode == 1) {
        // history.replace("/forgotpassresetpass", { direction: "none" });
        open4Drawer();
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  // const inputChangeHandler = async (e: any) => {
  //   setCursorPosition(e);
  //   // if(!isNaN(e.target.value)){
  //   // setOtp(e.target.value);
  //   // props.otpHandler(otp)
  //   // }
  // };

  // Forgotpassverify Page End

  // Forgotpasssetpass Page Start

  const resetpassword = async (item: any) => {
    if (item.pwd != item.confpwd) {
      presenttost({
        message: "Passwords does not match",
        duration: 5000,
        position: "bottom",
      });
      return;
    }
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   //duration: 3000
    // });
    var email = localStorage.getItem("email");
    var encodedString = btoa(item.pwd);

    const data = { email: email, password: encodedString };
    try {
      const response = await fetch(url + "/account/resetpassword", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      
      dismiss();

      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });

      if (json.StatusCode == 1) {
        submit();
      }
    } catch (error) {
      presenttost({
        message: "Please try again later",
        duration: 1500,
        position: "bottom",
      });
      dismiss();
      console.error(error);
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="icon">
              <IonIcon icon={lockClosed} />
            </div>
            <p>{t("forgotpassresetpass.submit.pass_cngd_succ")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton className="alertcancel w-150" routerLink="/login">
                {t("forgotpassresetpass.submit.continue")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };

  const togglePasswordVisiblitySet = () => {
    setIsRevealPwd(isRevealPwd ? false : true);
  };
  const togglePasswordVisiblityconfig = () => {
    setIsRevealPwdconf(isRevealPwdconf ? false : true);
  };

  // Forgotpasssetpass Page End

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonList className="full-height">
          <IonGrid className="center">
            <IonRow>
              <IonCol size="12" class="mainlogo">
                <IonImg className="logo" src="./assets/images/dummylogo.svg" />
                {/* <IonItem lines="none">
                  <IonLabel className="ion-text-center welcome">
                    <h1 className="no-margin">
                      <b>{t("starting.slogun")}</b>
                    </h1>
                  </IonLabel>
                </IonItem> */}
              </IonCol>
              <IonCol size="12" className="begin ion-text-center">
                <IonButton
                  onClick={() => setOpenModalLogin(true)}
                  color="primary"
                >
                  {t("starting.strt_btn_txt")}
                </IonButton>
                {/* <IonButton routerLink="/startinglogreg" color="primary">Begin</IonButton> */}
              </IonCol>
              <IonCol size="12" className="powered ion-text-center">
                {/* <p>{t("starting.powered_by")}</p> */}
                <IonImg src="./assets/images/logo_Blue_Horizontal.svg" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonContent>

      <IonModal
        isOpen={openModalLogin}
        onDidDismiss={() => setOpenModalLogin(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        className="mainjourney"
        // trigger="open-modal"
        // handleBehavior="cycle"
      >
        {/* <Drawer
        className="journey bottompopup skateInBottom bottompopupresult"
        isVisible={isVisible}
        onClose={onClose}
      > */}
        <IonRow className="joinpage">
          <IonGrid className="bg-box">
            <form noValidate onSubmit={login}>
              <IonGrid className="main-box">
                <h3>{t("login.sign_in")}</h3>
                <IonList className="userform">
                  <IonItem className="tooltiparea" color="white">
                    <IonLabel>
                      <IonText no-margin>{t("login.email")}</IonText>
                    </IonLabel>
                  </IonItem>
                  <IonItem className="inputwithicon twoicon pr-0">
                    <IonImg src="./assets/images/mailicon.svg" alt="" />
                    <IonInput
                      name="email"
                      type="text"
                      value={username}
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={(e) => [
                        setUsername(e.detail.value!),
                        liveCheckUsername(e.detail.value),
                      ]}
                      required
                    ></IonInput>
                  </IonItem>
                  {formSubmitted && usernameError && (
                    <IonText color="warning">
                      <p className="required">{t("login.urname_req_err")}</p>
                    </IonText>
                  )}
                  <IonItem className="tooltiparea" color="white">
                    <IonLabel>
                      <IonText no-margin>{t("login.psword")}</IonText>
                    </IonLabel>
                  </IonItem>
                  <IonItem className="inputwithicon iconwithinput twoicon">
                    <IonImg src="./assets/images/lock.svg" alt="" />
                    <IonInput
                      name="password"
                      type={isRevealPwd ? "text" : "password"}
                      value={password}
                      onIonChange={(e) => [
                        setPassword(e.detail.value!),
                        liveCheckPassword(e.detail.value),
                      ]}
                    ></IonInput>
                    <IonImg
                      onClick={() => togglePasswordVisiblity()}
                      src={
                        isRevealPwd
                          ? "/assets/images/showpassword.svg"
                          : "./assets/images/hidepassword.svg"
                      }
                      alt=""
                    />
                  </IonItem>
                  {formSubmitted && passwordError && (
                    <IonText color="warning">
                      <p className="required">{t("login.psword_req_err")}</p>
                    </IonText>
                  )}
                  {loginError && (
                    <IonText color="warning">
                      <p className="required">
                        Username or password is incorrect
                      </p>
                    </IonText>
                  )}
                </IonList>
                <IonRow>
                  <IonCol size="12">
                    <IonButton
                      color="white"
                      type="submit"
                      className="whitebtn"
                      expand="block"
                    >
                      {t("login.sign_in")}
                    </IonButton>
                  </IonCol>
                  <IonCol
                    size="12"
                    className="ion-text-center linktext"
                    onClick={open2Drawer}
                  >
                    <a color="light">{t("login.fgt_pass_link")}</a>
                    {/* <a href="/forgotpassemail" color="light">{t("login.fgt_pass_link")}</a> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonGrid>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>

      <IonModal
        isOpen={openModalFPEmail}
        onDidDismiss={() => setOpenModalFPEmail(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        className="mainjourney"
        // trigger="open-modal"
        // handleBehavior="cycle"
      >
        {/* <Drawer
        className="journey forgotpassemail bottompopup skateInBottom bottompopupresult"
        isVisible={is2DrawerVisible}
        onClose={onCloseForgotPass2}
      > */}
        <IonRow className="joinpage">
          <IonGrid className="bg-box">
            <form onSubmit={handleSubmit((data) => forgotemail(data))}>
              <IonGrid className="main-box">
                <h3>{t("forgotpassemail.fgt_pass")}</h3>
                <IonList className="userform">
                  <IonItem color="white" className="subtitle">
                    <IonText className="nowrap" no-margin>
                      {t("forgotpassemail.enter_registered_email")}
                    </IonText>
                  </IonItem>
                  <IonCol>
                    <IonItem className="tooltiparea forminput" color="white">
                      <IonLabel>
                        <IonText no-margin>
                          {t("forgotpassemail.email")}
                        </IonText>
                      </IonLabel>
                    </IonItem>
                    <div className="iconwithinput pr-0">
                      {/* <IonIcon icon={mail} /> */}
                      <IonImg src="./assets/images/mailicon.svg" alt="" />
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            required
                          />
                        )}
                        control={control}
                        name="email"
                        rules={{
                          required: "This is a required field",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: t("forgotpassemail.invalid_email_msg"),
                          },
                        }}
                      />
                    </div>
                  </IonCol>
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    as={<div className="inputerror" />}
                  />
                </IonList>

                <IonRow>
                  <IonCol size="12">
                    <IonButton
                      color="white"
                      type="submit"
                      className="whitebtn"
                      expand="block"
                      // onClick={open3Drawer}
                    >
                      {t("forgotpassemail.send")}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
            <div className="fixpagination">
              <div className="swiper-pagination swiper-pagination-bullets">
                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                <span className="swiper-pagination-bullet"></span>
                <span className="swiper-pagination-bullet"></span>
              </div>
            </div>
          </IonGrid>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>

      <IonModal
        isOpen={openModalVerifyOTP}
        onDidDismiss={() => setOpenModalVerifyOTP(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        className="mainjourney"
        // trigger="open-modal"
        // handleBehavior="cycle"
      >
        {/* <Drawer
        className="journey bottompopup skateInBottom bottompopupresult"
        isVisible={is3DrawerVisible}
        onClose={onCloseForgotPass3}
      > */}
        <IonRow className="joinpage">
          <IonGrid className="bg-box">
            <form>
              <IonGrid className="main-box">
                <h3>{t("forgotpassverify.verfi_code")}</h3>
                <IonList className="userform verification">
                  <IonItem color="white" className="subtitle">
                    <IonText className="nowrap" no-margin>
                      {t("forgotpassverify.enter_verfi_code")}
                      <br />{" "}
                      <b>
                        (
                        <Countdown
                          autoStart={true}
                          daysInHours={true}
                          zeroPadTime={1}
                          date={Date.now() + 60000}
                          renderer={({ minutes, seconds }) => (
                            <span>
                              {minutes.toString().padStart(2, "0")}:
                              {seconds.toString().padStart(2, "0")}
                            </span>
                          )}
                        >
                          <Completionist />
                        </Countdown>
                        )
                      </b>
                    </IonText>
                  </IonItem>

                  <IonItem className="inputwithicon twoicon otp">
                    <OtpInput
                      value={otp}
                      onChange={onChanged}
                      isInputNum={false}
                      className={"test"}
                      shouldAutoFocus={true}
                      /*focusStyle={{ background: 'white' }}*/ numInputs={6}
                    />
                  </IonItem>
                </IonList>

                <IonRow>
                  <IonCol
                    size="12"
                    className="ion-text-center linktext resend"
                    // onClick={open4Drawer}
                  >
                    <a color="light">
                      {t("forgotpassverify.receive_message")}
                      <br />
                      {t("forgotpassverify.resend")}
                    </a>
                    {/* <IonButton color="white" routerLink="/forgotpassresetpass" className='whitebtn' expand='block'>RESEND</IonButton> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
            <div className="fixpagination">
              <div className="swiper-pagination swiper-pagination-bullets">
                <span className="swiper-pagination-bullet"></span>
                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                <span className="swiper-pagination-bullet"></span>
              </div>
            </div>
          </IonGrid>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>

      <IonModal
        isOpen={openModalNewPass}
        onDidDismiss={() => setOpenModalNewPass(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        className="mainjourney resetpassword"
        // trigger="open-modal"
        // handleBehavior="cycle"
      >
        {/* <Drawer
        className="journey resetpassword bottompopup skateInBottom bottompopupresult"
        isVisible={is4DrawerVisible}
        onClose={onCloseForgotPass4}
      > */}
        <IonRow className="joinpage">
          <IonGrid className="bg-box">
            <form onSubmit={handleSubmit((data) => resetpassword(data))}>
              <IonGrid className="main-box">
                <h3 className="resetspace">
                  {t("forgotpassresetpass.reset_password")}
                </h3>
                <IonList className="userform">
                  <IonItem color="white" className="subtitle resetspace">
                    <IonText className="nowrap" no-margin>
                      {t("forgotpassresetpass.enter_new_password")}
                    </IonText>
                  </IonItem>
                  <IonCol>
                    <IonItem
                      className="inputwithicon tooltiparea forminput warning"
                      color="white"
                    >
                      <IonImg src="./assets/images/warning-white.svg" alt="" />
                      <IonLabel>
                        <IonText no-margin>
                          {t("forgotpassresetpass.new_password")} {isRevealPwd}
                        </IonText>
                      </IonLabel>
                      <div className="labeltitle">
                        {/* <IonIcon icon={informationCircleOutline} /> */}
                        <IonImg
                          src="./assets/images/bang-in-circle.png"
                          alt=""
                        />
                        <div className="tooltip">
                          {t("forgotpassresetpass.password_tooltip")}
                        </div>
                      </div>
                    </IonItem>
                    <div className="iconwithinput">
                      {/* <IonIcon icon={lockClosed} /> */}
                      <IonImg src="./assets/images/lock.svg" alt="" />
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            type={isRevealPwd ? "text" : "password"}
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                        control={control}
                        name="pwd"
                        rules={{
                          required: t("forgotpassresetpass.required"),
                        }}
                      />
                      <IonImg
                        onClick={() => togglePasswordVisiblitySet()}
                        src={
                          isRevealPwd
                            ? "/assets/images/showpassword.svg"
                            : "./assets/images/hidepassword.svg"
                        }
                        alt=""
                      />
                      {/* <IonIcon onClick={() => togglePasswordVisiblity()} icon={isRevealPwd ? eye : eyeOffOutline} /> */}
                    </div>
                  </IonCol>
                  <ErrorMessage
                    errors={errors}
                    name="pwd"
                    as={<div className="inputerror" />}
                  />
                  <IonCol>
                    <IonItem
                      className="inputwithicon tooltiparea forminput warning"
                      color="white"
                    >
                      <IonImg src="./assets/images/warning-white.svg" alt="" />
                      <IonLabel>
                        <IonText className="nowrap" no-margin>
                          {t("forgotpassresetpass.confirm_new_password")}
                        </IonText>
                      </IonLabel>
                    </IonItem>
                    <div className="iconwithinput">
                      {/* <IonIcon icon={lockClosed} /> */}
                      <IonImg src="./assets/images/lock.svg" alt="" />
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            type={isRevealPwdconf ? "text" : "password"}
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                        control={control}
                        name="confpwd"
                        rules={{
                          required: t("forgotpassresetpass.required"),
                        }}
                      />
                      <IonImg
                        onClick={() => togglePasswordVisiblityconfig()}
                        src={
                          isRevealPwdconf
                            ? "/assets/images/showpassword.svg"
                            : "./assets/images/hidepassword.svg"
                        }
                        alt=""
                      />
                      {/* <IonIcon onClick={() => togglePasswordVisiblityconf()} icon={isRevealPwdconf ? eye : eyeOffOutline} /> */}
                    </div>
                  </IonCol>
                  <ErrorMessage
                    errors={errors}
                    name="confpwd"
                    as={<div className="inputerror" />}
                  />
                </IonList>

                <IonRow className="resetspace">
                  <IonCol size="12">
                    <IonButton
                      color="white"
                      type="submit"
                      className="whitebtn"
                      expand="block"
                    >
                      {t("forgotpassresetpass.reset")}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
            <div className="fixpagination">
              <div className="swiper-pagination swiper-pagination-bullets">
                <span className="swiper-pagination-bullet"></span>
                <span className="swiper-pagination-bullet"></span>
                <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
              </div>
            </div>
          </IonGrid>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>
    </IonPage>
  );
};

export default React.memo(Starting);
