import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonToast,
  IonSlides,
  IonSlide,
} from "@ionic/react";
import "./Practicequestion.scss";
import "./E6s.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import CustomAudioPlayer from "./CustomAudioPlayer";
interface MyObject {
  id: number;
  name: string;
  type: "e6s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E6sProps {
  itemE6s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}
interface MergedListElement {
  ch: any;
  sc: any;
  py: any;
}
const E6s: React.FC<E6sProps> = (props: E6sProps) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [presenttost] = useIonToast();
  const [isDraggable, setIsDraggable] = useState(false);
  const [showResults, setShowResults] = React.useState(0);
  const [FinalScore, setFinalScore] = useState<any>(0);
  const [length, setLength] = React.useState(-1);
  const [index, setIndex] = React.useState(0);
  //CompareSentences
  const [showImage, setShowImage] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  const [answers, setAnswers] = React.useState<any[]>([]);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  // DnD Start
  const dataTxt = props.itemE6s[showResults].WordCh;
  const dataPy = props.itemE6s[showResults].WordPy;
  const initialList = dataTxt ? dataTxt.split("") : [];
  const iniPinyinList = dataPy ? dataPy.split(" ") : [];
  const listResult = [];
  const JSONdata: any = localStorage.getItem("answers");
  const data = JSON.parse(JSONdata);
  const dataArray = data?.E6s;
  // State for merged lists
  const [arrayList1, setArrayList1] = useState<MergedListElement[]>([]);
  const [arrayList2, setArrayList2] = useState<MergedListElement[]>([]);
  for (let i = 0; i < props.itemE6s.length; i++) {
    const element = props.itemE6s[i];
    const initialListCh = props.itemE6s[i].WordCh
      ? props.itemE6s[i].WordCh.split("")
      : [];
    const initialListSc = props.itemE6s[i].WordSc
      ? props.itemE6s[i].WordSc.split("")
      : [];
    const iniPinyinList2 = props.itemE6s[i].WordPy
      ? props.itemE6s[i].WordPy.split(" ")
      : [];
    let mergedArray = initialListCh.map((element, index) => ({
      value1: element,
      value2: initialListSc[index],
      value3: iniPinyinList2[index],
    })); //Merge three arrays
    let myArray_chaines = mergedArray;
    myArray_chaines.sort(randomSort);

    let array1 = myArray_chaines.map((obj) => obj.value1);
    let array2 = myArray_chaines.map((obj) => obj.value2);
    let array3 = myArray_chaines.map((obj) => obj.value3);
    element["listCh"] = array1;
    element["listSc"] = array2;
    element["listPy"] = array3;
  }
  function randomSort() {
    return 0.5 - Math.random();
  }
  // Function to merge Ch and Sc lists
  const mergeLists = (chList: any[], scList: any[], pinyinList: any[]) => {
    const mergedList = chList?.map((chItem, index) => ({
      ch: chItem,
      sc: scList[index],
      py: pinyinList[index],
    }));
    return mergedList;
  };

  useEffect(() => {
    // Perform null checks before merging lists
    const chList1 = props.itemE6s[showResults]?.listCh;
    const scList1 = props.itemE6s[showResults]?.listSc;
    const pinyinList1 = props.itemE6s[showResults]?.listPy;
    if (chList1 && scList1 && pinyinList1) {
      const mergedList1 =
        showResults !== answers[showResults]?.originalIndex
          ? mergeLists(chList1, scList1, pinyinList1)
          : dataArray[showResults]?.arrayList1;
      setArrayList1(mergedList1);
    }
    const chList2 = [];
    const scList2 = [];
    const pinyinList2 = [];
    if (chList2 && scList2 && pinyinList2) {
      const mergedList2 =
        showResults !== answers[showResults]?.originalIndex
          ? mergeLists(chList2, scList2, pinyinList2)
          : dataArray[showResults]?.arrayList2;
      setArrayList2(mergedList2);
    }
  }, [props.itemE6s, showResults]); // Adding dependencies to the useEffect

  const [resultList, setResultList] = useState<any>(listResult);
  // DnD end
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let finalanswer = {};
  let isMatchingSlide: boolean;
  useIonViewWillEnter(() => {
    setLength(1);
    if (dataArray) {
      setAnswers(dataArray);
    }
  });
  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e6s",
      pagecouter: pagecouter,
      totalque: props.itemE6s.length,
      back: back,
    };
    props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      setIndex(currentIndex);
      setShowResults(currentIndex);
      if (swipeDirection === "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        if (currentIndex === 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex === -1) {
          handleChange(1);
        }
        if (index === props.itemE6s.length - 1) {
          const originalIndex = answers.length;
          const isNewData =
            answers.findIndex(
              (entry) => entry.originalIndex === originalIndex
            ) === -1;
          if (isNewData) {
            const lastData = {
              arrayList1,
              arrayList2,
              originalIndex,
            };
            setAnswers((oldData) => [...oldData, lastData]);
          }
        }
      } else {
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          SaveExerciseQueAnswer(length);
          // Add this block to update answers with the last data
          let originalIndex = index - 1;
          const isNewData =
            answers.findIndex(
              (entry) => entry.originalIndex === originalIndex
            ) === -1;
          if (isNewData) {
            const lastData = {
              arrayList1,
              arrayList2,
              originalIndex,
            };
            setAnswers((oldData) => [...oldData, lastData]);
          }
          if (index + 1 === length) {
            saveContent();
            handleChange(0);
            setLength(-1);
          }
        });
      }
    }
  };
  const SaveExerciseQueAnswer = async (indexdb) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // score start
    const totalLength = resultList.length;
    const eachCharVal = 100 / totalLength;
    const status = "true";
    const filtrdResult = resultList.filter((item) =>
      status.includes(item.status)
    );
    const correctLength = filtrdResult.length;
    const totalScore = correctLength * eachCharVal;
    const finalScore = parseFloat(totalScore.toFixed(0));
    // score end
    const namesArray = resultList.map((obj) => obj.lText);
    const namesString = namesArray.join(""); // Modify the separator as per your requirement
    const data = {
      eId: props.itemE6s[ansIndex].EId,
      eType: props.itemE6s[ansIndex].TemplateCode,
      answer: namesString,
      rearrageAnswer: JSON.stringify(resultList), //only for rearrange exercise otherwise blank
      writingScore: finalScore,
    };
    let final = FinalScore + finalScore;
    localStorage.setItem("sce6", final);
    setFinalScore(final);
    props.itemE6s[ansIndex]["answer"] = namesString;
    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let anw = {};
    pagecouter = 0;
    for (let index = 0; index < props.itemE6s.length; index++) {
      const element = props.itemE6s[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;
      anw[propName] = propValue;
      finalanswer = anw;
    }
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    const myArray = Object.entries(finalanswer);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }
    let finalsc = localStorage.getItem("sce6");
    const data = {
      contentPageCode: props.itemE6s[0].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE6s[0].BookId,
      contentPageId: props.itemE6s[0].PageId,
      answer: stringWithoutBackslashes,
      score: finalsc,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      localStorage.setItem("sce6", "0");
      //dismiss()
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  // Merge drag end function
  const onDragEnd = (result) => {
    setIsDraggable(true);
    if (!result.destination) {
      return;
    }
    if (
      result.source.droppableId === "arrayList1" &&
      result.destination.droppableId === "arrayList1"
    ) {
      console.error("Draggable is not possible in same array");
    } else if (
      result.source.droppableId === "arrayList2" &&
      result.destination.droppableId === "arrayList2"
    ) {
      console.error("Draggable is not possible in same array");
    } else {
      if (initialList.length !== arrayList2.length) {
        const sourceList =
          result.source.droppableId === "arrayList1" ? arrayList1 : arrayList2;
        const destList =
          result.destination.droppableId === "arrayList1"
            ? arrayList1
            : arrayList2;
        const updatedSourceList = [...sourceList];
        const [removed] = updatedSourceList.splice(result.source.index, 1);
        const updatedDestList = [...destList];
        updatedDestList.splice(result.destination.index, 0, removed);
        if (result.source.droppableId === "arrayList1") {
          setArrayList1(updatedSourceList);
          setArrayList2(updatedDestList);
        } else {
          setArrayList2(updatedSourceList);
          setArrayList1(updatedDestList);
        }
      }
    }
  };
  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);
  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("answers");
    const data = JSON.parse(JSONdata);
    const formattedData = {
      E1s: data?.E1s,
      E2s: data?.E2s,
      E3s: data?.E3s,
      E4s: data?.E4s,
      E5s: data?.E5s,
      E6s: answers,
    };
    localStorage.setItem("answers", JSON.stringify(formattedData));
  };
  useEffect(() => {
    listCheck();
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [arrayList2]);
  const listCheck = () => {
    if (arrayList2 != undefined) {
      const result = initialList?.map((text, i) => ({
        Text: text,

        lText:
          arrayList2 !== null ? arrayList2[i]?.ch || arrayList2[i]?.sc : text,
        piniyin: arrayList2 !== null ? arrayList2[i]?.py : iniPinyinList[i],
        status: iniPinyinList[i] === arrayList2[i]?.py ? true : false,
      }));
      setResultList(result);
    }
  };
  const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };
  // drag and drop end
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1
                ? props.itemE6s.length - 1
                : activeSlideIndex,
            options,
            slideOpts,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          {props.itemE6s.map((items, index) => {
            // Identify the original index of the slide in the local storage answers array
            const data = answers?.find(
              (entry) => entry.originalIndex === index
            );
            // Check if the current slide index matches the original index in local storage
            isMatchingSlide =
              data?.originalIndex !== undefined &&
              data?.originalIndex === index;
            return (
              <IonSlide key={index} placeholder={undefined}>
                <div
                  key={index}
                  style={{
                    display: showResults == index ? "block" : "none",
                  }}
                  className="audiorecord"
                >
                  <IonList className="ion-no-padding" placeholder={undefined}>
                    <IonRow
                      className="Practice spacing ion-padding-horizontal"
                      placeholder={undefined}
                    >
                      <IonCol placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          {t("e6s.listen_to_audio")}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="12"
                        className="test-audio ion-text-left whitebg"
                        placeholder={undefined}
                      >
                        <div className="customaudioplayer">
                          <CustomAudioPlayer src={items?.AudioUrl} />
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow
                      className="answer spacing droppablefield ion-padding-horizontal swiper-no-swiping"
                      placeholder={undefined}
                    >
                      <IonCol size="12" placeholder={undefined}>
                        {isMatchingSlide ? (
                          <>
                            <div>
                              {arrayList2?.map((item, index) => (
                                <div
                                  className="droppableProps"
                                  style={{
                                    border:
                                      initialList?.length === arrayList2?.length
                                        ? resultList[index]?.status
                                          ? "2px solid var(--ion-color-success)"
                                          : "2px solid var(--ion-color-danger)"
                                        : "2px solid var(--ion-color-medium-rgb)",
                                  }}
                                >
                                  {props.cheChar &&
                                    (props.isTranLangSim ? item.sc : item.ch)}
                                  <br />
                                  {props.piniyin && item.py}
                                </div>
                              ))}
                              <div className="dragcotext">
                                {arrayList1?.map((item, index) => (
                                  <div
                                    className="droppableProps"
                                    style={{
                                      border:
                                        "2px solid var(--ion-color-medium-rgb)",
                                    }}
                                  >
                                    {props.cheChar &&
                                      (props.isTranLangSim ? item.sc : item.ch)}
                                    <br />
                                    {props.piniyin && item.py}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          <DragDropContext
                            // onIonDrag={(e) => e.preventDefault()}
                            onDragEnd={onDragEnd}
                          >
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                                overflow: "scroll",
                              }}
                            >
                              <Droppable
                                droppableId="arrayList2"
                                direction="horizontal"
                              >
                                {(provided) => (
                                  <div
                                    className="answer single Practiceresultanswer borderbottom"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    {arrayList2?.map((item, index) => (
                                      <Draggable
                                        key={index}
                                        draggableId={`arrayList2-${index}`}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <div
                                            className="droppableProps"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              border:
                                                initialList?.length ===
                                                arrayList2?.length
                                                  ? resultList[index]?.status
                                                    ? "2px solid var(--ion-color-success)"
                                                    : "2px solid var(--ion-color-danger)"
                                                  : "2px solid var(--ion-color-medium-rgb)",
                                              ...provided.draggableProps.style,
                                            }}
                                          >
                                            {props.cheChar &&
                                              (props.isTranLangSim
                                                ? item.sc
                                                : item.ch)}
                                            <br />
                                            {props.piniyin && item.py}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                              <div className="dragcotext">
                                <Droppable
                                  droppableId="arrayList1"
                                  direction="horizontal"
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {arrayList1?.map((item, index) => (
                                        <Draggable
                                          key={index}
                                          draggableId={`arrayList1-${index}`}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              className="droppableProps"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              {props.cheChar &&
                                                (props.isTranLangSim
                                                  ? item.sc
                                                  : item.ch)}
                                              <br />
                                              {props.piniyin && item.py}
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </div>
                          </DragDropContext>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonList>
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(E6s);
