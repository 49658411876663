import React, { useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonImg,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonCardHeader,
  IonRow,
} from "@ionic/react";
import "./Progress.scss";
import {
  arrowForwardCircleOutline,
  close,
  lockClosedOutline,
} from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
// import { RouteComponentProps, useHistory, useLocation } from "react-router";

// interface OwnProps extends RouteComponentProps {
//   id: string;
// }
interface OwnProps extends RouteComponentProps {
  id: string;
}

interface ProgressProps extends OwnProps {}

const Progress: React.FC<ProgressProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live

  const { t } = useTranslation();

  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitemsprog] = React.useState<any>([]);
  const [itemlevellist, setitemslevel] = React.useState<any>([]);
  const [itemlistremcount, setitemsRemCount] = React.useState<any>([]);
  const [activeText, setActiveText] = useState("On Going");
  const [itemlogin, setLogindata] = React.useState<any>({});
  const [itemDetailReport, setDetailReport] = React.useState<any>({});
  const [Reading, setReading] = React.useState<any>({});
  const [Writing, setWriting] = React.useState<any>({});
  const [Listening, setListening] = React.useState<any>({});
  const [Speaking, setSpeaking] = React.useState<any>({});

  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    index: string;
  }>();

  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";

  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  // setthemeIddb(0)
  // setthemeIddb(themeIddb)
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";

  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";

  const index = historyss.location.state ? historyss.location.state.index : "";

  let token = "";
  let resfreshtoken = "";
  let userdata: any;
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    GetMyLessons();
    GetRemainBooksCount();
    getUserLevel();
    GetDetailReport();
    let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    //userdata = logindata.Result
    setLogindata(logindata.Result);
  });
  // const handleClick = (text) => {
  //   setActiveText(text);
  // };
  const savebookmark = (val, index, type, value) => {
    setitemsprog((prevItemList) => {
      const newItemList = { ...prevItemList };

      if (value === 1) {
        const item = newItemList.Ongoings[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);

        if (item.IsFavourite) {
          // If bookmarked, move from Ongoings to Bookmarkeds
          newItemList.Bookmarkeds.push(item);
          // newItemList.Ongoings.splice(index, 1);
        } else {
          // Remove the item from Bookmarkeds if it exists
          if (type === 1) {
            const bookmarkIndex = newItemList.Bookmarkeds.findIndex(
              (bookmarkItem) => bookmarkItem.BookId === item.BookId
            );
            if (bookmarkIndex !== -1) {
              newItemList.Bookmarkeds.splice(bookmarkIndex, 1);
            }
          } else if (type === 2) {
            const bookmarkIndex = newItemList.Bookmarkeds.findIndex(
              (bookmarkItem) => bookmarkItem.DialogueId === item.DialogueId
            );
            if (bookmarkIndex !== -1) {
              newItemList.Bookmarkeds.splice(bookmarkIndex, 1);
            }
          }
        }
      } else if (value === 2) {
        const item = newItemList.Completeds[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);

        if (item.IsFavourite) {
          // If bookmarked, move from Completeds to Bookmarkeds
          newItemList.Bookmarkeds.push(item);
          // newItemList.Completeds.splice(index, 1);
        } else {
          // Remove the item from Bookmarkeds if it exists
          if (type === 1) {
            const bookmarkIndex = newItemList.Bookmarkeds.findIndex(
              (bookmarkItem) => bookmarkItem.BookId === item.BookId
            );
            if (bookmarkIndex !== -1) {
              newItemList.Bookmarkeds.splice(bookmarkIndex, 1);
            }
          } else if (type === 2) {
            const bookmarkIndex = newItemList.Bookmarkeds.findIndex(
              (bookmarkItem) => bookmarkItem.DialogueId === item.DialogueId
            );
            if (bookmarkIndex !== -1) {
              newItemList.Bookmarkeds.splice(bookmarkIndex, 1);
            }
          }
        }
      } else if (value === 3) {
        const item = newItemList.Bookmarkeds[index];
        item.IsFavourite = !item.IsFavourite;
        saveContent(val, item.IsFavourite, type);

        if (!item.IsFavourite) {
          newItemList.Bookmarkeds.splice(index, 1);
        }
      }

      return newItemList;
    });
  };

  const saveContent = async (val, isFavourite, type) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const data = {
      referenceId: type == 1 ? val.BookId : val.DialogueId,
      isFavourite: isFavourite,
      typeId: type,
    };
    //dismiss()

    try {
      const response = await fetch(url + "/User/Favourite", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  const gotoPractice = (val, index) => {
    if (val.IsExistExercise == 1) {
      history.replace({
        pathname: "/tabs/progress/Practicequestion",
        state: {
          themeArray: val,
          themeId: themeIddb,
          lessonname: val.Title,
          index: index,
          lessonsId: val.BookGuid,
          themename: themename,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: "progress",
        },
      });
      // history.go(0);
      // window.location.reload();
    } else {
      presenttost({
        message: "Exercise not available",
        duration: 1500,
        position: "bottom",
      });
    }
  };

  const getUserLevel = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      // if (!response.ok) {
      //   throw Error(response.statusText)
      // }
      const json = await response.json();

      dismiss();
      if (json.StatusCode === 1) {
        setitemslevel(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const GetMyLessons = async () => {
    try {
      const response = await fetch(url + "/Book/GetMyLessons", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode === 1) {
        // setitemsprog(json.Result);

        // Assuming the Books and Dialogues data is available
        const booksData = json.Result.Books;
        const dialoguesData = json.Result.Dialogues;

        const mergeArrays = (array1, array2) => {
          const maxLength = Math.max(array1.length, array2.length);
          const mergedArray: any[] = [];

          for (let i = 0; i < maxLength; i++) {
            // Your logic here
            if (array1[i]) {
              array1[i]["Type"] = "Books";
              array1[i]["Number"] = i + 1;
              mergedArray.push(array1[i]);
            }
            if (array2[i]) {
              array2[i]["Type"] = "Dialogues";
              array2[i]["Number"] = i + 1;
              mergedArray.push(array2[i]);
            }
          }

          return mergedArray;
        };

        const mergedOngoings = mergeArrays(
          booksData.Ongoings,
          dialoguesData.Ongoings
        );
        const mergedCompleteds = mergeArrays(
          booksData.Completeds,
          dialoguesData.Completeds
        );
        const mergedBookmarkeds = mergeArrays(
          booksData.Bookmarkeds,
          dialoguesData.Bookmarkeds
        );

        const mergedData = {
          Ongoings: mergedOngoings,
          Completeds: mergedCompleteds,
          Bookmarkeds: mergedBookmarkeds,
        };
        setitemsprog(mergedData);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const GetRemainBooksCount = async () => {
    // present({
    //   message: "",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/Book/GetRemainBooksCount", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode === 1) {
        setitemsRemCount(json.Result.RemainBooksCount);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetDetailReport = async () => {
    // present({
    //   message: "",
    //   cssClass: "custom-spinner",
    //   duration: 1000,
    // });

    try {
      const response = await fetch(url + "/Book/GetDetailReport", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });

      const json = await response.json();

      dismiss();
      if (json.StatusCode === 1) {
        setDetailReport(json.Result);
        setReading(json.Result.Reading || 0);
        setWriting(json.Result.Writing || 0);
        setListening(json.Result.Listening || 0);
        setSpeaking(json.Result.Speaking || 0);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();

      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }

      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body levelalert">
            <div className="close">
              <IonIcon onClick={onClose} icon={close} />
            </div>
            <div className="imgview">
              {itemlevellist.Level === 1 && (
                <IonImg
                  src="./assets/images/shoeprint.svg"
                  placeholder={undefined}
                />
              )}
              {itemlevellist.Level === 2 && (
                <IonImg
                  src="./assets/images/bicycle.svg"
                  placeholder={undefined}
                />
              )}
              {itemlevellist.Level === 3 && (
                <IonImg src="./assets/images/car.svg" placeholder={undefined} />
              )}
              {itemlevellist.Level === 4 && (
                <IonImg
                  src="./assets/images/plane.svg"
                  placeholder={undefined}
                />
              )}
              {itemlevellist.Level === 5 && (
                <IonImg
                  src="./assets/images/rocet.svg"
                  placeholder={undefined}
                />
              )}
            </div>
            <p>
              {t("progress.submit.level")} {itemlevellist.Level}:{" "}
              {itemlevellist.Name}
            </p>
            <p className="subtext">{itemlevellist.Description}</p>
          </div>
        );
      },
    });
  };

  const gonextPage = (val, index) => {
    //history.replace('/tabs/mainhome/themesports/5');
    history.replace({
      pathname: "/tabs/progress/lesson",
      state: {
        themeArray: val,
        themeId: val.ThemeGuid,
        lessonname: val.Title,
        index: index,
        lessonsId: val.BookGuid,
        bookId: val.Bookid,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        backpage: "progress",
        backpageValue: 2,
      },
    });
  };

  const goDialoguePage = (items, index) => {
    const stringSpac = items?.Types; // Your string with spaces
    const stringWtot = stringSpac.replace(/\s/g, ""); // Remove spaces from the string
    history.replace({
      pathname: "/tabs/progress/dialoguevideo",
      state: {
        themeArray: items,
        themeId: themeIddb,
        index: index,
        dialogueId: items.DialogueId,
        title: items.ContentEn,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        videoUrl: items.VideoUrl,
        role: stringWtot,
        backpage: "progress",
        backpageValue: 2,
      },
    });
  };

  const submitDialogue = (item, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body rolebox">
            <p className="mt0">{t("themesports.submit2.pick_role")}</p>
            <div className="react-confirm-alert-button-group">
              <div className="boxwidth">
                <div
                  onClick={() => {
                    goDialoguepractice(item, 0, index);
                    onClose();
                  }}
                  className="usercircle"
                >
                  Crew
                </div>
                <p>Staff</p>
              </div>
              <div className="boxwidth">
                <div
                  onClick={() => {
                    goDialoguepractice(item, 1, index);
                    onClose();
                  }}
                  className="usercircle"
                >
                  Pax
                </div>
                <p>Guest</p>
              </div>
            </div>
          </div>
        );
      },
    });
  };

  const goDialoguepractice = (item, value, index) => {
    const str = item.Types;
    const trimmedStr = str.replace(/\s/g, "");
    let string = trimmedStr;
    let one = "";
    let two = "";
    if (string) {
      let result = string.split(",");
      one = result[0];
      two = result[1];
    }

    history.replace({
      pathname: "/tabs/progress/dialoguepractice",
      state: {
        themeArray: itemlist,
        index: index,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: item.DialogueId,
        role: value,
        rolename: value === 0 ? one : two,
        allrole: trimmedStr,
        backpage: "progress",
        backpageValue: 2,
      },
    });
  };

  const selectedElementRef = useRef<any>(null);
  const handleClick = (text) => {
    setActiveText(text);
    if (selectedElementRef.current) {
      selectedElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const routeDetailedReports = () => {
    // href="/tabs/progress/detailedreports"
    history.replace({
      pathname: "/tabs/progress/detailedreports",
      state: {
        backpage: "progress",
        backpageValue: 2,
      },
    });
  };

  return (
    <IonPage className="intro" id="progress-page">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonTitle color="primary" className="font20 ion-no-padding">
            <span>{t("progress.progress")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="progresslist">
        <div className="ion-padding-horizontal">
          <IonList
            className="themes ion-no-padding mylessons ion-margin-top"
            style={{ marginTop: "0px" }}
          >
            <IonCol>
              <IonText>
                {itemlogin?.UserName}, {t("progress.cur_lvl_txt")}
              </IonText>
            </IonCol>
          </IonList>

          <IonList className="PadT0 reports">
            <IonCard className="adjectcard progresscard">
              <IonCardContent class="ion-no-padding">
                {/* <CircularProgressbar
                  strokeWidth={10}
                  text={itemlevellist.Level}
                  value={50}
                  styles={{
                    background: {
                      fill: "var(--ion-color-primary-tint)",
                      textAlign: "center",
                    },
                    path: { stroke: "var(--ion-color-primary)" },
                    trail: { stroke: "var(--ion-color-primary-tint)" },
                    text: {
                      fill: "var(--ion-color-primary)",
                      textAlign: "center",
                      position: "absolute",
                      verticalAlign: "middle",
                      lineHeight: "50",
                      fontSize: "52px",
                    },
                  }}
                /> */}
                <div className="name">
                  <h5>{itemlevellist.Name}</h5>
                  <span>
                    {itemlistremcount} {t("progress.more_lsns")}{" "}
                  </span>
                </div>
                {/* <IonIcon onClick={submit} color='primary' icon={informationCircleOutline} /> */}
                <IonImg
                  className="info"
                  onClick={submit}
                  src="./assets/images/bang-circle-blue.svg"
                  alt=""
                />
              </IonCardContent>
            </IonCard>
          </IonList>

          <IonList className="themes ion-no-padding mylessons ion-margin-top">
            <IonCol>
              <IonText>{t("progress.re_over")}</IonText>
            </IonCol>
          </IonList>

          <IonList className="PadT0 progresscard reports">
            {/* <IonCard className="adjectcard">
              <IonCardContent class="ion-no-padding alignment">
                <div className="lessons_score donut-chart-Number">204</div>
                <div className="name">
                  <h5>{t("progress.vocb_lrnt")}</h5>
                  <span>{t("progress.sub_txt")}</span>
                </div>
                <IonImg src="./assets/images/rightarrow.png" alt="" />
                <IonIcon color='primary' icon={arrowForwardCircleOutline} />
              </IonCardContent>
            </IonCard> */}

            <IonCard className="highlightarea">
              <IonCardHeader>
                <IonRow className="progress-details">
                  <IonCol size="5" className="ion-text-center">
                    {/* <img width="170" src="./assets/images/progress-round.svg" /> */}
                    <div className="progress-bar-1">
                      <CircularProgressbar
                        strokeWidth={8}
                        value={Reading}
                        counterClockwise
                        styles={{
                          background: {
                            fill: "var(--ion-color-warning)",
                            textAlign: "center",
                          },
                          path: { stroke: "var(--ion-color-warning)" },
                          trail: { stroke: "#fff9d4" },
                        }}
                      />
                    </div>

                    <div className="progress-bar-2">
                      <CircularProgressbar
                        strokeWidth={10}
                        value={Writing}
                        counterClockwise
                        styles={{
                          background: {
                            fill: "var(--ion-color-success)",
                            textAlign: "center",
                          },
                          path: { stroke: "var(--ion-color-success)" },
                          trail: { stroke: "#e8f9e0" },
                        }}
                      />
                    </div>

                    <div className="progress-bar-3">
                      <CircularProgressbar
                        strokeWidth={12}
                        value={Listening}
                        counterClockwise
                        styles={{
                          background: { fill: "#49bdee", textAlign: "center" },
                          path: { stroke: "#49bdee" },
                          trail: { stroke: "#dbf2fc" },
                        }}
                      />
                    </div>

                    <div className="progress-bar-4">
                      <CircularProgressbar
                        strokeWidth={14}
                        value={Speaking}
                        counterClockwise
                        styles={{
                          background: { fill: "#b9b9b9", textAlign: "center" },
                          path: { stroke: "#b9b9b9" },
                          trail: { stroke: "#f2f2f2" },
                        }}
                      />
                    </div>
                  </IonCol>
                  <IonCol size="7">
                    <ul>
                      <li>
                        <img src="./assets/images/colorReader.svg" />{" "}
                        {t("progress.prgrs_dtil.reading")}
                      </li>
                      <li>
                        <img src="./assets/images/listening.svg" />{" "}
                        {t("progress.prgrs_dtil.listening")}
                      </li>
                      <li>
                        <img src="./assets/images/speaking.svg" />{" "}
                        {t("progress.prgrs_dtil.speaking")}
                      </li>
                      <li className="graycolor">
                        <img src="./assets/images/colorWriting.svg" />{" "}
                        {t("progress.prgrs_dtil.writing")}
                      </li>
                    </ul>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardContent class="ion-no-padding">
                <IonRow>
                  <IonCol size="12" className="bank reports">
                    <span onClick={routeDetailedReports}>
                      <div>
                        <img alt="Bank" src="./assets/images/progress.svg" />
                        <h5>{t("progress.dtil_rprt")}</h5>
                      </div>
                      <IonIcon
                        color="primary"
                        icon={arrowForwardCircleOutline}
                      />
                    </span>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>

            {/* <IonCard className="bank">
              <IonCardContent class="ion-no-padding">
                <a href="/tabs/progress/vocabularybank">
                  <IonIcon color='primary' icon={home} />
                  <img alt="Bank" src="./assets/images/bank.svg" />
                  <h5>{t("progress.vocb_bnk")}</h5>
                </a>
              </IonCardContent>
            </IonCard> */}
          </IonList>

          <IonList className="themes reports mylessons">
            <IonCol>
              <IonText>{t("progress.my_lsns")}</IonText>
            </IonCol>
          </IonList>
        </div>

        <IonList className="themes reports mylessons nopadding">
          <IonCol className="data-selection">
            {/* <IonText
              className={activeText === "On Going" ? "select active" : ""}
              onClick={() => handleClick("On Going")}
            >
              {t("progress.on_going")}
            </IonText>
            <IonText
              className={activeText === "Completed" ? "select active" : ""}
              onClick={() => handleClick("Completed")}
            >
              {t("progress.completed")}
            </IonText>
            <IonText
              className={activeText === "Bookmarked" ? "select active" : ""}
              onClick={() => handleClick("Bookmarked")}
            >
              {t("progress.bookmarked")}
            </IonText> */}

            <IonText
              ref={(element) => {
                if (element) {
                  element.addEventListener("click", () =>
                    handleClick("On Going")
                  );
                }
                if (activeText === "On Going" && element) {
                  selectedElementRef.current = element;
                }
              }}
              className={activeText === "On Going" ? "select active" : ""}
            >
              {t("progress.on_going")}
            </IonText>
            <IonText
              ref={(element) => {
                if (element) {
                  element.addEventListener("click", () =>
                    handleClick("Completed")
                  );
                }
                if (activeText === "Completed" && element) {
                  selectedElementRef.current = element;
                }
              }}
              className={activeText === "Completed" ? "select active" : ""}
            >
              {t("progress.completed")}
            </IonText>
            <IonText
              ref={(element) => {
                if (element) {
                  element.addEventListener("click", () =>
                    handleClick("Bookmarked")
                  );
                }
                if (activeText === "Bookmarked" && element) {
                  selectedElementRef.current = element;
                }
              }}
              className={activeText === "Bookmarked" ? "select active" : ""}
            >
              {t("progress.bookmarked")}
            </IonText>
          </IonCol>
        </IonList>

        <div className="ion-padding-horizontal">
          <IonList
            className="themes reports mylessons"
            style={{ margin: "5px 0px 10px" }}
          >
            {activeText === "On Going" ? (
              <div>
                {itemlist?.Ongoings?.length > 0 ? (
                  itemlist?.Ongoings?.map((items, index) => {
                    return (
                      <div key={index}>
                        {items.Type === "Books" ? (
                          <>
                            <IonCard key={index}>
                              <IonCardContent class="ion-no-padding">
                                <div
                                  className="lessongbox"
                                  onClick={() => gonextPage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg src={items.ImageUrl} alt="" />
                                  </div>
                                  <div className="details">
                                    <h4>
                                      {t("progress.lesson")} {items.Number}
                                    </h4>
                                    <span>{items.Title}</span>
                                    <small>
                                      {t("progress.level")} {items.Level}
                                    </small>
                                    {items.Progress !== 0 ? (
                                      <div className="line">
                                        <ProgressBar
                                          bgColor="var(--ion-color-primary)"
                                          height="9px"
                                          className="process"
                                          completed={items.Progress}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 1, 1)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                  />
                                  <IonImg
                                    onClick={() => gotoPractice(items, index)}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        ) : (
                          <>
                            <IonCard placeholder={undefined}>
                              <IonCardContent
                                class="ion-no-padding"
                                placeholder={undefined}
                              >
                                <div
                                  className="lessongbox"
                                  onClick={() => goDialoguePage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg
                                      src={
                                        items.ImageUrl !== ""
                                          ? items.ImageUrl
                                          : "./assets/images/noimg.jpg"
                                      }
                                      placeholder={undefined}
                                    />
                                  </div>
                                  <div className="details">
                                    <h4 className="title">{items.Title}</h4>
                                    <h4>
                                      {t("progress.dialogue")} {items.Number}
                                    </h4>
                                    <span className="gamename">
                                      {items.ContentEn}
                                    </span>
                                    <div className="line">
                                      <ProgressBar
                                        bgColor="var(--ion-color-primary)"
                                        height="9px"
                                        className="process"
                                        completed={80}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 2, 1)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                    placeholder={undefined}
                                  />
                                  <IonImg
                                    onClick={() => {
                                      submitDialogue(items, index);
                                    }}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                    placeholder={undefined}
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="ion-text-center">
                    {t("progress.no_dta_avilbl")}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
            {activeText === "Completed" ? (
              <div>
                {itemlist?.Completeds?.length > 0 ? (
                  itemlist?.Completeds?.map((items, index) => {
                    return (
                      <div key={index}>
                        {items.Type === "Books" ? (
                          <>
                            <IonCard key={index}>
                              <IonCardContent class="ion-no-padding">
                                <div
                                  className="lessongbox"
                                  onClick={() => gonextPage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg src={items.ImageUrl} alt="" />
                                  </div>
                                  <div className="details">
                                    <h4>
                                      {t("progress.lesson")} {items.Number}
                                    </h4>
                                    <span>{items.Title}</span>
                                    <small>
                                      {t("progress.level")} {items.Level}
                                    </small>
                                    {items.Progress !== 0 ? (
                                      <div className="line">
                                        <ProgressBar
                                          bgColor="var(--ion-color-primary)"
                                          height="9px"
                                          className="process"
                                          completed={items.Progress}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 1, 2)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                  />
                                  <IonImg
                                    onClick={() => gotoPractice(items, index)}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        ) : (
                          <>
                            <IonCard placeholder={undefined}>
                              <IonCardContent
                                class="ion-no-padding"
                                placeholder={undefined}
                              >
                                <div
                                  className="lessongbox"
                                  onClick={() => goDialoguePage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg
                                      src={
                                        items.ImageUrl !== ""
                                          ? items.ImageUrl
                                          : "./assets/images/noimg.jpg"
                                      }
                                      placeholder={undefined}
                                    />
                                  </div>
                                  <div className="details">
                                    <h4 className="title">{items.Title}</h4>
                                    <h4>
                                      {t("progress.dialogue")} {items.Number}
                                    </h4>
                                    <span className="gamename">
                                      {items.ContentEn}
                                    </span>
                                    <div className="line">
                                      <ProgressBar
                                        bgColor="var(--ion-color-primary)"
                                        height="9px"
                                        className="process"
                                        completed={80}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 2, 2)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                    placeholder={undefined}
                                  />
                                  <IonImg
                                    onClick={() => {
                                      submitDialogue(items, index);
                                    }}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                    placeholder={undefined}
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="ion-text-center">
                    {t("progress.no_dta_avilbl")}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
            {activeText === "Bookmarked" ? (
              <div>
                {itemlist?.Bookmarkeds?.length > 0 ? (
                  itemlist?.Bookmarkeds?.map((items, index) => {
                    return (
                      <div key={index}>
                        {items.Type === "Books" ? (
                          <>
                            <IonCard key={index}>
                              <IonCardContent class="ion-no-padding">
                                <div
                                  className="lessongbox"
                                  onClick={() => gonextPage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg src={items.ImageUrl} alt="" />
                                  </div>
                                  <div className="details">
                                    <h4>
                                      {t("progress.lesson")} {items.Number}
                                    </h4>
                                    <span>{items.Title}</span>
                                    <small>
                                      {t("progress.level")} {items.Level}
                                    </small>
                                    {items.Progress !== 0 ? (
                                      <div className="line">
                                        <ProgressBar
                                          bgColor="var(--ion-color-primary)"
                                          height="9px"
                                          className="process"
                                          completed={items.Progress}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 1, 3)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                  />
                                  <IonImg
                                    onClick={() => gotoPractice(items, index)}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        ) : (
                          <>
                            <IonCard placeholder={undefined}>
                              <IonCardContent
                                class="ion-no-padding"
                                placeholder={undefined}
                              >
                                <div
                                  className="lessongbox"
                                  onClick={() => goDialoguePage(items, index)}
                                >
                                  <div className="themelesson">
                                    <IonImg
                                      src={
                                        items.ImageUrl !== ""
                                          ? items.ImageUrl
                                          : "./assets/images/noimg.jpg"
                                      }
                                      placeholder={undefined}
                                    />
                                  </div>
                                  <div className="details">
                                    <h4 className="title">{items.Title}</h4>
                                    <h4>
                                      {t("progress.dialogue")} {items.Number}
                                    </h4>
                                    <span className="gamename">
                                      {items.ContentEn}
                                    </span>
                                    <div className="line">
                                      <ProgressBar
                                        bgColor="var(--ion-color-primary)"
                                        height="9px"
                                        className="process"
                                        completed={80}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="icons">
                                  <IonImg
                                    onClick={() =>
                                      savebookmark(items, index, 2, 3)
                                    }
                                    className="ion-padding-bottom bookmark"
                                    src={
                                      items.IsFavourite
                                        ? "./assets/images/Bookmarkfill.svg"
                                        : "./assets/images/Bookmark.svg"
                                    }
                                    alt=""
                                    placeholder={undefined}
                                  />
                                  <IonImg
                                    onClick={() => {
                                      submitDialogue(items, index);
                                    }}
                                    className="pen"
                                    src="./assets/images/pen.svg"
                                    alt=""
                                    placeholder={undefined}
                                  />
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="ion-text-center">
                    {t("progress.no_dta_avilbl")}
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Progress);
