import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  IonImg,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonSkeletonText,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./E5sResultDetails.scss";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
interface OwnProps extends RouteComponentProps {}
interface MyObject {
  id: number;
  name: string;
  type: "e1s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E1sProps {
  itemE1s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E1sResultDetails: React.FC<E1sProps> = (
  props: E1sProps,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
 // const tDataCon = JSON.parse(themeData);
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE1slist, setE1stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [length, setLength] = React.useState(-1);
  const [showResults, setShowResults] = React.useState(1);
  const [successOption, setSuccessOption] = useState<boolean | undefined>();
  const [wrongOption, setWrongOption] = useState<boolean | undefined>();
  const [setRightAns, setRightAnsOption] = useState<boolean | undefined>();
  const [selectedDiv, setSelectedDiv] = useState<any>(null);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState<string | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number | null>(
    null
  );
  const [correctAnswerIndices, setCorrectAnswerIndices] = useState<number[]>(
    []
  );
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  let lastValue: any;
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let totalque: any = 0;
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const index = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
    const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useIonViewWillEnter(() => {
    getExercise();
  });
  const getExercise = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: 'custom-spinner',
      duration: 1000,
    });
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        setAllExcies(json.Result);
        // setAPIcall(false);
        //New Code
        // setE1stems(json.Result.E1s);
        //Exercise E1s  4 images and 1 text logic set
        let tmpoptione1s: any = [];
        let resulteE1s = json.Result;
        resulteE1s.forEach((axq: any[], indexq: number) => {
          let qq = [
            {
              Word: resulteE1s[indexq].ImageUrl1,
              questionno: 1,
            },
            {
              Word: resulteE1s[indexq].ImageUrl2,
              questionno: 2,
            },
            {
              Word: resulteE1s[indexq].ImageUrl3,
              questionno: 3,
            },
            {
              Word: resulteE1s[indexq].ImageUrl4,
              questionno: 4,
            },
          ];
          const random_o = qq
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          let final = {
            EId: resulteE1s[indexq].EId,
            Answer: resulteE1s[indexq].Answer,
            //Ev_guid: resulteE1s[indexq].Ev_guid,
            TemplateCode: resulteE1s[indexq].TemplateCode,
            PageId: resulteE1s[indexq].PageId,
            BookId: resulteE1s[indexq].BookId,
            Options: random_o,
            Question:
              resulteE1s[indexq].WordCh + " " + resulteE1s[indexq].WordPy,
            WordCh: resulteE1s[indexq].WordCh,
            WordPy: resulteE1s[indexq].WordPy,
            Rightanswer: resulteE1s[indexq].RightImg,
            score: false,
          };
          tmpoptione1s.push(final);
        });
        setitems(tmpoptione1s);
        setE1stems(tmpoptione1s);
        // End E1s
        //Exercise E2s 1 images and 4 text logic set
        // let tmpoptione2s: any = [];
        // let resulteE2s = json.Result.E2s;
        // resulteE2s.forEach((axq: any[], indexq: number) => {
        //   let qq = [
        //     {
        //       Word: resulteE2s[indexq].WordCh1,
        //       WordPy: resulteE2s[indexq].WordPy1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh2,
        //       WordPy: resulteE2s[indexq].WordPy2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh3,
        //       WordPy: resulteE2s[indexq].WordPy3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE2s[indexq].WordCh4,
        //       WordPy: resulteE2s[indexq].WordPy4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_b = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   let final = {
        //     EId: resulteE2s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE2s[indexq].TemplateCode,
        //     PageId: resulteE2s[indexq].PageId,
        //     BookId: resulteE2s[indexq].BookId,
        //     Options: random_b,
        //     Question: resulteE2s[indexq].ImageUrl,
        //     // WordCh: resulteE2s[indexq].WordCh,
        //     // WordPy: resulteE2s[indexq].WordPy,
        //     answer: resulteE2s[indexq].CorrectCh,
        //     score: false,
        //   };
        //   tmpoptione2s.push(final);
        // });
        // // setitems(tmpoptione2s);
        // setE2stems(tmpoptione2s);
        // End E2s
        //Exercise E4s 1 audio and 4 text logic set
        // let tmpoptione4s: any = [];
        // let resulteE4s = json.Result.E4s;
        // resulteE4s.forEach((axq: any[], indexq: number) => {
        //   let qq = [
        //     {
        //       Word: resulteE4s[indexq].WordCh1,
        //       WordPy: resulteE4s[indexq].WordPy1,
        //       questionno: 1,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh2,
        //       WordPy: resulteE4s[indexq].WordPy2,
        //       questionno: 2,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh3,
        //       WordPy: resulteE4s[indexq].WordPy3,
        //       questionno: 3,
        //     },
        //     {
        //       Word: resulteE4s[indexq].WordCh4,
        //       WordPy: resulteE4s[indexq].WordPy4,
        //       questionno: 4,
        //     },
        //   ];
        //   const random_c = qq
        //     .map((a) => ({ sort: Math.random(), value: a }))
        //     .sort((a, b) => a.sort - b.sort)
        //     .map((a) => a.value);
        //   let final = {
        //     EId: resulteE4s[indexq].EId,
        //     //Ev_guid: resulteE1s[indexq].Ev_guid,
        //     TemplateCode: resulteE4s[indexq].TemplateCode,
        //     PageId: resulteE4s[indexq].PageId,
        //     BookId: resulteE4s[indexq].BookId,
        //     Options: random_c,
        //     Question: resulteE4s[indexq].AudioUrl,
        //     // WordCh: resulteE2s[indexq].WordCh,
        //     // WordPy: resulteE2s[indexq].WordPy,
        //     answer: resulteE4s[indexq].CorrectCh,
        //     score: false,
        //   };
        //   tmpoptione4s.push(final);
        // });
        // // setitems(tmpoptione2s);
        // setE4stems(tmpoptione4s);
        // End E2s
        //Old Code
        // setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: 'custom-spinner',
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  function handleChange(back) {
    // const myObject: MyObject = {
    //   id: 1,
    //   name: "My Object",
    //   type: "e1s",
    //   pagecouter: pagecouter,
    //   back:back,
    //   totalque: itemE1slist.length,
    // };
    // props.onObjectChange(myObject);
  }
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  useIonViewWillEnter(() => {
    setLength(1);
    setShowResults(1);
  });
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        //Back Login code
        let index = backSlideIndex == -1 ? -2 : 0;
        ///   setbackSlideIndex(index);
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex == -1) {
        }
      } else {
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
         // SaveExerciseQueAnswer(currentIndex, index);
          if (index + 1 == length) {
            setLength(-1);
           // saveContent();
            handleChange(0);
          }
        });
      }
    }
  };
  let tmpscore = 0;
  const handleQuestionCheckClick = (
    items: any,
    index: any,
    indexq: any,
    type,
    indexnew
  ) => {
    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(itemE1slist[index].Options?.[indexnew].Choice);
      if (!itemE1slist[index].answer) {
        itemE1slist[index].answer = type;
        itemE1slist[index].score = true;
        tmpscore += tmpscore;
        // setSum(sum + sum);
      }
    } else {
      isSuccess = false;
      isWrong = true;
      setRightAnsOption(true);
      setSuccessOption(isSuccess);
      setWrongOption(isWrong);
      setSelectedDiv(type);
      setmyanswer("");
      setmyanswer(itemE1slist[index].Options?.[indexnew].Choice);
      if (!itemE1slist[index].answer) {
        itemE1slist[index].answer = type;
        itemE1slist[index].score = false;
      }
    }
  };
  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    setSelectedAnswer(selectedOption);
    setCorrectAnswer(correctAnswer);
    if (selectedOption === correctAnswer) {
      setIsCorrect(true);
      setRightAnsOption(true);
      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setmyanswer("");
      setmyanswer(selectedOption);
      setIsCorrect(false);
      setRightAnsOption(false);
      // setCorrectAnswerIndex(index); // Set the correct answer index
    }
    // Set the correct answer index
    const correctIndex = itemE1slist[index].Options.findIndex(
      (option) => option.Word === correctAnswer
    );
    setCorrectAnswerIndex(correctIndex);
    setCorrectAnswerIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });
    // Update the state or perform other actions as needed
    // Example: itemE1slist[index].answer = selectedOption;
  };
  const gotonextques = async (items: any, index: any, type) => {
    //1 for Understand and 2= dont understand
    if (items?.Question?.[0].soundno == type) {
      isSuccess = true;
      isWrong = false;
    } else {
      isSuccess = false;
      isWrong = true;
    }
    //  let data = itemlist[showResults - 1];
    if (items) {
      //
      setShowResults(index);
      setLength(index);
      resetstate();
      // ansques = data.QuestionId + ',' + type;
      // setQuestion([...quesdata, ansques]);
    }
  };
  const resetstate = () => {
    setSuccessOption(false);
    setWrongOption(false);
    setSelectedDiv(false);
    setRightAnsOption(false);
  };
  useEffect(() => {
    if (length > itemE1slist.length) {
      setLength(-1);
     // saveContent();
      handleChange(0);
    }
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, itemE1slist.length]);

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 1000); // Delay in milliseconds (e.g. 2000ms = 2 seconds)
    return () => clearTimeout(timer);
  }, []);
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname: backpageValue===2?"/tabs/progress/practiceresult":"/tabs/mainhome/practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: index,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E1s",
          backpageValue:backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={gobackpage}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[index ? index : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              {/* <IonButton onClick={openDrawer} color="primary">
                <IonIcon icon={ellipsisVertical} />
              </IonButton> */}
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? itemE1slist.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}>
          {itemE1slist.map((items, index) => {
            return (
              <IonSlide>
                <IonList key={index} className="ion-no-padding height100">
                  <IonRow className="Practice leftrightspace ion-padding-horizontal">
                    <IonCol>
                      <IonText>
                        {t("e1s.which_picture_matches_chinese")}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="bigbox leftrightspace ion-padding-horizontal">
                    <IonCol
                      size="12"
                      className="ion-no-padding ion-text-center">
                      <ul className="lession-icon">
                        <li>
                          <span>{items.WordCh}</span>
                          {items.WordPy}
                        </li>
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer leftrightspace datakey ion-padding-horizontal">
                    {itemE1slist?.[index].Options?.map((datav, indexnew) => (
                      <IonCol size="6" key={indexnew}>
                        {/* "var(--ion-color-success)" green */}
                        {/* "#f63b12" red */}
                        {/* <IonCard
                          id={datav.questionno}
                          style={{
                            borderColor: isCorrect !== null && isCorrect && datav.Word === selectedAnswer ? "var(--ion-color-success)" // Green outline for the correct answer
                                : correctAnswerIndices[index] !== null && indexnew === correctAnswerIndices[index] ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                : isCorrect !== null && !isCorrect && datav.Word === selectedAnswer ? "#f63b12" // Red outline for the wrong answer
                                : "",
                          }}
                          onClick={() =>
                            handleAnswerSelection(
                              datav.Word,
                              items.answer,
                              index
                            )
                          }
                        >
                          <IonCardContent class="ion-no-padding imageview">
                            <div className="min80">
                              {showImage ? (
                                <IonImg
                                  src={datav.Word}
                                  onLoad={handleImageLoad}
                                />
                              ) : (
                                <IonSkeletonText
                                  animated
                                  style={{
                                    width: "138px",
                                    height: "138px",
                                    margin: "0px",
                                  }}
                                />
                              )}
                            </div>
                          </IonCardContent>
                        </IonCard> */}
                        <IonCard
                          id={datav.questionno}
                          style={{
                            borderColor: datav.Word === items.Rightanswer
                              ? "var(--ion-color-success)"
                              : datav.Word === items.Answer
                                ? "#f63b12"
                                : "",
                          }}>
                          <IonCardContent class="ion-no-padding imageview">
                            <div className="min80">
                              {showImage ? (
                                <IonImg
                                  src={datav.Word}
                                  onLoad={handleImageLoad}/>
                              ) : (
                                <IonSkeletonText
                                    animated
                                    style={{ width: "100%", height: "100%", margin: "0px" }}/>
                              )}
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </IonCol>
                    ))}
                    <IonCol size="12">
                      {/* {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif"/>
                      )} */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow>
          <IonList className="list">
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e1s.drawer.lesson_language")}</IonLabel>
              <div className="right-space">
                <IonText color='primary' placeholder={undefined}>Simp.</IonText>
                <IonText placeholder={undefined}>Trad.</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e1s.drawer.chinese_characters")}</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e1s.drawer.pinyin")}</IonLabel>
              <IonToggle checked slot="end"></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding">
              <IonLabel>{t("e1s.drawer.playback_speed")}</IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a><span className="speed">1.0</span><a href="#">&gt;</a>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(E1sResultDetails);
