import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  IonImg,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonModal,
  getPlatforms,
} from "@ionic/react";
import "./Practiceresult.scss";
import { chevronBack } from "ionicons/icons";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Share } from "@capacitor/share";
import { Directory, Filesystem } from "@capacitor/filesystem";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";

interface OwnProps extends RouteComponentProps {}
interface PracticeresultProps extends OwnProps {
  progress: number;
}
const Practiceresult: React.FC<PracticeresultProps> = ({
  progress,
  history,
}) => {
  // Get the platform information
  const platform = getPlatforms();
  const { t } = useTranslation();
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    bookId: string;
    backpage: string;
    title: string;
    dialogueId: string;
    allrole: string;
    rolename: string;
    pagename: string;
    backpageValue: number;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";

  const getData: any = localStorage.getItem("resultBack");
  const backPageValue = JSON.parse(getData);
  const [isVisible, setIsVisible] = React.useState(false);
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  let url: string = CONFIG.API_ENDPOINT; //live
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [FileToaster] = useIonToast();
  const [itemprofilelist, setprofileitems] = React.useState<any>({});
  const [Reading, setReading] = React.useState<any>({});
  const [Writing, setWriting] = React.useState<any>({});
  const [Listening, setListening] = React.useState<any>({});
  const [Speaking, setSpeaking] = React.useState<any>({});
  const [showDate, setShowDate] = useState(false);
  const [openModal, setOpenModal] = useState<any>(false);
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    GetResult(lessonsId);
    GetProfie();
  });
  const GetResult = async (lessonsId) => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      let lessonGuid = lessonsId;
      const response = await fetch(
        url + "/Book/GetProgress?lessonGuid=" + lessonGuid,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setitems(json.Result);

        let restdata = json["Result"]["Progress"];
        if (restdata !== null) {
          setReading(restdata?.Reading || 0);
          setWriting(restdata?.Writing || 0);
          setListening(restdata?.Listening || 0);
          setSpeaking(restdata?.Speaking || 0);
        }
        if (itemlist["Progress"]?.CompleteDate !== 0 || null || undefined) {
          setShowDate(true);
        }
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  const handleClickResult = async (data, index) => {
    //

    if (data.ExerciseType === "E1s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E1sResultDetails"
            : "/tabs/mainhome/E1sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E2s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E2sResultDetails"
            : "/tabs/mainhome/E2sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E3s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E3sResultDetails"
            : "/tabs/mainhome/E3sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E4s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E4sResultDetails"
            : "/tabs/mainhome/E4sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E5s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E5sResultDetails"
            : "/tabs/mainhome/E5sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else if (data.ExerciseType === "E6s" && data.TotalCount !== 0) {
      history.replace({
        pathname:
          backPageValue === 2
            ? "/tabs/progress/E6sResultDetails"
            : "/tabs/mainhome/E6sResultDetails",
        state: {
          themeArray: themeArray,
          // lessonGuid: lessonsId,
          type: data.ExerciseType,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: backpage,
          pagename: data.ExerciseType,
          backpageValue: backpageValue,
        },
      });
    } else {
      presenttost({
        message: "No Results Found",
        duration: 1500,
        position: "bottom",
      });
    }
    // E5ev1ResultDetails
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setprofileitems(json.Result);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  // const shareButton = async () => {
  //   FileToaster({
  //     message: 'Which format do you want to share?',
  //     duration: 10000,
  //     position: 'top',
  //     layout: 'stacked',
  //     cssClass: 'custom-toast',
  //     buttons: [
  //       {
  //         text: 'Share Text',
  //         handler: shareGenerateTXT
  //       }, {
  //         text: 'Share Image',
  //         handler: shareGenerateIMG
  //       },
  //     ],
  //   });
  //   onClose()
  // }
  // const shareGenerateTXT = async () => {
  //   const div = document.getElementById('content') as HTMLElement;
  //   const textContent = div ? (div.textContent || div.innerText || '') : '';
  //   const formattedText = textContent.replace(/(%|\/0|:|ing)/g, '$1\n');
  //   try {
  //     await Share.share({
  //       title: 'Share Result',
  //       text: formattedText,
  //       dialogTitle: 'Share via',
  //     });
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  //   onClose();
  // };
  const shareGenerateIMG = async () => {
    const hiddenContent = document.getElementById("hidden-content");
    if (hiddenContent) {
      hiddenContent.classList.remove("hidden-content");
      const element = document.getElementById("content") as HTMLElement;
      if (element) {
        html2canvas(element).then(async (canvas) => {
          const imageDataUrl = canvas.toDataURL();
          await Filesystem.requestPermissions();
          await Filesystem.writeFile({
            data: imageDataUrl,
            directory: Directory.Cache,
            path: "Lesson Result.jpg",
          });
          let imageResult = await Filesystem.getUri({
            directory: Directory.Cache,
            path: "Lesson Result.jpg",
          });
          Share.share({
            title: `Lesson Result ${lessonname}`,
            url: imageResult.uri,
          })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing: ", error));
        });
        setOpenModal(false);
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
      await hiddenContent.classList.add("hidden-content");
    } else {
      console.error("Element not found. Make sure the selector is correct.");
    }
  };

  const generateIMG = async () => {
    if (platform.includes("android")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imageDataUrl = canvas.toDataURL();
            await Filesystem.requestPermissions();
            const imagePath = await Filesystem.writeFile({
              data: imageDataUrl,
              directory: Directory.ExternalStorage,
              path: `Download/Lesson Result ${lessonname}.jpg`,
            });
            if (imagePath !== null) {
              FileToaster({
                message:
                  "Image save successfully at Location:Download/Lesson Result.jpg",
                duration: 3000,
                position: "top",
                cssClass: "custom-toast",
                buttons: [{ text: "Ok", handler: () => onClose() }],
              });
            }
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else if (platform.includes("ios")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imageDataUrl = canvas.toDataURL();
            await Filesystem.requestPermissions();
            await Filesystem.writeFile({
              data: imageDataUrl,
              directory: Directory.Cache,
              path: `Lesson Result.png`,
            });
            let imageResult = await Filesystem.getUri({
              directory: Directory.Cache,
              path: `Lesson Result.png`,
            });
            Share.share({
              title: `Lesson Result ${lessonname}.png`,
              url: imageResult.uri,
            })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing: ", error));
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    }
  };

  const generatePDF = async () => {
    if (platform.includes("android")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const doc = new jsPDF({
              unit: "px",
              format: [imgWidth, imgHeight],
            });
            const imageDataUrl = canvas.toDataURL();
            doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            await Filesystem.requestPermissions();
            const filePath = await Filesystem.writeFile({
              path: `Download/Lesson Result ${lessonname}.pdf`,
              data: doc.output("datauristring"),
              directory: Directory.ExternalStorage,
            });
            if (filePath !== null) {
              FileToaster({
                message:
                  "PDF saved successfully at Location: Download/Lesson Result.pdf",
                duration: 3000,
                position: "top",
                cssClass: "custom-toast",
                buttons: [{ text: "Ok", handler: () => onClose() }],
              });
            }
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    } else if (platform.includes("ios")) {
      const hiddenContent = document.getElementById("hidden-content");
      if (hiddenContent) {
        hiddenContent.classList.remove("hidden-content");
        const element = document.getElementById("content") as HTMLElement;
        if (element) {
          html2canvas(element).then(async (canvas) => {
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const doc = new jsPDF({
              unit: "px",
              format: [imgWidth, imgHeight],
            });
            const imageDataUrl = canvas.toDataURL();
            doc.addImage(imageDataUrl, "PNG", 0, 0, imgWidth, imgHeight);
            await Filesystem.requestPermissions();
            const filePath = await Filesystem.writeFile({
              path: `Lesson Result ${lessonname}.pdf`,
              data: doc.output("datauristring"),
              directory: Directory.Cache,
            });

            let filePathGet = await Filesystem.getUri({
              directory: Directory.Cache,
              path: `Lesson Result ${lessonname}.pdf`,
            });
            Share.share({
              title: `Lesson Result ${lessonname}.pdf`,
              url: filePathGet.uri,
            })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing: ", error));
            if (filePath !== null) {
              FileToaster({
                message:
                  "PDF saved successfully at Location: Download/Result.pdf",
                duration: 3000,
                position: "top",
                cssClass: "custom-toast",
                buttons: [{ text: "Ok", handler: () => onClose() }],
              });
            }
          });
          setOpenModal(false);
          await hiddenContent.classList.add("hidden-content");
        } else {
          console.error(
            "Element not found. Make sure the selector is correct."
          );
        }
        await hiddenContent.classList.add("hidden-content");
      } else {
        console.error("Element not found. Make sure the selector is correct.");
      }
    }
  };

  const back = () => {
    history.replace({
      pathname:
        backPageValue === 2
          ? "/tabs/progress/detailedreports"
          : "/tabs/mainhome/practicecompleted",
      state: {
        themeArray: themeArray,
        index: indexs,
        themeId: themeId,
        lessonsId: lessonsId,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        rolename: rolename,
        allrole: allrole,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={back}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("practiceresult.lesson")}{" "}
                {/* {themeArray[indexs ? indexs : 0]?.number} */}
                {themeArray && themeArray.length > 0
                  ? themeArray[indexs ?? 0]?.number
                  : ""}
                : {t("practiceresult.practice")}
                <p className="ion-no-margin">
                  {themename ? themename : "Test Theme"}
                </p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                // onClick={openDrawer}
                onClick={() => setOpenModal(true)}
                color="primary"
              >
                {/* <IonIcon icon={cloudUploadOutline} /> */}
                <IonImg src="./assets/images/share.svg" alt="" />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing mxm">
        <div has-bouncing="true" className="psdviewer" id="content">
          <IonRow
            class="blue-bg pdfhedding"
            id="hidden-content"
            className="hidden-content"
          >
            <IonCol size="12">
              <span>
                <h3>{t("practiceresult.lesson_report")}</h3>
                <p className="pdflavel">
                  {t("practiceresult.level")} {level}
                </p>
                <p className="ion-no-margin">
                  {themename !== null ? themename : "Test Theme"}:{" "}
                  {t("practiceresult.lesson")}{" "}
                  {/* {themeArray[indexs ? indexs : 0]?.number} */}
                  {themeArray && themeArray.length > 0
                    ? themeArray[indexs ?? 0]?.number
                    : ""}
                </p>
                <p className="ion-no-margin themename">{lessonname}</p>
              </span>
              <span className="logoarea">
                <img src="./assets/images/pdfMMXlogo.png" alt="" />
                <p className="ion-no-margin username">
                  <img src="./assets/images/pdfusericon.png" alt="" />
                  {itemprofilelist?.Name}
                </p>
              </span>
            </IonCol>
          </IonRow>
          <IonRow className="Practice count">
            <IonCol size="12" className="partspaceing">
              <IonCard className="count">
                <IonCardContent class="ion-no-padding">
                  <IonRow>
                    <IonCol size="6">
                      <h5>{t("practiceresult.overall_score")} :</h5>
                      <div>
                        <h1>{itemlist["Progress"]?.OverAll || 0}%</h1>
                      </div>
                    </IonCol>
                    {showDate && (
                      <IonCol size="6" className="ion-text-right bookfont">
                        {t("practiceresult.completed_date")}:
                        <br />
                        {itemlist["Progress"]?.CompleteDate}
                      </IonCol>
                    )}
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonCard className="scorebox">
                <IonRow className="">
                  <IonCol size="6">
                    <CircularProgressbar
                      strokeWidth={12}
                      text={`${Reading === 0 ? "--" : Reading}`}
                      value={Reading}
                      styles={{
                        background: {
                          fill: "var(--ion-color-light-tint)",
                          textAlign: "center",
                        },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-light-tint)" },
                        text: {
                          fill:
                            Reading === 0
                              ? "var(--ion-color-tertiary-tint)"
                              : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    <div>
                      <IonImg
                        src={
                          Reading === 0
                            ? "./assets/images/Reader-light.svg"
                            : "./assets/images/Reader.svg"
                        }
                        alt=""
                      />
                      <IonText
                        style={{
                          color:
                            Reading === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                        }}
                      >
                        {t("practiceresult.score.reading")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <CircularProgressbar
                      strokeWidth={12}
                      text={`${Writing === 0 ? "--" : Writing}`}
                      value={Writing}
                      styles={{
                        background: {
                          fill: "var(--ion-color-light-tint)",
                          textAlign: "center",
                        },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-light-tint)" },
                        text: {
                          fill:
                            Writing === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">80</div>
                    </div> */}
                    <div>
                      <IonImg
                        src={
                          Writing === 0
                            ? "./assets/images/Writing-light.svg"
                            : "./assets/images/Writing.png"
                        }
                        alt=""
                      />
                      <IonText
                        style={{
                          color:
                            Writing === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                        }}
                      >
                        {t("practiceresult.score.writing")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <CircularProgressbar
                      strokeWidth={12}
                      text={`${Listening === 0 ? "--" : Listening}`}
                      value={Listening}
                      styles={{
                        background: {
                          fill: "var(--ion-color-light-tint)",
                          textAlign: "center",
                        },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-light-tint)" },
                        text: {
                          fill:
                            Listening === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">90</div>
                    </div> */}
                    <div>
                      {/* <IonIcon icon={headset} /> */}
                      <IonImg
                        src={
                          Listening === 0
                            ? "./assets/images/Listening-light.png"
                            : "./assets/images/ListeningOrg.svg"
                        }
                        alt=""
                      />
                      <IonText
                        style={{
                          color:
                            Listening === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                        }}
                      >
                        {t("practiceresult.score.listening")}
                      </IonText>
                    </div>
                  </IonCol>
                  <IonCol size="6">
                    <CircularProgressbar
                      strokeWidth={12}
                      text={`${Speaking === 0 ? "--" : Speaking}`}
                      value={Speaking}
                      styles={{
                        background: {
                          fill: "var(--ion-color-light-tint)",
                          textAlign: "center",
                        },
                        path: { stroke: "var(--ion-color-primary)" },
                        trail: { stroke: "var(--ion-color-light-tint)" },
                        text: {
                          fill:
                            Speaking === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                          textAlign: "center",
                          position: "absolute",
                          verticalAlign: "middle",
                          lineHeight: "50",
                          fontSize: "28px",
                        },
                      }}
                    />
                    {/* <div className="round-chart">
                      <div className="chart-center">80</div>
                    </div> */}
                    <div>
                      {/* <IonIcon icon={chatboxEllipsesOutline} /> */}
                      <IonImg
                        src={
                          Speaking === 0
                            ? "./assets/images/Speaking-light.svg"
                            : "./assets/images/SpeakingOrg.svg"
                        }
                        alt=""
                      />
                      <IonText
                        style={{
                          color:
                            Speaking === 0
                              ? "var(--ion-color-light-tint)"
                              : "var(--ion-color-primary)",
                        }}
                      >
                        {t("practiceresult.score.speaking")}
                      </IonText>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonCol>
            <IonCol size="12">
              <IonCard className="count overview">
                <IonCardContent class="ion-no-padding">
                  <IonRow>
                    <IonCol size="12">
                      <h5>{t("practiceresult.results_overview")}:</h5>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonCard className="scorebox mnxview">
                <IonRow className="">
                  {/* {itemlist["Types"]?.map((items, index) => {
                    return (
                      <IonCol
                        key={index}
                        size="6"
                        onClick={() =>
                          items?.TotalCount === 0
                            ? ""
                            : handleClickResult(items, index)
                        }
                      >
                        <div className="circleimg">
                          <CircularProgressbar
                            strokeWidth={12}
                            value={0}
                            styles={{
                              background: {
                                fill: "var(--ion-color-light-tint)",
                                textAlign: "center",
                              },
                              path: { stroke: "var(--ion-color-primary)" },
                              trail: {
                                stroke: "var(--ion-color-primary-shade)",
                              },
                            }}
                          />
                          {items.ExerciseType === "E1s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Reader-light.svg"
                                  : "./assets/images/Reader.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E2s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Reader-light.svg"
                                  : "./assets/images/Reader.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E3s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Writing-light.svg"
                                  : "./assets/images/Writing.png"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E4s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Listening-light.png"
                                  : "./assets/images/ListeningOrg.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E5s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Speaking-light.svg"
                                  : "./assets/images/SpeakingOrg.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {items.ExerciseType === "E6s" ? (
                            <IonImg
                              src={
                                items?.TotalCount === 0
                                  ? "./assets/images/Speaking-light.svg"
                                  : "./assets/images/SpeakingOrg.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <IonText
                            style={{
                              color:
                                items?.TotalCount === 0
                                  ? "var(--ion-color-light-tint)"
                                  : "var(--ion-color-primary)",
                            }}
                          >
                            {items?.TotalCount === 0 ? (
                              <>--/--</>
                            ) : (
                              <>
                                {items?.RightCount}/{items?.TotalCount}
                              </>
                            )}
                          </IonText>
                        </div>
                      </IonCol>
                    );
                  })} */}
                  {itemlist["Types"]?.map((items, index) => {
                    return (
                      <IonCol
                        key={index}
                        size="6"
                        onClick={() =>
                          items?.RightCount === 0
                            ? ""
                            : handleClickResult(items, index)
                        }
                      >
                        <div className="circleimg">
                          <CircularProgressbar
                            strokeWidth={12}
                            value={0}
                            styles={{
                              background: {
                                fill: "var(--ion-color-light-tint)",
                                textAlign: "center",
                              },
                              path: { stroke: "var(--ion-color-primary)" },
                              trail: {
                                stroke: "var(--ion-color-primary-shade)",
                              },
                            }}
                          />
                          {items.ExerciseType === "E1s" ||
                          items.ExerciseType === "E2s" ? (
                            <IonImg
                              src={
                                items?.RightCount === 0
                                  ? "./assets/images/Reader-light.svg"
                                  : "./assets/images/Reader.svg"
                              }
                              alt=""
                            />
                          ) : items.ExerciseType === "E3s" ? (
                            <IonImg
                              src={
                                items?.RightCount === 0
                                  ? "./assets/images/Writing-light.svg"
                                  : "./assets/images/Writing.png"
                              }
                              alt=""
                            />
                          ) : items.ExerciseType === "E4s" ? (
                            <IonImg
                              src={
                                items?.RightCount === 0
                                  ? "./assets/images/Listening-light.png"
                                  : "./assets/images/ListeningOrg.svg"
                              }
                              alt=""
                            />
                          ) : items.ExerciseType === "E5s" ||
                            items.ExerciseType === "E6s" ? (
                            <IonImg
                              src={
                                items?.RightCount === 0
                                  ? "./assets/images/Speaking-light.svg"
                                  : "./assets/images/SpeakingOrg.svg"
                              }
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <IonText
                            style={{
                              color:
                                items?.RightCount === 0
                                  ? "var(--ion-color-light-tint)"
                                  : "var(--ion-color-primary)",
                            }}
                          >
                            {items?.TotalCount === 0 ? (
                              <>--/--</>
                            ) : (
                              <>
                                {items?.RightCount}/{items?.TotalCount}
                              </>
                            )}
                          </IonText>
                        </div>
                      </IonCol>
                    );
                  })}

                  {/* <IonCol size="6">
                    <div className="circleimg">
                      <CircularProgressbar
                        strokeWidth={12}
                        value={0}
                        styles={{
                          background: { fill: "var(--ion-color-light-tint)", textAlign: "center" },
                          path: { stroke: "var(--ion-color-primary-shade)" },
                          trail: { stroke: "var(--ion-color-light-tint)" },
                        }}
                      />
                      <IonImg src="./assets/images/Writing.png" alt="" />
                    </div>
                    <div>
                      <IonText>0/0</IonText>
                    </div>
                  </IonCol> */}
                </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
      <IonModal
        className="profilepage"
        isOpen={openModal}
        onDidDismiss={() => setOpenModal(false)}
        // trigger="open-modal"
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        // handleBehavior="cycle"
      >
        {/* <Drawer
          className="bottompopup skateInBottom bottompopupresult"
          isVisible={isVisible}
          onClose={onClose}
        > */}
        <IonRow placeholder={undefined}>
          <IonList className="list">
            <IonItem onClick={shareGenerateIMG}>
              {/* <IonIcon icon={share} /> */}
              <IonImg src="./assets/images/editShare.svg" alt="" />
              <IonLabel>{t("practiceresult.drawer.share_result")}</IonLabel>
            </IonItem>
            <IonItem onClick={generateIMG}>
              {/* <IonIcon icon={image} /> */}
              <IonImg src="./assets/images/Chooseimage.svg" alt="" />
              <IonLabel>{t("practiceresult.drawer.save_as_photo")}</IonLabel>
            </IonItem>
            <IonItem onClick={generatePDF}>
              {/* <IonIcon icon={download} /> */}
              <IonImg src="./assets/images/download.svg" alt="" />
              <IonLabel>{t("practiceresult.drawer.save_as_pdf")}</IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
        {/* </Drawer> */}
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Practiceresult);
