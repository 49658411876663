import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./Practicequestion.scss";
import "./E6sResultDetails.scss";
import Drawer from "react-bottom-drawer";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import HanziWriter from "hanzi-writer";
import ReactAudioPlayer from "react-audio-player";
import useMediaRecorder from "@wmik/use-media-recorder";
import { json } from "stream/consumers";
import { string } from "yup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import { RouteComponentProps, useHistory } from "react-router";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import CustomAudioPlayer from "./CustomAudioPlayer";
interface OwnProps extends RouteComponentProps {}
function Player({ srcBlob }: { srcBlob: any }) {
  if (!srcBlob) {
    return null;
  } else {
    return <audio src={URL.createObjectURL(srcBlob)} controls />;
  }
}
interface MyObject {
  id: number;
  name: string;
  type: "e6s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E6sProps {
  itemE6s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
}
const E6sResultDetails: React.FC<E6sProps> = (
  props: E6sProps,
  { history, isSuccess, isWrong }
) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const themeData: any = localStorage.getItem("itemlist");
  //const tDataCon = JSON.parse(themeData);
  const [allexcieslist, setAllExcies] = React.useState<any[]>([]);
  const [isApicall, setAPIcall] = useState<boolean | undefined>(true);
  const [itemE6slist, setE6stems] = React.useState<any[]>([]);
  const [itemlist, setitems] = React.useState<any[]>([]);
  let lastValue: any;
  const [isVisible, setIsVisible] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [showResults, setShowResults] = React.useState(0);
  const [length, setLength] = React.useState(-1);
  const [index, setIndex] = React.useState(0);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  let finalanswer = {};
  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);
  const historyss = useHistory<{
    themeArray: string;
    themeId: string;
    lessonsId: string;
    index: string;
    lessonname: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    pagename: string;
    backpageValue: any;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const itemArray: any = themeArray;
  const themeId = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const lessonsId = historyss.location.state
    ? historyss.location.state.lessonsId
    : "";
  const indexs = historyss.location.state ? historyss.location.state.index : "";
  const lessonname = historyss.location.state
    ? historyss.location.state.lessonname
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const pagename = historyss.location.state
    ? historyss.location.state.pagename
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  useEffect(() => {
    if (lessonsId) {
      // When lessonsId is available, fetch the corresponding exercises
      getExercise(lessonsId);
    }
  }, [lessonsId]);
  useIonViewWillEnter(() => {
    // getExercise();
    setLength(1);
    slidesRef.current?.getActiveIndex().then((index) => {
      if (itemE6slist.length == index + 1) {
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
    });
    setShowResults(0);
  });

  const [data, setData] = useState<any>();
  let dataPush: any[] = [];

  const getExercise = async (lessonsId) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    try {
      const response = await fetch(
        url +
          "/Book/GetExerciseResult?lessonGuid=" +
          lessonsId +
          "&type=" +
          pagename,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          method: "GET",
        }
      );
      const json = await response.json();
       
      dismiss();
      if (json.StatusCode == 1) {
        // setitems(json.Result.E4ev1s)
        // setLengthQue(json.Result.length)
        setAllExcies(json.Result);
        setitems(json.Result);
        setE6stems(json.Result);
        // Looping through each element in json.Result
        // for (let i = 0; i < json.Result.length; i++) {
        //   let jsonData = JSON.parse(json.Result[i].RearrageArrayAnswer);
        //   dataPush.push(jsonData);
        // }

        // setAPIcall(false);
        //Old Code
        // setLengthQue(3);
        //Logic E4ev1s
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  // DnD Start

  let dataTxt: any;
  let dataPy: any;

  // Sample data
  // const dataTxt = itemE6slist[showResults].WordCh;
  // const dataPy = itemE6slist[showResults].WordPy;
  // const dataTxt = "itemE6slist[showResults].WordCh";
  // const dataPy = "itemE6slist[showResults].WordPy";
  if (itemE6slist && itemE6slist.length > showResults) {
    // Accessing properties safely
    dataTxt = itemE6slist[showResults]?.WordCh || "";
    dataPy = itemE6slist[showResults]?.WordPy || "";
    // Rest of the code
  } else {
    // Handle the case where itemE6slist is undefined or empty
  }
  const initialList1 = dataTxt ? dataTxt.split("") : [];
  const iniPinyinList1 = dataPy ? dataPy.split(" ") : [];
  const initialList2 = [];
  const iniPinyinList2 = [];
  const listResult = [];
  if (itemE6slist !== null) {
    for (let i = 0; i < itemE6slist.length; i++) {
      const element = itemE6slist[i];
      const initialList3 = itemE6slist[i].WordCh
        ? itemE6slist[i].WordCh.split("")
        : [];
      const iniPinyinList4 = itemE6slist[i].WordPy
        ? itemE6slist[i].WordPy.split(" ")
        : [];
      // return word_ch.Word
      let mergedArray = initialList3.map((element, index) => ({
        value1: element,
        value2: iniPinyinList4[index],
      }));
      let myArray_chaines = mergedArray;
      myArray_chaines.sort(randomSort);
      //Merge two arrays
      let array1 = myArray_chaines.map((obj) => obj.value1);
      let array2 = myArray_chaines.map((obj) => obj.value2);
      element["list_ch"] = array1;
      element["list_py"] = array2;
    }
  }
  function randomSort() {
    return 0.5 - Math.random();
  }

  let listCh: any;
  let listPy: any;

  if (itemE6slist && itemE6slist.length > showResults) {
    // Accessing properties safely
    listCh = itemE6slist[showResults]?.list_ch || "";
    listPy = itemE6slist[showResults]?.list_py || "";
    // Rest of the code
  } else {
    // Handle the case where itemE6slist is undefined or empty
  }
  const [list1, setList1] = useState<any>(listCh);
  const [pinyinList1, setPinyinList1] = useState<any>(listPy);
  const [list2, setList2] = useState<any>(initialList2);
  const [pinyinList2, setPinyinList2] = useState<any>(iniPinyinList2);
  const [resultList, setResultList] = useState<any>(listResult);
  // DnD end

  function handleChange(back) {
    // const myObject: MyObject = {
    //   id: 1,
    //   name: "My Object",
    //   type: "e6s",
    //   pagecouter: pagecouter,
    //   totalque: itemE6slist.length,
    //   back: back,
    // };
    // props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
      setList1(itemE6slist[showResults + 1].list_ch);
      setPinyinList1(itemE6slist[showResults + 1].list_py);
      setList2([]);
      setPinyinList2([]);
       
      setShowResults(index);
      savequestion();
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setIndex(index);
      setLength(index - 1);
      setList1(itemE6slist[showResults - 1].list_ch);
      setPinyinList1(itemE6slist[showResults - 1].list_py);
      setList2([]);
      setPinyinList2([]);
      setShowResults(index);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
          if (index - 1 === -1) {
            handleChange(1);
          }
        });
      } else {
        setAnsSlideIndex(currentIndex);
        slidesRef.current?.getActiveIndex().then((index) => {
           
          //  SaveExerciseQueAnswer(length);
          if (index + 1 == length) {
            //  saveContent();
            handleChange(0);
            setLength(-1);
          }
        });
      }
    }
  };

  // Function to handle drag-and-drop
  const onDragEnd = (result) => {
    setIsDraggable(true);
    if (!result.destination) {
      return;
    }
    if (
      result.source.droppableId === "list1" &&
      result.destination.droppableId === "list1"
    ) {
      // Reorder within the same list
      // const newList1 = swapElements(
      //   list1,
      //   result.source.index,
      //   result.destination.index
      // );
      // setList1(newList1);
    } else if (
      result.source.droppableId === "list2" &&
      result.destination.droppableId === "list2"
    ) {
      // Reorder within the same list
      // const newList2 = swapElements(
      //   list2,
      //   result.source.index,
      //   result.destination.index
      // );
      // setList2(newList2);
    } else {
      // Move between lists
      const sourceList = result.source.droppableId === "list1" ? list1 : list2;
      const destList =
        result.destination.droppableId === "list1" ? list1 : list2;
      const sourcePinyinList =
        result.source.droppableId === "list1" ? pinyinList1 : pinyinList2;
      const destPinyinList =
        result.destination.droppableId === "list1" ? pinyinList1 : pinyinList2;
      const updatedSourceList = [...sourceList];
      const [removed] = updatedSourceList.splice(result.source.index, 1);
      const updatedDestList = [...destList];
      updatedDestList.splice(result.destination.index, 0, removed);
      const updatedSourcePinyinList = [...sourcePinyinList];
      const [removedPinyin] = updatedSourcePinyinList.splice(
        result.source.index,
        1
      );
      const updatedDestPinyinList = [...destPinyinList];
      updatedDestPinyinList.splice(result.destination.index, 0, removedPinyin);
       
      if (result.source.droppableId === "list1") {
        // itemE6slist[showResults].list_ch = updatedSourceList;
        setList1(updatedSourceList);
        setList2(updatedDestList);
        setPinyinList1(updatedSourcePinyinList);
        setPinyinList2(updatedDestPinyinList);
      } else {
        setList1(updatedDestList);
        setList2(updatedSourceList);
        setPinyinList2(updatedSourcePinyinList);
        setPinyinList1(updatedDestPinyinList);
      }
    }
  };
  useEffect(() => {
    listCheck();
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      const nextSlideIndex = activeSlideIndex + 1;
      setActiveSlideIndex(nextSlideIndex);
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [list2]);
  const listCheck = () => {
    const result = initialList1.map((text, i) => ({
      Text: text,
      lText: list2[i],
      piniyin: pinyinList2[i],
      status: text === list2[i] ? true : false,
    }));
    setResultList(result);
  };

  const test = (item, index) => {
    const data = JSON.parse(item);
    const fragments = data.map((newItems, index) => (
      <React.Fragment key={index}>
        {newItems["lText"] && (
          <div
            className="droppableProps"
            style={{
              border:
                newItems["status"] === true
                  ? "2px solid var(--ion-color-success) "
                  : "2px solid var(--ion-color-danger)",
              listStyleType: "none",
            }}
          >
            {newItems["lText"]}
            <br />
            {newItems["piniyin"]}
          </div>
        )}
      </React.Fragment>
    ));
    return fragments;
  };

  const testCorrect = (wordCh, wordPy, index) => {
    const word_Ch = wordCh.split("");
    const word_Py = wordPy.split(" ");
    const result = word_Ch.map((text, i) => ({
      text: text,
      piniyin: word_Py[i],
      status: true,
    }));
    const fragments = result.map((newItems, index) => (
      <React.Fragment key={index}>
        <div
          className="droppableProps"
          style={{
            border:
              newItems["status"] === true
                ? "2px solid var(--ion-color-success) "
                : "2px solid var(--ion-color-danger)",
            listStyleType: "none",
          }}
        >
          {newItems["text"]}
          <br />
          {newItems["piniyin"]}
        </div>
      </React.Fragment>
    ));
    return fragments;
  };

  const savequestion = async () => {
    const namesArray = resultList.map((obj) => obj.Text);
    const namesString = namesArray.join(""); // Modify the separator as per your requirement
    itemE6slist[showResults + 1]["answer"] = namesString;
     
  };
   const slideOpts = {
    initialSlide: 0,
    speed: 0,
  };
  // drag and drop end
  const gobackpage = () => {
    // Check if historyss.location.state is defined
    if (historyss.location.state) {
      historyss.replace({
        pathname: backpageValue===2?"/tabs/progress/practiceresult":"/tabs/mainhome/practiceresult",
        state: {
          themeArray: themeArray,
          themename: themename,
          themeId: themeId,
          lessonname: lessonname,
          index: indexs,
          lessonsId: lessonsId,
          levelId: levelId,
          level: level,
          levname: levname,
          backpage: historyss.location.state.backpage, // Access the backpage property safely
          pagename: "E6s",
          backpageValue: backpageValue,
        },
      });
    } else {
      // Handle the case when historyss.location.state is undefined
      console.error("historyss.location.state is undefined");
    }
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader
        className="ion-text-center dubbletitle"
        placeholder={undefined}
      >
        <IonToolbar placeholder={undefined}>
          <div className="ion-title-scroll">
            <IonButtons slot="start" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={gobackpage}
                placeholder={undefined}
              >
                <IonIcon icon={chevronBack} placeholder={undefined} />
              </IonButton>
            </IonButtons>
            <IonTitle
              color="primary"
              className="font20 ion-no-padding"
              placeholder={undefined}
            >
              <span>
                {t("practicequestion.lesson")}{" "}
                {themeArray[indexs ? indexs : 0]?.number}:{" "}
                {t("practicequestion.practice")}
                <p className="ion-no-margin">{themename}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end" placeholder={undefined}></IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing" placeholder={undefined}>
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemE6slist.length - 1 : activeSlideIndex,
            options,
            slideOpts,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
          placeholder={undefined}
        >
          {itemE6slist.map((items, index) => {
            return (
              <IonSlide placeholder={undefined}>
                <div
                  key={index}
                  style={{
                    display: showResults == index ? "block" : "none",
                  }}
                  className="audiorecord"
                >
                  <IonList className="ion-no-padding" placeholder={undefined}>
                    <IonRow
                      className="Practice spacing ion-padding-horizontal"
                      placeholder={undefined}
                    >
                      <IonCol placeholder={undefined}>
                        <IonText placeholder={undefined}>
                          {t("e6s.listen_to_audio")}
                        </IonText>
                      </IonCol>
                      <IonCol
                        size="12"
                        className="test-audio ion-text-left whitebg"
                        placeholder={undefined}
                      >
                        <div className="customaudioplayer">
                          <CustomAudioPlayer src={items?.AudioUrl} />
                        </div>
                        {/* <ReactAudioPlayer className="String" controls controlsList="nodownload noplaybackrate">
                          <source src={items?.AudioUrl} type="audio/mpeg"></source>
                        </ReactAudioPlayer> */}
                      </IonCol>
                    </IonRow>
                    <IonRow
                      className="answer spacing droppablefield ion-padding-horizontal swiper-no-swiping"
                      placeholder={undefined}
                    >
                      <IonCol
                        size="12"
                        style={{ overflow: "scroll" }}
                        placeholder={undefined}
                      >
                        <div className="listed">
                          {test(items.RearrageArrayAnswer, index)}
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="listed">
                          {testCorrect(items.WordCh, items.WordPy, index)}
                        </div>
                      </IonCol>
                    </IonRow>
                    {/* {showImage && (
                      <IonImg
                        className="slide"
                        src="./assets/images/swipe-left-right.gif" placeholder={undefined}/>
                    )} */}
                  </IonList>
                </div>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>

      <Drawer
        className="bottompopup skateInBottom"
        isVisible={isVisible}
        onClose={onClose}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("e6s.drawer.lesson_language")}
              </IonLabel>
              <div className="right-space">
                <IonText color='primary' placeholder={undefined}>Simp.</IonText>
                <IonText placeholder={undefined}>Trad.</IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("e6s.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("e6s.drawer.pinyin")}
              </IonLabel>
              <IonToggle checked slot="end" placeholder={undefined}></IonToggle>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("e6s.drawer.playback_speed")}
              </IonLabel>
              <div className="leftright">
                <a href="#">&lt;</a><span className="speed">1.0</span><a href="#">&gt;</a>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(E6sResultDetails);
