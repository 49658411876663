import React, { useState, useRef, useEffect } from "react";
import { IonImg } from "@ionic/react";
interface CustomAudioPlayerProps {
  src: string;
}
const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.src = src;
      audio.addEventListener("timeupdate", updateProgress);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, [src]);
  const updateProgress = () => {
    const audio = audioRef.current;
    if (audio) {
      const newProgress = (audio.currentTime / audio.duration) * 100;
      setProgress(newProgress);
    }
  };
  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  const handleEnd = () => {
    const audio = audioRef.current;
    if (audio) {
      setTimeout(() => {
        audio.pause();
        setIsPlaying(false);
        setProgress(0);
      }, 100);
    }
  };
  return (
    <>
      <audio controls={false} ref={audioRef} onEnded={handleEnd}></audio>
      <div>
        <button onClick={togglePlay}>
          <IonImg
            src={
              isPlaying
                ? "./assets/images/PlayPauseicon.svg"
                : "./assets/images/autoplayicon.svg"
            }
            placeholder={undefined}
          />
        </button>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "3px",
            background: "var(--ion-color-secondary)",
            borderRadius: "2px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: `${progress}%`,
              top: "-3px",
              width: "10px",
              height: "10px",
              background: "var(--ion-color-primary)",
              borderRadius: "50%",
              transform: "translateX(-50%)",
              transition: "left 0.001s ease-in-out", // Add transition property
            }}
          ></div>
          <div
            style={{
              width: `${progress}%`,
              height: "100%",
              background: "var(--ion-color-primary)",
              transition: "width 0.001s ease-in-out", // Add transition property
            }}
          ></div>
        </div>
      </div>
    </>
  );
};
export default CustomAudioPlayer;
