import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonToggle,
  IonContent,
  IonText,
  IonList,
  IonRow,
  IonIcon,
  IonPage,
  IonTitle,
  useIonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonImg,
  IonSpinner,
  IonModal,
  IonAvatar,
  IonSkeletonText,
} from "@ionic/react";
import "./Dialoguepractice.scss";
import { chevronBack, ellipsisVertical } from "ionicons/icons";
import Drawer from "react-bottom-drawer";
import { CONFIG } from "../App";
import { RouteComponentProps, useHistory } from "react-router";
import useMediaRecorder from "@wmik/use-media-recorder";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import ReactAudioPlayer from "react-audio-player";
interface OwnProps extends RouteComponentProps {
  id: string;
}
interface DialoguepracticeProps extends OwnProps {}
const Dialoguepractice: React.FC<DialoguepracticeProps> = ({ history }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = React.useState(false);
  const [isVisibletc, setIsVisibleTc] = React.useState(false);
  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [isrecST, setrecST] = useState(0);
  const [seconds, setSeconds] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isLastPopup, setIsLastPopup] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [numRecords, setNumRecords] = useState(1);
  const [cheChar, setCheChar] = useState<any>(true);
  const [piniyin, setPiniyin] = useState<any>(true);
  // settings
  const [IsTranLangSim, setIsTranLangSim] = useState<any>();
  const audioRef = useRef<any>();
  const ionListRef = useRef<any>(null);
  const historyss = useHistory<{
    themeArray: any;
    index: string;
    dialogueId: string;
    themeId: string;
    themename: string;
    levelId: string;
    level: string;
    levname: string;
    backpage: string;
    backpageValue: number;
    title: string;
    role: number;
    rolename: string;
    allrole: string;
    videoUrl: string;
    thumb: string;
  }>();
  const themeArray = historyss.location.state
    ? historyss.location.state.themeArray
    : "";
  const index2 = historyss.location.state ? historyss.location.state.index : "";
  const dialogueId = historyss.location.state
    ? historyss.location.state.dialogueId
    : "";
  const themeIddb = historyss.location.state
    ? historyss.location.state.themeId
    : "";
  const themename = historyss.location.state
    ? historyss.location.state.themename
    : "";
  const videoUrl = historyss.location.state
    ? historyss.location.state.videoUrl
    : "";
  const thumb = historyss.location.state ? historyss.location.state.thumb : "";
  const levelId = historyss.location.state
    ? historyss.location.state.levelId
    : "";
  const level = historyss.location.state ? historyss.location.state.level : "";
  const levname = historyss.location.state
    ? historyss.location.state.levname
    : "";
  const backpage = historyss.location.state
    ? historyss.location.state.backpage
    : "";
  const backpageValue = historyss.location.state
    ? historyss.location.state.backpageValue
    : "";
  const role = historyss.location.state ? historyss.location.state.role : "";
  const allrole = historyss.location.state
    ? historyss.location.state.allrole
    : "";
  let string = allrole;
  let one = "";
  let two = "";
  if (string) {
    let result = string.split(",");
    one = result[0];
    two = result[1];
  }
  const rolename = historyss.location.state
    ? historyss.location.state.rolename
    : "";
  const [currentRoleName, setCurrentRoleName] = useState<string>(rolename);
  const [isrecordindex, setRecordindex] = useState(rolename === one ? 0 : 1);
  let token = "";
  let resfreshtoken = "";
  useIonViewWillEnter(() => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let pagecode1 = "See Result";
    let pagecode2 = "Practice again";
    localStorage.setItem("pagecode1", JSON.stringify(pagecode1));
    localStorage.setItem("pagecode2", JSON.stringify(pagecode2));
    GetSettings();
  });
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const scrollToBottom = () => {
    const scrollTimeout = setTimeout(() => {
      if (ionListRef.current) {
        ionListRef.current.scrollIntoView({ behavior: "smooth" });
        const scrollHeight = ionListRef.current.scrollHeight;
        const clientHeight = ionListRef.current.clientHeight;
        ionListRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }, 1);
    return () => {
      clearTimeout(scrollTimeout);
    };
  };
  const endPractice = () => {
    history.replace({
      pathname:
        backpageValue === 2
          ? "/tabs/progress/dialoguedone"
          : "/tabs/mainhome/dialoguedone",
      state: {
        themeArray: themeArray,
        videoUrl: videoUrl,
        thumb: thumb,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        dialogueId: dialogueId,
        allrole: one + "," + two,
        rolename: rolename,
        role: role,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
    localStorage.setItem("pagecode1", JSON.stringify("See Result"));
    localStorage.setItem("pagecode2", JSON.stringify("Practice again"));
    endCompleteDialogue();
  };
  const endCompleteDialogue = async () => {
    try {
      let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
      if (currentUser) {
        token = currentUser.JwtToken;
        resfreshtoken = currentUser.RefreshToken;
      }
      const data = { DialogueId: dialogueId };
      const response = await fetch(url + "/book/CompleteDialogue", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        // handleNextClick();
      } else if (json.StatusCode === 2) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、——])|(?=[（『「〝“])/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“"];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };
  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };
  const textset_tc = (data_tc, data_py) => {
    const html_tc = data_tc;
    const html_py = data_py;
    const range = document.createRange();
    const fragment_tc = range.createContextualFragment(html_tc);
    const fragment_py = range.createContextualFragment(html_py);
    const textreturn_tc = fragment_tc.textContent;
    const textreturn_py = fragment_py.textContent;
    const text_tc = textreturn_tc;
    const text_py = textreturn_py;
    const fragmentsa: any = [];
    if (text_tc && text_py) {
      const words_tc = wordSplitter(text_tc);
      const words_py = pySplitter(text_py);
      if (words_tc.length === words_py.length) {
        for (let i = 0; i < words_tc.length; i++) {
          const wordtc = words_tc[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < words_py.length; i++) {
          const wordpy = words_py[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center dbaudio" id="top-center">
                {data_tc ? cheChar && word["words_tc"] : ""}
              </span>
              <p className="wordpy">{piniyin && word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      } else {
        const arr1 = words_tc;
        const arr2 = words_py;
        const maxLength = Math.max(arr1.length, arr2.length);
        const paddedArr1 = arr1.concat(Array(maxLength - arr1.length).fill(""));
        const paddedArr2 = arr2.concat(Array(maxLength - arr2.length).fill(""));
        for (let i = 0; i < paddedArr1.length; i++) {
          const wordtc = paddedArr1[i] as never;
          let data = {
            words_tc: wordtc,
            words_py: "",
          };
          fragmentsa.push(data);
        }
        for (let i = 0; i < paddedArr2.length; i++) {
          const wordpy = paddedArr2[i] as never;
          if (wordpy !== "" && wordpy !== " " && wordpy !== undefined) {
            fragmentsa[i]["words_py"] = wordpy ? wordpy : "";
          } else {
            fragmentsa[i]["words_py"] = "";
          }
        }
        const fragments = fragmentsa.map((word, index) => (
          <React.Fragment key={index}>
            <li>
              <span className="ion-text-center" id="top-center">
                {data_tc ? cheChar && word["words_tc"] : ""}
              </span>
              <p className="wordpy">{piniyin && word["words_py"]}</p>
            </li>{" "}
          </React.Fragment>
        ));
        //const fragments = ''
        return fragments;
      }
    } else if (text_tc) {
      const words_tc = text_tc.split("");
      for (let i = 0; i < words_tc.length; i++) {
        const word = words_tc[i] as never;
        let data = {
          words_tc: word,
          words_py: "",
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <span className="ion-text-center" id="top-center">
              {data_tc ? cheChar && word["words_tc"] : ""}
            </span>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    } else if (text_py) {
      const words_py = text_py.split(" ");
      for (let i = 0; i < words_py.length; i++) {
        const word = words_py[i] as never;
        let data = {
          words_tc: "",
          words_py: word,
        };
        fragmentsa.push(data);
      }
      const fragments = fragmentsa.map((word, index) => (
        <React.Fragment key={index}>
          <li>
            <p className="wordpy">{piniyin && word["words_py"]}</p>
          </li>{" "}
        </React.Fragment>
      ));
      return fragments;
    }
    //const htmls = { __html: spans.join('') };
  };
  const test = (ContentTc, ContentPy, dbanswer, indexs) => {
    const sentence1 = ContentTc ? ContentTc : "No Data";
    const sentence2 = dbanswer ? dbanswer : "";
    const sentencepy = ContentPy;
    // const sentence1 = "您好！";
    // const sentence2 = "好歡迎登機";
    // const sentencepy = "hǎo!";
    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];
    const characters1 = sentence1
      .split("")
      .filter((item) => !spChar.includes(item) && item !== " ");
    const charactersShow = wordSplitter(sentence1);
    const characters2 = sentence2.split("");
    const charPy = pySplitter(sentencepy);
    const result = characters1.map((text, i) => ({
      Text: charactersShow[i],
      Piniyan: charPy[i],
      status: spChar.includes(text) ? true : characters2.includes(text),
    }));
    const fragments = result.map((word, index) => (
      <React.Fragment key={index}>
        <li>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {ContentTc ? cheChar && word["Text"] : ""}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {piniyin && word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };
  const back = () => {
    history.replace({
      pathname:
        backpage === "progress"
          ? "/tabs/" + backpage
          : "/tabs/mainhome/" + backpage,
      state: {
        themeArray: themeArray,
        videoUrl: videoUrl,
        thumb: thumb,
        dialogueId: dialogueId,
        index: index2,
        themeId: themeIddb,
        themename: themename,
        levelId: levelId,
        level: level,
        levname: levname,
        role: role,
        rolename: rolename,
        allrole: one + "," + two,
        backpage: backpage,
        backpageValue: backpageValue,
      },
    });
    localStorage.setItem("pagecode1", JSON.stringify("Practice"));
    localStorage.setItem("pagecode2", JSON.stringify("Read again"));
  };
  const onClose = React.useCallback(() => {
    setIsVisible(false);
    setIsVisibleTc(true);
  }, []);
  const onDataAvailable = (data) => {
    const progress = setProgress((prevProgress) => prevProgress + data.size);
  };
  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: "audio/webm" },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable,
    });
  const handleStartRecording = () => {
    scrollToBottom();
    setLoading(true);
    setSeconds(0);
    setIsRunning(true);
    setProgress(0);
    setApicall(0);
    startRecording();
    let leng = isrecST + 1;
    setrecST(leng);
    setIsRecording(true);
  };
  const handleStopRecording = () => {
    scrollToBottom();
    setIsRunning(false);
    stopRecording();
    setIsRecording(false);
    setIsSpinner(true);
    setLoading(false);
    if (error) {
    }
    if (numRecords + 1 === itemlist.length) {
    }
  };
  function blobToBase64(blob: Blob): Promise<string> {
    // blob to base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = () => {
        reject("Failed to convert blob to base64");
      };
    });
  }
  useEffect(() => {
    // for media blob
    if (mediaBlob) {
      blobToBase64(mediaBlob)
        .then((base64String) => {
          if (status === "stopped" && isApicall === 0) {
            convertaudiototext(base64String);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [mediaBlob]);
  useEffect(() => {
    // get Dialogue API
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 1000,
    });
    const fetchData = async () => {
      try {
        const response = await fetch(
          url + "/Book/GetDialogueQueAns?dialogueId=" + dialogueId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            method: "GET",
          }
        );
        const json = await response.json();
        dismiss();
        setitems([]);
        if (json.StatusCode === 1) {
          for (let i = 0; i < json.Result.length; i++) {
            const element = json.Result[i];
            element["RedDisplay"] = false;
          }
          setitems(json.Result);
          handleNextClick(json.Result);
          // handleNextClick();
        } else if (json.StatusCode === 2) {
        } else {
          presenttost({
            message: json.Message,
            duration: 1500,
            position: "bottom",
          });
        }
      } catch (error) {
        console.error(error);
        dismiss();
      }
    };
    fetchData();
  }, []);
  // audio to text API
  const convertaudiototext = async (audiobasebase64: any) => {
    const lastRoleIndex = displayedDialogues.length - 1;
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    let indexR = itemlist.findIndex((item) => item.Type === currentRoleName);
    if (indexR === -1) {
      console.error("Role not found in itemlist.");
      return;
    }
    const targetValue = currentRoleName;
    const count = displayedDialogues.filter(
      (value) => value.Type === targetValue
    ).length;
    let data = {
      answerId: displayedDialogues[lastRoleIndex].Id,
      base64String: audiobasebase64,
      dialogueId: dialogueId,
      role: displayedDialogues[lastRoleIndex].Type,
      LangType: IsTranLangSim ? "cn" : "tw",
      sentence: IsTranLangSim
        ? displayedDialogues[lastRoleIndex].ContentSc
        : displayedDialogues[lastRoleIndex].ContentTc,
      itemCount: count,
    };
    try {
      const response = await fetch(url + "/Book/SaveDialogueAnswer", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const json = await response.json();
      const updatedItemlist = [...itemlist];
      setRecordindex(indexR);
      // handleNextClick(itemlist);
      // Delayed execution of handleNextClick(itemlist) by 1 seconds
      setShowButton(false);
      setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
      setTimeout(() => {
        handleNextClick(itemlist);
        // handleNextClick();
      }, 1000);
      //POPUP Open For Crew
      const arr = itemlist;
      const lastIndex = arr.length - 1;
      const lastElement = arr[lastIndex];
      if (
        arr.length == displayedDialogues.length &&
        lastElement.Type == currentRoleName
      ) {
        setShowSkeleton(false);
        await setTimeout(async () => {
          await setIsLastPopup(true);
        }, 300);
      }
      //End
      if (itemlist[lastRoleIndex].Type === currentRoleName) {
        itemlist[lastRoleIndex].RedDisplay = true;
      }
      if (json.StatusCode === 1) {
        updatedItemlist[lastRoleIndex]["dbanswer"] = json.Result.Answer;
        setitems(updatedItemlist);
        // setSentence2(json.Result.Answer ? json.Result.Answer : "abxx");
        setIsSpinner(false);
        // handleCheckword();
      } else {
      }
      setApicall(1);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // button circuler progress bar logic
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 60) {
          setSeconds((prevSeconds) => prevSeconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
          handleStopRecording();
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning, seconds]);
  const percentage = (seconds / 60) * 100;
  //NEW Logic
  const [currentIndex, setCurrentIndex] = useState<any>(0);
  const [switchIndex, setSwitchIndex] = useState(role === 0 ? 0 : 1);
  const [loading, setLoading] = useState(false);
  const [displayedDialogues, setDisplayedDialogues] = useState<any[]>([]);
  useMemo(() => {
    setDisplayedDialogues(itemlist.slice(0, currentIndex));
  }, [itemlist, currentIndex]);
  useEffect(() => {
    const scroll = setTimeout(() => {
      scrollToBottom();
    }, 100);
    if (isLastPopup) clearTimeout(scroll);
  }, [
    displayedDialogues,
    currentIndex,
    itemlist,
    showButton,
    showSkeleton,
    isRecording,
    loading,
    isSpinner,
    seconds,
    isLastPopup,
  ]);
  const handleNextClick = async (items) => {
    
    setCurrentIndex(currentIndex + 1);
    let index = displayedDialogues.length; // last index for loop
    const lastIndex = items.length - 1;
    const lastElement = items[lastIndex];
    
    if (index < items.length) {
      
      if (items[index]?.Type === currentRoleName) {
        setShowButton(true);
        setShowSkeleton(false);
        
      } else if (items[index]?.Type !== currentRoleName) {
        setShowButton(false);
        setTimeout(async () => {
          await setShowSkeleton(true);
        }, 2500);
        
      }
    } else {
      setShowSkeleton(false);
      // Popup open logic For Pax
      if (lastElement.Type !== currentRoleName) {
        await setTimeout(async () => {
          await setIsLastPopup(true);
        }, 300);
      }
      
    }
  };
  const handleRoleSwitch = async (items) => {
    // Determine the target role based on the current switchIndex
    const targetRole = currentRoleName === one ? two : one;
    // Determine the target index based on the switch direction
    const targetIndex = currentRoleName === one ? 1 : 0;
    let index = targetIndex; // Initialize index to the targetIndex
    // setCurrentIndex(index);
    for (let i = 0; i < items.length; i++) {
      // Check if the Type of the current item matches the targetRole
      if (items[i].Type !== targetRole) {
        // If not, increment index and continue to the next item
        index++;
      } else {
        // If it matches, set the index and break the loop
        setCurrentIndex(targetRole === one ? index + 1 : index);
        break;
      }
    }
    // Update the state variables accordingly
    setCurrentRoleName(targetRole);
    setSwitchIndex(targetIndex);
    setRecordindex(targetIndex);
    await setTimeout(async () => {
      await setIsLastPopup(false);
    }, 500);
    setShowButton(true);
    setShowSkeleton(false);
    // Reset the RedDisplay property of items
    const updatedItemList = itemlist.map((item) => ({
      ...item,
      RedDisplay: false,
    }));
    setitems(updatedItemList);
    // Clear displayedDialogues
    setDisplayedDialogues([]);
    // Check if the first item's type is different from the target role
    if (items[0].Type !== targetRole) {
      // Create an audio element
      const audioElement = new Audio(items[0].FileUrl);
      // Play the audio after a delay of 500 milliseconds
      setTimeout(() => {
        audioElement.play();
      }, 500);
    }
  };
  //End New Logic
  // Setting Code Start
  const setIsLangueTrans = (value, type) => {
    if (type == 1) {
      setIsTranLangSim(true);
      saveLangue(value, true, -1);
    } else {
      setIsTranLangSim(false);
      saveLangue(value, true, -1);
    }
  };
  const saveLangue = async (val, isLesson, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 3000,
    // });
    const data = {
      language: val,
      isLesson: isLesson,
    };
    try {
      const response = await fetch(url + "/User/SaveLanguage", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        // history.replace('/login');
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };
  const SaveUserSettings = async (char: any, piniyin: any) => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    const settings = {
      isChineseCharacter: char,
      isPinyin: piniyin,
    };
    try {
      const response = await fetch(url + "/User/SaveSetting", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
        body: JSON.stringify(settings),
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        // Settings saved successfully
        // Handle the response as needed
      } else if (json.StatusCode === 2) {
        // Handle token refresh logic
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const GetSettings = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    try {
      const response = await fetch(url + `/User/GetSetting`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          // Include other headers if needed for User/GetSetting
        },
      });
      const json = await response.json();
      // dismiss();
      if (json.StatusCode === 1) {
        setCheChar(json.Result.IsChineseCharacter);
        setPiniyin(json.Result.IsPinyin);
        setIsTranLangSim(
          (json.Result.LessonLanguage === "Chinese, Simplified") === true
        );
      } else if (json.StatusCode === 2) {
      } else {
        // Handle other StatusCode values
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };
  const toggleChe = () => {
    setCheChar((prevCheChar) => {
      const newCheChar = !prevCheChar;
      let newpiniyin: any;
      if (newCheChar === false) {
        setPiniyin(true);
        newpiniyin = true;
      } else {
        newpiniyin = null;
      }
      SaveUserSettings(newCheChar, newpiniyin !== null ? newpiniyin : piniyin); // Save the new state if needed
      return newCheChar;
    });
  };
  const togglepiniyin = () => {
    setPiniyin((prevPiniyin) => {
      const newPiniyin = !prevPiniyin;
      let newchechar: any;
      if (newPiniyin === false) {
        setCheChar(true);
        newchechar = true;
      } else {
        newchechar = null;
      }
      SaveUserSettings(newchechar !== null ? newchechar : cheChar, newPiniyin); // Save the new state if needed
      return newPiniyin;
    });
  };
  // Setting Code End
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center dubbletitle">
        <IonToolbar>
          <div className="ion-title-scroll">
            <IonButtons slot="start">
              <IonButton color="primary" onClick={back}>
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle color="primary" className="font20 ion-no-padding">
              <span>
                {t("dialoguepractice.dialogue")}:{" "}
                {t("dialoguepractice.practice")}
                <p className="ion-no-margin">{themeArray[index2]?.ContentEn}</p>
              </span>
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="ellipsisVertical"
                onClick={openDrawer}
                id="open-modal"
                color="primary"
              >
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
            </IonButtons>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing dialoguepractice">
        <div className="w-100">
          <IonList>
            {displayedDialogues.map((dialogue, index) => (
              <IonItem
                key={dialogue.Id}
                className={
                  (dialogue.Type === one ? "ion-text-end " : "") +
                  (dialogue.Type !== currentRoleName ? "play" : "")
                }
              >
                <IonAvatar slot={dialogue.Type === one ? "end" : "start"}>
                  <img src={dialogue.TypeImage} alt={dialogue.Type} />
                </IonAvatar>
                <IonLabel>
                  <div className="dialoguebox">
                    <ul
                      className={
                        "lession-icon " +
                        (IsTranLangSim === true ? "simp-char" : "trad-char")
                      }
                    >
                      <span id={`eldb_${index}`}>
                        {!dialogue?.RedDisplay &&
                          textset_tc(
                            IsTranLangSim
                              ? dialogue?.ContentSc
                              : dialogue?.ContentTc,
                            dialogue?.ContentPy
                          )}
                      </span>
                      <span id={`elst_${index}`}>
                        {dialogue?.RedDisplay &&
                          test(
                            IsTranLangSim
                              ? dialogue?.ContentSc
                              : dialogue?.ContentTc,
                            dialogue?.ContentPy,
                            dialogue?.dbanswer,
                            index
                          )}
                      </span>
                    </ul>
                    <p>{dialogue.ContentEn}</p>
                    {dialogue.Type !== currentRoleName ? (
                      ""
                    ) : (
                      <>
                        {(dialogue?.RedDisplay === false ||
                          undefined ||
                          null) && (
                          <>
                            {loading && (
                              <div className="ion-text-left audiowave">
                                <IonImg
                                  className="wave"
                                  src="./assets/images/record-button.gif"
                                />
                              </div>
                            )}
                            {!loading && (
                              <div className="ion-text-left audiowavemic">
                                <div>
                                  <IonImg
                                    className="mic"
                                    style={{ width: "30px", height: "20px" }}
                                    src="./assets/images/mic.svg"
                                  />
                                  <span>Record sentence</span>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </IonLabel>
                <ReactAudioPlayer
                  ref={audioRef}
                  autoPlay={dialogue.Type !== currentRoleName} // Autoplay only when dialogue.Type !== currentRoleName
                  controls={false} // Hide controls
                  src={dialogue?.FileUrl} // Use dialogue?.FileUrl as the source
                  onPlay={() => setShowSkeleton(false)}
                  // onEnded={() => handleNextClick(itemlist)} // Call handleNextClick when audio ends
                  onEnded={() =>
                    setTimeout(() => handleNextClick(itemlist), 3000)
                  } // Add a 3-second delay
                />
              </IonItem>
            ))}
            <div ref={ionListRef} />
            {!isLastPopup && (
              <>
                {showSkeleton && (
                  <IonItem>
                    {/* <IonAvatar slot="start">
                      <IonSkeletonText
                        animated
                        style={{ width: "40px", height: "40px" }}
                      />
                    </IonAvatar> */}
                    <IonLabel style={{ marginLeft: "56px" }}>
                      <IonSkeletonText
                        animated
                        style={{ width: "80%", height: "20px" }}
                      />
                      <IonSkeletonText
                        animated
                        style={{ width: "60%", height: "20px" }}
                      />
                    </IonLabel>
                  </IonItem>
                )}
              </>
            )}
            <div ref={ionListRef} />
          </IonList>
        </div>
        <div className="back-forward">
          {!isLastPopup && (
            <>
              <div className="audio-record">
                {!isSpinner ? (
                  <>
                    {showButton && (
                      <>
                        <span
                          onClick={(e: any) => {
                            isRecording
                              ? handleStopRecording()
                              : handleStartRecording();
                          }}
                        >
                          <IonImg
                            src={`./assets/images/${
                              isRecording ? "stop" : "micCircle"
                            }.svg`}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: "0",
                              right: "0",
                              top: "-15px",
                              margin: "0 auto",
                            }}
                          >
                            {isRecording ? (
                              <CircularProgressbar
                                value={percentage}
                                styles={buildStyles({
                                  pathTransition: "none",
                                  trailColor:
                                    "var(--ion-color-warning-contrast-rgb)",
                                  pathColor: "var(--ion-color-warning)",
                                })}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </span>
                        <p>{t("e5s.press_to_record")}</p>
                      </>
                    )}
                  </>
                ) : (
                  <IonSpinner
                    name="crescent"
                    style={{
                      height: "52px",
                      width: "52px",
                      color: "var(--ion-color-warning)",
                    }}
                  />
                )}
              </div>
            </>
          )}
          <div></div>
        </div>
      </IonContent>
      <IonModal trigger="open-modal" initialBreakpoint={1} breakpoints={[0, 1]}>
        <IonRow placeholder={undefined}>
          <IonList className="list">
            <IonItem class="ion-no-padding">
              <IonLabel>{t("lesson.drawer.book_language")}</IonLabel>
              <div className="right-space">
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Simplified", 1)}
                  style={{
                    color:
                      IsTranLangSim === true
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Simp.
                </IonText>
                <IonText
                  onClick={() => setIsLangueTrans("Chinese, Traditional", 2)}
                  style={{
                    color:
                      IsTranLangSim === false
                        ? "var(--ion-color-primary)"
                        : "rgba(0, 0, 0, 0.3)",
                  }}
                >
                  Trad.
                </IonText>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("dialoguepractice.drawer.chinese_characters")}
              </IonLabel>
              <IonToggle
                checked={cheChar}
                onClick={toggleChe}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
            <IonItem class="ion-no-padding" placeholder={undefined}>
              <IonLabel placeholder={undefined}>
                {t("dialoguepractice.drawer.pinyin")}
              </IonLabel>
              <IonToggle
                checked={piniyin}
                onClick={togglepiniyin}
                slot="end"
                placeholder={undefined}
              />
            </IonItem>
          </IonList>
        </IonRow>
      </IonModal>
      <Drawer
        className="bottompopup switch skateInBottom"
        isVisible={isLastPopup}
        onClose={onClose}
      >
        <IonRow>
          <IonList className="list Leisue">
            <IonItem class="ion-no-padding">
              <div className="mainpart">
                <IonButton
                  color="warning"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                  onClick={() => handleRoleSwitch(itemlist)} // Call the switchRole function on button click
                >
                  <IonImg className="wave" src="./assets/images/switch.svg" />{" "}
                  {t("dialoguepractice.drawer2.switch_role")}
                </IonButton>
                <IonButton
                  color="white"
                  type="submit"
                  className="whitebtn"
                  expand="block"
                  onClick={endPractice}
                >
                  {t("dialoguepractice.drawer2.end_practice")}
                </IonButton>
              </div>
            </IonItem>
          </IonList>
        </IonRow>
      </Drawer>
    </IonPage>
  );
};
export default React.memo(Dialoguepractice);
