import React, { useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  IonImg,
  useIonViewWillEnter,
  useIonToast,
  IonSlides,
  IonSlide,
  IonSkeletonText,
} from "@ionic/react";
import "./VocabularyE1.scss";
import { CONFIG } from "../App";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface MyObject {
  id: number;
  name: string;
  type: "e1s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface VocabularyE1Props {
  itemE1s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}
const VocabularyE1: React.FC<VocabularyE1Props> = (props: VocabularyE1Props, { isSuccess, isWrong }) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [presenttost] = useIonToast();
  const [length, setLength] = React.useState(-1);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isCorrectArray, setIsCorrectArray] = useState<boolean[]>([]);
  const [selectedOptionsIndex, setSelectedOptionsIndex] = useState<number[]>(
    []
  );
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number[]>([]);
  const [correctAnswerUser, setCorrectAnswerUser] = useState<any>([]);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  const [clickedIndices, setClickedIndices] = useState<any>([]);
  const [clickedSlides, setClickedSlides] = useState<number[]>([]);
  const [answers, setAnswers] = React.useState<any[]>([]);
  let finalanswer = {};
  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;

  // localStorage Variable
  const practiceData: any = localStorage.getItem("practice");
  const practice = JSON.parse(practiceData);
  const JSONdata: any = localStorage.getItem("vocabAnswers");
  const data = JSON.parse(JSONdata);
  const [itemList, setItemList] = useState<any[]>(() => {
    return practice.E1s !== null ? practice.E1s : props.itemE1s;
  });

  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e1s",
      pagecouter: pagecouter,
      back: back,
      totalque: itemList?.length,
    };
    props.onObjectChange(myObject);
  }
  const handleImageLoad = () => {
    setIsLoading(false);
  };
  useIonViewWillEnter(() => {
    setLength(1);
    setTimeout(() => {
      // setShowImage(true);
    }, 4000); // Delay in milliseconds (e.g. 4000ms = 4 seconds)
    // return () => clearTimeout(timer);

    if (practice.E1s !== null) {
      setShowImage(true);
    } else if (props.itemE1s !== null) {
      setTimeout(() => {
        setShowImage(true);
      }, 2000);
    }

    // Extract indices from dataAnswers and set them in clickedIndices
    if (data?.P1s) {
      const indices = data?.P1s?.map((answer) => answer.index);
      setClickedIndices(indices);

      // Call handleAnswerSelection fucntion if we have E1s data in local storage
      data?.P1s?.forEach((item) => {
        const { selected, answer, index: itemIndex } = item;
        handleAnswerSelection(selected, answer, itemIndex);
      });
    }
  });
  const slidesRef = useRef<HTMLIonSlidesElement>(null);
  const options = {
    keyboard: true,
  };
  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };
  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };
  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    // setShowImage(false);
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      if (swipeDirection == "prev") {
        // Back Login code
        setAnsSlideIndex(currentIndex);
        // Back Login code
        if (currentIndex == 0) {
          setbackSlideIndex(-1);
        }
      } else {
        // setSelected(false);
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          // SaveExerciseQueAnswer(currentIndex, index);
          
          if (index + 1 == length) {
            setLength(-1);
            // saveContent();
            handleChange(0);
          }
        });
      }
    }
  };

  const handleSlideDidStart = async () => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const currentIndex = swiper.activeIndex;
      // Check if the current slide index is in the list of clicked slides
      if (!clickedSlides.includes(currentIndex)) {
        // Randomize options only if the slide hasn't been clicked
        const randomizedOptions = [...itemList[currentIndex].Options];
        for (let i = randomizedOptions.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [randomizedOptions[i], randomizedOptions[j]] = [
            randomizedOptions[j],
            randomizedOptions[i],
          ];
        }
        // Update itemList with randomizedOptions
        const updatedItemList = [...itemList]; // Create a copy of itemList
        updatedItemList[currentIndex] = {
          ...updatedItemList[currentIndex],
          Options: randomizedOptions,
        };
        // Update itemList in state
        setItemList(updatedItemList);
      }
    }
  };

  const handleAnswerSelection = (
    selectedOption: string,
    correctAnswer: string,
    index: number
  ) => {
    const data = {
      selected: selectedOption,
      answer: correctAnswer,
      index,
    };
    setAnswers((oldData) => [...oldData, data]);

    if (selectedOption === correctAnswer) {
      setmyanswer("");
      setmyanswer(selectedOption);
      const pushValue = (selectedOption) => {
        setCorrectAnswerUser((prevValue) => [...prevValue, selectedOption]);
      };
      pushValue(selectedOption);
    } else {
      setmyanswer("");
      setmyanswer(selectedOption);
    }
    // true & false condition for each
    const isAnswerCorrect = selectedOption === correctAnswer;
    setIsCorrectArray((prevIsCorrectArray) => {
      const newIsCorrectArray = [...prevIsCorrectArray];
      newIsCorrectArray[index] = isAnswerCorrect;
      return newIsCorrectArray;
    });

    // selection option
    const selectedIndex = itemList[index]?.Options?.findIndex(
      (option) => option?.Word === selectedOption
    );

    setSelectedOptionsIndex((prevSelcted) => {
      const newSelectedOptions = [...prevSelcted];
      newSelectedOptions[index] = selectedIndex;
      return newSelectedOptions;
    });

    // Set the correct answer index
    const correctIndex = itemList[index]?.Options?.findIndex(
      (option) => option?.Word === correctAnswer
    );
    // setCorrectAnswerIndex(correctIndex);
    setCorrectAnswerIndex((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[index] = correctIndex;
      return newIndices;
    });

    // Add the index of the clicked slide to clickedSlides
    setClickedSlides((prevClickedSlides) => [...prevClickedSlides, index]);
  };

  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);

  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("vocabAnswers");
    const data = JSON.parse(JSONdata);
    const formattedData = {
      P1s: answers,
      P2s: data?.P2s,
      P3s: data?.P3s,
      P4s: data?.P4s,
      P5s: data?.P5s,
      P6s: data?.P6s,
    };
    localStorage.setItem("vocabAnswers", JSON.stringify(formattedData));
    // setDataAnswers(formattedData);
  };

  useEffect(() => {
    if (length > itemList?.length) {
      setLength(-1);
      // saveContent();
      handleChange(0);
    }
  }, [activeSlideIndex, itemList?.length]);

  const SaveExerciseQueAnswer = async (indexdb, index) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    
    const data = {
      eId: itemList[ansIndex].EId,
      eType: itemList[ansIndex].TemplateCode,
      //question: props.itemE2s[indexdb - 1]?.Question?.[0].ImageUrl,
      //rightAnswer: foundValue.Choice,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      answer: myanswer,
      writingScore: 0,
    };
    
    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }
    // present({
    //   message: '',
    // })
    let anw = {};
    let tmscore = 0;
    pagecouter = 0;
    for (let index = 0; index < itemList?.length; index++) {
      const element = itemList[index];
      const propName = ("q" + (index + 1)).toString();
      const propValue = element.answer;
      anw[propName] = propValue.toString();
      finalanswer = anw;
      if (element.score) {
        tmscore += element.score;
      }
    }
    const stringWithBackslashes = JSON.stringify(finalanswer);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    const myArray = Object.entries(finalanswer);
    for (let k = 0; k < myArray.length; k++) {
      const element = myArray[k][1];
      if (element != "") {
        pagecouter++;
      }
    }

    const data = {
      contentPageCode: itemList[0].TemplateCode,
      type: "exercise-score",
      bookId: itemList[0].BookId,
      contentPageId: itemList[0].PageId,
      answer: stringWithoutBackslashes,
      score: correctAnswerUser.length,
      options: null,
      pageRead: pagecouter,
    };
    //dismiss()
    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      const json = await response.json();
      //dismiss()
      if (json.StatusCode == 1) {
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  return (
    <IonPage className="intro">
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide:
              props.lastSlide === 1 ? itemList?.length - 1 : activeSlideIndex,
            options,
          }}
          onIonSlideTransitionStart={() =>
            itemList?.length - 1 && props.lastSlide === 1
              ? ""
              : handleSlideDidStart()
          }
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
        >
          {itemList?.map((items, index) => {
            return (
              <IonSlide key={index}>
                <IonList className="ion-no-padding height100">
                  <IonRow className="Practice leftrightspace ion-padding-horizontal">
                    <IonCol>
                      <IonText>
                        {t("e1s.which_picture_matches_chinese")}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow className="bigbox leftrightspace ion-padding-horizontal">
                    <IonCol
                      size="12"
                      className="ion-no-padding ion-text-center"
                    >
                      <ul className="lession-icon">
                        <li>
                          <span>
                            {props.cheChar &&
                              (props.isTranLangSim
                                ? items.WordSc
                                : items.WordCh)}
                          </span>
                          {props.piniyin && items.WordPy}
                        </li>
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer leftrightspace datakey ion-padding-horizontal">
                    {itemList?.[index]?.Options?.map((datav, indexnew) => (
                      <IonCol key={indexnew} size="6">
                        {showImage ? (
                          <IonCard
                            id={datav.questionno}
                            style={{
                              borderColor:
                                isCorrectArray[index] !== null &&
                                isCorrectArray[index] &&
                                // datav.Word === selectedAnswer
                                indexnew === selectedOptionsIndex[index]
                                  ? "var(--ion-color-success)" // Green outline for the correct answer
                                  : // correctAnswerIndex[index] !== null &&
                                  indexnew === correctAnswerIndex[index]
                                  ? "var(--ion-color-success)" // Green outline for the correct answer when wrong is selected
                                  : isCorrectArray[index] !== null &&
                                    !isCorrectArray[index] &&
                                    // datav.Word === selectedAnswer
                                    indexnew === selectedOptionsIndex[index]
                                  ? "var(--ion-color-danger)" // Red outline for the wrong answer
                                  : "",
                            }}
                            onClick={() =>
                              showImage && !clickedIndices?.includes(index)
                                ? (() => {
                                    setClickedIndices((prevIndices: any) => [
                                      ...prevIndices,
                                      index,
                                    ]);
                                    handleAnswerSelection(
                                      datav.Word,
                                      items.answer,
                                      index
                                    );
                                  })()
                                : ""
                            }
                          >
                            <IonCardContent class="ion-no-padding imageview">
                              <div className="min80">
                                <IonImg
                                  src={datav.Word}
                                  onLoad={handleImageLoad}
                                />
                              </div>
                            </IonCardContent>
                          </IonCard>
                        ) : (
                          <IonSkeletonText
                            animated
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0px",
                              border: "var(--ion-color-secondary) solid 2px",
                              borderRadius: "20px",
                            }}
                          />
                        )}
                      </IonCol>
                    ))}
                    <IonCol size="12">
                      {/* {showImage && (
                        <IonImg
                          className="slide"
                          src="./assets/images/swipe-left-right.gif"/>
                      )} */}
                    </IonCol>
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(VocabularyE1);
