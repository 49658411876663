import React, { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonItem,
  IonImg,
  IonRow,
  IonGrid,
  IonText,
  IonCol,
  IonInput,
  IonList,
  IonIcon,
  IonButton,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonToast,
  useIonLoading,
  useIonViewWillEnter,
  IonModal,
} from "@ionic/react";
import "./Editprofile.scss";
import { chevronBack, close } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {}
interface EditprofileProps extends OwnProps {}
const Editprofile: React.FC<EditprofileProps> = () => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const history = useHistory();
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>({});
  const [inputValue, setInputValue] = useState<any>("");
  const [imageBase64, setImageBase64] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [openModal, setOpenModal] = useState<any>(false);
  const [openConfirm, setOpenConfirm] = useState<any>(false);
  let token = "";
  let resfreshtoken = "";
  let userdata;
  let username;
  useIonViewWillEnter(() => {
    const imageData: any = localStorage.getItem("ProfileImageSrc");
    const newImageSrc = JSON.parse(imageData);
    setImageSrc(newImageSrc);
    //setMenuEnabled(false);
    let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
    if (logindata) {
      userdata = logindata?.Result;
      username = userdata?.UserName;
    }
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    GetProfie();
  });
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const GetProfie = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 2000,
    });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode === 1) {
        setitems(json.Result);
        setInputValue(json.Result.Name);
      } else if (json.StatusCode === 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      //duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode === 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };

  // const submit = () => {
  //   confirmAlert({
  //     customUI: ({}) => {
  //       return (
  //         <div className="react-confirm-alert-body">
  //           <div className="close">
  //             <IonIcon
  //               onClick={openConfirm}
  //               icon={close}
  //               placeholder={undefined}
  //             />
  //           </div>
  //           <div className="icon">
  //             <IonImg
  //               src="./assets/images/bigTrash.svg"
  //               alt=""
  //               placeholder={undefined}
  //             />
  //           </div>
  //           <p>{t("editprofile.submit.title")}</p>
  //           <div className="react-confirm-alert-button-group">
  //             <IonButton
  //               onClick={openConfirm}
  //               className="alertcancel"
  //               placeholder={undefined}
  //             >
  //               {t("editprofile.submit.cancel")}
  //             </IonButton>
  //             <IonButton
  //               onClick={setRemoveImage}
  //               className="alertsubmit"
  //               placeholder={undefined}
  //             >
  //               {t("editprofile.submit.remove")}
  //             </IonButton>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // };
  // const onClose = React.useCallback(() => {
  //   setIsVisible(false);
  // }, []);
  const save = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    present({
      message: "",
      cssClass: "custom-spinner",
      duration: 2000,
    });
    const data = { name: inputValue, imageBase64: imageBase64 };
    localStorage.setItem("ProfileImageSrc", JSON.stringify(imageSrc));
    try {
      const response = await fetch(url + "/User/SaveProfile", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      presenttost({
        message: json.Message,
        duration: 1500,
        position: "bottom",
      });
      GetProfie();
      if (json.StatusCode === 1) {
        await history.replace(
          { pathname: "/tabs/profile" },
          { direction: "none" }
        );
      } else if (json.StatusCode === 2) {
        refreshtoken();
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const handleImageUpload = async (sourceType: CameraSource) => {
    try {
      // Take a photo or select from the gallery
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: sourceType,
      });
      // Get the file path of the image
      const fileUri: any = image.webPath;
      // Write the image to the filesystem
      const binaryData = await fetch(fileUri).then((r) => r.blob());
      // Convert image to Base64
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(binaryData);
      });
      // Store the Base64 data in a variable
      const imageBase64: any = base64Image;
      setImageSrc(imageBase64);

      //remove data:image/jpeg;base64, portion from Base64
      var strImage = imageBase64.replace(/^data:image\/[a-z]+;base64,/, "");
      setImageBase64(strImage);
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const setRemoveImage = async () => {
    try {
      const imagePath: any = [{ url: "./assets/images/noimg.jpg" }];
      setImageSrc(imagePath[0].url);
      // Fetch the image file using fetch API
      const response = await fetch(imagePath[0].url);
      const blob = await response.blob();
      // Read the image file as a data URL
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        // Extract the base64 data URI from the reader result
        const base64DataURI: any = reader.result;
        let removeImage = base64DataURI.replace(
          /^data:image\/[a-z]+;base64,/,
          ""
        );
        // Set the base64 image to state or do whatever you need with it
        setImageBase64(removeImage);
      };
      // onClose();
      setOpenConfirm(false);
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const back = () => {
    history.replace({ pathname: "/tabs/profile" }, { direction: "none" });
  };
  return (
    <IonPage className="intro" placeholder={undefined}>
      <IonHeader className="ion-text-center" placeholder={undefined}>
        <IonToolbar placeholder={undefined}>
          <IonButtons slot="start" placeholder={undefined}>
            <IonButton onClick={back} color="primary" placeholder={undefined}>
              <IonIcon icon={chevronBack} placeholder={undefined} />
            </IonButton>
          </IonButtons>
          <IonTitle
            color="primary"
            className="font20 ion-no-padding"
            placeholder={undefined}
          >
            <span>{t("editprofile.edit_profile")}</span>
          </IonTitle>
          <IonButtons slot="end" placeholder={undefined}>
            {/* <IonButton color='primary'>
              <IonIcon icon={ellipsisVertical} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {/*<div>
        <input type="file" accept="image/*" onChange={handleImageUpload} />
        {imageSrc && <IonImg src={imageSrc} />}
          </div>*/}
      <IonContent className="listing" placeholder={undefined}>
        <IonGrid className="changepassword editprofile" placeholder={undefined}>
          <IonList placeholder={undefined}>
            <IonRow className="edit ion-text-center" placeholder={undefined}>
              <IonCol size="12" class="pro pointer" placeholder={undefined}>
                <div className="logo" onClick={() => setOpenModal(true)}>
                  {/* : "./assets/images/bang-circle-blue.svg" */}
                  {/* <IonImg src={imageSrc? imageSrc : itemlist.ProfileUrl } /> */}
                  <IonImg
                    src={imageSrc ? imageSrc : itemlist.ProfileUrl}
                    placeholder={undefined}
                  />
                  {/* <IonImg src={itemlist.ProfileUrl ? itemlist?.ProfileUrl : './assets/images/bang-circle-blue.svg'} /> */}
                </div>
                <span>Pro</span>
              </IonCol>
              <IonCol
                size="12"
                className="ion-text-center"
                placeholder={undefined}
              >
                <h6 className="no-margin" onClick={() => setOpenModal(true)}>
                  <b>{t("editprofile.edit_picture")}</b>
                </h6>
              </IonCol>
            </IonRow>
            <IonItem
              className="inputwithicon tooltiparea"
              placeholder={undefined}
            >
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>
                  {t("editprofile.email")}
                </IonText>
              </IonLabel>
              <div className="labeltitle">
                {/* <IonIcon icon={informationCircleOutline} /> */}
                <IonImg
                  class="profileout"
                  src="./assets/images/bang-circle-blue.svg"
                  alt=""
                  placeholder={undefined}
                />
                <div className="tooltip">
                  {t("editprofile.registered_email_note")}
                </div>
              </div>
            </IonItem>
            <IonItem className="inputwithicon twoicon" placeholder={undefined}>
              {/* <IonIcon icon={mail} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/darkmail.svg"
                alt=""
                placeholder={undefined}
              />
              <IonText color="primary" placeholder={undefined}>
                {itemlist?.Email ? itemlist.Email : userdata?.Email}
              </IonText>
            </IonItem>
            <IonItem
              className="inputwithicon tooltiparea"
              color="white"
              placeholder={undefined}
            >
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>
                  {t("editprofile.account")}
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem className="inputwithicon twoicon" placeholder={undefined}>
              {/* <IonIcon icon={calendarClearSharp} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/calendar.svg"
                alt=""
                placeholder={undefined}
              />
              <IonText color="primary" placeholder={undefined}>
                {itemlist?.Account}
              </IonText>
            </IonItem>
            <IonItem
              className="inputwithicon tooltiparea"
              color="white"
              placeholder={undefined}
            >
              <IonLabel placeholder={undefined}>
                <IonText no-margin placeholder={undefined}>
                  {t("editprofile.name")}
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem
              className="inputwithicon twoicon pr-0"
              placeholder={undefined}
            >
              {/* <IonIcon icon={person} /> */}
              <IonImg
                class="profileout"
                src="./assets/images/user-icon.svg"
                alt=""
                placeholder={undefined}
              />
              <IonInput
                name="username"
                value={inputValue}
                onIonChange={handleInputChange}
                type="text"
                required
                placeholder={undefined}
              ></IonInput>
            </IonItem>
          </IonList>
          <IonRow class="passwordbtn" placeholder={undefined}>
            <IonCol size="12" placeholder={undefined}>
              <IonButton
                color="primary"
                onClick={save} // Check if inputValue is empty or whitespace
                expand="block"
                disabled={inputValue?.trim() === ""} // Disable the button if inputValue is empty or whitespace
                placeholder={undefined}
              >
                {t("editprofile.save")}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonModal
        className="profilepage"
        isOpen={openModal}
        onDidDismiss={() => setOpenModal(false)}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
      >
        <IonRow placeholder={undefined}>
          <IonList className="list" placeholder={undefined}>
            <IonItem
              onClick={() => handleImageUpload(CameraSource.Camera)}
              placeholder={undefined}
            >
              <IonImg
                src="./assets/images/camerawhite.svg"
                alt=""
                placeholder={undefined}
              />
              <IonLabel placeholder={undefined}>
                {t("editprofile.drawer.take_photo")}
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => handleImageUpload(CameraSource.Photos)}
              placeholder={undefined}
            >
              <IonImg
                src="./assets/images/Chooseimage.svg"
                alt=""
                placeholder={undefined}
              />
              <IonLabel placeholder={undefined}>
                {t("editprofile.drawer.choose_from_library")}
              </IonLabel>
            </IonItem>
            <IonItem
              onClick={() => setOpenConfirm(true)}
              placeholder={undefined}
            >
              <IonImg
                src="./assets/images/Trash.svg"
                alt=""
                placeholder={undefined}
              />
              <IonLabel placeholder={undefined}>
                {t("editprofile.drawer.remove_current_picture")}
              </IonLabel>
            </IonItem>
          </IonList>
        </IonRow>
      </IonModal>
      <IonModal className="profilepopup" isOpen={openConfirm} onDidDismiss={() => setOpenConfirm(false)}>
        <div className="react-confirm-alert-body">
          <div className="close">
            <IonIcon
              onClick={() => setOpenConfirm(false)}
              icon={close}
              placeholder={undefined}
            />
          </div>
          <div className="icon">
            <IonImg
              src="./assets/images/bigTrash.svg"
              alt=""
              placeholder={undefined}
            />
          </div>
          <p>{t("editprofile.submit.title")}</p>
          <div className="react-confirm-alert-button-group">
            <IonButton
              onClick={() => setOpenConfirm(false)}
              className="alertcancel"
              placeholder={undefined}
            >
              {t("editprofile.submit.cancel")}
            </IonButton>
            <IonButton
              onClick={setRemoveImage}
              className="alertsubmit"
              placeholder={undefined}
            >
              {t("editprofile.submit.remove")}
            </IonButton>
          </div>
        </div>
      </IonModal>
    </IonPage>
  );
};
export default React.memo(Editprofile);
