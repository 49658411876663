import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonItem,
  IonImg,
  IonRow,
  IonCol,
  IonList,
  IonIcon,
  IonButton,
  IonLabel,
  IonContent,
  IonPage,
  IonTitle,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import "./Profile.scss";
import { chevronForward } from "ionicons/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RouteComponentProps, useHistory } from "react-router";
import { CONFIG } from "../App";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
interface OwnProps extends RouteComponentProps {}
interface ProfileProps extends OwnProps {}
const Profile: React.FC<ProfileProps> = () => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [present, dismiss] = useIonLoading();
  const [presenttost] = useIonToast();
  const [itemlist, setitems] = React.useState<any>([]);
  const [itemlevellist, setlevelitems] = React.useState<any>({});
  let token = "";
  let resfreshtoken = "";
  let userdata;
  let username;
  
  const imageData: any = localStorage.getItem("ProfileImageSrc");
  const imageSrc = JSON.parse(imageData);

  useIonViewWillEnter(() => {
    //setMenuEnabled(false);
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser?.JwtToken;
      resfreshtoken = currentUser?.RefreshToken;
    }
    GetProfie();
    GetLevels();
  });

  let logindata = JSON.parse(localStorage.getItem("logininfo") || "{}");
  const history = useHistory();
  if (logindata) {
    userdata = logindata?.Result;
    username = userdata?.UserName;
  }
  //conformation popup code
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <div className="icon">
              <IonImg src="./assets/images/bigsignout.svg" alt="" />
            </div>
            <p className="popuppadding">{t("profile.submit.msg")}</p>
            <div className="react-confirm-alert-button-group">
              <IonButton onClick={onClose} className="alertcancel">
                {t("profile.submit.cancel")}
              </IonButton>
              <IonButton
                onClick={() => {
                  // onSignout();
                  onClose();
                }}
                className="alertsubmit"
              >
                {t("profile.submit.sgn_out")}
              </IonButton>
            </div>
          </div>
        );
      },
    });
  };
  const GetProfie = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    try {
      const response = await fetch(url + "/User/GetProfile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        setitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const GetLevels = async () => {
    // present({
    //   message: "",
    //   cssClass: 'custom-spinner',
    //   duration: 1000,
    // });
    try {
      const response = await fetch(url + "/User/GetUserLevel", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "GET",
      });
      const json = await response.json();
      dismiss();
      if (json.StatusCode == 1) {
        setlevelitems(json.Result);
      } else if (json.StatusCode == 2) {
        refreshtoken();
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const refreshtoken = async () => {
    present({
      message: "",
      cssClass: "custom-spinner",
      // duration: 3000
    });
    const data = { refreshToken: resfreshtoken };
    try {
      const response = await fetch(url + "/account/refreshtoken", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      if (json.StatusCode == 1) {
        let alltoken = {
          JwtToken: json.Result.JwtToken,
          RefreshToken: json.Result.RefreshToken,
        };
        localStorage.setItem("alltoken", JSON.stringify(alltoken));
      } else {
        presenttost({
          message: json.Message,
          duration: 1500,
          position: "bottom",
        });
      }
      dismiss();
    } catch (error) {
      console.error(error);
      dismiss();
    }
  };
  const onSignout = () => {
    localStorage.clear();
    history.replace("/login", { direction: "none" });
  };
  const goToNext = (value) => {
    history.replace(
      { pathname: "/tabs/profile/" + value },
      { direction: "none" }
    );
  };
  return (
    <IonPage className="intro">
      <IonHeader className="ion-text-center">
        <IonToolbar>
          <IonTitle color="primary" className="font20">
            <span>{t("profile.my_pro")}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="listing spacingset">
        <IonRow className="edit ion-text-center">
          <IonCol size="12" class="pro">
            {/* {itemlist.ProfileUrl} */}
            <div className="logo">
              <IonImg
                src={
                  imageSrc !== null
                    ? imageSrc
                    : itemlist?.ProfileUrl
                    ? itemlist?.ProfileUrl
                    : "./assets/images/bang-circle-blue.svg"
                }
              />
            </div>
            <span>{t("profile.pro")}</span>
          </IonCol>
          <IonCol size="12" className="ion-text-center">
            <h4 className="no-margin">
              {itemlist?.Name ? itemlist.Name : userdata?.username}
            </h4>
            <h6 className="no-margin">
              {itemlist?.Email ? itemlist.Email : userdata?.Email}
            </h6>
          </IonCol>
          <IonCol size="12">
            <IonButton onClick={() => goToNext("editprofile")} shape="round">
              {t("profile.edt_pro")}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonList className="list prolist">
          <IonItem
            class="ion-no-padding"
            onClick={() => goToNext("currentlevel")}
          >
            <IonLabel>
              {t("profile.crnt_level")}
              <span className="sublevel">
                {t("profile.level")} {itemlevellist["Level"]}
              </span>
            </IonLabel>
            <IonIcon color="primary" icon={chevronForward} />
          </IonItem>
          {/* <IonItem class="ion-no-padding" onClick={() => goToNext("subscribe")}>
            <IonLabel>{t("profile.subscribe")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} />
          </IonItem>
          <IonItem
            class="ion-no-padding"
            onClick={() => goToNext("mysubscription")}
          >
            <IonLabel>{t("profile.mng_sub")}</IonLabel>
            <IonIcon color="primary" icon={chevronForward} />
          </IonItem>
          <IonItem onClick={submit} class="ion-no-padding pointer">
            <IonLabel>{t("profile.sgn_out")}</IonLabel>
            <IonIcon color="primary" icon={logOutOutline} />
            <IonImg src="./assets/images/Signout.svg" alt="" />
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Profile);
