import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonContent,
  IonList,
  IonCol,
  IonText,
  IonCard,
  IonCardContent,
  IonRow,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  useIonToast,
  IonSlides,
  IonSlide,
  IonImg,
  IonSpinner,
} from "@ionic/react";
import "./E5s.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CONFIG } from "../App";
import useMediaRecorder from "@wmik/use-media-recorder";
import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import "./i18n/i18n";
import CustomAudioPlayer from "./CustomAudioPlayer";

interface MyObject {
  id: number;
  name: string;
  type: "e5s";
  pagecouter: number;
  totalque: number;
  back: number;
}
interface E5sProps {
  itemE5s: any;
  onObjectChange: (object: MyObject) => void;
  lastSlide: any;
  cheChar: any;
  piniyin: any;
  isTranLangSim: any;
}

const E5s: React.FC<E5sProps> = (props: E5sProps) => {
  let url: string = CONFIG.API_ENDPOINT; //live
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [isApicall, setApicall] = useState(0);
  const [length, setLength] = React.useState(-1);
  //CompareSentences
  const [sentence1, setSentence1] = useState("");
  const [sentence2Array, setSentence2Array] = useState<string[]>([]);
  const [showImage, setShowImage] = useState(false);
  const [myanswer, setmyanswer] = useState("");
  const [isRecordingArray, setIsRecordingArray] = useState<boolean[]>([]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [ansIndex, setAnsSlideIndex] = useState(0);
  const [seconds, setSeconds] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [isSpinnerArray, setIsSpinnerArray] = useState<boolean[]>([]);
  const [answers, setAnswers] = React.useState<any[]>([]);
  const [backSlideIndex, setbackSlideIndex] = useState(-1);
  //end
  const [tmscorefinal, setTmscorefinal] = useState<any>([]);

  let token = "";
  let resfreshtoken = "";
  let pagecouter: any = 0;
  useIonViewWillEnter(() => {
    setLength(1);
    slidesRef.current?.getActiveIndex().then((index) => {
      // const JSONdata: any = localStorage.getItem("answers");
      // const data = JSON.parse(JSONdata);
      // Extract indices from dataAnswers and set them in clickedIndices
      // if (data?.E5s) {
      //   lssetAnswers(data?.E5s);
      // }
      if (props.itemE5s.length == index + 1) {
        // setLength(-1)
        // saveContent()
        // handleChange()
      }
      const JSONdata: any = localStorage.getItem("answers");
      const data = JSON.parse(JSONdata);
      // Extract indices from dataAnswers and set them in clickedIndices
      if (data?.E5s) {
        //  lssetAnswers(data?.E5s);
        data?.E5s?.forEach((item, index) => {
          const {
            sentenceOne,
            sentencePy,
            sentenceTwo,
            ansIndex: indexs,
          } = item;
          // setSentence2("");
          // setSentence2(sentenceTwo || "hello");
          setSentence2Array((prevState) => {
            const newArray = [...prevState];
            newArray[indexs] = sentenceTwo || "hello";
            return newArray;
          });
          // Check if the ansIndex matches the current slide index
          if (ansIndex === activeSlideIndex) {
            // Call the test function with the corresponding data
            test(sentenceOne, sentencePy, indexs);
          }
        });
      }
    });
    // setShowResults(1);
  });
  function handleChange(back) {
    const myObject: MyObject = {
      id: 1,
      name: "My Object",
      type: "e5s",
      pagecouter: pagecouter,
      totalque: props.itemE5s.length,
      back: back,
    };
    props.onObjectChange(myObject);
  }
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  const options = {
    keyboard: true,
  };
  const onDataAvailable = (data) => {
    // Update your progress bar using the data.size property
    const progress = setProgress((prevProgress) => prevProgress + data.size);
  };

  // const { startRecording, stopRecording } = useMediaRecorder({ onDataAvailable });

  let { error, status, mediaBlob, stopRecording, startRecording } =
    useMediaRecorder({
      recordScreen: false,
      blobOptions: { type: "audio/webm" },
      mediaStreamConstraints: { audio: true, video: false },
      onDataAvailable,
    });

  const handleStartRecording = (index) => {
    if (index === activeSlideIndex) {
      setIsRunning(true);
      setProgress(0);
      setApicall(0);
      // setIsRecording(true); // Set isRecording to true when recording starts
      // Update the specific index in the isRecordingArray
      setIsRecordingArray((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      startRecording();
    }
  };

  const handleStopRecording = (index) => {
    if (index === activeSlideIndex) {
      setIsRunning(false);
      stopRecording();
      //setIsRecording(false); // Set isRecording to false when recording stops
      // Update the specific index in the isRecordingArray
      setIsRecordingArray((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
      // setIsSpinner(true);
      setIsSpinnerArray((prevState) => {
        const newState = [...prevState];
        newState[index] = true; // Set isSpinner to true when recording stops
        return newState;
      });
    }
  };

  //dbaudio
  function handleCheckword() {
    const spanElement = document.querySelector(".dbaudio");

    if (spanElement) {
      if (spanElement.textContent) {
        // setSentence1('hello my testing')
        setSentence1(spanElement.textContent);
      }
    } else {
    }
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(",")[1]);
      };
      reader.onerror = () => {
        reject("Failed to convert blob to base64");
      };
    });
  }

  const convertaudiototext = async (audiobasebase64: any, index) => {
    // present({
    //   message: "",
    //   duration: 3000,
    // });
    let data = {
      langType: props.isTranLangSim ? "cn" : "tw",
      base64String: audiobasebase64,
    };

    try {
      //speechtotext
      //SpeechAudio
      const response = await fetch(url + "/account/speechtotext", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      const json = await response.json();

      setmyanswer(json.result);
      if (json.statusCode == 1) {
        setSentence2Array((prevState) => {
          const newArray = [...prevState];
          newArray[index] = json.result || "hello";
          return newArray;
        });
        // setSentence2("");
        // setSentence2(json.result || "hello");

        const data = {
          sentenceOne: props.itemE5s[ansIndex]?.WordCh,
          sentencePy: props.itemE5s[ansIndex]?.WordPy,
          sentenceTwo: json.result,
          ansIndex,
        };

        setAnswers((oldData) => [...oldData, data]);

        handleCheckword();
        // status = 'idle'
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: 'bottom'
        // });
      }
      setApicall(1);
      // dismiss();
    } catch (error) {
      console.error(error);
      // dismiss();
    }
  };

  useEffect(() => {
    if (answers.length > 0) {
      checkData(answers);
    }
  }, [answers]);

  const checkData = (answers) => {
    const JSONdata: any = localStorage.getItem("answers");
    const data = JSON.parse(JSONdata);
    const formattedData = {
      E1s: data?.E1s,
      E2s: data?.E2s,
      E3s: data?.E3s,
      E4s: data?.E4s,
      E5s: answers,
      E6s: data?.E6s,
    };
    localStorage.setItem("answers", JSON.stringify(formattedData));
    // setDataAnswers(formattedData);
  };

  useEffect(() => {
    if (mediaBlob && activeSlideIndex === ansIndex) {
      // present({
      //   message: "",
      //   duration: 3000,
      // });
      //convertaudiototextlive(mediaBlob)
      blobToBase64(mediaBlob)
        .then((base64String) => {
          if (status === "stopped" && isApicall == 0) {
            convertaudiototext(base64String, ansIndex);
            // dismiss();
          }
          // dismiss();
        })
        .catch((error) => {
          console.error(error);
          // dismiss();
        });
      // dismiss();
    }
  }, [mediaBlob, activeSlideIndex]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowImage(true);
      // Calculate the index of the next slide
      if (mediaBlob === null && sentence2Array[ansIndex] === null) {
        const nextSlideIndex = activeSlideIndex + 1;
        // setActiveSlideIndex(nextSlideIndex);
      }
      // Call the slideTo method to swipe to the next slide
      // slidesRef.current?.slideTo(nextSlideIndex);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSlideIndex, props.itemE5s.length]);

  const handleSlideNextEnded = (event: CustomEvent<any>) => {
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index + 1);
    });
  };

  const handleSlidePrevEnded = (event: CustomEvent<any>) => {
    // setLength(length - 1)
    slidesRef.current?.getActiveIndex().then((index) => {
      setLength(index - 1);
    });
  };

  const handleSlideDidChanged = async (event: CustomEvent<any>) => {
    const swiper = await slidesRef.current?.getSwiper();
    if (swiper) {
      const swipeDirection = swiper.swipeDirection;
      const currentIndex = swiper.activeIndex;
      setActiveSlideIndex(currentIndex);

      if (swipeDirection == "prev") {
        //Back Login code
        setAnsSlideIndex(currentIndex);
        if (currentIndex === 0) {
          setbackSlideIndex(-1);
        }
        if (backSlideIndex === -1) {
          handleChange(1);
        }
        // slidesRef.current?.getActiveIndex().then((index) => {
        //   if (index - 1 === -1) {
        //     handleChange(1);
        //   }
        // });
      } else {
        setAnsSlideIndex(currentIndex);
        setbackSlideIndex(0);
        slidesRef.current?.getActiveIndex().then((index) => {
          SaveExerciseQueAnswer(length);
          if (index + 1 === length) {
            setLength(-1);
            saveContent();
            handleChange(0);
          }
        });
      }
    }
  };

  const SaveExerciseQueAnswer = async (length) => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    const spanElement = document.querySelector(".dbaudio");

    let ans = {
      srcCompare: srcText ? srcText : "",
      destCompare: sentence2Array[ansIndex],
    };

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    const data = {
      eId: props.itemE5s[ansIndex].EId,
      eType: props.itemE5s[ansIndex].TemplateCode,
      answer: myanswer,
      rearrageAnswer: "", //only for rearrange exercise otherwise blank
      writingScore: 0,
    };

    try {
      const response = await fetch(url + "/Book/SaveExerciseQueAnswer", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();

      if (json.StatusCode == 1) {
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const wordSplitter = (word) => {
    let words_tc = word.split(
      /(@\[\d+\]@)|(?![，。;：””''！？）、、。：」？！……；、——‘’\/，。：』〞？！……;、——])|(?=[（『「〝“])/g
    );
    let combinedArray: any[] = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_tc.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "“"];
    for (let i = 0; i < charactersArray.length; i++) {
      if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    words_tc = combinedArray
      .filter((item) => !spaceRemove.includes(item))
      .map((item) => item.replace(/@\[|\]@/g, ""));
    return words_tc;
  };

  const pySplitter = (word) => {
    let words_py = word.split(/\s+/);
    let combinedArray: any = [];
    const removeEmpty = [undefined, null];
    let charactersArray = words_py.filter(
      (item) => !removeEmpty.includes(item)
    );
    const beforeBracket = ["「", "〝", "『", "（", "["];
    const spChar = [
      "/",
      "！",
      "?",
      "!",
      "，",
      "。",
      ";",
      "：",
      "”",
      "'",
      "'",
      "？",
      "）",
      "、",
      "]",
      ")",
    ];
    for (let i = 0; i < charactersArray.length; i++) {
      if (charactersArray[i].startsWith("@[")) {
        let mergedString = charactersArray[i].replace(/@\[|\]@/g, "");
        for (i++; i < charactersArray.length; i++) {
          mergedString += " " + charactersArray[i];
          if (charactersArray[i].endsWith("]@")) {
            mergedString = mergedString.replace(/@\[|\]@/g, "");
            break;
          }
        }
        combinedArray.push(mergedString);
      } else if (spChar.includes(charactersArray[i]) && i > 0) {
        combinedArray[combinedArray.length - 1] += charactersArray[i];
      } else if (
        beforeBracket.includes(charactersArray[i]) &&
        i < charactersArray.length - 1
      ) {
        combinedArray.push(charactersArray[i] + charactersArray[i + 1]);
        i++; // Skip the next character as it has already been combined
      } else {
        combinedArray.push(charactersArray[i]);
      }
    }
    const spaceRemove = ["", " ", undefined, null];
    let arrayData = combinedArray.filter((item) => !spaceRemove.includes(item));
    // Add the following code to apply modifications to words_py after filter operation
    words_py = arrayData.map((item) =>
      item.includes("?") ? item.replace("?", " ?") : item
    );
    return words_py;
  };

  let srcText = "";
  const test_main = (text, textpy) => {
    srcText = text;
    const characters1 = wordSplitter(text);
    const charPy = pySplitter(textpy);

    const result = characters1?.map((text, i) => ({
      Text: text,
      Piniyan: charPy[i] || null,
    }));

    const fragments = result?.map((word, index) => (
      <React.Fragment key={index}>
        <li className={""}>
          <span className={"ion-text-center dbaudio"} id="top-center">
            {props.cheChar && word["Text"]}
          </span>
          <p>{props.piniyin && word["Piniyan"]}</p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  const test = (text, textpy, index) => {
    const spChar = [
      "！",
      "(",
      "?",
      "!",
      "[",
      "，",
      "。",
      ";",
      "：",
      "”",
      "”",
      "'",
      "'",
      "？",
      "（",
      "）",
      "、",
      "]",
      ")",
    ];

    const characters1 = text
      ?.split("")
      .filter((item) => !spChar.includes(item) && item !== " ");

    const charactersShow = wordSplitter(text);
    const text2 = sentence2Array[index];
    const characters2 = text2?.split("");
    const charPy = pySplitter(textpy);

    const result = characters1?.map((text, i) => ({
      Text: charactersShow[i],
      Piniyan: charPy[i] || null,
      status: spChar.includes(text) ? true : characters2?.includes(text),
    }));

    // score count start

    const filteredChar1 = characters1?.filter((char) => !spChar.includes(char));
    const charLength: number = filteredChar1?.length;
    const charValue: number = 100 / charLength;
    const rouCharValue: number = parseFloat(charValue.toFixed(0));

    const filteredResult = filteredChar1?.map((text, i) => ({
      Text: text,
      status: characters2?.includes(text),
    }));
    const trueStatusLength: number = filteredResult?.filter(
      (item) => item.status === true
    ).length;

    const score: number = trueStatusLength * rouCharValue;

    // tmscorefinal.push({ [`q${index}`]: score });

    updateTmscorefinal(index, score);
    // score count end

    // dismiss();
    const fragments = result?.map((word, index) => (
      <React.Fragment key={index}>
        <li className={""}>
          <span
            className={
              word["status"] === false
                ? "ion-text-center red"
                : "ion-text-center"
            }
            id="top-center"
          >
            {props.cheChar && word["Text"]}
          </span>
          <p className={word["status"] === false ? "wordpy red" : "wordpy"}>
            {props.piniyin && word["Piniyan"]}
          </p>
        </li>{" "}
      </React.Fragment>
    ));
    return fragments;
  };

  let averageScore: number;

  // Function to update tmscorefinal
  const updateTmscorefinal = (index, score) => {
    // Check if the index already exists in tmscorefinal
    const existingIndex = tmscorefinal.findIndex(
      (item) => Object.keys(item)[0] === `q${index}`
    );

    // If the index does not exist, update tmscorefinal with the new score
    if (existingIndex === -1) {
      setTmscorefinal((prevState) => [...prevState, { [`q${index}`]: score }]);
    }

    // Calculate average score
    const fullValue = props.itemE5s.length;
    let totalValue = 0;

    // Loop through tmscorefinal and calculate totalValue
    for (const item of tmscorefinal) {
      const key = Object.keys(item)[0];
      totalValue += item[key];
    }

    // Calculate average if fullValue is not 0
    let value = fullValue !== 0 ? totalValue / fullValue : 0;
    averageScore = parseFloat(value.toFixed(0))
  };

  const saveContent = async () => {
    let currentUser = JSON.parse(localStorage.getItem("alltoken") || "{}");
    if (currentUser) {
      token = currentUser.JwtToken;
      resfreshtoken = currentUser.RefreshToken;
    }

    pagecouter = 0;

    let ans = {
      srcCompare: srcText ? srcText : "",
      destCompare: sentence2Array[ansIndex],
    };

    const stringWithBackslashes = JSON.stringify(ans);
    const stringWithoutBackslashes = stringWithBackslashes.replace(/\\/g, "");
    const myArray = Object.entries(ans);
    // for (let k = 0; k < myArray.length; k++) {
    //   const element = myArray[k][1];
    //   if (element !='') {
    //     pagecouter++
    //   }

    // }

    if (myArray[0][1]) {
      pagecouter = 1;
    }

    const data = {
      contentPageCode: props.itemE5s[0]
        ? props.itemE5s[0].TemplateCode
        : props.itemE5s[length - 1].TemplateCode,
      type: "exercise-score",
      bookId: props.itemE5s[0].BookId,
      contentPageId: props.itemE5s[0]
        ? props.itemE5s[0].PageId
        : props.itemE5s[length - 1].PageId,
      answer: stringWithoutBackslashes,
      score: averageScore,
      options: "",
      pageRead: pagecouter,
    };
    console.log("data: ", data);

    //dismiss()

    try {
      const response = await fetch(url + "/Book/SaveContent", {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "POST",
      });

      const json = await response.json();
      //dismiss()

      if (json.StatusCode == 1) {
      } else {
        // presenttost({
        //   message: json.Message,
        //   duration: 1500,
        //   position: "bottom",
        // });
      }

      // dismiss()
    } catch (error) {
      console.error(error);
      // dismiss()
    }
  };

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds < 60) {
          setSeconds((prevSeconds) => prevSeconds + 1);
        } else {
          clearInterval(timer);
          setIsRunning(false);
          handleStopRecording(ansIndex);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, seconds]);

  const percentage = (seconds / 60) * 100;

  return (
    <IonPage className="intro">
      <IonContent className="listing">
        <IonSlides
          pager={false}
          scrollbar={true}
          options={{
            initialSlide: props.lastSlide === 1 ? props.itemE5s.length - 1 : "",
            options,
          }}
          onIonSlideTransitionEnd={handleSlideDidChanged}
          onIonSlideNextEnd={handleSlideNextEnded}
          onIonSlidePrevEnd={handleSlidePrevEnded}
          ref={slidesRef}
        >
          {props.itemE5s.map((items, index) => {
            return (
              <IonSlide key={index} className="audiorecord">
                <IonList className="ion-no-padding bottomfix record">
                  <IonRow className="Practice spacing ion-padding-horizontal">
                    <IonCol>
                      <IonText>{t("e5s.record_sentence")}</IonText>
                    </IonCol>
                    <IonCol
                      size="12"
                      className="test-audio ion-text-left whitebg"
                    >
                      <div className="customaudioplayer">
                        <CustomAudioPlayer src={items?.AudioUrl} />
                      </div>
                      {/* <ReactAudioPlayer className="String" controls controlsList="nodownload noplaybackrate">
                              <source src={itemse5?.AudioUrl} type="audio/mpeg"></source>
                            </ReactAudioPlayer> */}
                    </IonCol>
                  </IonRow>
                  <IonRow className="answer spacing ion-padding-horizontal">
                    <IonCol size="12">
                      <IonCard className="select">
                        <IonCardContent className="Lessonpracticeaudio">
                          <ul className="lession-icon">
                            {!sentence2Array[index]
                              ? test_main(
                                  props.isTranLangSim
                                    ? items.WordSc
                                    : items.WordCh,
                                  items.WordPy
                                )
                              : ""}

                            {sentence2Array[index]
                              ? test(
                                  props.isTranLangSim
                                    ? items.WordSc
                                    : items.WordCh,
                                  items.WordPy,
                                  index
                                )
                              : ""}
                          </ul>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonList>
                <IonList className="ion-text-center audio-bottom ion-padding-horizontal">
                  <IonRow>
                    {/* <IonCol size="12">
                            {showImage && (
                              <IonImg
                                className="slide"
                                src="./assets/images/swipe-left-right.gif"/>
                            )}
                          </IonCol> */}
                    <IonCol size="12" className="back-forward">
                      <div className="audio-record">
                        {!sentence2Array[index] ? (
                          <>
                            {!isSpinnerArray[index] ? (
                              <>
                                <span
                                  onClick={(e: any) => {
                                    isRecordingArray[index]
                                      ? handleStopRecording(index)
                                      : handleStartRecording(index);
                                  }}
                                >
                                  <IonImg
                                    src={`./assets/images/${
                                      isRecordingArray[index]
                                        ? "stop"
                                        : "micCircle"
                                    }.svg`}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "0",
                                      right: "0",
                                      top: "-15px",
                                      margin: "0 auto",
                                      width: "150px",
                                    }}
                                  >
                                    {isRecordingArray[index] ? (
                                      <CircularProgressbar
                                        value={percentage}
                                        // text={`${seconds}s`}
                                        styles={buildStyles({
                                          pathTransition: "none",
                                          // textSize: '16px',
                                          // textColor: '#fff',
                                          trailColor:
                                            "var(--ion-color-warning-contrast-rgb)",
                                          pathColor: "var(--ion-color-warning)",
                                        })}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </span>
                                <p>{t("e5s.press_to_record")}</p>
                              </>
                            ) : (
                              <IonSpinner
                                name="crescent"
                                style={{
                                  height: "52px",
                                  width: "52px",
                                  color: "var(--ion-color-warning)",
                                }}
                              />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </IonCol>
                    {/* <IonCol size="12">
                            <IonButton expand="block" onClick={() => gotonextques(itemse5, showResults + 1, 1)}>Next</IonButton>
                          </IonCol> */}
                  </IonRow>
                </IonList>
              </IonSlide>
            );
          })}
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(E5s);
